import { useCallback } from 'react';
import { Rubric } from '../models/Channel';
import { Question } from '../models/Question';
import { ContainerRubric } from '../models/requests/AssignQuestionsToContainerRequest';
import { RubricsSummaryData } from '../pages/QuestionContainers/EditQuestionsContainer/ContainerData/RubricsSummary';

const useSortQuestionsInContainer = () => {
  function reorderQuestionsBasedOnRubricsSubRubrics(questions: Question[], rubricsSummaryData: RubricsSummaryData[]) {
    let rubricsQuestionsMap = new Map<string, Map<string, Question[]>>();

    rubricsSummaryData.forEach((rubricSection) => {
      rubricsQuestionsMap.set(rubricSection.rubricId, new Map<string, Question[]>());

      rubricSection.children.forEach((subRubric) => {
        rubricsQuestionsMap.get(rubricSection.rubricId)!.set(subRubric.subRubricId, []);
      });
    });

    questions.forEach((question) => {
      if (!question.containerInfo) return;

      const storedQuestions = rubricsQuestionsMap
        .get(question.containerInfo.containerRubricId)
        ?.get(question.containerInfo.containerSubRubricId);

      if (storedQuestions) {
        rubricsQuestionsMap
          .get(question.containerInfo.containerRubricId)
          ?.set(question.containerInfo.containerSubRubricId, [...storedQuestions, question]);
      } else {
        rubricsQuestionsMap
          .get(question.containerInfo.containerRubricId)
          ?.set(question.containerInfo.containerSubRubricId, [question]);
      }
    });

    const sortedQuestions = Array.from(rubricsQuestionsMap.values(), (item) => Array.from(item.values()).flat()).flat();

    return sortedQuestions;
  }

  const reorderQuestionsBasedOnQuestionIds = useCallback((questions: Question[], questionIds: string[]) => {
    const sortedQuestions = questions.sort((a, b) => questionIds.indexOf(a.id) - questionIds.indexOf(b.id));

    return sortedQuestions;
  }, []);

  const groupQuestionsByRubrics = useCallback((questions: Question[]) => {
    let rubricsQuestionsMap = new Map<string, Map<string, Question[]>>();

    questions.forEach((question) => {
      const { rubricId, subRubricId } = question.containerInfo
        ? {
            rubricId: question.containerInfo.containerRubricId,
            subRubricId: question.containerInfo.containerSubRubricId,
          }
        : { rubricId: question.rubricId, subRubricId: question.subRubricId };

      const storedRubric = rubricsQuestionsMap.get(rubricId);
      const storedQuestions = rubricsQuestionsMap.get(rubricId)?.get(subRubricId);

      if (storedQuestions) {
        rubricsQuestionsMap.get(rubricId)?.set(subRubricId, [...storedQuestions, question]);
      } else if (storedRubric) {
        rubricsQuestionsMap.get(rubricId)?.set(subRubricId, [question]);
      } else {
        rubricsQuestionsMap.set(rubricId, new Map([[subRubricId, [question]]]));
      }
    });

    return rubricsQuestionsMap;
  }, []);

  const fromGroupedQuestionsToQuestions = useCallback((groupedQuestions: Map<string, Map<string, Question[]>>) => {
    return Array.from(groupedQuestions.values(), (item) => Array.from(item.values()).flat()).flat();
  }, []);

  const fromGroupedQuestionsToRubricsSummaryData = useCallback(
    (groupedQuestions: Map<string, Map<string, Question[]>>, allRubrics: Rubric[]) => {
      const summaryData: RubricsSummaryData[] = [];

      groupedQuestions.forEach((subRubricQuestionsMap, rubricId) => {
        const rubric = allRubrics.find((rubric) => rubric.rubricId === rubricId);

        let subRubricsChildren: {
          subRubricName: string;
          subRubricId: string;
          questionsNumberInSubRubric: number;
        }[] = [];

        subRubricQuestionsMap.forEach((questions, subRubricId) => {
          const subRubricName = rubric?.subRubrics.find((subRubric) => subRubric.subRubricId === subRubricId)
            ?.subRubricName!;

          subRubricsChildren.push({
            subRubricId: subRubricId,
            subRubricName: subRubricName,
            questionsNumberInSubRubric: questions.length,
          });
        });

        summaryData.push({
          rubricName: rubric!.rubricName,
          rubricId: rubric!.rubricId,
          fiscal: rubric!.isFiscal,
          questionsNumber: subRubricsChildren.reduce(
            (num, subRubricsChild) => num + subRubricsChild.questionsNumberInSubRubric,
            0,
          ),
          children: subRubricsChildren,
        });
      });

      return summaryData;
    },
    [],
  );

  const fromQuestionsToContainerRubrics = useCallback((questions: Question[]): ContainerRubric[] => {
    return questions.map((question) => ({
      questionId: question.id,
      rubricId: question.containerInfo?.containerRubricId || question.rubricId,
      subRubricId: question.containerInfo?.containerSubRubricId || question.subRubricId,
    }));
  }, []);

  const sortedQuestionsIdsWithSequence = (questions: Question[]): [string, number][] => {
    return questions.map((question, index) => [question.id, index]);
  };

  return {
    reorderQuestionsBasedOnRubricsSubRubrics,
    reorderQuestionsBasedOnQuestionIds,
    groupQuestionsByRubrics,
    sortedQuestionsIdsWithSequence,
    fromGroupedQuestionsToQuestions,
    fromGroupedQuestionsToRubricsSummaryData,
    fromQuestionsToContainerRubrics,
  };
};

export default useSortQuestionsInContainer;
