import React, { useCallback, useEffect, useState } from 'react';
import { Question } from '../../../../models/Question';
import QuestionsContainerService from '../../../../services/QuestionsContainerService';

interface ContainerColumnProps {
  question: Question;
  containerService: QuestionsContainerService | undefined;
}

interface containersList {
  text: string;
  id: string;
}

const ContainersColumn: React.FC<ContainerColumnProps> = (props) => {
  const [containersList, setContainerList] = useState<containersList[]>([]);

  const getContainersByQuestionId = useCallback(async () => {
    const containers = await props.containerService!.getQuestionsContainersByQuestionId(props.question.id);

    const containersDataToShow = containers.map((container) => {
      return {
        text: `#${container.issueNumber} - ${container.title}`,
        id: container.containerId,
      };
    });

    setContainerList(containersDataToShow);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerService, props.question.id, props.question.status, props.question.blockedUntil]);

  useEffect(() => {
    getContainersByQuestionId();
  }, [getContainersByQuestionId]);

  return (
    <ul>
      {containersList.map((container) => (
        <li key={container.id}>{container.text}</li>
      ))}
    </ul>
  );
};

export default ContainersColumn;
