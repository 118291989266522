import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Dropdown, Space } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import Constants from '../Constants';

interface Props {
  setDateFilters: (filters: any[] | null, filterName: string) => void;
  filterName: string;
}

type DataRange = {
  from: string;
  to: string;
};

const DateFilterSelect: React.FC<Props> = ({ setDateFilters, filterName }) => {
  const [filtered, setFiltered] = useState(false);
  const [dateRange, setDateRange] = useState<DataRange>({ from: '', to: '' });

  const handleSearch = () => {
    setFiltered(true);
    setDateFilters([dateRange.from, dateRange.to], filterName);
  };

  const handleReset = () => {
    setDateRange({ from: '', to: '' });
    setDateFilters(null, filterName);
    setFiltered(false);
  };

  const DateFilterSelect = (
    <Card style={{ padding: 8 }}>
      <div style={{ marginBottom: 8, display: 'block' }} onClick={(e) => e.stopPropagation()}>
        <DatePicker
          format={Constants.FRONTEND_DATE_FORMAT}
          onChange={(dateMoment) => {
            setDateRange({
              from: dateMoment ? dateMoment.startOf('day').format(Constants.BACKEND_DATETIME_FORMAT) : '',
              to: dateRange.to,
            });
          }}
          value={dateRange.from ? moment(dateRange.from, Constants.BACKEND_DATETIME_FORMAT) : null}
          allowClear={false}
          placeholder={'Dalla data'}
        />
        <DatePicker
          format={Constants.FRONTEND_DATE_FORMAT}
          onChange={(dateMoment) => {
            setDateRange({
              to: dateMoment ? dateMoment.endOf('day').format(Constants.BACKEND_DATETIME_FORMAT) : '',
              from: dateRange.from,
            });
          }}
          value={dateRange.to ? moment(dateRange.to, Constants.BACKEND_DATETIME_FORMAT) : null}
          allowClear={false}
          placeholder={'Alla data'}
        />
      </div>
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            handleReset();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button type="link" size="small">
          Close
        </Button>
      </Space>
    </Card>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown overlay={DateFilterSelect} trigger={['click']}>
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      </Dropdown>
    </div>
  );
};

export default DateFilterSelect;
