import React from 'react';
import { PublicationDetails, QuestionDetail } from '../../../models/Question';
import { Table } from 'antd';
import DateUtils from '../../../utils/DateUtils';

import './PublishingFormItem.css';
interface Props {
  question: QuestionDetail;
  UnpublishButton?: React.FC;
  RemoveOutputChannelButton?: React.FC<{ outputChannelId: string }>;
}

const PublishingFormItem: React.FC<Props> = ({ question, UnpublishButton, RemoveOutputChannelButton }) => {
  const columns = [
    {
      title: 'Canale di pubblicazione',
      dataIndex: 'outputChannelName',
      key: 'outputChannel',
    },
    {
      title: 'Fascicolo',
      dataIndex: 'containerName',
      key: 'container',
    },
    {
      title: 'Publisher',
      dataIndex: 'publisherName',
      key: 'publisher',
    },
    {
      title: 'Data di pubblicazione',
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (publicationDate: Date) => <span>{DateUtils.format(publicationDate)}</span>,
    },
    {
      ...(RemoveOutputChannelButton && {
        title: 'Action',
        key: 'action',
        render: (publicationDetails: PublicationDetails) => (
          <RemoveOutputChannelButton outputChannelId={publicationDetails.outputChannel} />
        ),
      }),
    },
  ];

  const publicationDetails = question.publicationDetails ?? [];

  return (
    <div data-testid="publishing-output-channel-form-item" className="publishing-output-channel-form-item">
      {UnpublishButton && (
        <div className="unpublish-button">
          <UnpublishButton />
        </div>
      )}
      <Table pagination={false} columns={columns} dataSource={publicationDetails} />
    </div>
  );
};

export default PublishingFormItem;
