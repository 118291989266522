import React, { useState } from 'react';
import { Modal, Button, Input, Rate } from 'antd';

const { TextArea } = Input;

interface ModalGenAIProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (feedback: string, rating: number, isFromPropose?: boolean) => void;
  isModalGenAIFromPropose: boolean;
}

const ModalGenAI: React.FC<ModalGenAIProps> = ({ visible, onCancel, onSubmit, isModalGenAIFromPropose = false }) => {
  const [feedback, setFeedback] = useState<string>('');
  const [rating, setRating] = useState<number>(-1);
  const [showError, setShowError] = useState<boolean>(false);

  const handleSubmit = () => {
    if (feedback.trim().length < 20) {
      setShowError(true);
    } else {
      if (isModalGenAIFromPropose) {
        onSubmit(feedback, rating, true);
      } else {
        onSubmit(feedback, rating);
      }
      setFeedback('');
      setRating(-1);
      setShowError(false);
      onCancel();
    }
  };

  return (
    <Modal
      visible={visible}
      title="Feedback della risposta generata da GenAI"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Chiudi
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {isModalGenAIFromPropose ? 'Salva & Invia risposta' : 'Invia & Salva'}
        </Button>,
      ]}
    >
      <div>
        <label>Feedback: come ti è sembrato il servizio?*</label>
        <TextArea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Inserisci qui il tuo feedback"
          autoSize={{ minRows: 3, maxRows: 5 }}
          required
        />
        {showError && feedback.trim().length < 20 && (
          <div style={{ color: 'red', marginTop: '0.5rem' }}>Il feedback deve essere di almeno 20 caratteri.</div>
        )}
      </div>
      <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column' }}>
        <label>Voto</label>
        <Rate value={rating} onChange={(value) => setRating(value)} count={5} />
      </div>
    </Modal>
  );
};

export default ModalGenAI;
