import ApiGateway from './ApiGateway';

const GET_EXPERT_PER_CHANNEL = '/payments/get-period';
const UPDATE_ANSWER_PAYABILITY = '/payments/update-answer-state';
const SEND_PAYMENTS = '/payments/send-payments';

export type PaymentDetail = {
  questionId: string;
  questionText: string;
  editorSubject: string;
  answerId: string;
  answerDate: Date;
  acceptedDate: Date;
  deadlineDate?: Date;
  payable: boolean;
  typology: 'FREE' | 'PAY';
};

export type PaymentExpertRow = {
  channel: string;
  fiscalCode: string;
  name: string;
  surname: string;
  freeQuestionsAnswersCounted: number;
  freeQuestionsAnswersToBePaid: number;
  payQuestionsAnswersComputed: number;
  payQuestionsAnswersToBePaid: number;
  payQuestionsAnswersGivenLate: number;
  details: PaymentDetail[];
};

export type MonthlyPaymentsFilters = {
  channel?: string;
  month: number;
  year: number;
};

export type ExpertPerChannelResponse = {
  experts: PaymentExpertRow[];
  fullCount: number;
};

export type UpdateAswserPayabilityPayload = {
  year: number;
  month: number;
  channel: string;
  answerId: string;
  payable: boolean;
};

const fetchExpertPerChannel = async (filters: MonthlyPaymentsFilters): Promise<ExpertPerChannelResponse> => {
  try {
    const response = await ApiGateway.post(GET_EXPERT_PER_CHANNEL, filters);
    return response.data;
  } catch {
    throw new Error('Impossibile recuperare i dati');
  }
};

const updateAswserPayability = async (payload: UpdateAswserPayabilityPayload): Promise<void> => {
  try {
    await ApiGateway.post(UPDATE_ANSWER_PAYABILITY, payload);
  } catch {
    throw new Error(
      `Impossibile aggiornare lo stato della risposta ${payload.answerId} per il canale ${payload.channel}`,
    );
  }
};

const sendMonthlyPayments = async (filters: MonthlyPaymentsFilters): Promise<void> => {
  try {
    await ApiGateway.post(SEND_PAYMENTS, filters);
  } catch {
    throw new Error('Impossibile inviare i pagamenti');
  }
};

const GestionePagheService = {
  fetchExpertPerChannel,
  updateAswserPayability,
  sendMonthlyPayments,
};

export default GestionePagheService;
