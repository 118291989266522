import { Button, Form, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import { useState } from 'react';
import { QuestionDetail } from '../../../../models/Question';
import QuestionService from '../../../../services/QuestionService';

type DefinitiveRefuseModalProps = {
  question: QuestionDetail;
  visible: boolean;
  questionService: QuestionService;
  onOk: () => void;
  onCancel: () => void;
};
export const DefinitiveRefuseModal: React.FC<DefinitiveRefuseModalProps> = (props: DefinitiveRefuseModalProps) => {
  const [definitiveRefuseForm] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onDefinitiveRefuse = async (form: any) => {
    try {
      setLoading(true);
      await definitiveRefuseForm.validateFields();
      const reason = definitiveRefuseForm.getFieldValue('definitiveRefuseReason');
      await props.questionService.definitiveRefuseQuestion(props.question.id, reason);
      message.success('Quesito rifiutato definitivamente con successo');
      props.onOk();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  const getFooterButtons = () => {
    return [
      <Button key="back" onClick={onCancel} loading={loading}>
        Annulla
      </Button>,
      <Button
        key="proposeAnswer"
        type="primary"
        htmlType="submit"
        onClick={onDefinitiveRefuse}
        loading={loading}
        data-testid="question-modal-definite-refuse-button"
      >
        Invia Rifiuto
      </Button>,
    ];
  };

  return (
    <>
      <Modal
        width={800}
        title={`Stai rifiutando definitivamente il quesito: ${props.question.id}`}
        data-testid="definitive-refuse-modal"
        visible={props.visible}
        onCancel={onCancel}
        centered
        destroyOnClose={false}
        getContainer={false}
        forceRender
        footer={getFooterButtons()}
      >
        <Form form={definitiveRefuseForm} name="definitive-refuse-modal-form" layout="vertical">
          <Form.Item
            name="definitiveRefuseReason"
            label="Motivo di rifiuto del quesito"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Inserisci la ragione del rifiuto',
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
