import { Button, Dropdown, Menu } from 'antd';
import React, { ReactNode } from 'react';
import CurrentUser from '../../models/CurrentUser';
import { Question } from '../../models/Question';
import { Channel } from '../../models/Channel';
import UserAssignableToQuestion from '../../models/UserAssignableToQuestion';
import AuthorizationHelper from '../../utils/AuthorizationHelper';
import QuestionStatus from '../../utils/QuestionStatus';
import PopConfirmMenuItem from '../QuestionModal/components/PopconfirmActionMenuItem';
import MenuActions from './MenuActions';

interface Props {
  questions: Question[];
  currentUser: CurrentUser;
  assignedTo: UserAssignableToQuestion | undefined;
  isWorking: boolean;
  setIsWorking: (working: boolean) => void;
  setIsDefinitiveRefuseModalOpen?: (open: boolean) => void;
  setIsAskForInfoModalOpen?: (open: boolean) => void;
  actionOnConfirm: (action: () => Promise<any>, successMessage?: string, errorMessage?: string) => Promise<void>;
  menuActions: MenuActions;
  label: ReactNode;
  allChannelsInfo?: Channel[];
}

const QuestionActions: React.FC<Props> = ({
  questions,
  currentUser,
  assignedTo,
  isWorking,
  setIsWorking,
  setIsDefinitiveRefuseModalOpen,
  setIsAskForInfoModalOpen,
  actionOnConfirm,
  menuActions,
  label,
  allChannelsInfo,
}) => {
  if (!questions.length) return null;

  const questionIsAssignedToCurrentUser = (): boolean => {
    return (
      questions.every((question) => AuthorizationHelper.questionIsAssignedToCurrentUser(currentUser, question)) &&
      !assignedToIsChanged()
    );
  };

  const questionIsDefinitivelyRefusable = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      setIsDefinitiveRefuseModalOpen !== undefined &&
      AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, questions[0]) &&
      ['ING', 'ASS', 'PROG', 'INFO'].includes(questions[0].status)
    );
  };

  const assignedToIsChanged = (): boolean => {
    if (assignedTo === undefined || questions.length !== 1) return false;
    return questions[0].assignedTo?.id !== assignedTo?.id;
  };

  const questionCanBeDiscarded = (): boolean => {
    return questions.every(
      (question) =>
        AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, question) &&
        ['ING', 'ASS', 'PROG', 'INFO', 'ANS', 'ACE', 'READY'].includes(question.status),
    );
  };

  const questionCanReturnToAssignedStatus = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      questions[0].status === 'INFO' &&
      (currentUser.isAdministrator() ||
        currentUser.isSuperAdministrator() ||
        currentUser.isEditorOrSuperEditorOnChannel(questions[0].channelId))
    );
  };

  const answerCanBeProposed = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      menuActions.PROPOSE_ANSWER !== undefined &&
      AuthorizationHelper.answerCanBeProposedByUser(currentUser, questions[0]) &&
      !assignedToIsChanged()
    );
  };

  const canEditClassifications = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      menuActions.RECLASSIFY_QUESTION !== undefined &&
      AuthorizationHelper.canEditClassifications(currentUser, questions[0])
    );
  };

  const hasClassification = (): boolean => {
    return questions.every(
      (question) => question.classificationsCount !== undefined && question.classificationsCount > 0,
    );
  };

  const answerCanBeAccepted = (): boolean => {
    return questions.every((question) => AuthorizationHelper.answerCanBeAcceptedByUser(currentUser, question));
  };

  const answerCanBeMovedToReady = (): boolean => {
    return questions.every(
      (question) =>
        question.status === QuestionStatus.ACE &&
        hasClassification() &&
        AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, question),
    );
  };

  const canAskForInformation = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      setIsAskForInfoModalOpen !== undefined &&
      (questionIsAssignedToCurrentUser() ||
        (questions[0].status === 'ASS' &&
          AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, questions[0])))
    );
  };

  const questionCanBeUnlocked = (): boolean => {
    return questions.every(
      (question) =>
        (question?.status === QuestionStatus.NOTIFIED ||
          (question?.status === QuestionStatus.PUBLISHED && false === question.isInContainer)) &&
        question.isBlockedForPublication() &&
        AuthorizationHelper.isAdministratorOrHigher(currentUser),
    );
  };

  const questionCanBePublished = (): boolean => {
    return questions.every(
      (question) =>
        menuActions.PUBLISH !== undefined &&
        AuthorizationHelper.questionCanBePublishedByUser(currentUser, question) &&
        !question.isBlockedForPublication(),
    );
  };

  const questionCanBeAskedForValidation = (): boolean => {
    return questions.every(
      (question) =>
        !question.isBlockedForPublication() &&
        AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, question) &&
        [QuestionStatus.NOTIFIED, QuestionStatus.PUBLISHED, QuestionStatus.UNPUB].includes(question.status),
    );
  };

  const questionCanBeRepublished = (): boolean => {
    if (questions.length !== 1) return false;
    return (
      menuActions.SAVE_AND_REPUBLISH !== undefined &&
      AuthorizationHelper.questionCanBeRePublishedByUser(currentUser, questions[0]) &&
      !questions[0].isBlockedForPublication()
    );
  };

  const questionCanBeAssignedToContainer = (): boolean => {
    return questions.every(
      (question) =>
        menuActions.ASSIGN_TO_CONTAINER !== undefined &&
        AuthorizationHelper.questionCanBePublishedByUser(currentUser, question),
    );
  };

  const questionCanBeValidated = (): boolean => {
    if (questions.length !== 1) return false;
    return AuthorizationHelper.questionCanBeValidatedByUser(currentUser, questions[0]);
  };

  const questionCanBeNotifiedByUser = (): boolean => {
    return questions.every((question) => AuthorizationHelper.questionCanBeNotifiedByUser(currentUser, question));
  };

  const questionCanBeAssignedByUser = (): boolean => {
    if (questions.length !== 1) return false;
    return AuthorizationHelper.questionCanBeAssignedByUser(currentUser, questions[0]);
  };

  const canAssignQuestionToMe = (): boolean => {
    if (questions.length !== 1) return false;
    return AuthorizationHelper.canAssignQuestionToMe(questions[0], currentUser);
  };

  const isEnableAssignment = (): boolean => {
    if (!Array.isArray(allChannelsInfo)) {
      return false;
    }

    // Se l'utente loggato è Super Admin o Super Editor, ritorno sempre true
    if (currentUser.isSuperAdministrator() || currentUser.isAdministrator()) {
      return true;
    }

    const questionChannelId = questions[0].channelId;
    const matchingChannel = allChannelsInfo.find((channel) => channel.channelId === questionChannelId);

    if (matchingChannel && matchingChannel.enableAssignment === true) {
      return true;
    }
    return false;
  };

  const isAlreadyAssignedToMe = (): boolean => {
    if (questions.length !== 1) return false;

    return questions[0].assignedTo?.id === currentUser.id;
  };

  const actionsAvailable = (): boolean => {
    return (
      questionIsDefinitivelyRefusable() ||
      questionCanBeDiscarded() ||
      questionCanReturnToAssignedStatus() ||
      canAskForInformation() ||
      questionIsAssignedToCurrentUser() ||
      answerCanBeProposed() ||
      answerCanBeAccepted() ||
      canEditClassifications() ||
      answerCanBeMovedToReady() ||
      questionCanBeUnlocked() ||
      questionCanBeNotifiedByUser() ||
      questionCanBePublished() ||
      questionCanBeAssignedToContainer() ||
      questionCanBeValidated() ||
      questionCanBeRepublished() ||
      questionCanBeAssignedByUser() ||
      (canAssignQuestionToMe() && isEnableAssignment())
    );
  };

  const menu = (
    <div onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()}>
      <Menu>
        {questionCanBeAskedForValidation() && (
          <PopConfirmMenuItem
            popconfirmText="Vuoi richiedere la validazione per questo quesito?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.QUESTION_ASK_VALIDATION,
                'Validazione richiesta con successo',
                'Errore nella richiesta della validazione',
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="question-ask-button"
            buttonText="Richiedi Validazione"
          />
        )}
        {questionCanBeValidated() && (
          <PopConfirmMenuItem
            popconfirmText="Vuoi validare la risposta di questo quesito?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.VALIDATE_QUESTION,
                'Risposta validata',
                'Errore nella validazione della risposta',
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="question-validate-button"
            buttonText="Valida Risposta"
          />
        )}
        {questionIsDefinitivelyRefusable() && (
          <PopConfirmMenuItem
            popconfirmText="Sei sicuro di rifiutare definitivamente il quesito?"
            onConfirm={() => setIsDefinitiveRefuseModalOpen && setIsDefinitiveRefuseModalOpen(true)}
            buttonIsDisabled={isWorking}
            buttonTestId="definitive-refuse-question-button"
            buttonText="Rifiuta definitivamente"
          />
        )}
        {questionCanBeDiscarded() && (
          <PopConfirmMenuItem
            popconfirmText="Il quesito sarà eliminato definitivamente e non sarà più recuperabile. Confermi l'eliminazione?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.DISCARD,
                'Quesito eliminato definitivamente',
                "Errore nell'eliminazione del quesito",
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="discard-question-button"
            buttonText="Cestina"
          />
        )}
        {questionCanBeAssignedByUser() && (
          <Menu.Item
            key="assignToExpert"
            disabled={isWorking}
            onClick={() => actionOnConfirm(menuActions.ASSIGN_TO_EXPERT)}
            data-testid="assignation-button"
          >
            {questions[0].status === 'ASS' || questions[0].status === 'PROG' ? 'Riassegna' : 'Assegna'}
          </Menu.Item>
        )}
        {questions.length > 1 && (
          <Menu.Item
            key="assignToExpert"
            disabled={isWorking}
            onClick={() => actionOnConfirm(menuActions.MULTIPLE_ASSIGN_TO_EXPERT)}
            data-testid="assignation-button"
          >
            {questions[0].status === 'ASS' || questions[0].status === 'PROG' ? 'Riassegna' : 'Assegna'}
          </Menu.Item>
        )}
        {canAssignQuestionToMe() && !isAlreadyAssignedToMe() && isEnableAssignment() && (
          <Menu.Item
            key="assignToMe"
            disabled={isWorking}
            onClick={async () =>
              actionOnConfirm(
                menuActions.ASSIGN_TO_ME,
                'Il quesito è stato assegnato con successo',
                "Si è verificato un errore durante l'assegnazione del quesito",
              )
            }
            data-testid="assignation-to-me-button"
          >
            {questions[0].status === 'ASS' || questions[0].status === 'PROG' ? 'Riassegna a me' : 'Assegna a me'}
          </Menu.Item>
        )}
        {questionCanReturnToAssignedStatus() && (
          <Menu.Item
            key="returnToAssign"
            disabled={isWorking}
            onClick={async () =>
              actionOnConfirm(
                menuActions.RETURN_TO_ASSIGN_STATUS,
                'Quesito riportato in assegnato',
                'Errore nel riportare il quesito in assegnato',
              )
            }
            data-testid="return-to-assign-button"
          >
            Ritorna in Assegnato
          </Menu.Item>
        )}

        {canAskForInformation() && (
          <Menu.Item
            key="askEndUserForInfo"
            onClick={() => setIsAskForInfoModalOpen && setIsAskForInfoModalOpen(true)}
            data-testid="ask-for-info-button"
          >
            Richiedi Chiarimenti
          </Menu.Item>
        )}

        {questionIsAssignedToCurrentUser() && (
          <>
            <PopConfirmMenuItem
              popconfirmText="Sei sicuro di prendere in carico il quesito? Se procedi, l'utente finale riceverà una notifica di conferma e non potrai più inviargli richieste di chiarimento-"
              onConfirm={async () =>
                actionOnConfirm(
                  menuActions.ACCEPT_BY_EXPERT,
                  'Quesito preso in carico',
                  'Errore nel prendere in carico il quesito',
                )
              }
              buttonIsDisabled={isWorking}
              buttonTestId="accept-question-button"
              buttonText="Accetta"
            />

            <PopConfirmMenuItem
              popconfirmText="Sei sicuro di non prendere in carico del quesito?"
              onConfirm={async () =>
                actionOnConfirm(menuActions.REJECT_BY_EXPERT, 'Quesito rifiutato', 'Errore nel rifiutare il quesito')
              }
              buttonIsDisabled={isWorking}
              buttonTestId="reject-question-button"
              buttonText="Non accettare"
            />
          </>
        )}

        {answerCanBeProposed() && menuActions.PROPOSE_ANSWER && (
          <Menu.Item
            key="proposeAnswer"
            disabled={isWorking}
            onClick={async () =>
              actionOnConfirm(
                menuActions.PROPOSE_ANSWER!,
                'Risposta proposta correttamente',
                'Errore nel proporre la risposta',
              )
            }
            data-testid="propose-answer-button"
          >
            Invia risposta
          </Menu.Item>
        )}

        {answerCanBeAccepted() && (
          <>
            <Menu.Item
              key="refuseAnswer"
              disabled={isWorking}
              onClick={async () =>
                actionOnConfirm(menuActions.REFUSE_ANSWER, 'Risposta rifiutata', 'Errore nel rifiutare la riposta')
              }
              data-testid="refuse-answer-button"
            >
              Rifiuta risposta
            </Menu.Item>
            <Menu.Item
              key="acceptAnswer"
              disabled={isWorking}
              onClick={async () =>
                actionOnConfirm(menuActions.ACCEPT_ANSWER, 'Risposta accettata', "Errore nell'accettare la risposta")
              }
              data-testid="accept-answer-button"
            >
              Accetta risposta
            </Menu.Item>
            <Menu.Item
              key="integrateAnswer"
              disabled={isWorking}
              onClick={async () => actionOnConfirm(menuActions.INTEGRATE_ANSWER)}
              data-testid="integrate-answer-button"
            >
              Integra risposta
            </Menu.Item>
          </>
        )}

        {canEditClassifications() && menuActions.RECLASSIFY_QUESTION && (
          <PopConfirmMenuItem
            popconfirmText="Eventuali modifiche manuali alla classificazione verranno eliminate. Continuare?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.RECLASSIFY_QUESTION!,
                'Quesito riclassificato',
                'Errore nel riclassificare il quesito',
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="reclassify-question-button"
            buttonText="Ri-Categorizza"
          />
        )}

        {answerCanBeMovedToReady() && (
          <Menu.Item
            key="readyAnswer"
            disabled={isWorking}
            onClick={async () =>
              actionOnConfirm(
                menuActions.PUT_ANSWER_IN_READY,
                'Quesito pronto per la notifica e la pubblicazione',
                'Errore nel preparare il quesito',
              )
            }
            data-testid="put-answer-in-ready-button"
          >
            Rendi pubblicabile
          </Menu.Item>
        )}

        {questionCanBeUnlocked() && (
          <PopConfirmMenuItem
            popconfirmText="L'embargo verrà rimosso. Continuare?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.UNLOCK_NOTIFIED_QUESTION,
                'Quesito sbloccato',
                'Errore nello sbloccare il quesito',
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="unlock-notified-question-button"
            buttonText="Sblocca"
          />
        )}

        {questionCanBeNotifiedByUser() && (
          <PopConfirmMenuItem
            popconfirmText="Sei sicuro di voler notificare all'utente la risposta?"
            onConfirm={async () =>
              actionOnConfirm(menuActions.NOTIFY_TO_USER, 'Quesito notificato', 'Errore nella notifica')
            }
            buttonIsDisabled={isWorking}
            buttonTestId="notify-question-button"
            buttonText="Notifica all'utente"
          />
        )}

        {questionCanBePublished() && (
          <Menu.Item
            key="publish"
            disabled={isWorking}
            onClick={() => actionOnConfirm(menuActions.PUBLISH)}
            data-testid="publish-question-button"
          >
            Pubblica
          </Menu.Item>
        )}

        {questionCanBeAssignedToContainer() && (
          <Menu.Item
            key="assignQuestionToContainer"
            disabled={isWorking}
            onClick={() => actionOnConfirm(menuActions.ASSIGN_TO_CONTAINER)}
            data-testid="assign-question-to-container-button"
          >
            Aggiungi a un fascicolo
          </Menu.Item>
        )}

        {questionCanBeRepublished() && menuActions.SAVE_AND_REPUBLISH && (
          <PopConfirmMenuItem
            popconfirmText="Sei sicuro di voler ripubblicare il quesito su tutti i canali?"
            onConfirm={async () =>
              actionOnConfirm(
                menuActions.SAVE_AND_REPUBLISH!,
                'Quesito ripubblicato',
                'Errore nel ripubblicare il quesito',
              )
            }
            buttonIsDisabled={isWorking}
            buttonTestId="republish-question-button"
            buttonText="Salva e Ripubblica"
          />
        )}
      </Menu>
    </div>
  );

  return (
    <>
      {actionsAvailable() && (
        <Dropdown overlay={menu} placement="topLeft">
          <Button
            disabled={isWorking}
            type="primary"
            className="question-actions-dropdown-menu"
            data-testid="question-actions-dropdown-menu"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {label}
          </Button>
        </Dropdown>
      )}
    </>
  );
};
export default QuestionActions;
