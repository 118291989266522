import { ContainerStatus } from '../../models/QuestionsContainer';

export const translateContainerStatus = (status: ContainerStatus): string => {
  switch (status) {
    case 'OPEN':
      return 'Aperto'
    case 'BLOCKED':
      return 'Bloccato'
    case 'CLOSED':
      return 'Chiuso'
  }
}
