import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Popconfirm, Skeleton, Tooltip } from 'antd';
import React, { useState } from 'react';
import useDisassociateQuestionsFromContainer from '../../../../hooks/useDisassociateQuestionsFromContainer';
import { Question } from '../../../../models/Question';
import { arrayMove } from './ContainerData';
import './RubricsSummary.css';

export type RubricsSummaryData = {
  rubricName: string;
  rubricId: string;
  fiscal: boolean;
  questionsNumber: number;
  children: {
    subRubricName: string;
    subRubricId: string;
    questionsNumberInSubRubric: number;
  }[];
};

interface RubricsSummaryProps {
  data: RubricsSummaryData[];
  reorderRubrics: (reorderedRubrics: RubricsSummaryData[]) => void;
  reorderSubRubrics: (reorderedSubRubrics: SubRubricSummary[], rubricId: string) => void;
  questions: Question[];
  editMode: boolean;
  isLoading: boolean;
  updateQuestions: (questions: Question[]) => void;
}

const RubricsSummary: React.FC<RubricsSummaryProps> = ({
  data,
  reorderRubrics,
  reorderSubRubrics,
  questions,
  editMode,
  isLoading,
  updateQuestions,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = data.findIndex((rubricSummaryData) => rubricSummaryData.rubricId === active.id);
      const newIndex = data.findIndex((rubricSummaryData) => rubricSummaryData.rubricId === over?.id);

      const sortedList = arrayMove(data, oldIndex, newIndex);
      reorderRubrics(sortedList);
    }
  };

  return (
    <div data-testid="rubrics-summary" className="rubrics-summary">
      {isLoading ? (
        <RubricsSummarySkeleton />
      ) : (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={data.map((rubricSummaryData) => ({
              id: rubricSummaryData.rubricId,
            }))}
            strategy={verticalListSortingStrategy}
          >
            {data.map((rubric) => (
              <RubricsSummaryTable
                editMode={editMode}
                rubricId={rubric.rubricId}
                rubricName={rubric.rubricName}
                questionsInRubric={rubric.questionsNumber}
                children={rubric.children}
                key={rubric.rubricId}
                reorderSubRubrics={reorderSubRubrics}
                updateQuestions={updateQuestions}
                questions={questions}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};

interface RubricsSummaryTableProps {
  rubricId: string;
  rubricName: string;
  questionsInRubric: number;
  children: SubRubricSummary[];
  editMode: boolean;
  reorderSubRubrics: (reorderedSubRubrics: SubRubricSummary[], rubricId: string) => void;
  updateQuestions: (questions: Question[]) => void;
  questions: Question[];
}

export type SubRubricSummary = { subRubricName: string; subRubricId: string; questionsNumberInSubRubric: number };

const RubricsSummaryTable: React.FC<RubricsSummaryTableProps> = ({
  rubricId,
  rubricName,
  questionsInRubric,
  children,
  editMode,
  reorderSubRubrics,
  updateQuestions,
  questions,
}) => {
  const [showChildren, setShowChildren] = useState(true);
  const subRubricsSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: rubricId });
  const { disassociateQuestionsWithRubricId } = useDisassociateQuestionsFromContainer();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSubRubricDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = children.findIndex((subRubricSummaryData) => subRubricSummaryData.subRubricId === active.id);
      const newIndex = children.findIndex((subRubricSummaryData) => subRubricSummaryData.subRubricId === over?.id);
      const sortedList = arrayMove(children as SubRubricSummary[], oldIndex, newIndex);
      reorderSubRubrics(sortedList as SubRubricSummary[], rubricId);
    }
  };

  return (
    <div className="rubrics-summary-table drag-me" ref={setNodeRef} style={style}>
      <div className="rubrics-summary-table-header">
        {editMode && (
          <div {...listeners} {...attributes} className="sort-button" data-testid="rubric-sort-button">
            <MoreOutlined />
          </div>
        )}

        <div role="button" className="collapse-button" onClick={() => setShowChildren(!showChildren)}>
          <svg
            viewBox="0 0 24 24"
            focusable="false"
            className={showChildren ? 'show-children-icon' : 'hide-children-icon'}
            aria-hidden="true"
          >
            <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        </div>

        <div className="rubrics-summary-table-header-name">
          <span>{rubricName}</span>
          <div className="rubrics-summary-table-icons">
            <Tooltip title="Numero Quesiti">
              <div className="rubrics-summary-table-questions-number">{questionsInRubric}</div>
            </Tooltip>
            {editMode && (
              <Popconfirm
                title={`Sei sicuro di voler eliminare tutti i quesiti associati alla rubrica "${rubricName}?"`}
                onConfirm={() => updateQuestions(disassociateQuestionsWithRubricId(questions, rubricId))}
                okText="Sì"
                cancelText="No"
              >
                <DeleteOutlined
                  role="button"
                  className="rubrics-summary-table-delete-button"
                  data-testid="rubrics-summary-table-delete-button"
                />
              </Popconfirm>
            )}
          </div>
        </div>
      </div>

      <div className={`rubrics-summary-table-children ${showChildren === false ? 'hidden' : ''}`}>
        <DndContext sensors={subRubricsSensors} collisionDetection={closestCenter} onDragEnd={handleSubRubricDragEnd}>
          <SortableContext
            items={children.map((subRubricSummaryData) => ({
              id: subRubricSummaryData.subRubricId,
            }))}
            strategy={verticalListSortingStrategy}
          >
            <ul>
              {children.map((subRubric) => (
                <RubricsSummarySubRubric
                  rubricName={rubricName}
                  rubricId={rubricId}
                  subRubric={subRubric}
                  editMode={editMode}
                  updateQuestions={updateQuestions}
                  questions={questions}
                  key={subRubric.subRubricId}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

interface RubricsSummarySubRubricProps {
  rubricName: string;
  rubricId: string;
  subRubric: SubRubricSummary;
  editMode: boolean;
  updateQuestions: (questions: Question[]) => void;
  questions: Question[];
}

const RubricsSummarySubRubric: React.FC<RubricsSummarySubRubricProps> = ({
  rubricName,
  rubricId,
  subRubric,
  editMode,
  updateQuestions,
  questions,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: subRubric.subRubricId });
  const { disassociateQuestionsWithSubRubricId } = useDisassociateQuestionsFromContainer();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <li className="drag-me" ref={setNodeRef} style={style}>
      <div className="collapse-child">
        {editMode && (
          <div {...listeners} {...attributes} className="sort-button" data-testid="subRubric-sort-button">
            <MoreOutlined />
          </div>
        )}
        <span>{subRubric.subRubricName}</span>
        <div className="rubrics-summary-table-icons">
          <Tooltip title="Numero Quesiti">
            <div className="rubrics-summary-table-questions-number">{subRubric.questionsNumberInSubRubric}</div>
          </Tooltip>
          {editMode && (
            <Popconfirm
              title={`Sei sicuro di voler eliminare tutti i quesiti associati alla rubrica "${rubricName}" e sotto rubrica "${subRubric.subRubricName}"?`}
              onConfirm={() =>
                updateQuestions(disassociateQuestionsWithSubRubricId(questions, rubricId, subRubric.subRubricId))
              }
              okText="Sì"
              cancelText="No"
            >
              <DeleteOutlined
                role="button"
                className="rubrics-summary-table-delete-button"
                data-testid="rubrics-summary-table-delete-button"
              />
            </Popconfirm>
          )}
        </div>
      </div>
    </li>
  );
};

const RubricsSummarySkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} data-testid="loading-skeleton">
      <Skeleton.Input active style={{ height: '1em', width: '20em', marginBottom: '0.3em' }} />
      <Skeleton.Input active style={{ height: '1em', width: '20em', marginBottom: '0.3em' }} />
      <Skeleton.Input active style={{ height: '1em', width: '20em', marginBottom: '0.3em' }} />
      <Skeleton.Input active style={{ height: '1em', width: '20em', marginBottom: '0.3em' }} />
    </div>
  );
};

export default RubricsSummary;
