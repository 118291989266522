import { History } from 'history';

export default class NavigationService {
  private readonly HOME = 'home';
  private readonly USERS = 'users';
  private readonly QUESTIONS = 'questions';
  private readonly DASHBOARD = 'dashboard';
  private readonly REPORT = 'report';
  private readonly WORKABLE_QUESTIONS = 'workable-questions';
  private readonly PUBLISHABLE_QUESTIONS = 'publishable-questions';
  private readonly MY_QUESTIONS = 'my-questions';
  private readonly LOGIN = 'login';
  private readonly CONTAINERS = 'containers';
  private readonly ARCHIVED_CONTAINERS = 'archived-containers';
  private readonly ORGANIZATION = 'organization';
  private readonly GESTIONE_PAGHE = 'gestione-paghe';

  constructor(private history: History) {}

  getHomePath(): string {
    return `/${this.HOME}`;
  }

  getUsersPath(): string {
    return `/${this.HOME}/${this.USERS}`;
  }

  getCreateUsersPath(): string {
    return `/${this.HOME}/${this.USERS}/create`;
  }

  getUpdateUsersPath(): string {
    return `/${this.HOME}/${this.USERS}/update`;
  }

  getAllQuestionsPath(): string {
    return `/${this.HOME}/${this.QUESTIONS}/`;
  }

  getDashboardPath(): string {
    return `/${this.HOME}/${this.DASHBOARD}/:dashboardType`;
  }

  getReportPath(): string {
    return `/${this.HOME}/${this.REPORT}/:reportType`;
  }

  getWorkableQuestionsPath(): string {
    return `/${this.HOME}/${this.WORKABLE_QUESTIONS}`;
  }

  getPublishableQuestionsPath(): string {
    return `/${this.HOME}/${this.PUBLISHABLE_QUESTIONS}`;
  }

  getMyQuestionsPath(): string {
    return `/${this.HOME}/${this.MY_QUESTIONS}`;
  }

  getCreateQuestionsPath(): string {
    return `/${this.HOME}/${this.QUESTIONS}/create`;
  }

  getContainersPath(): string {
    return `/${this.HOME}/${this.CONTAINERS}`;
  }

  getArchivedContainersPath(): string {
    return `/${this.HOME}/${this.ARCHIVED_CONTAINERS}`;
  }

  getUpdateContainersPath(): string {
    return `/${this.HOME}/${this.CONTAINERS}/update`;
  }

  getCreateContainersPath(): string {
    return `/${this.HOME}/${this.CONTAINERS}/create`;
  }

  getOrganizationPath(): string {
    return `/${this.HOME}/${this.ORGANIZATION}`;
  }

  getGestionePaghePath(): string {
    return `/${this.HOME}/${this.GESTIONE_PAGHE}`;
  }

  getLoginPath(): string {
    return `/${this.LOGIN}`;
  }

  kickToHome(): void {
    this.history.replace(`/${this.HOME}`);
  }

  kickToLogin(): void {
    this.history.replace(`/${this.LOGIN}`);
  }

  goToHome(): void {
    this.history.push(this.getHomePath());
  }

  goToPersonaleEspertoReport(): void {
    this.history.push(this.getReportPath().replace(':reportType', 'personale-esperto'));
  }

  goToRedazioneReport(): void {
    this.history.push(this.getReportPath().replace(':reportType', 'redazione'));
  }

  goToQuesitiReport(): void {
    this.history.push(this.getReportPath().replace(':reportType', 'quesiti'));
  }

  goToEspertiReport(): void {
    this.history.push(this.getReportPath().replace(':reportType', 'esperti'));
  }

  goToClientiReport(): void {
    this.history.push(this.getReportPath().replace(':reportType', 'clienti'));
  }

  goToUsers(): void {
    this.history.push(this.getUsersPath());
  }

  goToCreateUsers(): void {
    this.history.push(this.getCreateUsersPath());
  }

  goToUpdateUsers(userId: string): void {
    this.history.push(this.getUpdateUsersPath() + '/' + btoa(userId));
  }

  goToAllQuestions(): void {
    this.history.push(this.getAllQuestionsPath());
  }

  goToWorkableQuestions(): void {
    this.history.push(this.getWorkableQuestionsPath());
  }

  goToMyQuestions(): void {
    this.history.push(this.getMyQuestionsPath());
  }

  goToPublishableQuestions(): void {
    this.history.push(this.getPublishableQuestionsPath());
  }

  goToCreateQuestions(): void {
    this.history.push(this.getCreateQuestionsPath());
  }

  goToContainers(): void {
    this.history.push(this.getContainersPath());
  }

  goToArchivedContainers(): void {
    this.history.push(this.getArchivedContainersPath());
  }

  goToUpdateContainers(containerId: string): void {
    this.history.push(this.getUpdateContainersPath() + '/' + btoa(containerId));
  }

  goToArchivedDetailsContainers(containerId: string): void {
    this.history.push(this.getArchivedContainersPath() + '/' + btoa(containerId));
  }

  goToCreateContainers(): void {
    this.history.push(this.getCreateContainersPath());
  }

  goToOrganization(): void {
    this.history.push(this.getOrganizationPath());
  }

  goToGestionePaghe(): void {
    this.history.push(this.getGestionePaghePath());
  }

  goBack(): void {
    this.history.goBack();
  }
}
