import { Layout } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { version } from '../../../package.json';
import { Channel } from '../../models/Channel';
import CurrentUser from '../../models/CurrentUser';
import NavigationService from '../../services/NavigationService';
import OrganizationService from '../../services/OrganizationService';
import QuestionsContainerService from '../../services/QuestionsContainerService';
import QuestionService from '../../services/QuestionService';
import UploadService from '../../services/UploadService';
import UserService from '../../services/UserService';
import Organization from '../Organization/Organization';
import ArchivedQuestionsContainersDetailsPage from '../QuestionContainers/ArchivedQuestionsContainersPage/ArchivedQuestionsContainersDetailsPage';
import ArchivedQuestionsContainersPage from '../QuestionContainers/ArchivedQuestionsContainersPage/ArchivedQuestionsContainersPage';
import CreateQuestionsContainer from '../QuestionContainers/CreateQuestionsContainer/CreateQuestionsContainer';
import EditQuestionsContainer from '../QuestionContainers/EditQuestionsContainer/EditQuestionsContainer';
import QuestionsContainersPage from '../QuestionContainers/QuestionsContainersPage';
import AllQuestions from '../Questions/AllQuestions/AllQuestions';
import CreateQuestion from '../Questions/CreateQuestion/CreateQuestion';
import MyQuestions from '../Questions/MyQuestions/MyQuestions';
import PublishableQuestions from '../Questions/PublishableQuestions/PublishableQuestions';
import WorkableQuestions from '../Questions/WorkableQuestions/WorkableQuestions';
import Dashboard from '../Dashboard/Dashboard';
import Reports from '../Reports/Reports';
import GestionePagheEsperti from '../GestionePagheEsperti/GestionePagheEsperti';
import CreateOrEditUser from '../Users/CreateUser/CreateOrEditUser';
import Users from '../Users/Users';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import SideBar from './components/SideBar/SideBar';
import HomeDashboard from './components/HomeDashboard/HomeDashboard';
import './Home.css';

const { Content, Footer } = Layout;

type HomeProps = {
  navigationService: NavigationService;
  userService: UserService;
  organizationService: OrganizationService;
  questionService: QuestionService;
  questionsContainerService: QuestionsContainerService;
  uploadService: UploadService;
};

const Home: FC<HomeProps> = (props) => {
  let useEffectSubscribed = false;

  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [ERChannel, setERChannel] = useState<Channel | undefined>(undefined);

  const loadCurrentUser = async () => {
    if (useEffectSubscribed) {
      setIsLoading(true);
      const currentUser = await props.userService.getCurrentUser();
      if (currentUser === null) {
        props.navigationService.kickToLogin();
        return;
      } else setCurrentUser(currentUser);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffectSubscribed = true;
    loadCurrentUser();
    return () => {
      useEffectSubscribed = false;
    };
  }, [props.userService]);

  const getERChannel = useCallback(async () => {
    try {
      const channels = await props.organizationService.getOutputChannels();
      const channel = channels.find((c) => c.channelName === 'ER');

      setERChannel(channel);
    } catch (error: any) {
      console.error(error);
    }
  }, [props.organizationService]);

  useEffect(() => {
    getERChannel();
  }, [getERChannel]);

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          currentUser={currentUser}
          navigationService={props.navigationService}
          userService={props.userService}
          organizationService={props.organizationService}
        />
        <Layout>
          <SideBar
            navigationService={props.navigationService}
            currentUser={currentUser}
            ERChannel={ERChannel}
            organizationService={props.organizationService}
          />
          <Layout>
            <Content>
              <div style={{ padding: 32 }}>
                <Switch>
                  <Route exact path={props.navigationService.getHomePath()}>
                    <h2>Home page</h2>
                    {currentUser && (
                      <HomeDashboard
                        currentUser={currentUser}
                        userService={props.userService}
                        questionService={props.questionService}
                      />
                    )}
                  </Route>

                  <Route
                    exact
                    path={props.navigationService.getReportPath()}
                    render={(p: any) => (
                      <Reports
                        currentUser={currentUser}
                        userService={props.userService}
                        navigationService={props.navigationService}
                        organizationService={props.organizationService}
                        {...p}
                      />
                    )}
                  />

                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getUsersPath()}
                      render={(p: any) => (
                        <Users
                          currentUser={currentUser}
                          userService={props.userService}
                          navigationService={props.navigationService}
                          organizationService={props.organizationService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getCreateUsersPath()}
                      render={(p: any) => (
                        <CreateOrEditUser
                          currentUser={currentUser}
                          navigationService={props.navigationService}
                          userService={props.userService}
                          organizationService={props.organizationService}
                          uploadService={props.uploadService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getUpdateUsersPath() + '/:userId'}
                      render={(p: any) => (
                        <CreateOrEditUser
                          currentUser={currentUser}
                          navigationService={props.navigationService}
                          userService={props.userService}
                          organizationService={props.organizationService}
                          uploadService={props.uploadService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path={props.navigationService.getAllQuestionsPath()}
                    render={(p: any) => (
                      <AllQuestions
                        currentUser={currentUser}
                        userService={props.userService}
                        navigationService={props.navigationService}
                        questionService={props.questionService}
                        organizationService={props.organizationService}
                        containerService={props.questionsContainerService}
                        {...p}
                      />
                    )}
                  />
                  {(currentUser?.isExpert() || currentUser?.isAtLeastEditor()) && (
                    <Route
                      exact
                      path={props.navigationService.getDashboardPath()}
                      render={(p: any) => (
                        <Dashboard
                          currentUser={currentUser}
                          userService={props.userService}
                          navigationService={props.navigationService}
                          questionService={props.questionService}
                          organizationService={props.organizationService}
                          containerService={props.questionsContainerService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path={props.navigationService.getWorkableQuestionsPath()}
                    render={(p: any) => (
                      <WorkableQuestions
                        currentUser={currentUser}
                        userService={props.userService}
                        navigationService={props.navigationService}
                        questionService={props.questionService}
                        organizationService={props.organizationService}
                        {...p}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={props.navigationService.getMyQuestionsPath()}
                    render={(p: any) => (
                      <MyQuestions
                        currentUser={currentUser}
                        userService={props.userService}
                        navigationService={props.navigationService}
                        questionService={props.questionService}
                        organizationService={props.organizationService}
                        {...p}
                      />
                    )}
                  />
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getPublishableQuestionsPath()}
                      render={(p: any) => (
                        <PublishableQuestions
                          currentUser={currentUser}
                          userService={props.userService}
                          navigationService={props.navigationService}
                          questionService={props.questionService}
                          organizationService={props.organizationService}
                          containerService={props.questionsContainerService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  <Route
                    exact
                    path={props.navigationService.getCreateQuestionsPath()}
                    render={(p: any) => (
                      <CreateQuestion
                        navigationService={props.navigationService}
                        organizationService={props.organizationService}
                        questionService={props.questionService}
                        currentUser={currentUser}
                        {...p}
                      />
                    )}
                  />
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getContainersPath()}
                      render={(p: any) => (
                        <QuestionsContainersPage
                          currentUser={currentUser}
                          userService={props.userService}
                          navigationService={props.navigationService}
                          questionsContainerService={props.questionsContainerService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getArchivedContainersPath()}
                      render={(p: any) => (
                        <ArchivedQuestionsContainersPage
                          currentUser={currentUser}
                          userService={props.userService}
                          navigationService={props.navigationService}
                          questionsContainerService={props.questionsContainerService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getUpdateContainersPath() + '/:containerId'}
                      render={(p: any) => (
                        <EditQuestionsContainer
                          currentUser={currentUser}
                          navigationService={props.navigationService}
                          userService={props.userService}
                          questionsContainerService={props.questionsContainerService}
                          questionService={props.questionService}
                          organizationService={props.organizationService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getArchivedContainersPath() + '/:containerId'}
                      render={(p: any) => (
                        <ArchivedQuestionsContainersDetailsPage
                          currentUser={currentUser}
                          navigationService={props.navigationService}
                          userService={props.userService}
                          questionsContainerService={props.questionsContainerService}
                          organizationService={props.organizationService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {!currentUser?.isExpert() && (
                    <Route
                      exact
                      path={props.navigationService.getCreateContainersPath()}
                      render={(p: any) => (
                        <CreateQuestionsContainer
                          navigationService={props.navigationService}
                          questionsContainerService={props.questionsContainerService}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {(currentUser?.isAdministrator() || currentUser?.isSuperAdministrator()) && (
                    <Route
                      exact
                      path={props.navigationService.getOrganizationPath()}
                      render={(p: any) => (
                        <Organization
                          organizationService={props.organizationService}
                          uploadService={props.uploadService}
                          currentUser={currentUser}
                          {...p}
                        />
                      )}
                    />
                  )}
                  {(currentUser?.isAdministrator() || currentUser?.isSuperAdministrator()) && (
                    <Route
                      exact
                      path={props.navigationService.getGestionePaghePath()}
                      render={(p: any) => <GestionePagheEsperti organizationService={props.organizationService} />}
                    />
                  )}
                </Switch>
              </div>
            </Content>
            <Footer className="footer">L'Esperto Risponde - v{version}</Footer>
          </Layout>
        </Layout>
      </Layout>
    );
};

export default Home;
