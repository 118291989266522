import { Question } from '../models/Question';
import DateUtils from './DateUtils';
export default class QuestionStatus {
  static readonly ING = 'ING';
  static readonly ASS = 'ASS';
  static readonly PROG = 'PROG';
  static readonly REJ = 'REJ';
  static readonly ANS = 'ANS';
  static readonly ACE = 'ACE';
  static readonly READY = 'READY';
  static readonly NOTIFIED = 'NOTIFIED';
  static readonly PUBLISHED = 'PUBLISHED';
  static readonly TO_VALIDATE = 'TOVALIDATE';
  static readonly VALIDATED = 'VALIDATED';
  static readonly INFO = 'INFO';
  static readonly CES = 'CES';
  static readonly PUBLISHEDB = 'PUBLISHEDB';
  static readonly NOTIFIEDB = 'NOTIFIEDB';
  static readonly NOTIFIEDU = 'NOTIFIEDU';
  static readonly UNPUB = 'UNPUB';

  public static readonly statusLabelMap = QuestionStatus.createStatusLabelMap();

  private static createStatusLabelMap(): Map<string, string> {
    return new Map(Object.entries({ ...QuestionStatus.realStatus(), ...QuestionStatus.fakeStatus() }));
  }

  public static realStatus(): Record<string, string> {
    return {
      [QuestionStatus.ING]: 'Inserito',
      [QuestionStatus.ASS]: 'Assegnato',
      [QuestionStatus.PROG]: 'Preso in carico',
      [QuestionStatus.INFO]: 'Richiesta informazioni',
      [QuestionStatus.REJ]: 'Rifiutato definitivamente',
      [QuestionStatus.ANS]: 'Risposto',
      [QuestionStatus.ACE]: 'Accettato',
      [QuestionStatus.READY]: 'Pronto per la notifica e pubblicazione',
      [QuestionStatus.NOTIFIED]: 'Notificato',
      [QuestionStatus.PUBLISHED]: 'Pubblicato',
      [QuestionStatus.UNPUB]: 'Depubblicato',
      [QuestionStatus.TO_VALIDATE]: 'Da validare',
      [QuestionStatus.VALIDATED]: 'Validato',
      [QuestionStatus.CES]: 'Cestinato',
    };
  }

  public static fakeStatus(): Record<string, string> {
    return {
      [QuestionStatus.NOTIFIEDB]: 'Notificato & Bloccato',
      [QuestionStatus.NOTIFIEDU]: 'Notificato & Aggiornato',
      [QuestionStatus.PUBLISHEDB]: 'Pubblicato & Bloccato',
    };
  }

  public static label(status: string | undefined): string {
    if (status === undefined) return '';
    return QuestionStatus.statusLabelMap.has(status) ? (this.statusLabelMap.get(status) as string) : status;
  }

  public static beforePROG(status: string): boolean {
    return status === QuestionStatus.ING || status === QuestionStatus.ASS || status === QuestionStatus.INFO;
  }

  public static beforeACE(status: string): boolean {
    return QuestionStatus.beforePROG(status) || status === QuestionStatus.PROG || status === QuestionStatus.ANS;
  }

  public static beforeNOTIFIED(status: string): boolean {
    return QuestionStatus.beforeACE(status) || status === QuestionStatus.ACE || status === QuestionStatus.READY;
  }

  public static getStatusDescription(question: Question | undefined): string {
    return (
      QuestionStatus.label(question?.status) +
      QuestionStatus.hasMoreThanOneVersion(question) +
      QuestionStatus.blockedUntilDescription(question)
    );
  }

  private static blockedUntilDescription(question: Question | undefined) {
    if (question && question.isBlockedForPublication())
      return ' bloccato fino al ' + DateUtils.formatOnlyDate(question?.blockedUntil);
    return '';
  }

  private static hasMoreThanOneVersion(question: Question | undefined) {
    if (question?.versionNumber && question.versionNumber > 0 && question.status === QuestionStatus.NOTIFIED) {
      return ' Aggiornato';
    }
    return '';
  }

  static toFilterArray(): FilterElement[] {
    const result: FilterElement[] = [];
    this.statusLabelMap.forEach((value: string, key: string) => {
      result.push({ text: value, value: key });
    });
    return result;
  }
}

export type FilterElement = {
  text: string;
  value: string;
};
