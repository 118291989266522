import UserRole from '../utils/UserRole';
import { Topic, UserChannel } from './UserChannel';

export default class User {
  static readonly SUPER_AMMINISTRATORE = 'Super Admin';
  static readonly AMMINISTRATORE = 'Super Editor';
  static readonly REDATTORE = 'Editor';
  static readonly ESPERTO = 'Expert';
  static readonly SUPER_ESPERTO = 'Super Expert';

  key: string = '';
  readonly id: string;
  readonly name: string;
  readonly surname: string;
  readonly role: string;
  readonly channels: UserChannel[];
  readonly enabled: boolean;
  readonly userNote?: string;
  topics: Topic[] = [];
  topicsAsExpert: Topic[] = [];
  topicsAsSuperExpert: Topic[] = [];

  constructor(
    id: string,
    name: string,
    surname: string,
    role: string,
    channels: UserChannel[],
    enabled: boolean = true,
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.role = role;
    this.channels = channels;
    this.enabled = enabled;
  }

  static convertRoleName = (name: string): string => {
    const parts = name.split('-');
    const interestingPart = parts[parts.length - 1];
    if (UserRole.isSuperAdministrator(interestingPart)) return User.SUPER_AMMINISTRATORE;
    if (UserRole.isAdministrator(interestingPart)) return User.AMMINISTRATORE;
    if (UserRole.isEditor(interestingPart)) return User.REDATTORE;
    if (UserRole.isExpert(interestingPart)) return User.ESPERTO;
    return name;
  };
}
