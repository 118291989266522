import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Skeleton, Space, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import QuestionModal from '../../../../components/QuestionModal/QuestionModal';
import useDisassociateQuestionsFromContainer from '../../../../hooks/useDisassociateQuestionsFromContainer';
import CurrentUser from '../../../../models/CurrentUser';
import { DisplayUser } from '../../../../models/DisplayUser';
import { Question } from '../../../../models/Question';
import OrganizationService from '../../../../services/OrganizationService';
import QuestionService from '../../../../services/QuestionService';
import UserService from '../../../../services/UserService';
import { arrayMove } from './ContainerData';

interface Props {
  isLoading: boolean;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  questions: Question[];
  editMode: boolean;
  currentUser: CurrentUser;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  updateQuestions: (questions: Question[]) => void;
}

type Direction = 'up' | 'down';

const QuestionsTable: React.FC<Props> = ({
  isLoading,
  setRefreshData,
  questions,
  editMode,
  currentUser,
  userService,
  questionService,
  organizationService,
  updateQuestions,
}) => {
  const [isQuestionDetailsModalVisible, setIsQuestionDetailsModalVisible] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | undefined>(undefined);
  const { disassociateQuestionWithId } = useDisassociateQuestionsFromContainer();

  const columns = [
    {
      key: 'order',
      title: '',
      width: 50,
      render: (question: Question) => (
        <Space size="small" onClick={(e) => e.stopPropagation()}>
          {editMode && (
            <>
              {showUpSortingArrow(question) ? (
                <CaretUpOutlined data-testid="reorder-question-up-button" onClick={() => moveRow(question, 'up')} />
              ) : (
                <div style={{ width: '14px' }}></div>
              )}
              {showDownSortingArrow(question) ? (
                <CaretDownOutlined
                  data-testid="reorder-question-down-button"
                  onClick={() => moveRow(question, 'down')}
                />
              ) : (
                <div style={{ width: '14px' }}></div>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Rubrica',
      key: 'rubric',
      render: (question: Question) => (
        <span>{question.containerInfo ? question.containerInfo.containerRubricName : question.rubricName}</span>
      ),
    },
    {
      title: 'SottoRubrica',
      key: 'subRubric',
      render: (question: Question) => (
        <span>{question.containerInfo ? question.containerInfo.containerSubRubricName : question.subRubricName}</span>
      ),
    },
    {
      title: 'Firma (Esperti)',
      dataIndex: 'signerUsers',
      key: 'signerUser',
      render: (signerUsers: DisplayUser[]) => <span>{signerUsers?.map((user) => user?.displayName)}</span>,
    },
    {
      title: 'Titolo Quesito',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (question: Question) => (
        <>
          {editMode && (
            <Tooltip title="Dissocia Quesito">
              <Button
                data-testid="disassociate-question-button"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  updateQuestions(disassociateQuestionWithId(questions, question.id));
                }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const onQuestionModalOk = async () => {
    setIsQuestionDetailsModalVisible(false);
    setSelectedQuestion(undefined);
    setRefreshData(true);
  };

  const onQuestionModalCancel = () => {
    setIsQuestionDetailsModalVisible(false);
    setSelectedQuestion(undefined);
  };

  const showUpSortingArrow = (question: Question): boolean => {
    const questionIndex = questions.findIndex((q) => q.id === question.id);

    return questions
      .slice(0, questionIndex)
      .find((q) => q.containerInfo?.containerSubRubricId === question.containerInfo?.containerSubRubricId)
      ? true
      : false;
  };

  const showDownSortingArrow = (question: Question): boolean => {
    const questionIndex = questions.findIndex((q) => q.id === question.id);

    return questions
      .slice(questionIndex + 1)
      .find((q) => q.containerInfo?.containerSubRubricId === question.containerInfo?.containerSubRubricId)
      ? true
      : false;
  };

  const moveRow = async (question: Question, direction: Direction) => {
    const oldIndex = questions.findIndex((q) => q.id === question.id);
    const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;

    const updatedQuestions = arrayMove(questions, oldIndex, newIndex);
    updateQuestions([...updatedQuestions]);
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton data-testid="loading-skeleton" active paragraph={{ rows: 5, width: '15em' }} />
      ) : (
        <Table
          sticky={true}
          data-testid="questions-table-in-container"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                setIsQuestionDetailsModalVisible(true);
                setSelectedQuestion(record);
              },
            };
          }}
          rowClassName="questions-section-row"
          dataSource={questions}
          columns={columns as any}
          pagination={{
            pageSize: 50,
          }}
        />
      )}
      {selectedQuestion?.id && (
        <QuestionModal
          visible={isQuestionDetailsModalVisible}
          currentUser={currentUser}
          onOk={onQuestionModalOk}
          onCancel={onQuestionModalCancel}
          questionId={selectedQuestion.id}
          userService={userService}
          questionService={questionService}
          organizationService={organizationService}
        />
      )}
    </div>
  );
};

export default QuestionsTable;
