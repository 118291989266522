import { Tabs } from 'antd';
import ChannelsTab from './InputChannelsTab/ChannelsTab';
import OrganizationService from '../../services/OrganizationService';
import RubricsTab from './RubricsTab/RubricsTab';
import CurrentUser from '../../models/CurrentUser';
import UploadService from '../../services/UploadService';

const { TabPane } = Tabs;

interface props {
  organizationService: OrganizationService;
  uploadService: UploadService;
  currentUser: CurrentUser;
}

const Organization: React.FC<props> = (props) => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Canali" key="1">
        <ChannelsTab
          organizationService={props.organizationService}
          uploadService={props.uploadService}
          currentUser={props.currentUser}
        />
      </TabPane>
      <TabPane tab="Rubriche" key="2">
        <RubricsTab
          organizationService={props.organizationService}
          currentUser={props.currentUser}
        />
      </TabPane>
    </Tabs>
  );
};

export default Organization;
