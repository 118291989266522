import React, { useEffect } from 'react';
import { Badge } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

type TableScrollNavigationProps = {
  children: React.ReactNode;
};

export default function TableScrollNavigation(props: TableScrollNavigationProps) {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <Badge.Ribbon
      text={<CaretLeftOutlined key="left" onClick={tableScrollLeft} className="ant-table-sticky-arrows" />}
      placement="start"
      style={{ zIndex: 99 }}
    >
      <Badge.Ribbon
        text={<CaretRightOutlined key="right" onClick={tableScrollRight} className="ant-table-sticky-arrows" />}
        placement="end"
        style={{ zIndex: 99 }}
      >
        {props.children}
      </Badge.Ribbon>
    </Badge.Ribbon>
  );

  function handleScroll() {
    const stickyTableHeader: HTMLElement | null = document.querySelector(
      'div.ant-table-header.ant-table-sticky-holder',
    );
    document.querySelectorAll('.ant-table-sticky-arrows').forEach((arrow: any) => {
      arrow.closest('.ant-ribbon').style.marginTop = `${stickyTableHeader?.offsetTop}px`;
    });
  }

  function tableScrollLeft({ target }: any) {
    target.closest('.ant-ribbon-wrapper').querySelector('.ant-table-body').scrollLeft -= 100;
  }

  function tableScrollRight({ target }: any) {
    target.closest('.ant-ribbon-wrapper').querySelector('.ant-table-body').scrollLeft += 100;
  }
}
