import { Alert } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { FC, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import CurrentUser from '../../../models/CurrentUser';
import { Question } from '../../../models/Question';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import OrganizationUtils from '../../../utils/OrganizationUtils';
import { UserTranslator } from '../../../utils/UserTranslator';
import QuestionsTable, { ColumnKey, defaultPagination } from '../QuestionsTable/QuestionsTable';

type PublishableQuestionsProps = {
  navigationService: NavigationService;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  containerService: QuestionsContainerService;
  currentUser: CurrentUser;
};

const PublishableQuestions: FC<PublishableQuestionsProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const [canCreateQuestion, setCanCreateQuestion] = useState(false);

  const refreshData = useCallback(
    async (
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<Question>,
      pagination: TablePaginationConfig,
    ): Promise<void> => {
      try {
        setIsRefreshingData(true);

        const userTranslator = new UserTranslator(props.userService);
        const queryResponse = await props.questionService.getPublishableQuestions(
          await userTranslator.translateUsersFor(filters),
          sorter,
          pagination,
        );

        const retrievedQuestions = queryResponse.questions;
        await OrganizationUtils.addOrganizationNamesToQuestions(retrievedQuestions, props.organizationService);

        setTotal(queryResponse.fullCount);
        setQuestions(retrievedQuestions);

        setIsRefreshingData(false);
      } catch (e: any) {
        setIsRefreshingData(false);
        console.error(e.message)
        setErrorMessage('Errore nel caricamento dei quesiti');
      }
    },
    [props.organizationService, props.questionService, props.userService],
  );

  useEffect(() => {
    refreshData({}, {}, defaultPagination);
  }, [refreshData]);

  useEffect(() => {
    setCanCreateQuestion(
      props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator() || props.currentUser.isEditor(),
    );
  }, [props.currentUser]);

  const columnsOrder = [
    ColumnKey.id,
    ColumnKey.typology,
    ColumnKey.channel,
    ColumnKey.rubric,
    ColumnKey.subRubric,
    ColumnKey.status,
    ColumnKey.deadline,
    ColumnKey.creationDate,
    ColumnKey.subject,
    ColumnKey.question,
    ColumnKey.assignedTo,
    ColumnKey.signerUser,
    ColumnKey.answerDateTime,
    ColumnKey.containers,
    ColumnKey.blockedUntil,
    ColumnKey.endUser,
    ColumnKey.outputChannels,
    ColumnKey.actions,
  ].filter((columnName: string) => !(columnName === 'containers' && props.currentUser.isExpert()));

  return (
    <>
      <h2>Quesiti Pubblicabili</h2>
      <div>
        {errorMessage !== undefined && (
          <Alert message="Errore" description={errorMessage} type="error" showIcon></Alert>
        )}
        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <QuestionsTable
            currentUser={props.currentUser}
            data={questions}
            total={total}
            refreshData={refreshData}
            questionService={props.questionService}
            userService={props.userService}
            organizationService={props.organizationService}
            containerService={props.containerService}
            navigationService={props.navigationService}
            canCreateQuestion={canCreateQuestion}
            canPublishQuestion={true}
            columnsOrder={columnsOrder}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default PublishableQuestions;
