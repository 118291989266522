import React, { useEffect, useState } from 'react';
import { List, Avatar } from 'antd';
import UserService from '../../../../services/UserService';
import CurrentUser from '../../../../models/CurrentUser';
import QuestionService from '../../../../services/QuestionService';
import DashboardServiceFacade from '../../../../services/DashboardServiceFacade';
import { Link } from 'react-router-dom';
import {
  TitlesMapEditor,
  TitlesMapExpert,
  TitlesOrderEditor,
  TitlesOrderExpert,
  orderFilterMapEditor,
} from '../../../Dashboard/utilsDashboard';

interface HomeDashboardProps {
  currentUser: CurrentUser;
  userService: UserService;
  questionService: QuestionService;
}

type HomeCounter = { title: string; count: number };
const pagination = {
  current: 0,
  pageSize: 0,
  showSizeChanger: false,
};

const HomeDashboard: React.FC<HomeDashboardProps> = (props) => {
  const [counters, setCounters] = useState<HomeCounter[]>([]);

  const loadDashboardCounters = () => {
    if (props.currentUser.isExpert() || props.currentUser.isAtLeastEditor()) {
      const dahboardTypes = props.currentUser?.isExpert() ? TitlesOrderExpert : TitlesOrderEditor;
      dahboardTypes.map((dashboardType, index) => {
        const { filters, order } = orderFilterMapEditor[dashboardType];
        DashboardServiceFacade.getDashboardQuestions({
          currentUser: props.currentUser,
          questionService: props.questionService,
          userService: props.userService,
          newPagination: pagination,
          newFilters: filters,
          newSorter: order,
          dashboardType,
        }).then((queryResponse) => {
          const aCounter: HomeCounter = { title: TitlesMapEditor[dashboardType], count: queryResponse.fullCount };
          setCounters((prevCounters) => {
            const newCounters = [...prevCounters];
            newCounters[index] = aCounter;
            return newCounters;
          });
        });
        return dashboardType;
      });
    }
  };

  useEffect(() => {
    loadDashboardCounters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const getCounterByTitle = (title: string) => {
    if (!counters || !title) return 'counting..';
    const counter = counters.find((c) => c && c.title === title);
    return counter ? counter.count : 'counting..';
  };

  return (
    <div>
      {props.currentUser?.isAtLeastEditor() && (
        <List header={<h2 className="dashboard-title">Dashboard Editor</h2>} size="large" bordered>
          {TitlesOrderEditor.map((titleKey) => (
            <List.Item key={titleKey}>
              <Link className="dashboard-link" to={`/home/dashboard/${titleKey}`}>
                <Avatar size={64} className="dashboard-avatar">
                  {getCounterByTitle(TitlesMapEditor[titleKey])}
                </Avatar>
                {TitlesMapEditor[titleKey]}
              </Link>
            </List.Item>
          ))}
        </List>
      )}

      {props.currentUser?.isExpert() && (
        <List header={<h2 className="dashboard-title">Dashboard Esperto</h2>} size="large" bordered>
          {TitlesOrderExpert.map((titleKey) => (
            <List.Item key={titleKey}>
              <Link className="dashboard-link" to={`/home/dashboard/${titleKey}`}>
                <Avatar size={64} className="dashboard-avatar">
                  {getCounterByTitle(TitlesMapEditor[titleKey])}
                </Avatar>
                {TitlesMapExpert[titleKey]}
              </Link>
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};

export default HomeDashboard;
