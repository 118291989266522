import { Form, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import QuestionService from '../services/QuestionService';

interface Props {
  questionsService: QuestionService;
  questionId: string;
  onOk: () => void;
  setVisible: (value: React.SetStateAction<boolean>) => void;
  visible: boolean;
}

const AskForInfoModal: React.FC<Props> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const handleOk = async () => {
    form
      .validateFields()
      .then(async () => {
        await props.questionsService
          .askEndUserForInfo(props.questionId, form.getFieldValue('message'))
          .then(() => {
            props.onOk();
            message.success('Messaggio inviato con succeso');
            setConfirmLoading(true);
            props.setVisible(false);
            form.resetFields();
          })
          .catch((e) => {
            message.error(`Errore nell'inviare il messaggio: ${e}`);
          });
      })
      .catch(() => {});
  };

  const handleCancel = () => {
    props.setVisible(false);
  };

  return (
    <Modal
      title="Richiesta Chiarimenti"
      visible={props.visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      data-testid="ask-for-info-modal"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Campo obbligatorio',
              whitespace: true,
            },
          ]}
          label="Messaggio da inviare all'utente finale"
          name="message"
        >
          <TextArea placeholder="Messaggio da inviare all'utente" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AskForInfoModal;
