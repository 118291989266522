import { useCallback } from 'react';
import { Question } from '../models/Question';
import useSortQuestionsInContainer from './useSortQuestionsInContainer';

const useAssociateQuestionsToContainer = () => {
  const { groupQuestionsByRubrics, fromGroupedQuestionsToQuestions } = useSortQuestionsInContainer();

  const associateQuestions = useCallback(
    (questions: Question[], questionsToAssociate: Question[]) => {
      const groupedQuestions = groupQuestionsByRubrics(questions.concat(questionsToAssociate));
      const updatedQuestions = fromGroupedQuestionsToQuestions(groupedQuestions);
      return updatedQuestions;
    },
    [fromGroupedQuestionsToQuestions, groupQuestionsByRubrics],
  );

  const containerRubricsToAssociate = useCallback((initialQuestions: Question[], updatedQuestions: Question[]) => {
    const initialQuestionIds = initialQuestions.map(({ id }) => id);

    return updatedQuestions
      .filter(({ id: questionId }) => !initialQuestionIds.includes(questionId))
      .map((question) => ({
        questionId: question.id,
        rubricId: question.containerInfo?.containerRubricId || question.rubricId,
        subRubricId: question.containerInfo?.containerSubRubricId || question.subRubricId,
      }));
  }, []);

  return {
    associateQuestions,
    containerRubricsToAssociate,
  };
};

export default useAssociateQuestionsToContainer;
