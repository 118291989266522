import './GestionePagheModal.css';
import { EuroOutlined } from '@ant-design/icons';
import { PaymentExpertRow, PaymentDetail } from '../../../../services/GestionePagheService';
import { Modal, Table, Space, Tooltip } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DateUtils from '../../../../utils/DateUtils';
import Constants from '../../../../Constants';

type GestionePagheModalProps = {
  paymentExpertRow: PaymentExpertRow;
  onModalCancel: () => void;
  onModalSaveConfirm: () => void;
  updateAnswersPayability: (answersToUpdate: PaymentDetail[]) => Promise<void>;
  isModalVisible: boolean;
};

const columns = [
  {
    title: 'ID Quesito',
    dataIndex: 'questionId',
    key: 'questionId',
    render: (_: string, question: PaymentDetail) => (
      <div>
        <Link target={'_blank'} to={`/home/questions/?initialQuestionDetails=${question.questionId}`}>
          {question.questionId}
        </Link>
      </div>
    ),
  },
  {
    title: 'Campo Editoriale',
    dataIndex: 'editorSubject',
    key: 'editorSubject',
    width: '35%',
    render: (_: string, question: PaymentDetail) => (
      <span>{question.editorSubject.split(Constants.STRING_SPLITTER).join(' ')}</span>
    ),
  },
  {
    title: '€',
    dataIndex: 'typology',
    key: 'typology',
    width: 50,
    render: (_: string, question: PaymentDetail) => (
      <Space size="middle">
        <Tooltip title="Tipo">{question.typology === 'PAY' && <EuroOutlined />}</Tooltip>
      </Space>
    ),
  },
  {
    title: 'Valore',
    dataIndex: 'answerValue',
    key: 'answerValue',
  },
  {
    title: 'Versione',
    dataIndex: 'version',
    key: 'version',
    render: (version: number) => <span>{version + 1}ª</span>,
  },
  {
    title: 'Data Accettazione',
    dataIndex: 'acceptedDate',
    key: 'acceptedDate',
    render: (date: Date) => <span>{DateUtils.formatOnlyDate(date)}</span>,
  },
  {
    title: 'Data Invio Risposta',
    dataIndex: 'answerDate',
    key: 'answerDate',
    render: (date: Date) => <span>{DateUtils.formatOnlyDate(date)}</span>,
  },
  {
    title: 'Data Scadenza',
    dataIndex: 'deadlineDate',
    key: 'deadlineDate',
    render: (date: Date) => <span>{DateUtils.formatOnlyDate(date)}</span>,
  },
];
const GestionePagheModal = (props: GestionePagheModalProps) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    props.paymentExpertRow?.details.filter((d) => d.payable).map((d) => d.answerId) ?? [],
  );

  const findTheRowsToUpdates = (newSelectedRowKeys: React.Key[]): PaymentDetail[] => {
    const oldRows: PaymentDetail[] = [...props.paymentExpertRow.details];
    const changedRows: PaymentDetail[] = [];

    oldRows.forEach((old: PaymentDetail) => {
      if (newSelectedRowKeys.includes(old.answerId) && !old.payable) {
        changedRows.push({ ...old, payable: true });
      }
      if (!newSelectedRowKeys.includes(old.answerId) && old.payable) {
        changedRows.push({ ...old, payable: false });
      }
    });

    return changedRows;
  };

  const onModalSaveConfirm = async () => {
    setIsModalLoading(true);
    const rowsToUpdate = findTheRowsToUpdates(selectedRowKeys);
    await props.updateAnswersPayability(rowsToUpdate);
    setIsModalLoading(false);
    props.onModalSaveConfirm();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <Modal
      okText="Salva"
      title={`Modifica risposte da pagare ${props.paymentExpertRow?.name} ${props.paymentExpertRow?.surname}`}
      visible={props.isModalVisible}
      onOk={onModalSaveConfirm}
      confirmLoading={isModalLoading}
      onCancel={props.onModalCancel}
      width={'95vw'}
    >
      <div>
        <Table
          className="GestionePagheModal__table"
          scroll={{ y: '60vh' }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          rowKey={(record: PaymentDetail) => record.answerId}
          columns={columns}
          dataSource={props.paymentExpertRow.details}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </div>
    </Modal>
  );
};

export default GestionePagheModal;
