import { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartProps = {
  data: ChartData<'pie'>;
  onSliceClickAtIndex?: (index: number) => void;
};

const PieChart: FC<PieChartProps> = (props) => {
  return (
    <>
      {props.onSliceClickAtIndex && <p>Fare click sulla torta per analizzare ulteriormente i dati</p>}
      <Pie
        data={props.data}
        options={{
          onHover: function (event, activeElements) {
            if (!props.onSliceClickAtIndex) return 'auto';
            (event?.native?.target as HTMLElement).style.cursor = activeElements?.length > 0 ? 'pointer' : 'auto';
          },
          onClick: function (_, element) {
            if (element.length > 0 && element[0].index !== undefined) {
              props.onSliceClickAtIndex?.(element[0].index);
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                filter: (legendItem, data) => {
                  const label: string = legendItem.text;
                  if (!data || !data.labels) return true;
                  const findLabel = (l: unknown) => l === label;
                  const labelIndex = data.labels.findIndex(findLabel);
                  if (labelIndex < 0) {
                    legendItem.text = `${legendItem.text}: 0`;
                    return true;
                  }
                  const qtd = data.datasets[0].data[labelIndex];
                  legendItem.text = `${legendItem.text}: ${qtd}`;
                  return true;
                },
              },
            },
          },
        }}
        style={{ maxHeight: '55vh', marginTop: '2rem' }}
      />
    </>
  );
};

export default PieChart;
