import { FC, useEffect, useState } from 'react';
import ReportsControlBar, { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import PieChart from '../components/PieChart/PieChart';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ReportProps, downloadCSVFromChartData, DEFAULT_FILTERS } from '../utils';
import { ChartData } from 'chart.js';
import ReportService, { ReportPersonaleEspertoData } from '../../../services/ReportService';
import getColorForIndex from '../../../utils/getColorForIndex';

const mapReportPersonaleEspertoToChart = (data: ReportPersonaleEspertoData[], title: string): ChartData<'pie'> => {
  const labels: string[] = [];
  const values: number[] = [];
  const backgroundColor: string[] = [];
  data.forEach((e: ReportPersonaleEspertoData, i: number) => {
    const color: string = getColorForIndex(i);
    labels.push(e.statusName);
    values.push(e.total);
    backgroundColor.push(color);
  });

  return {
    labels,
    datasets: [
      {
        label: title,
        data: values,
        backgroundColor,
      },
    ],
  };
};

const ReportPersonaleEsperto: FC<ReportProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [reportData, setReportData] = useState<ChartData<'pie'> | null>(null);

  const getReportData = async (theFilters: FiltersCallbackResult) => {
    setIsRefreshingData(true);
    try {
      const data: ReportPersonaleEspertoData[] = await ReportService.fetchReportPersonaleEsperto(theFilters);
      setReportData(mapReportPersonaleEspertoToChart(data, 'Personale esperto'));
    } catch {
      setReportData(null);
      console.error('Impossibile recuperare il personale esperto');
    } finally {
      setIsRefreshingData(false);
    }
  };
  useEffect(() => {
    getReportData(DEFAULT_FILTERS);
  }, []);

  const handleDownloadCSV = (data: ChartData<'pie'> | null, title: string, columns: string[]) => {
    if (!data) return;
    downloadCSVFromChartData(data, title, columns);
  };

  return (
    <div>
      <h2>Report Personale Esperto</h2>
      <ReportsControlBar
        filtersAvailable={['typology', 'creationDate']}
        channelData={props.channelData}
        rubricTreeData={props.rubricTreeData}
        questionStatusData={props.questionStatusData}
        onSubmit={(values) => getReportData(values)}
        onDownloadCSV={() => handleDownloadCSV(reportData, 'Personale esperto', ['label', 'value'])}
      />
      <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
        {reportData && <PieChart data={reportData} />}
      </LoadingSpinner>
    </div>
  );
};

export default ReportPersonaleEsperto;
