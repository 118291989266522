import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Channel } from '../../../../models/Channel';
import CurrentUser from '../../../../models/CurrentUser';
import OrganizationService from '../../../../services/OrganizationService';
import ExpertChannelFormListRow from './ExpertChannelFormListRow';

interface Props {
  form: FormInstance;
  organizationService: OrganizationService;
  disabled?: boolean;
  currentUser: CurrentUser;
}

const ExpertChannelFormList: React.FC<Props> = (props) => {
  const formListName = 'expertChannels';
  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);
  const [editorChannels, setEditorChannels] = useState<string[] | undefined>(undefined);

  const loadChannels = useCallback(async (): Promise<void> => {
    let channels = await props.organizationService.getInputChannels();

    if (props.currentUser.isEditor()) {
      const editorChannelsIds = props.currentUser.channels.map((channel) => channel.id);
      setEditorChannels(editorChannelsIds);
    }

    setChannels(channels);
  }, [props.currentUser, props.organizationService]);

  useEffect(() => {
    loadChannels();
  }, [loadChannels]);

  return (
    <Form.List
      name={formListName}
      rules={[
        {
          validator: async (_, selectedChannels) => {
            if (!selectedChannels || selectedChannels.length === 0) {
              return Promise.reject(new Error('Selezionare almeno un canale'));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field) => (
            <ExpertChannelFormListRow
              key={field.key}
              formListName={formListName}
              fieldName={field.name}
              channels={channels}
              remove={remove}
              form={props.form}
              organizationService={props.organizationService}
              disabled={props.disabled}
              editorChannels={editorChannels}
            />
          ))}
          <Form.Item>
            <Button
              disabled={props.disabled}
              data-testid="add-button"
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Aggiungi canale
            </Button>
          </Form.Item>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};

export default ExpertChannelFormList;
