import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
const { Option } = Select;

interface ClassificationFormItemProps {
  form: FormInstance;
  classifications: string[];
  canEditClassifications: () => boolean;
}

const ClassificationsFormItem: React.FC<ClassificationFormItemProps> = (props: ClassificationFormItemProps) => {
  return (
    <Form.List name="classifications">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <div key={field.key} style={{ display: 'flex', alignItems: 'center' }}>
                <Input.Group compact>
                  <Form.Item name={[field.name, 'formRank']} style={{ marginBottom: '0' }}>
                    <Input readOnly style={{ width: '7.5em', textAlign: 'right' }} disabled />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'classificationValue']}
                    style={{ marginBottom: 0, width: '28em' }}
                    rules={[{ required: true, message: 'Inserisci la classificazione' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Cerca classificazione"
                      optionFilterProp="children"
                      dropdownMatchSelectWidth={600}
                      // getPopupContainer={() => document.querySelector('.question-modal')!}
                      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                      disabled={!canEditClassificationValue(index)}
                    >
                      {props.classifications.map((classificationName) => (
                        <Option value={classificationName}>{classificationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
                {props.canEditClassifications() && (
                  <>
                    <MinusCircleOutlined data-testid="remove-button" onClick={() => remove(field.name)} />
                  </>
                )}
              </div>
            ))}
            {props.canEditClassifications() && (
              <Form.Item>
                <Button
                  data-testid="add-button"
                  type="dashed"
                  onClick={() =>
                    add({
                      formRank: 'MANUAL',
                      manual: true,
                      classificationValue: '',
                      rank: 0,
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  Aggiungi classificazione
                </Button>
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.List>
  );

  function canEditClassificationValue(index: number) {
    return props.canEditClassifications() && props.form.getFieldValue('classifications')[index].manual === true;
  }
};

export default ClassificationsFormItem;
