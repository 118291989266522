import { Button, Card, Col, Form, message, Row, Space, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArchivedQuestion, ArchivedQuestionsContainerDetails } from '../../../models/ArchivedQuestionContainerDetails';
import CurrentUser from '../../../models/CurrentUser';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import UserService from '../../../services/UserService';
import DateUtils from '../../../utils/DateUtils';
import OrganizationUtils from '../../../utils/OrganizationUtils';
import { ArchivedQuestionDetails } from './ArchivedQuestionDetails';

type Props = {
  navigationService: NavigationService;
  userService: UserService;
  questionsContainerService: QuestionsContainerService;
  currentUser: CurrentUser;
  organizationService: OrganizationService;
};

const ArchivedQuestionsContainersDetailsPage: FC<Props> = (props) => {
  const [form] = Form.useForm();

  const { containerId } = useParams<{ containerId?: string }>();
  const [archivedContainer, setArchivedContainer] = useState<ArchivedQuestionsContainerDetails | undefined>(undefined);
  const [archivedQuestionToShow, setArchivedQuestionToShow] = useState<ArchivedQuestion | undefined>(undefined);
  const [showArchivedQuestionModal, setShowArchivedQuestionModal] = useState(false);

  const loadData = useCallback(async () => {
    if (!containerId) {
      message.error(`${containerId} is undefined`);
      return;
    }

    const archivedContainerDetails = await props.questionsContainerService.getArchivedQuestionsContainerDetails(
      atob(containerId),
    );

    await OrganizationUtils.addOrganizationNamesToQuestions(
      archivedContainerDetails.questions,
      props.organizationService,
    );

    setArchivedContainer(archivedContainerDetails);
  }, [containerId, props.organizationService, props.questionsContainerService]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onCloseModal = () => {
    setShowArchivedQuestionModal(false);
  };

  let columns = [
    {
      title: 'Rubrica',
      key: 'rubricId',
      render: (question: ArchivedQuestion) => (
        <span>{question.containerInfo ? question.containerInfo.containerRubricName : question.rubricName}</span>
      ),
    },
    {
      title: 'SottoRubrica',
      key: 'subRubricId',
      render: (question: ArchivedQuestion) => (
        <span>{question.containerInfo ? question.containerInfo.containerSubRubricName : question.subRubricName}</span>
      ),
    },
    {
      title: 'Firma (Esperti)',
      key: 'signerUser',
      render: (question: ArchivedQuestion) => (
        <span>{question.answers?.[0].answeredBy.map((user) => user.displayName).join(', ')}</span>
      ),
    },
    {
      title: 'Titolo Quesito',
      dataIndex: 'subject',
      key: 'subject',
    },
  ];

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical">
        <Card title={'title'} type="inner">
          <Form
            form={form}
            layout="vertical"
            name="create_or_edit_questions_container"
            className="ant-create-or-edit-questions-container-form"
          >
            <Row gutter={24}>
              <Col span={12}>
                <Text>
                  <b>Titolo:</b> {archivedContainer?.title}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <b>Numero Uscita:</b> {archivedContainer?.issueNumber}
                </Text>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Text>
                  <b>Data pubblicazione:</b> {DateUtils.format(archivedContainer?.publicationDate)}
                </Text>
              </Col>
              <Col span={12}>
                <Text>
                  <b>Numero progressivo quesito:</b> {archivedContainer?.startQuestionNumber}
                </Text>
              </Col>
            </Row>
          </Form>
        </Card>

        <Table
          sticky={true}
          rowClassName={'archived-question-container-table-row'}
          bordered
          columns={columns}
          dataSource={archivedContainer?.questions}
          onRow={(archivedQuestionInArchivedContainer, _rowIndex) => {
            return {
              onClick: () => {
                setShowArchivedQuestionModal(true);
                setArchivedQuestionToShow(archivedQuestionInArchivedContainer);
              },
            };
          }}
        />

        <Button type={'primary'} onClick={() => props.navigationService.goToArchivedContainers()}>
          Archivio
        </Button>
      </Space>
      {archivedQuestionToShow && (
        <Modal
          width={'60%'}
          title="Archived Question"
          visible={showArchivedQuestionModal}
          onOk={onCloseModal}
          onCancel={onCloseModal}
        >
          <ArchivedQuestionDetails question={archivedQuestionToShow} />
        </Modal>
      )}
    </>
  );
};

export default ArchivedQuestionsContainersDetailsPage;
