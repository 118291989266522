import {
  AllArchivedQuestionsContainer,
  ArchivedQuestionsContainerDetails,
} from '../models/ArchivedQuestionContainerDetails';
import { QuestionsContainer } from '../models/QuestionsContainer';
import AssignQuestionsToContainerRequest from '../models/requests/AssignQuestionsToContainerRequest';
import { SortQuestionsFromContainerRequest } from '../models/requests/SortQuestionsInContainerRequest';
import { AssignQuestionsToContainerResponse } from '../models/responses/AssignQuestionsToContainerResponse';
import ApiGateway from './ApiGateway';

export default class QuestionsContainerService {
  private readonly GET_QUESTIONS_CONTAINERS = '/containers/all';
  private readonly GET_ARCHIVED_QUESTIONS_CONTAINERS = '/containers/archived';
  private readonly GET_QUESTIONS_CONTAINER_BY_ID = '/containers/get';
  private readonly QUESTIONS_CONTAINER_QUERY = '/containers/query';
  private readonly QUESTIONS_CONTAINER_CREATE = '/containers/create';
  private readonly QUESTIONS_CONTAINER_BLOCK = '/containers/block';
  private readonly QUESTIONS_CONTAINER_CLOSE = '/containers/close';
  private readonly QUESTIONS_CONTAINER_UPDATE = '/containers/update';
  private readonly QUESTIONS_CONTAINER_ASSIGN = '/containers/assign';
  private readonly QUESTIONS_CONTAINER_DISASSOCIATE = '/containers/disassociate';
  private readonly QUESTIONS_CONTAINER_SORT = '/containers/sort';
  private readonly QUESTIONS_CONTAINER_DRAFT = '/containers/draftContainer';

  async getQuestionsContainerById(containerId: string): Promise<QuestionsContainer> {
    try {
      const response = await ApiGateway.get(this.GET_QUESTIONS_CONTAINER_BY_ID, { containerId });
      return response.data;
    } catch (e: any) {
      console.error('getQuestionsContainers', e);
      throw new Error(`Impossibile recuperare il fascicolo ${containerId}. (Dettaglio dell'errore: ${e})`);
    }
  }

  async getQuestionsContainersByQuestionId(questionId: string): Promise<QuestionsContainer[]> {
    try {
      const response = await ApiGateway.post(this.QUESTIONS_CONTAINER_QUERY, { questionId });
      return response.data;
    } catch (e: any) {
      console.error('getContainersByQuestionId', e);
      throw new Error(
        `Impossibile recuperare i fascicoli associati al quesito: ${questionId}. (Dettaglio dell'errore: ${e})`,
      );
    }
  }

  async getQuestionsContainers(): Promise<QuestionsContainer[]> {
    try {
      const response = await ApiGateway.get(this.GET_QUESTIONS_CONTAINERS);
      return response.data;
    } catch (e: any) {
      console.error('getQuestionsContainers', e);
      if (e.message && e.message === 'Forbidden') {
        return [];
      }
      throw new Error(`Impossibile recuperare i fascicoli. (Dettaglio dell'errore: ${JSON.stringify(e, null, 2)})`);
    }
  }

  async getArchivedQuestionsContainers(): Promise<AllArchivedQuestionsContainer[]> {
    try {
      const response = await ApiGateway.get(this.GET_ARCHIVED_QUESTIONS_CONTAINERS);
      return response.data;
    } catch (e: any) {
      console.error('getQuestionsContainers', e);
      if (e.message && e.message === 'Forbidden') {
        return [];
      }
      throw new Error(
        `Impossibile recuperare i fascicoli archiviati. (Dettaglio dell'errore: ${JSON.stringify(e, null, 2)})`,
      );
    }
  }

  async getArchivedQuestionsContainerDetails(containerId: string): Promise<ArchivedQuestionsContainerDetails> {
    try {
      const response = await ApiGateway.get(this.GET_ARCHIVED_QUESTIONS_CONTAINERS, { containerId });
      return response.data;
    } catch (e: any) {
      console.error('getQuestionsContainers', e);
      throw new Error(
        `Impossibile recuperare il fascicolo archiviato. (Dettaglio dell'errore: ${JSON.stringify(e, null, 2)})`,
      );
    }
  }

  async createOrUpdateContainer(request: QuestionsContainer, type: string) {
    if (type === 'create') {
      await ApiGateway.post(this.QUESTIONS_CONTAINER_CREATE, request);
    } else if (type === 'update') {
      await ApiGateway.post(this.QUESTIONS_CONTAINER_UPDATE, request);
    }
  }

  async blockContainer(containerId: string, block: boolean) {
    await ApiGateway.post(this.QUESTIONS_CONTAINER_BLOCK, { containerId: containerId, block: block });
  }

  async assignQuestionsToContainer(
    request: AssignQuestionsToContainerRequest,
  ): Promise<AssignQuestionsToContainerResponse> {
    const response = await ApiGateway.post(this.QUESTIONS_CONTAINER_ASSIGN, request);
    return response.data;
  }

  async disassociateQuestionsFromContainer(questionsIds: string[], containerId: string) {
    await ApiGateway.post(this.QUESTIONS_CONTAINER_DISASSOCIATE, { containerId, questionsIds });
  }

  async sortQuestionsInContainer(request: SortQuestionsFromContainerRequest) {
    try {
      const response = await ApiGateway.post(this.QUESTIONS_CONTAINER_SORT, request);
      return response.data;
    } catch (e: any) {
      console.error('sortQuestionsInContainer', e);
      throw new Error(
        `Impossibile ordinare i quesiti nel fascicolo. (Dettaglio dell'errore: ${JSON.stringify(e, null, 2)})`,
      );
    }
  }

  async closeContainer(containerId: string) {
    await ApiGateway.post(this.QUESTIONS_CONTAINER_CLOSE, { containerId: containerId });
  }

  async getContainerDraft(containerId: string): Promise<any> {
    try {
      const response = await ApiGateway.get(this.QUESTIONS_CONTAINER_DRAFT, { containerId });
      return response.data;
    } catch (e: any) {
      console.error('getContainerDraft', e);
      if (e.message && e.message === 'Forbidden') {
        return [];
      }
      throw new Error(`Impossibile creare bozza. (Dettaglio dell'errore: ${JSON.stringify(e, null, 2)})`);
    }
  }
}
