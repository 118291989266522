import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Drawer, Form, Input, message, Select, Space, Transfer, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import TextArea from 'antd/lib/input/TextArea';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import PopconfirmWithCustomButtons from '../../../components/PopconfirmWithCustomButtons/PopconfirmWithCustomButtons';
import Constants from '../../../Constants';
import * as config from '../../../env/env.json';
import { Channel, Rubric } from '../../../models/Channel';
import { ChannelType } from '../../../models/requests/CreateChannelRequest';
import OrganizationService from '../../../services/OrganizationService';
import UploadService, { S3UploadInfo } from '../../../services/UploadService';
const { RangePicker } = DatePicker;
const cfg = (config as any).default;

interface props {
  setCreateChannelDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  createChannelDrawerVisible: boolean;
  organizationService: OrganizationService;
  uploadService: UploadService;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChannel: Channel | undefined;
}

const CreateEditChannelDrawer: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [channelType, setChannelType] = useState<ChannelType>('NORMAL');
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [isEnabledForMethode, setIsEnabledForMethode] = useState(false);
  const [isOutputChannel, setIsOutputChannel] = useState(false);

  const [formFieldsChanged, setFormFieldsChanged] = useState<boolean>(false);
  const [popConfirmExitWithChangesVisible, setPopConfirmExitWithChangesVisible] = useState<boolean>(false);

  const [enableAssignment, setEnableAssignment] = useState<boolean>(props.selectedChannel?.enableAssignment || true);

  const handleChange = (nextTargetKeys: string[]) => {
    setFormFieldsChanged(true);
    setTargetKeys(nextTargetKeys);
  };

  const createChannelButton = (
    <Button
      loading={isLoading}
      onClick={() => {
        createOrUpdateInputChannel('create');
      }}
      type="primary"
    >
      Salva
    </Button>
  );

  const editChannelButton = (
    <Button
      disabled={!formFieldsChanged}
      loading={isLoading}
      onClick={() => {
        createOrUpdateInputChannel('update');
      }}
      type="primary"
    >
      Salva
    </Button>
  );

  const createOrUpdateInputChannel = (action: 'create' | 'update') => {
    setIsLoading(true);
    return form
      .validateFields()
      .then(async () => {
        // FIXME: add try/catch here below, or chain promises
        let s3UploadInfoResponse: S3UploadInfo | undefined;
        if (imageFile) {
          s3UploadInfoResponse = await props.uploadService.getS3ForumBannerUploadInfo(imageFile.name);

          uploadImage(imageFile, s3UploadInfoResponse);
        }
        const { isInputChannel, isOutputChannel } = mapToChannelDirection(form.getFieldValue('channelDirection'));
        await props.organizationService
          .createOrUpdateChannel(
            {
              ...form.getFieldsValue(true),
              rubricIds: targetKeys,
              visibleFrom: form.getFieldValue('visibilityRange')?.at(0)?.toDate(),
              visibleTo: form.getFieldValue('visibilityRange')?.at(1)?.toDate(),
              acceptQuestionFrom: form.getFieldValue('acceptQuestionRange')?.at(0)?.toDate(),
              acceptQuestionTo: form.getFieldValue('acceptQuestionRange')?.at(1)?.toDate(),
              isInput: isInputChannel,
              isOutput: isOutputChannel,
              ...(s3UploadInfoResponse && { bannerUrl: s3UploadInfoResponse.fields.key }),
              enableAssignment: enableAssignment,
            },
            action,
          )
          .then(() => {
            const actionMessage = action === 'create' ? 'creato' : 'modificato';
            message.success(`Canale ${actionMessage} con successo`);
            setFormFieldsChanged(false);
            props.setRefreshData(true);
          })
          .catch((e) => {
            const actionMessage = action === 'create' ? 'creare' : 'modificare';
            message.error(`Errore nel ${actionMessage} il canale: ${e}`);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((errorInfo) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  async function beforeUpload(file: File) {
    const isJpg = file.type === 'image/jpeg';
    if (!isJpg) {
      message.error("L'unico formato supportato è JPG");
      throw new Error('You can only upload JPG file!');
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Le dimensioni dell'immagine non possono superare 10MB");
      throw new Error('Image must smaller than 10MB!');
    }

    setFormFieldsChanged(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = document.createElement('img');
      img.src = reader.result as string;
      img.onload = async () => {
        var canvas = document.createElement('canvas');
        canvas.width = 570;
        canvas.height = 320;
        var ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, 570, 320);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob!], file.name, file);
          console.log(resizedFile);
          setImageFile(resizedFile);

          let URLObj = window.URL || window.webkitURL;

          const previewUrl = URLObj.createObjectURL(blob!);
          setImageUrl(previewUrl);
        });
      };
    };
  }

  function uploadImage(imageFile: File, s3UploadInfo: S3UploadInfo): void {
    props.uploadService.uploadImage(imageFile, s3UploadInfo).then((response) => {
      console.log(response);
    });
  }

  useEffect(() => {
    if (!props.createChannelDrawerVisible) return;

    if (props.selectedChannel) {
      populateForm(props.selectedChannel);
    } else {
      resetComponent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedChannel, props.createChannelDrawerVisible]);

  useEffect(() => {
    const onComponentLoad = async () => {
      const rubrics = await props.organizationService.getRubrics();
      setRubrics(rubrics);
    };

    onComponentLoad();
  }, [props.organizationService, props.createChannelDrawerVisible]);

  useEffect(() => {
    if (!isEnabledForMethode) {
      form.setFieldsValue({ methodeFolder: '' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabledForMethode]);

  const onCancel = () => {
    setEnableAssignment(true);
    setFormFieldsChanged(false);
    props.setCreateChannelDrawerVisible(false);
  };

  return (
    <React.Fragment>
      <Drawer
        title={isEditingAChannel() ? 'Modifica canale' : 'Crea un nuovo canale'}
        width={720}
        onClose={() => {
          onClose();
        }}
        visible={props.createChannelDrawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <PopconfirmWithCustomButtons
              title={'Ci sono delle modifiche non salvate. Sei sicuro di voler uscire senza salvare?'}
              buttons={
                <>
                  <Button type="ghost" size="small" onMouseDown={handlePopConfirmExitWithChangesCancel}>
                    Esci senza salvare
                  </Button>
                  <Button type="primary" size="small" onMouseDown={handlePopConfirmExitWithChangesOk}>
                    Salva ed esci
                  </Button>
                </>
              }
              visible={popConfirmExitWithChangesVisible && isEditingAChannel()}
              setVisible={setPopConfirmExitWithChangesVisible}
              placement="bottomLeft"
            >
              <Button onClick={onClose}>Indietro</Button>
            </PopconfirmWithCustomButtons>

            {!isEditingAChannel() && createChannelButton}
            {isEditingAChannel() && editChannelButton}
          </Space>
        }
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Form
            form={form}
            name="channelName"
            autoComplete="off"
            onFinish={onFinish}
            onFieldsChange={() => setFormFieldsChanged(true)}
            initialValues={{
              type: 'NORMAL',
              channelDirection: 'INPUT',
              enableAssignment: props.selectedChannel?.enableAssignment || enableAssignment,
            }}
          >
            <Form.Item
              label="Nome Canale"
              name="channelName"
              rules={[{ required: true, message: 'Nome Canale Obbligatorio' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              data-testid="form-item-channel-channel-direction"
              name={`channelDirection`}
              label={`Input/Output`}
            >
              <Select
                data-testid="form-item-channel-direction-select"
                onSelect={(selectedValue: string) => {
                  if (selectedValue.includes('OUTPUT')) {
                    setIsOutputChannel(true);
                  } else {
                    setIsOutputChannel(false);
                  }
                }}
              >
                {
                  <Select.Option role="form-item-channel-channel-direction-options" value="INPUT">
                    Input
                  </Select.Option>
                }
                {
                  <Select.Option role="form-item-channel-channel-direction-options" value="OUTPUT">
                    Output
                  </Select.Option>
                }
                {
                  <Select.Option role="form-item-channel-channel-direction-options" value="INPUT+OUTPUT">
                    Input e Output
                  </Select.Option>
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="indexingName"
              label="Nome Indicizzazione"
              rules={[{ required: true, message: 'Nome Indicizzazione Obbligatorio' }]}
            >
              <Input />
            </Form.Item>
            {isOutputChannel && (
              <>
                <Form.Item
                  name="enabledForMethode"
                  label="Abilitato per Methode"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    checked={isEnabledForMethode}
                    onChange={(e) => {
                      setIsEnabledForMethode(!isEnabledForMethode);
                    }}
                  ></Checkbox>
                </Form.Item>
                {isEnabledForMethode && (
                  <Form.Item
                    name="methodeFolder"
                    label="Cartella Methode"
                    rules={[
                      {
                        required: isEnabledForMethode,
                        message: 'Nome Indicizzazione Obbligatorio',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item data-testid="form-item-channel-type" name={`type`} label={`Tipo di canale`}>
              <Select
                disabled={isEditingAChannel()}
                data-testid="form-item-role"
                onChange={(e: string) => {
                  setChannelType(e as ChannelType);
                }}
              >
                {
                  <Select.Option role="form-item-channel-type-options" value="NORMAL">
                    Normale
                  </Select.Option>
                }
                {
                  <Select.Option role="form-item-channel-type-options" value="FORUM">
                    Forum
                  </Select.Option>
                }
              </Select>
            </Form.Item>
            <Form.Item name={`enableAssignment`} data-testid="form-item-enableAssignment">
              <span>Abilita l'auto-assegnazione dei quesiti:</span>
              <Checkbox
                checked={enableAssignment}
                style={{ marginLeft: '8px' }}
                onChange={(e) => {
                  const checked = e.target.checked;
                  form.setFieldsValue({ enableAssignment: checked });
                  setEnableAssignment(checked);
                  setFormFieldsChanged(true);
                }}
              ></Checkbox>
            </Form.Item>
            {isForum(channelType) && (
              <>
                <ImgCrop aspect={570 / 320}>
                  <Upload
                    name="banner"
                    listType="picture-card"
                    className="banner-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    action=""
                  >
                    {imageUrl ? (
                      <img src={imageUrl} alt="banner" style={{ width: '100%' }} />
                    ) : (
                      <div>
                        {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>Carica banner del forum</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
                <Form.Item
                  label="Titolo"
                  name="title"
                  rules={[{ required: isForum(channelType), message: 'Titolo del forum obbligatorio' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Descrizione"
                  name="description"
                  rules={[{ required: isForum(channelType), message: 'Descrizione del forum obbligatorio' }]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  label="Periodo di visibilità"
                  name="visibilityRange"
                  rules={[{ required: isForum(channelType), message: 'Periodo di visibilità del forum obbligatorio' }]}
                >
                  <RangePicker format={Constants.FRONTEND_DATE_FORMAT} disabledDate={dateIsBeforeToday} />
                </Form.Item>
                <Form.Item
                  label="Periodo di accettazione"
                  name="acceptQuestionRange"
                  rules={[
                    {
                      required: isForum(channelType),
                      message: 'Periodo di accettazione delle risposte nel forum obbligatorio',
                    },
                  ]}
                >
                  <RangePicker format={Constants.FRONTEND_DATE_FORMAT} disabledDate={dateIsOutsideVisibilityRange} />
                </Form.Item>
                <Form.Item
                  data-testid="form-item-reading-type"
                  name={`readingType`}
                  label={`Tipologia di lettura`}
                  rules={[{ required: isForum(channelType), message: 'Tipologia di lettura obbligatoria' }]}
                >
                  <Select data-testid="form-reading-type">
                    {
                      <Select.Option role="form-item-reading-type-options" value="FREE">
                        FREE
                      </Select.Option>
                    }
                    {
                      <Select.Option role="form-item-reading-type-options" value="PAY">
                        PAY
                      </Select.Option>
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="sitePageAdv" label="Site page ADV">
                  <Input placeholder="ilsole24ore.com/Speciali_Dossier/default_dossier" />
                </Form.Item>
                <Form.Item name="brandedContentDescription" label="Descrizione Branded Content">
                  <Input placeholder="sponsored by" />
                </Form.Item>
                <Form.Item name="clientContentDescription" label="Cliente Branded Content">
                  <Input placeholder="Fiat" />
                </Form.Item>
              </>
            )}
          </Form>
          <div className="rubrics-transfer-component">
            <Transfer
              disabled={isLoading}
              dataSource={rubrics?.map((rubric) => ({ ...rubric, key: rubric.rubricId }))}
              titles={['Tutte le rubriche', 'Rubriche Associate']}
              targetKeys={targetKeys}
              onChange={handleChange}
              render={(item) => item.rubricName}
              oneWay
              listStyle={{
                width: 400,
                height: 300,
              }}
            />
          </div>
        </Space>
      </Drawer>
    </React.Fragment>
  );

  function onFinish() {
    createOrUpdateInputChannel('update');
  }

  function onClose() {
    if (formFieldsChanged && isEditingAChannel()) {
      setPopConfirmExitWithChangesVisible(true);
    } else {
      onCancel();
    }
  }

  function handlePopConfirmExitWithChangesOk() {
    setPopConfirmExitWithChangesVisible(false);
    onFinish();
    onCancel();
  }

  function handlePopConfirmExitWithChangesCancel() {
    setPopConfirmExitWithChangesVisible(false);
    onCancel();
  }

  function isEditingAChannel() {
    return props.selectedChannel !== undefined;
  }

  function isForum(channelType: ChannelType) {
    return channelType === 'FORUM';
  }

  function resetComponent() {
    form.resetFields();
    setChannelType('NORMAL');
    setTargetKeys([]);
    // set all the fields to their initial values
    setIsEnabledForMethode(false);
    setImageUrl(undefined);
    setImageFile(undefined);
    setIsOutputChannel(false);
  }

  async function populateForm(channel: Channel) {
    setIsLoading(true);
    setChannelType(channel.type);
    setIsEnabledForMethode(channel.enabledForMethode ?? false);
    setIsOutputChannel(channel.isOutput);
    setEnableAssignment(channel.enableAssignment ?? true);
    form.setFieldsValue({
      ...props.selectedChannel,
      channelDirection: mergeChannelDirection(channel),
      visibilityRange: mergeVisibilityRange(channel),
      acceptQuestionRange: mergeAcceptQuestionRange(channel),
    });
    const rubrics = await props.organizationService.getRubricsByChannelId(channel.channelId);
    console.log(rubrics.map((rubric) => rubric.rubricId));
    setTargetKeys(rubrics.map((rubric) => rubric.rubricId));
    if (channel.bannerUrl) {
      setImageUrl(cfg.IMAGE_BASE_URL + channel.bannerUrl);
    } else {
      setImageUrl(undefined);
    }
    setIsLoading(false);
  }

  function mapToChannelDirection(channelDirection: string) {
    const channelDirections = channelDirection.split('+');
    const isInputChannel = channelDirections.includes('INPUT');
    const isOutputChannel = channelDirections.includes('OUTPUT');
    return { isInputChannel, isOutputChannel };
  }

  function mergeChannelDirection(channelDirection: { isInput: boolean; isOutput: boolean }) {
    const channelDirections: string[] = [];
    if (channelDirection.isInput) {
      channelDirections.push('INPUT');
    }
    if (channelDirection.isOutput) {
      channelDirections.push('OUTPUT');
    }
    return channelDirections.join('+');
  }

  function mergeVisibilityRange(visibilityRange?: { visibleFrom?: Date; visibleTo?: Date }) {
    return [
      moment(visibilityRange?.visibleFrom, Constants.BACKEND_DATETIME_FORMAT),
      moment(visibilityRange?.visibleTo, Constants.BACKEND_DATETIME_FORMAT),
    ];
  }
  function mergeAcceptQuestionRange(acceptQuestionRange?: { acceptQuestionFrom?: Date; acceptQuestionTo?: Date }) {
    return [
      moment(acceptQuestionRange?.acceptQuestionFrom, Constants.BACKEND_DATETIME_FORMAT),
      moment(acceptQuestionRange?.acceptQuestionTo, Constants.BACKEND_DATETIME_FORMAT),
    ];
  }

  function dateIsBeforeToday(current: Moment): boolean {
    return current && current < moment().startOf('day');
  }

  function dateIsOutsideVisibilityRange(current: Moment): boolean {
    const visibilityFrom: moment.Moment | undefined = form.getFieldValue('visibilityRange')?.at(0);
    const visibilityTo: moment.Moment | undefined = form.getFieldValue('visibilityRange')?.at(1);

    return (
      (current &&
        visibilityFrom &&
        visibilityTo &&
        (current < visibilityFrom.startOf('day') || current > visibilityTo.endOf('day'))) ??
      true
    );
  }
};

export default CreateEditChannelDrawer;
