import { Col, Skeleton, Space, Statistic } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Rubric } from '../../../../models/Channel';
import { Question } from '../../../../models/Question';

type QuestionsStatisticsType = {
  total: number;
  fiscal: number;
  notFiscal: number;
};

interface Props {
  rubrics: Rubric[];
  questions: Question[];
  isLoading: boolean;
}

const QuestionsStatistics: React.FC<Props> = (props) => {
  const [questionStatistics, setQuestionStatistics] = useState<QuestionsStatisticsType>({
    total: 0,
    fiscal: 0,
    notFiscal: 0,
  });

  const getQuestionsStatistics = useCallback(() => {
    const fiscalRubrics = props.rubrics.filter((rubric) => rubric.isFiscal).map((rubric) => rubric.rubricId);

    const fiscalQuestionsNumber = props.questions.filter((question) =>
      fiscalRubrics.includes(question.rubricId),
    ).length;

    setQuestionStatistics({
      total: props.questions.length,
      fiscal: fiscalQuestionsNumber,
      notFiscal: props.questions.length - fiscalQuestionsNumber,
    });
  }, [props.questions, props.rubrics]);

  useEffect(() => {
    getQuestionsStatistics();
  }, [getQuestionsStatistics]);

  return (
    <>
      {props.isLoading ? (
        <QuestionsStatisticsSkeleton />
      ) : (
        <div data-testid="questions-statistics">
          <Space size="large">
            <Col>
              <Statistic title="Quesiti Totali" value={questionStatistics.total} />
            </Col>
            <Col>
              <Statistic title="Quesiti Fiscali" value={questionStatistics.fiscal} />
            </Col>
            <Col>
              <Statistic title="Quesiti Non Fiscali" value={questionStatistics.notFiscal} />
            </Col>
          </Space>
        </div>
      )}
    </>
  );
};

const QuestionsStatisticsSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <QuestionsStatisticsSkeletonLine />
      <QuestionsStatisticsSkeletonLine />
      <QuestionsStatisticsSkeletonLine />
    </div>
  );
};

const QuestionsStatisticsSkeletonLine = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '0.75em' }} data-testid="loading-skeleton">
      <Skeleton.Input active style={{ height: '1.5em', width: '5em', marginBottom: '0.5em' }} />
      <Skeleton.Input active style={{ height: '2.5em', width: '5em', marginBottom: '0.5em' }} />
    </div>
  );
};

export default QuestionsStatistics;
