import { CheckOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Input, Menu, Space, Table, Tag } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { ReactNode } from 'react';
import ResizableTableHeader from '../../../components/ResizableTableHeader/ResizableTableHeader';
import TableScrollNavigation from '../../../components/TableScrollNavigation';
import User from '../../../models/User';
import { Topic } from '../../../models/UserChannel';
import NavigationService from '../../../services/NavigationService';
import UserService from '../../../services/UserService';
import UserRole from '../../../utils/UserRole';
import './UsersTable.css';

type UsersTableProps = {
  data: User[];
  refreshData: () => Promise<void>;
  userService: UserService;
  navigationService: NavigationService;
};

type UsersTableState = {
  columns: any;
};

type UserKeys = keyof User;

export default class UsersTable extends React.Component<UsersTableProps, UsersTableState> {
  getColumnSearchProps(placeholder: string, dataIndex: UserKeys) {
    return {
      filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) {
        const onInputChange = ({ target: { value } }: any) => setSelectedKeys(value ? [value] : []);
        const onConfirmAndClose = () => {
          confirm({ closeDropdown: true });
        };
        const onConfirm = () => {
          confirm({ closeDropdown: false });
        };
        const onReset = () => {
          if (clearFilters) clearFilters();
          confirm({ closeDropdown: false });
        };
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => node}
              placeholder={`Cerca ${placeholder}`}
              value={selectedKeys[0]}
              onChange={onInputChange}
              onPressEnter={onConfirm}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button type="primary" onClick={onConfirm} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                Search
              </Button>
              <Button onClick={onReset} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button type="link" size="small" onClick={onConfirmAndClose}>
                Close
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon(filtered: any) {
        const color = filtered ? '#1890ff' : undefined;
        return <SearchOutlined style={{ color }} />;
      },
      onFilterDropdownVisibleChange(visible: any) {},
      onFilter(searchText: string, user: User) {
        return user[dataIndex]?.toString().toLocaleLowerCase().includes(searchText.toLowerCase());
      },
    };
  }
  createColumns(
    title: string,
    dataIndex: UserKeys,
    width: number,
    sortable: boolean,
    searchable: boolean,
    filters: any[] = [],
    render?: (text: string, record: User) => ReactNode,
    defaultFilteredValue: any[] = [],
  ) {
    const sorter = sortable
      ? (a: User, b: User) => a[dataIndex]!.toString().localeCompare(b[dataIndex]!.toString())
      : undefined;

    const fullTextSearchProps = searchable ? this.getColumnSearchProps(title, dataIndex) : {};

    const dropDownSearchProps = filters.length
      ? {
          filters,
          defaultFilteredValue,
          filterDropdown({ filters, setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) {
            const onConfirm = () => {
              confirm({ closeDropdown: true });
            };
            const onReset = () => {
              if (clearFilters) clearFilters();
              confirm({ closeDropdown: false });
            };

            const onSelect = ({ item, key, keyPath, selectedKey, domEvent }: any) => {
              console.log(key);
              setSelectedKeys([...selectedKeys, key]);
            };

            const onDeselect = ({ item, key, keyPath, selectedKey, domEvent }: any) => {
              setSelectedKeys(selectedKeys.filter((k) => k !== key));
            };
            return (
              <div style={{ padding: 8 }}>
                <Menu onSelect={onSelect} onDeselect={onDeselect}>
                  {filters?.map((filter: any, index) => {
                    const key = String(filter.value);
                    return (
                      <Menu.Item key={filter.value !== undefined ? key : index}>
                        <Checkbox checked={selectedKeys.includes(key)} />
                        <span>{filter.text}</span>
                      </Menu.Item>
                    );
                  })}
                </Menu>

                <div className={`ant-table-filter-dropdown-btns`}>
                  <Button type="link" size="small" disabled={selectedKeys.length === 0} onClick={() => onReset()}>
                    Reset
                  </Button>
                  <Button type="primary" size="small" onClick={() => onConfirm()}>
                    Ok
                  </Button>
                </div>
              </div>
            );
          },
          onFilter(value: any, user: User) {
            return value === user[dataIndex];
          },
        }
      : {};

    return {
      title,
      dataIndex,
      width,
      sorter,
      render,
      ...fullTextSearchProps,
      ...dropDownSearchProps,
    };
  }

  renderUserRole(_: string, user: User) {
    return User.convertRoleName(user.role);
  }

  renderUserEnabled(_: string, user: User) {
    return user.enabled ? <CheckOutlined className={'center-icon'} /> : <StopOutlined className={'center-icon'} />;
  }

  renderUserTopics(topics: Topic[]) {
    return topics.map(({ name }) => (
      <div key={name}>
        <Tag>{name}</Tag>
        <br />
      </div>
    ));
  }

  handleResize(index: number) {
    return (e: any, { size }: any) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };
  }

  state = {
    columns: [
      this.createColumns('Nome', 'name', 200, true, true),
      { defaultSortOrder: 'ascend', ...this.createColumns('Cognome', 'surname', 200, true, true) },
      this.createColumns('Email', 'id', 200, false, true),
      this.createColumns(
        'Ruolo',
        'role',
        100,
        true,
        false,
        [
          { text: User.SUPER_AMMINISTRATORE, value: UserRole.SUPER_ADMINISTRATOR },
          { text: User.AMMINISTRATORE, value: UserRole.ADMINISTRATOR },
          { text: User.REDATTORE, value: UserRole.EDITOR },
          { text: User.ESPERTO, value: UserRole.EXPERT },
        ],
        this.renderUserRole,
      ),
      this.createColumns(
        'Attivo',
        'enabled',
        100,
        true,
        false,
        [
          { text: 'Attivo', value: true },
          { text: 'Non Attivo', value: false },
        ],
        this.renderUserEnabled,
        ['true'],
      ),
      this.createColumns('SuperEsperto In', 'topicsAsSuperExpert', 450, false, true, [], (_: string, user: User) =>
        this.renderUserTopics(user.topicsAsSuperExpert),
      ),
      this.createColumns('Esperto/Redattore In', 'topicsAsExpert', 450, false, true, [], (_: string, user: User) =>
        this.renderUserTopics(user.topicsAsExpert),
      ),
      this.createColumns('Note', 'userNote', 450, false, true),
    ],
  };

  components = {
    header: {
      cell: ResizableTableHeader,
    },
  };

  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column: { width: number }) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    return (
      <TableScrollNavigation>
        <Table
          sticky={true}
          bordered
          components={this.components}
          columns={columns as any}
          scroll={{ x: 1300 }}
          dataSource={this.props.data}
          className="users-table"
          rowClassName="users-table-row"
          onRow={(user: User, _) => {
            return {
              onClick: (_) => this.props.navigationService.goToUpdateUsers(user.id),
            };
          }}
        />
      </TableScrollNavigation>
    );
  }
}
