import React from 'react';
import { Alert } from 'antd';
import { Question } from '../../models/Question';
import UserAssignableToQuestion from '../../models/UserAssignableToQuestion';
import AuthorizationHelper from '../../utils/AuthorizationHelper';
import UserRole from '../../utils/UserRole';

interface Props {
  question: Question | undefined;
  user: UserAssignableToQuestion | undefined;
}

const UserAssignableToQuestionNotMatchingTopicAlert: React.FC<Props> = (props) => {
  const getMessage = (): string | undefined => {
    if (
      props.question &&
      props.user?.role &&
      AuthorizationHelper.assignableUserMatchesQuestionTopic(props.user, props.question) === false
    ) {
      if (UserRole.isEditor(props.user.role)) {
        return `Il canale "${props.question.channelName}" del quesito non corrisponde a quello del redattore ${props.user.displayName}`;
      }
      if (UserRole.isExpert(props.user.role)) {
        return `Il canale/rubrica/sottorubrica "${props.question.channelName}/${props.question.rubricName}/${props.question.subRubricName}" 
                del quesito non corrisponde a quello dell'esperto ${props.user.displayName}`;
      }
    }
    return undefined;
  };

  return (
    <>
      {getMessage() !== undefined && (
        <Alert
          data-testid="user-assignable-to-question-not-matching-topic-alert"
          style={{ fontSize: 'smaller' }}
          message={getMessage()}
          type="warning"
          showIcon
        ></Alert>
      )}
    </>
  );
};

export default UserAssignableToQuestionNotMatchingTopicAlert;
