import { Button } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import PopconfirmWithCustomButtons from '../../../components/PopconfirmWithCustomButtons/PopconfirmWithCustomButtons';
import './Popconfirm.css';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onGoBackButtonClick: () => void;
  onSaveAndBlockButtonClick: () => Promise<void>;
  onSaveAndUnblockButtonClick: () => Promise<void>;
  placement: TooltipPlacement;
}

const Popconfirm: React.FC<Props> = ({
  visible,
  setVisible,
  children,
  onGoBackButtonClick,
  onSaveAndBlockButtonClick,
  onSaveAndUnblockButtonClick,
  placement,
}) => {
  const GoBackButton = () => (
    <Button type="ghost" size="small" onMouseDown={onGoBackButtonClick}>
      Esci
    </Button>
  );

  const SaveAndBlockButton = () => (
    <Button type="primary" size="small" onMouseDown={onSaveAndBlockButtonClick}>
      Salva e Blocca
    </Button>
  );

  const SaveAndUnblock = () => (
    <Button type="primary" size="small" onMouseDown={onSaveAndUnblockButtonClick}>
      Salva e Sblocca
    </Button>
  );

  const buttons = (
    <div className="questions-container-popconfirm-buttons">
      <GoBackButton />
      <SaveAndBlockButton />
      <SaveAndUnblock />
    </div>
  );

  return (
    <PopconfirmWithCustomButtons
      placement={placement}
      title="Ci sono delle modifiche non salvate. Sei sicuro di voler uscire senza salvare?"
      visible={visible}
      buttons={buttons}
      setVisible={setVisible}
    >
      {children}
    </PopconfirmWithCustomButtons>
  );
};

export default Popconfirm;
