import User from './User';
import { UserChannel, userChannelsToTopics } from './UserChannel';

export default class CurrentUser {
  key: string = '';
  readonly id: string;
  readonly name: string;
  readonly surname: string;
  readonly role: string;
  readonly channels: UserChannel[];
  readonly enableGenAI: boolean;

  constructor(id: string, name: string, surname: string, role: string, channels: UserChannel[], enableGenAI: boolean = false) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.role = User.convertRoleName(role);
    this.channels = channels;
    this.enableGenAI = enableGenAI;
  }

  getChannels(): UserChannel[] {
    return [...this.channels];
  }

  isSuperAdministrator(): boolean {
    return this.role === User.SUPER_AMMINISTRATORE;
  }

  isAdministrator(): boolean {
    return this.role === User.AMMINISTRATORE;
  }

  isEditor(): boolean {
    return this.role === User.REDATTORE;
  }

  isExpert(): any {
    return this.role === User.ESPERTO;
  }

  isSuperExpert(): boolean {
    return userChannelsToTopics(this.channels).some(({ isSuperExpert }) => isSuperExpert);
  }

  isSuperExpertOnTopic(subRubric: string): boolean {
    const allSubRubrics = this.channels
      .map((channel) => channel.rubrics.map((rubric) => rubric.subRubrics.map((subRubric) => subRubric)))
      .flat()
      .flat();
    const subRubricFound = allSubRubrics.find((s) => s.id === subRubric);
    return this.isExpert() && subRubricFound?.isSuperExpert;
  }

  isEditorOrSuperEditorOnChannel(channel: string): boolean {
    return (this.isEditor() || this.isAdministrator()) && this.channels.find((c) => c.id === channel) !== undefined;
  }

  isEditorOnChannel(channel: string): boolean {
    return this.isEditor() && this.channels.find((c) => c.id === channel) !== undefined;
  }

  isExpertOnTopic(subRubric: string): boolean {
    const allSubRubrics = this.channels
      .map((channel) => channel.rubrics.map((rubric) => rubric.subRubrics.map((subRubric) => subRubric)))
      .flat()
      .flat();

    const subRubricFound = allSubRubrics.find((s) => s.id === subRubric);

    return this.isExpert() && subRubricFound !== undefined;
  }

  isAtLeastSuperExpert(): boolean {
    return this.isSuperExpert() || this.isEditor() || this.isSuperAdministrator() || this.isAdministrator();
  }

  isAtLeastEditor(): boolean {
    return this.isEditor() || this.isSuperAdministrator() || this.isAdministrator();
  }
  isEditorOrSuperEditor(): boolean {
    return this.isEditor() || this.isAdministrator();
  }
}
