import { UserRubric } from './UserRubric';
import { UserSubRubric } from './UserSubRubric';

export interface UserChannel {
  name?: string;
  id: string;
  rubrics: UserRubric[];
}

export class Topic {
  constructor(public name: string, public isSuperExpert: boolean = false) { }

  addPrefix(prefix: string): Topic {
    this.name = prefix + ' / ' + this.name;
    return this;
  }

  toString(): string {
    return this.name;
  }
}

export function userChannelsToTopics(channels: UserChannel[]): Topic[] {
  const topicList: Topic[] = [];
  for (const channel of channels) {
    if (channel.rubrics.length === 0) {
      topicList.push(new Topic(channel.name!));
    } else {
      const subtopics: Topic[] = printRubric(channel.rubrics);
      for (const subtopic of subtopics) {
        topicList.push(subtopic.addPrefix(channel.name!));
      }
    }
  }
  return topicList;

  function printRubric(rubrics: UserRubric[]): Topic[] {
    const topicList: Topic[] = [];
    for (const rubric of rubrics) {
      if (rubric.subRubrics.length === 0) {
        topicList.push(new Topic(rubric.name!));
      } else {
        const subtopics = printSubRubric(rubric.subRubrics);
        for (const subtopic of subtopics) {
          topicList.push(subtopic.addPrefix(rubric.name!));
        }
      }
    }
    return topicList;
  }

  function printSubRubric(subRubrics: UserSubRubric[]): Topic[] {
    const topicList: Topic[] = [];
    for (const subrubric of subRubrics) {
      topicList.push(new Topic(subrubric.name!, subrubric.isSuperExpert));
    }
    return topicList;
  }
}
