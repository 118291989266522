import { Alert, Button, Modal } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useCallback, useEffect, useState } from 'react';
import AssociateQuestionsToContainerWithDifferentTaxonomyModal from '../../../../components/AssociateQuestionsToContainerWithDifferentTaxonomyModal';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useAssociateQuestionsToContainer from '../../../../hooks/useAssociateQuestionsToContainer';
import { Channel, Rubric, SubRubric } from '../../../../models/Channel';
import CurrentUser from '../../../../models/CurrentUser';
import { Question } from '../../../../models/Question';
import OrganizationService from '../../../../services/OrganizationService';
import QuestionsContainerService from '../../../../services/QuestionsContainerService';
import QuestionService from '../../../../services/QuestionService';
import UserService from '../../../../services/UserService';
import { UserTranslator } from '../../../../utils/UserTranslator';
import QuestionsTable, { ColumnKey, defaultPagination } from '../../../Questions/QuestionsTable/QuestionsTable';

type Props = {
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  containerService: QuestionsContainerService;
  currentUser: CurrentUser;
  questionsAlreadyInContainer: Question[];
  updateQuestions: (questions: Question[]) => void;
};

const SearchAndSelectQuestion: React.FC<Props> = ({
  userService,
  questionService,
  organizationService,
  containerService,
  currentUser,
  questionsAlreadyInContainer,
  updateQuestions,
}) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const [questionsModalVisible, setQuestionsModalVisible] = useState(false);
  const [checkedQuestions, setCheckedQuestions] = useState<Question[]>([]);
  const { associateQuestions } = useAssociateQuestionsToContainer();
  const [taxonomyModalVisible, setTaxonomyModalVisible] = useState(false);
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({});
  const [sorter, setSorter] = useState<SorterResult<Question>>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);

  const showModal = () => {
    setQuestionsModalVisible(true);
  };

  const handleOk = async () => {
    const isNotAllQuestionsFromEr = checkedQuestions.some(({ channelName }) => channelName !== 'ER');

    setQuestionsModalVisible(false);

    if (isNotAllQuestionsFromEr) {
      setTaxonomyModalVisible(true);
    } else {
      updateQuestions(associateQuestions(questionsAlreadyInContainer, checkedQuestions));
      setCheckedQuestions([]);
    }
  };

  const handleCancel = () => {
    setQuestionsModalVisible(false);
    setErrorMessage(undefined);
    setCheckedQuestions([]);
  };

  const refreshData = useCallback(
    async (
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<Question>,
      pagination: TablePaginationConfig,
    ): Promise<void> => {
      try {
        setFilters(filters);
        setSorter(sorter);
        setPagination(pagination);

        const userTranslator = new UserTranslator(userService);
        setIsRefreshingData(true);
        const queryResponse = await questionService.getPublishableQuestions(
          await userTranslator.translateUsersFor(filters),
          sorter,
          pagination,
        );

        setTotal(queryResponse.fullCount);
        const retrievedQuestions = queryResponse.questions;

        const questionsToShow = retrievedQuestions.filter(
          (question) =>
            questionsAlreadyInContainer.findIndex((questionInContainer) => questionInContainer.id === question.id) ===
            -1,
        );

        setQuestions(questionsToShow);
        setIsRefreshingData(false);
      } catch (e: any) {
        setIsRefreshingData(false);
        console.error(e.message)
        setErrorMessage('Errore nel caricamento dei quesiti');
      }
    },
    [questionService, questionsAlreadyInContainer, userService],
  );

  useEffect(() => {
    refreshData(filters, sorter, pagination);
  }, [filters, pagination, refreshData, sorter]);

  return (
    <>
      <Button type="primary" onClick={showModal} data-testid="select-questions-button">
        Seleziona Quesiti
      </Button>
      <AssociateQuestionsToContainerWithDifferentTaxonomyModal
        isModalVisible={taxonomyModalVisible}
        onOk={handleOnTaxonomyModalOk}
        onCancel={() => setTaxonomyModalVisible(false)}
        questionService={questionService}
        organizationService={organizationService}
        questions={checkedQuestions}
      />
      <Modal
        title="Scegli i quesiti"
        visible={questionsModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="90%"
      >
        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <QuestionsTable
            currentUser={currentUser}
            data={questions}
            total={total}
            refreshData={refreshData}
            questionService={questionService}
            userService={userService}
            organizationService={organizationService}
            containerService={containerService}
            canCreateQuestion={false}
            canPublishQuestion={false}
            setCheckedQuestions={setCheckedQuestions}
            columnsOrder={[
              ColumnKey.id,
              ColumnKey.typology,
              ColumnKey.channel,
              ColumnKey.rubric,
              ColumnKey.subRubric,
              ColumnKey.status,
              ColumnKey.deadline,
              ColumnKey.creationDate,
              ColumnKey.subject,
              ColumnKey.question,
              ColumnKey.assignedTo,
              ColumnKey.signerUser,
              ColumnKey.answerDateTime,
              ColumnKey.containers,
              ColumnKey.blockedUntil,
              ColumnKey.endUser,
              ColumnKey.outputChannels,
            ]}
          />
        </LoadingSpinner>
        {errorMessage && <Alert message={errorMessage} type="error" />}
      </Modal>
    </>
  );

  function handleOnTaxonomyModalOk(channel: Channel, rubric: Rubric, subRubric: SubRubric) {
    setTaxonomyModalVisible(false);

    const updatedQuestionsToAssociate = checkedQuestions.map((question) =>
      question.channelName !== 'ER'
        ? ({
            ...question,
            containerInfo: {
              containerRubricId: rubric.rubricId,
              containerRubricName: rubric.rubricName,
              containerSubRubricId: subRubric.subRubricId,
              containerSubRubricName: subRubric.subRubricName,
            },
          } as Question)
        : ({
            ...question,
            containerInfo: {
              containerRubricId: question.rubricId,
              containerRubricName: question.rubricName,
              containerSubRubricId: question.subRubricId,
              containerSubRubricName: question.subRubricName,
            },
          } as Question),
    );

    updateQuestions(associateQuestions(questionsAlreadyInContainer, updatedQuestionsToAssociate));
    setCheckedQuestions([]);
  }
};

export default SearchAndSelectQuestion;
