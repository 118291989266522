import getColorForIndex from '../../../utils/getColorForIndex';
import { ChartData } from 'chart.js';
import { StatusQuesitiData, RubricheQuesitiData } from '../../../services/ReportService';

export const mapStatusQuesitiDataToChart = (data: StatusQuesitiData[], label: string): ChartData<'pie'> => {
  const labels: string[] = [];
  const values: number[] = [];
  const backgroundColor: string[] = [];

  data.forEach((e: StatusQuesitiData, i: number) => {
    const color: string = getColorForIndex(i);
    labels.push(e.statusLabel);
    values.push(e.count);
    backgroundColor.push(color);
  });

  return {
    labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor,
      },
    ],
  };
};

export const mapRubricheDataToChart = (data: RubricheQuesitiData[], label: string): ChartData<'pie'> => {
  const labels: string[] = [];
  const values: number[] = [];
  const backgroundColor: string[] = [];

  data.forEach((e: any, i: number) => {
    const color: string = getColorForIndex(i);
    labels.push(e.name);
    values.push(e.total);
    backgroundColor.push(color);
  });

  return {
    labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor,
      },
    ],
  };
};

export const mapSottoRubricheToChart = (data: RubricheQuesitiData[], indexSottoRubrica: number): ChartData<'pie'> => {
  const labelSottoRubrica = data[indexSottoRubrica].name;
  const sottoRubricheData = data[indexSottoRubrica].subRubrics;

  const labels: string[] = [];
  const values: number[] = [];
  const backgroundColor: string[] = [];

  sottoRubricheData.forEach((e: any, i: number) => {
    const color: string = getColorForIndex(i);
    labels.push(e.name);
    values.push(e.total);
    backgroundColor.push(color);
  });

  return {
    labels,
    datasets: [
      {
        label: labelSottoRubrica,
        data: values,
        backgroundColor,
      },
    ],
  };
};
