import { Rule } from "antd/lib/form";

export const mandatoryFieldCheckRule: Rule = {
  required: true,
  message: 'Campo obbligatorio',
};

export const mandatoryTextFieldCheckRule: Rule = {
    ...mandatoryFieldCheckRule,
    whitespace: false,
};

export const mandatoryIntegerFieldCheckRule: Rule = {
    ...mandatoryFieldCheckRule,
    type: 'integer',
};
