import axios from 'axios';
import ApiGateway from './ApiGateway';

export default class UploadService {
  private readonly GET_S3_UPLOAD_INFO = '/upload';

  async getS3ExpertAvatarUploadInfo(fileName: string): Promise<S3UploadInfo> {
    const result = await ApiGateway.post(this.GET_S3_UPLOAD_INFO, { imageName: fileName, type: 'expert' });
    console.log('result', result);
    return result.data;
  }

  async getS3ForumBannerUploadInfo(fileName: string): Promise<S3UploadInfo> {
    const result = await ApiGateway.post(this.GET_S3_UPLOAD_INFO, { imageName: fileName, type: 'forum' });
    return result.data;
  }

  async uploadImage(file: File, s3UploadInfo: S3UploadInfo): Promise<void> {
    const formData = new FormData();

    formData.append('acl', s3UploadInfo.fields.acl);
    formData.append('key', s3UploadInfo.fields.key);
    formData.append('bucket', s3UploadInfo.fields.bucket);

    formData.append('X-Amz-Algorithm', s3UploadInfo.fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', s3UploadInfo.fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', s3UploadInfo.fields['X-Amz-Date']);
    formData.append('X-Amz-Security-Token', s3UploadInfo.fields['X-Amz-Security-Token']);
    formData.append('Policy', s3UploadInfo.fields['Policy']);
    formData.append('X-Amz-Signature', s3UploadInfo.fields['X-Amz-Signature']);

    formData.append('file', file, file.name);

    await axios.post(s3UploadInfo.url, formData);
  }
}

export interface S3UploadInfo {
  url: string;
  fields: any;
}
