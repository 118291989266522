import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons/lib/icons';
import { Button, Descriptions, Form, FormInstance, Input, Popconfirm, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import CurrentUser from '../../../models/CurrentUser';
import { Answer, QuestionDetail } from '../../../models/Question';
import { GeneratedAnswer } from '../../../models/GeneratedAnswer';
import AuthorizationHelper from '../../../utils/AuthorizationHelper';
import DateUtils from '../../../utils/DateUtils';

import LoadingSpinner from '../../LoadingSpinner';

const { TextArea } = Input;
const { Option } = Select;

interface AnswerFormItemProps {
  answers: Answer[] | undefined;
  currentUser: CurrentUser;
  question: QuestionDetail;
  selectAnswer: (answerId: string) => void;
  updateQuestionWithEmptyNewAnswer: () => Promise<void>;
  genAIAnswer: (questionQ: string, questionId: string) => Promise<GeneratedAnswer>;
  form: FormInstance;
  setFormFieldsChanged: (val: boolean) => void;
  onGeneratedAnswer?: (answer: string) => void;
  onGeneratedAnswerId?: (genAiId: string) => void;
}

const AnswerFormItem: React.FC<AnswerFormItemProps> = ({
  answers,
  question,
  currentUser,
  selectAnswer,
  updateQuestionWithEmptyNewAnswer,
  genAIAnswer,
  form,
  setFormFieldsChanged,
  onGeneratedAnswer,
  onGeneratedAnswerId,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(undefined);

  const selectAnswerFromSelector = (answerId: string) => {
    setSelectedAnswer(answerId);
    selectAnswer(answerId);
  };

  useEffect(() => {
    if (answers?.length) {
      selectAnswerFromSelector(answers[answers.length - 1].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  if (!answers?.length && AuthorizationHelper.canEditAnswer(currentUser, question)) {
    return (
      <Form.List name="answers">
        {() => {
          return (
            <EditableFormAnswer
              question={question}
              genAIAnswer={genAIAnswer}
              form={form}
              setFormFieldsChanged={setFormFieldsChanged}
              currentUserEnableGenAI={currentUser.enableGenAI}
              onGeneratedAnswer={onGeneratedAnswer}
              onGeneratedAnswerId={onGeneratedAnswerId}
            />
          );
        }}
      </Form.List>
    );
  }

  return (
    <>
      <Form.List name="answers">
        {(fields) => {
          return (
            <div key="answers-form-list">
              {fields.map((field, index) => (
                <>
                  {isSelectedAnswer(answers?.[index]?.id) && (
                    <>
                      {shouldShowSelectedEditableFormAnswer(index) ? (
                        <div key={field.key}>
                          <EditableFormAnswer
                            index={field.name}
                            question={question}
                            genAIAnswer={genAIAnswer}
                            form={form}
                            setFormFieldsChanged={setFormFieldsChanged}
                            currentUserEnableGenAI={currentUser.enableGenAI}
                            onGeneratedAnswer={onGeneratedAnswer}
                            onGeneratedAnswerId={onGeneratedAnswerId}
                          />
                        </div>
                      ) : (
                        <AnswerReadonly answer={answers?.find(({ id }) => id === selectedAnswer)} />
                      )}
                    </>
                  )}
                </>
              ))}

              {AuthorizationHelper.canAddNewAnswers(currentUser, question) && answers && (
                <>
                  <br />
                  <CreateNewAnswerButton updateQuestionWithEmptyNewAnswer={updateQuestionWithEmptyNewAnswer} />
                </>
              )}
            </div>
          );
        }}
      </Form.List>

      <br />

      {answers && (
        <div>
          <br />
          <h1>Risposte</h1>
          <Select
            onChange={(answerId) => {
              selectAnswerFromSelector(answerId);
            }}
            style={{ width: '100%' }}
            value={selectedAnswer}
            data-testid="answers-select"
          >
            {answers
              .filter(({ id }) => id !== undefined)
              .map((answer, index) => (
                <Option key={answer.id} value={answer.id}>
                  {index === answers!.length - 1 ? 'Risposta attuale del' : 'Risposta del '}{' '}
                  {DateUtils.formatOnlyDate(answer.answerDate)} [
                  {answer.answeredBy.map((user) => user.displayName).join(', ')}]
                </Option>
              ))
              .reverse()}
          </Select>
        </div>
      )}
    </>
  );

  function shouldShowSelectedEditableFormAnswer(currentIndex: number) {
    const lastIndex = answers!.length - 1;
    const answerIdAtIndex = answers?.[currentIndex]?.id;

    return (
      (question.status === 'PUBLISHED' && AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, question)) ||
      selectedAnswerIsCurrentValidatedAnswer(answerIdAtIndex, currentIndex, lastIndex)
    );
  }

  function selectedAnswerIsCurrentValidatedAnswer(
    answerIdAtIndex: string | undefined,
    index: number,
    lastIndex: number,
  ): boolean {
    return (
      AuthorizationHelper.canEditAnswer(currentUser, question) &&
      answerIdAtIndex === selectedAnswer &&
      index === lastIndex
    );
  }

  function isSelectedAnswer(answerIdAtIndex: string | undefined) {
    return answerIdAtIndex === selectedAnswer;
  }
};

interface EditableFormAnswerProps {
  index?: number;
  question?: QuestionDetail;
  genAIAnswer?: (questionQ: string, questionId: string) => Promise<GeneratedAnswer>;
  form: FormInstance;
  setFormFieldsChanged: (val: boolean) => void;
  currentUserEnableGenAI: boolean;
  onGeneratedAnswer?: (answer: string) => void;
  onGeneratedAnswerId?: (genAiId: string) => void;
}

const EditableFormAnswer: React.FC<EditableFormAnswerProps> = ({
  index = 0,
  question,
  genAIAnswer,
  form,
  setFormFieldsChanged,
  currentUserEnableGenAI,
  onGeneratedAnswer,
  onGeneratedAnswerId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasGenerateAnswer, setHasGenerateAnswer] = useState<string>('');
  const [disableGenerateAi, setDisableGenerateAi] = useState<boolean>(false);

  // Punto in cui verifico se il quesito è nello stato "Preso in carico" (cioè PROG)
  const isQuestionInProgress = question && question.status === 'PROG';
  const questionQ = question && question.question;
  const questionId = question && question.id;
  const answerGenAiAlreadyPresent = question && question.answerGenAi;

  const handleGenAIAnswerClick = async () => {
    if ((answerGenAiAlreadyPresent && answerGenAiAlreadyPresent !== '') || hasGenerateAnswer !== '') {
      setIsLoading(true);
      const answers = form.getFieldsValue().answers;
      if (answers[index] && answers[index].answer) {
        answers[index] = {
          ...answers[index].answer,
          answer: answerGenAiAlreadyPresent || hasGenerateAnswer,
        };
        //riposta già presente
        form.setFieldsValue({
          answers: answers,
        });
      } else {
        form.setFieldsValue({
          answers: [
            {
              answer: answerGenAiAlreadyPresent || hasGenerateAnswer,
            },
          ],
        });
      }
      setIsLoading(false);
      setFormFieldsChanged(true);
    } else if (genAIAnswer && questionQ && questionId) {
      setIsLoading(true);
      try {
        setDisableGenerateAi(true);
        const generatedAnswer: GeneratedAnswer = await genAIAnswer(questionQ, questionId);
        setDisableGenerateAi(false);
        setHasGenerateAnswer(generatedAnswer.answer);
        onGeneratedAnswer && onGeneratedAnswer(generatedAnswer.answer); // callback che mi serve in QuestionModal
        onGeneratedAnswerId && onGeneratedAnswerId(generatedAnswer.answerGenAiId);
        // Imposta il valore iniziale del campo 'answer' nel form solo se generatedAnswer non è nullo o indefinito
        if (generatedAnswer.answer !== undefined && generatedAnswer.answer !== null) {
          setIsLoading(false);
          // Questo sovrascriverà eventuali answer già presenti
          // console.log(form.getFieldsValue());
          const answers = form.getFieldsValue().answers;
          if (answers[index] && answers[index].answer) {
            answers[index] = {
              ...answers[index].answer,
              answer: generatedAnswer.answer,
            };
            //riposta già presente
            form.setFieldsValue({
              answers: answers,
            });
          } else {
            form.setFieldsValue({
              answers: [
                {
                  answer: generatedAnswer.answer,
                },
              ],
            });
          }
          setFormFieldsChanged(true);
        }
      } catch (error) {
        // FIXME: Si dovrebbe mostrare un errore. Forse serve una callback verso QuestionModal per gestire lì l'errore
        console.error('Si è verificato un errore durante la chiamata al servizio GenAI:', error);
      }
    }
  };

  return (
    <>
      <Form.Item name={[index, 'abstract']} label="Sintesi" data-testid="question-modal-form-abstract">
        <TextArea rows={2} placeholder={'Sintesi'} />
      </Form.Item>
      {isQuestionInProgress && currentUserEnableGenAI && (
        <Form.Item style={{ textAlign: 'right' }}>
          <Button type="primary" disabled={disableGenerateAi} onClick={handleGenAIAnswerClick}>
            {(answerGenAiAlreadyPresent && answerGenAiAlreadyPresent !== '') || hasGenerateAnswer !== ''
              ? 'Recupera risposta AI'
              : 'Genera risposta AI'}
          </Button>
        </Form.Item>
      )}
      <LoadingSpinner fontSize={40} isSpinning={isLoading}>
        <Form.Item
          name={[index, 'answer']}
          label="Risposta"
          data-testid="question-modal-form-answer"
          rules={[
            {
              type: 'string',
              required: true,
              whitespace: true,
              message: 'Inserisci la risposta',
            },
          ]}
        >
          <TextArea rows={6} placeholder={'Risposta'} />
        </Form.Item>
      </LoadingSpinner>
      <span>Riferimenti normativi</span>
      <Form.List name={[index, 'legalReferences']}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Form.Item required={false} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: false,
                      whitespace: true,
                      message: 'Inserisci il riferimento normativo.',
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Riferimento normativo" style={{ width: '95%' }} />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => remove(field.name)}
                  style={{ paddingLeft: '10px', fontSize: '18px' }}
                />
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} style={{ width: '95%' }} icon={<PlusOutlined />}>
                Aggiungi riferimento normativo
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

interface CreateNewAnswerButtonProps {
  updateQuestionWithEmptyNewAnswer: () => Promise<void>;
}

const CreateNewAnswerButton: React.FC<CreateNewAnswerButtonProps> = ({ updateQuestionWithEmptyNewAnswer }) => {
  return (
    <Popconfirm
      title={'Sei sicuro di voler creare una nuova risposta?'}
      onConfirm={async () => {
        await updateQuestionWithEmptyNewAnswer();
      }}
    >
      <Button>Crea nuova risposta</Button>
    </Popconfirm>
  );
};

interface AnswerReadonlyProps {
  answer: Answer | undefined;
}

export const AnswerReadonly: React.FC<AnswerReadonlyProps> = ({ answer }) => {
  if (!answer) return null;

  return (
    <div data-testid="answer-descriptions">
      <Descriptions bordered size="small" layout="vertical">
        {answer.abstract && (
          <Descriptions.Item label="Sintesi" span={3}>
            {answer.abstract}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Risposta" span={3}>
          {answer.answer}
        </Descriptions.Item>
        {answer.legalReferences && answer.legalReferences.length > 0 && (
          <Descriptions.Item label="Riferimenti legislativi" span={3}>
            {answer.legalReferences.map((legalReference: string) => (
              <p>- {legalReference}</p>
            ))}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="Data inserimento risposta">{DateUtils.format(answer.answerDate)}</Descriptions.Item>
        <Descriptions.Item label="Firma">
          {answer.answeredBy.map((user) => user.displayName).join(', ')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default AnswerFormItem;
