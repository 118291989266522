import { Divider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import useSortQuestionsInContainer from '../../../../hooks/useSortQuestionsInContainer';
import { Rubric } from '../../../../models/Channel';
import CurrentUser from '../../../../models/CurrentUser';
import { Question } from '../../../../models/Question';
import { QuestionsContainer } from '../../../../models/QuestionsContainer';
import OrganizationService from '../../../../services/OrganizationService';
import QuestionsContainerService from '../../../../services/QuestionsContainerService';
import QuestionService from '../../../../services/QuestionService';
import UserService from '../../../../services/UserService';
import './ContainerData.css';
import CreateXMLDraftButton from './CreateXMLDraftButton';
import QuestionsStatistics from './QuestionsStatistics';
import QuestionsTable from './QuestionsTable';
import RubricsSummary, { RubricsSummaryData, SubRubricSummary } from './RubricsSummary';
import SearchAndSelectQuestion from './SearchAndSelectQuestion';
import StandardOrderButton from './StandardOrderButton';

interface ContainerDataProps {
  questionService: QuestionService;
  organizationService: OrganizationService;
  questionsContainerService: QuestionsContainerService;
  userService: UserService;
  container: QuestionsContainer | undefined;
  currentUser: CurrentUser;
  editMode: boolean;
  setUpdatedContainerStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setMadeChanges: React.Dispatch<React.SetStateAction<boolean>>;
  questions: Question[];
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
  saveContainer: () => Promise<void>;
}

const ContainerData: React.FC<ContainerDataProps> = ({
  questionService,
  organizationService,
  questionsContainerService,
  userService,
  container,
  currentUser,
  editMode,
  setUpdatedContainerStatus,
  setMadeChanges,
  questions,
  setQuestions,
  saveContainer,
}) => {
  const [rubrics, setRubrics] = useState<Rubric[]>([]);
  const [rubricsSummaryData, setRubricsSummaryData] = useState<RubricsSummaryData[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    reorderQuestionsBasedOnRubricsSubRubrics,
    groupQuestionsByRubrics,
    fromGroupedQuestionsToRubricsSummaryData,
  } = useSortQuestionsInContainer();

  const getContainerData = useCallback(async () => {
    const allRubrics: Rubric[] = await organizationService.getRubrics(true);

    setRubrics(allRubrics);

    const rubricsQuestionsMap = groupQuestionsByRubrics(questions);
    const rubricsSectionData = fromGroupedQuestionsToRubricsSummaryData(rubricsQuestionsMap, allRubrics);

    setRubricsSummaryData(rubricsSectionData);
  }, [fromGroupedQuestionsToRubricsSummaryData, groupQuestionsByRubrics, organizationService, questions]);

  const refreshContainerData = useCallback(async () => {
    setIsLoading(true);
    const questions = await questionService.getQuestionsByContainer(container!.containerId);
    setQuestions(questions);

    await getContainerData();
    setIsLoading(false);
  }, [container, getContainerData, questionService, setQuestions]);

  useEffect(() => {
    if (refreshData) {
      refreshContainerData();

      setRefreshData(false);
    }
  }, [getContainerData, container, refreshData, refreshContainerData]);

  useEffect(() => {
    getContainerData();
    setIsLoading(false);
  }, [getContainerData, questions]);

  return (
    <div data-testid="container-data" className="container-data">
      {editMode && (
        <div style={{ textAlign: 'right' }}>
          <CreateXMLDraftButton
            containerId={container?.containerId!}
            containerService={questionsContainerService}
            setUpdatedContainerStatus={setUpdatedContainerStatus}
            saveContainer={saveContainer}
          />
          <StandardOrderButton
            containerId={container?.containerId!}
            questionsContainerService={questionsContainerService}
            questions={questions}
            updateQuestions={updateQuestions}
          />
          <SearchAndSelectQuestion
            currentUser={currentUser}
            userService={userService}
            questionService={questionService}
            organizationService={organizationService}
            containerService={questionsContainerService}
            questionsAlreadyInContainer={questions}
            updateQuestions={updateQuestions}
          />
        </div>
      )}
      <h1>Quesiti</h1>

      <>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <RubricsSummary
            editMode={editMode}
            data={rubricsSummaryData}
            reorderRubrics={reorderRubrics}
            questions={questions}
            isLoading={isLoading}
            reorderSubRubrics={reorderSubRubrics}
            updateQuestions={updateQuestions}
          />

          <QuestionsStatistics isLoading={isLoading} questions={questions} rubrics={rubrics} />
        </div>

        <Divider />
        <QuestionsTable
          isLoading={isLoading}
          questions={questions}
          setRefreshData={setRefreshData}
          editMode={editMode}
          currentUser={currentUser}
          userService={userService}
          questionService={questionService}
          organizationService={organizationService}
          updateQuestions={updateQuestions}
        />
      </>
    </div>
  );
  function reorderRubrics(reorderedRubrics: RubricsSummaryData[]) {
    setRubricsSummaryData(reorderedRubrics);

    updateQuestions(reorderQuestionsBasedOnRubricsSubRubrics(questions, reorderedRubrics));
  }

  function reorderSubRubrics(reorderedSubRubrics: SubRubricSummary[], rubricId: string) {
    const reorderedRubricsSectionData = rubricsSummaryData.map((rubric) => {
      if (rubric.rubricId === rubricId) {
        return { ...rubric, subRubrics: reorderedSubRubrics };
      }
      return rubric;
    });

    setRubricsSummaryData(reorderedRubricsSectionData);

    updateQuestions(reorderQuestionsBasedOnRubricsSubRubrics(questions, reorderedRubricsSectionData));
  }

  function updateQuestions(updatedQuestions: Question[]) {
    setMadeChanges(true);
    setQuestions(updatedQuestions);
  }
};

export default ContainerData;

export function arrayMove(array: any[], oldIndex: number, newIndex: number) {
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}
