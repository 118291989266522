import { Alert, Button, message, Modal, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Question } from '../models/Question';
import UserAssignableToQuestion from '../models/UserAssignableToQuestion';
import QuestionService from '../services/QuestionService';
import UserService from '../services/UserService';
import AssignQuestionToExpertSelect from './AssignQuestionToExpertSelect';
import UserAssignableToQuestionNotMatchingTopicAlert from './UserAssignableToQuestionNotMatchingTopicAlert/UserAssignableToQuestionNotMatchingTopicAlert';

interface AssignQuestionToExpertModalProps {
  isModalVisible: boolean;
  question: Question;
  questions?: Question[];
  onOk: () => void;
  onCancel: () => void;
  userService: UserService;
  questionService: QuestionService;
}

const AssignQuestionToExpertModal: React.FC<AssignQuestionToExpertModalProps> = (props) => {
  const [selectedExpert, setSelectedExpert] = useState<UserAssignableToQuestion | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  const isMultipleQuestions = !!props.questions;

  const onComponentLoaded = useCallback(async () => {
    if (!props.question || !props.question.assignedTo) return;
    setSelectedExpert(props.question.assignedTo as UserAssignableToQuestion);

    // FIXME props.question, props.question?.assignedTo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question?.assignedTo]);

  async function onOk() {
    setIsLoading(true);

    if (!selectedExpert || (!isMultipleQuestions && !props.question)) {
      props.onCancel();
      setIsLoading(false);
      return;
    }

    if (isMultipleQuestions) {
      try {
        // FIXME: Da passare correttamente il body alla nuova lambda
        const questionIds = props.questions ? props.questions.map((question) => question.id) : [];

        await props.questionService.assignMultipleQuestionsTo(questionIds, selectedExpert.id);
        props.onOk();
        message.success('I quesiti sono stati assegnati con successo');
      } catch (e: any) {
        setErrorMessage(e.toString());
        message.error("Si è verificato un errore durante l'assegnazione dei quesiti");
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        await props.questionService.assignQuestionTo(props.question.id, selectedExpert.id);
        props.onOk();
        message.success('Il quesito è stato assegnato con successo');
      } catch (e: any) {
        setErrorMessage(e.toString());
        message.error("Si è verificato un errore durante l'assegnazione del quesito");
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function onCancel() {
    props.onCancel();
  }

  useEffect(() => {
    onComponentLoaded();
  }, [onComponentLoaded]);

  return (
    <Modal
      title="Assegna"
      visible={props.isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      data-testid="assign-to-expert-modal"
      footer={
        <>
          <Button key="cancel" data-testid="assign-to-expert-modal-cancel" onClick={onCancel}>
            Annulla
          </Button>
          <Button key="ok" data-testid="assign-to-expert-modal-ok" type="primary" loading={isLoading} onClick={onOk}>
            OK
          </Button>
        </>
      }
    >
      <div>
        <Space size="middle">
          <AssignQuestionToExpertSelect
            question={props.question}
            isMultipleQuestions={isMultipleQuestions}
            questions={props.questions}
            userService={props.userService}
            selectedExpert={selectedExpert}
            onExpertSelected={(expert) => setSelectedExpert(expert)}
            width={'470px'}
          />
        </Space>
      </div>
      {errorMessage !== undefined && (
        <div style={{ marginTop: 16 }}>
          <Alert message={errorMessage} type="error" showIcon></Alert>
        </div>
      )}
      <UserAssignableToQuestionNotMatchingTopicAlert question={props.question} user={selectedExpert} />
    </Modal>
  );
};

export default AssignQuestionToExpertModal;
