import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { FiltersCallbackResult } from '../pages/Reports/components/ReportsFilters/ReportsControlBar';
import ApiGateway from './ApiGateway';

const REPORT_STATUS_QUESITI = '/reports/count-question-by-status';
const REPORT_RUBRIC_QUESITI = '/reports/count-question-by-rubric';
const REPORT_PERSONALE_ESPERTO = '/reports/count-expert-question-by-status';
const REPORT_ESPERTI_STATI_QUESITI = '/reports/expert-states-recap';
const DOWNLOAD_REPORT_ESPERTI_STATI_QUESITI = '/reports/expert-states-recap/csv';
const REPORT_ESPERTI_MEDIA_TEMPI_RISPOSTA = '/reports/average-answer-time-by-expert';
const DOWNLOAD_REPORT_ESPERTI_MEDIA_TEMPI_RISPOSTA = '/reports/average-answer-time-by-expert/csv';
const REPORT_CLIENTI_UNICI = '/reports/count-question-by-customer';
const DOWNLOAD_REPORT_CLIENTI_UNICI_CSV = '/reports/count-question-by-customer/csv';
const REPORT_REDAZI0NE_MEDIA_TEMPI_RISPOSTA = '/reports/average-question-by-channel';
const DOWNLOAD_REPORT_REDAZI0NE_MEDIA_TEMPI_RISPOSTA = '/reports/average-question-by-channel/csv';

export type ExpertStatiInfo = {
  fullName: string;
  ass: number;
  ace: number;
  rif: number;
  info: number;
  prog: number;
  ans: number;
  ansrif: number;
  ansagg: number;
};

export type ReportEspertiStatiDeiQuesitiQueryResponse = {
  experts: ExpertStatiInfo[];
  fullCount: number;
};

export type ChannelInfo = {
  channel: string;
  status: string;
  average: number;
  max: number;
  min: number;
};

export type ReportMediaTempiRisposteQueryResponse = {
  readonly channels: ChannelInfo[];
  readonly fullCount: number;
};

export type CustomerInfo = {
  name: string;
  total: number;
};

export type CustomerQuestionsQueryResponse = {
  customers: CustomerInfo[];
  fullCount: number;
};

export type StatusQuesitiData = {
  count: number;
  statusLabel: string;
  status: string;
};

export type RubricheQuesitiData = {
  id: 'string';
  name: 'string';
  total: number;
  subRubrics: {
    id: 'string';
    name: 'string';
    total: number;
  }[];
};

export type ReportPersonaleEspertoData = {
  statusId: string;
  statusName: string;
  total: number;
};

export type CSVlike = {
  [key: string]: string | number;
};

const filterEmptyValues = (filters: FiltersCallbackResult) => {
  return Object.fromEntries(
    Object.entries(filters).filter(
      ([_, v]) =>
        v != null &&
        ((Array.isArray(v) && v.length > 0) ||
          (!Array.isArray(v) && typeof v === 'object' && Object.keys(v).length > 0)),
    ),
  );
};

const fetchReportStatusQuesiti = async (filters: FiltersCallbackResult): Promise<StatusQuesitiData[]> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_STATUS_QUESITI, { filters: filtersWithoutEmptyValues });
    return response.data;
  } catch {
    throw new Error('Impossibile recuperare gli stati dei quesiti');
  }
};

const fetchReportRubricheQuesiti = async (filters: FiltersCallbackResult): Promise<RubricheQuesitiData[]> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_RUBRIC_QUESITI, { filters: filtersWithoutEmptyValues });
    return response.data;
  } catch {
    throw new Error('Impossibile recuperare le rubriche dei quesiti');
  }
};

const fetchReportPersonaleEsperto = async (filters: FiltersCallbackResult): Promise<ReportPersonaleEspertoData[]> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_PERSONALE_ESPERTO, {
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const downloadReportClientiUnici = async (filters: FiltersCallbackResult): Promise<CSVlike> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(DOWNLOAD_REPORT_CLIENTI_UNICI_CSV, {
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const fetchReportEspertiStatiDeiQuesiti = async (
  filters: FiltersCallbackResult,
  sorter: SorterResult<ExpertStatiInfo>,
  pagination: TablePaginationConfig,
): Promise<ReportEspertiStatiDeiQuesitiQueryResponse> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_ESPERTI_STATI_QUESITI, {
      sorter,
      pagination,
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const downloadReportEspertiStatiDeiQuesiti = async (filters: FiltersCallbackResult): Promise<CSVlike> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(DOWNLOAD_REPORT_ESPERTI_STATI_QUESITI, {
      sorter: {
        field: 'fullName',
        order: 'ascend',
      },
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

export type AverageAnswerTimeByExpertInfo = {
  fullName: string;
  averageTime: number;
};

export type AverageAnswerTimeByExpertQueryResponse = {
  experts: AverageAnswerTimeByExpertInfo[];
  fullCount: number;
};

const fetchReportEspertiMediaTempiRisposta = async (
  filters: FiltersCallbackResult,
  sorter: SorterResult<AverageAnswerTimeByExpertInfo>,
  pagination: TablePaginationConfig,
): Promise<AverageAnswerTimeByExpertQueryResponse> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_ESPERTI_MEDIA_TEMPI_RISPOSTA, {
      sorter,
      pagination,
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const downloadReportEspertiMediaTempiRisposta = async (filters: FiltersCallbackResult): Promise<CSVlike> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(DOWNLOAD_REPORT_ESPERTI_MEDIA_TEMPI_RISPOSTA, {
      sorter: {},
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const fetchReportClientiUnici = async (
  filters: FiltersCallbackResult,
  pagination: TablePaginationConfig,
): Promise<CustomerQuestionsQueryResponse> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_CLIENTI_UNICI, {
      filters: filtersWithoutEmptyValues,
      pagination,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const fetchReportRedazioneMediaTempiRisposte = async (
  filters: FiltersCallbackResult,
  sorter: SorterResult<ChannelInfo>,
  pagination: TablePaginationConfig,
): Promise<ReportMediaTempiRisposteQueryResponse> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(REPORT_REDAZI0NE_MEDIA_TEMPI_RISPOSTA, {
      sorter,
      pagination,
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const downloadReportRedazioneMediaTempiRisposte = async (
  filters: FiltersCallbackResult,
  sorter: SorterResult<ChannelInfo>,
): Promise<CSVlike> => {
  const filtersWithoutEmptyValues = filterEmptyValues(filters);
  try {
    const response = await ApiGateway.post(DOWNLOAD_REPORT_REDAZI0NE_MEDIA_TEMPI_RISPOSTA, {
      sorter,
      filters: filtersWithoutEmptyValues,
    });
    return response.data;
  } catch {
    throw new Error('Impossibile scaricare il report');
  }
};

const ReportService = {
  fetchReportStatusQuesiti,
  fetchReportRubricheQuesiti,
  fetchReportPersonaleEsperto,
  downloadReportClientiUnici,
  fetchReportEspertiStatiDeiQuesiti,
  downloadReportEspertiStatiDeiQuesiti,
  fetchReportEspertiMediaTempiRisposta,
  downloadReportEspertiMediaTempiRisposta,
  fetchReportClientiUnici,
  fetchReportRedazioneMediaTempiRisposte,
  downloadReportRedazioneMediaTempiRisposte,
};

export type ReportServiceType = typeof ReportService;
export default ReportService;
