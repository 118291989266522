import React from 'react';
import './NotFound.css';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = (props) => {
  return (
    <div className="page-not-found">
      <h1>L'Esperto Risponde</h1>
      <h2>Pagina non trovata</h2>
      <img className="astronaut" alt="astronaut" src="./images/astronaut.png" />
    </div>
  );
};

export default NotFound;
