import { useCallback } from 'react';
import { Question } from '../models/Question';

const useDisassociateQuestionsFromContainer = () => {
  const disassociateQuestionsWithRubricId = useCallback((questions: Question[], rubricId: string) => {
    return questions.filter(({ rubricId: questionRubricId, containerInfo }) =>
      containerInfo ? containerInfo.containerRubricId !== rubricId : questionRubricId !== rubricId,
    );
  }, []);

  const disassociateQuestionsWithSubRubricId = useCallback(
    (questions: Question[], rubricId: string, subRubricId: string) => {
      return questions.filter(({ rubricId: questionRubricId, subRubricId: questionSubRubricId, containerInfo }) =>
        containerInfo
          ? !(containerInfo.containerSubRubricId === subRubricId && containerInfo.containerRubricId === rubricId)
          : !(questionSubRubricId === subRubricId && questionRubricId === rubricId),
      );
    },
    [],
  );

  const disassociateQuestionWithId = useCallback((questions: Question[], id: string) => {
    return questions.filter(({ id: questionId }) => questionId !== id);
  }, []);

  const questionIdsToDisassociate = useCallback((initialQuestions: Question[], updatedQuestions: Question[]) => {
    const updatedQuestionsIds = updatedQuestions.map(({ id }) => id);

    return initialQuestions
      .filter(({ id: questionId }) => !updatedQuestionsIds.includes(questionId))
      .map(({ id }) => id);
  }, []);

  return {
    disassociateQuestionsWithRubricId,
    disassociateQuestionsWithSubRubricId,
    disassociateQuestionWithId,
    questionIdsToDisassociate,
  };
};

export default useDisassociateQuestionsFromContainer;
