import { Form, Row, Col, Input } from 'antd';
import React from 'react';

interface AdvancedSearchFormProps {
  advancedFilterForm: any;
}

const AdvancedSearchForm: React.FC<AdvancedSearchFormProps> = ({ advancedFilterForm }) => {
  return (
    <Form form={advancedFilterForm} layout="horizontal" name="advanced_search">
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            data-testid="advanced-form-filter-subject"
            name={`subject`}
            label={`Titolo`}
            rules={[{ whitespace: true }]}
          >
            <Input placeholder="Titolo quesito" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            data-testid="advanced-form-filter-endUserNameSurname"
            name={`endUserNameSurname`}
            label={`Nome Cognome cliente`}
            rules={[{ whitespace: true }]}
          >
            <Input placeholder="Nome Cognome Cliente" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            data-testid="advanced-form-filter-question"
            name={`question`}
            label={`Domanda`}
            rules={[{ whitespace: true }]}
          >
            <Input placeholder="Domanda" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item data-testid="advanced-form-filter-endUserEmail" name={`endUserEmail`} label={`Email Cliente`}>
            <Input placeholder="Email Cliente" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item data-testid="advanced-form-filter-answer" name={`answer`} label={`Risposta`}>
            <Input placeholder="Risposta" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            data-testid="advanced-form-filter-classification"
            name={`classification`}
            label={`Classificazione`}
          >
            <Input placeholder="Classificazione" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AdvancedSearchForm;
