import { Channel } from '../models/Channel';
import CurrentUser from '../models/CurrentUser';
import { Question } from '../models/Question';
import UserAssignableToQuestion from '../models/UserAssignableToQuestion';
import QuestionStatus from './QuestionStatus';
import UserRole from './UserRole';

export default class AuthorizationHelper {
  public static isAdministratorOrHigher(user: CurrentUser): boolean {
    return user.isAdministrator() || user.isSuperAdministrator();
  }

  public static canEditQuestion(user: CurrentUser, question: Question): boolean {
    if (user.isEditorOrSuperEditorOnChannel(question.channelId)) {
      return true;
    }
    if (user.isSuperExpertOnTopic(question.subRubricId) && user.id === question.assignedTo?.id) {
      return true;
    }
    if (user.isExpert() && user.id === question.assignedTo?.id && question.status === QuestionStatus.PROG) {
      return true;
    }
    return user.isSuperAdministrator();
  }

  public static canEditAnswer(user: CurrentUser, question: Question): boolean {
    return (
      QuestionStatus.beforeACE(question.status) &&
      (AuthorizationHelper.canEditQuestion(user, question) ||
        (question.status === 'ANS' && user.isSuperExpertOnTopic(question.subRubricId)))
    );
  }

  public static canAddNewAnswers(currentUser: CurrentUser, question: Question): boolean {
    return this.canModifyQuestionToValidate(currentUser, question)
  }

  public static canAddComments(user: CurrentUser, question: Question): boolean {
    if (user.isEditorOrSuperEditorOnChannel(question.channelId)) {
      return true;
    }
    if (user.isSuperExpertOnTopic(question.subRubricId) && user.id === question.assignedTo?.id) {
      return true;
    }
    if (
      user.isExpert() &&
      user.id === question.assignedTo?.id &&
      [QuestionStatus.PROG, QuestionStatus.ASS].includes(question.status)
    ) {
      return true;
    }
    return user.isSuperAdministrator();
  }

  public static canEditQuestionRubricAndSubRubric(user: CurrentUser, question: Question): boolean {
    if (user.isEditorOrSuperEditorOnChannel(question.channelId)) {
      return true;
    }
    if (user.isExpert() && user.id === question.assignedTo?.id) {
      return true;
    }
    return user.isSuperAdministrator();
  }

  public static isAtLeastSuperExpertOn(user: CurrentUser, question: Question): boolean {
    return (
      user.isEditorOrSuperEditorOnChannel(question.channelId) ||
      user.isSuperExpertOnTopic(question.subRubricId) ||
      user.isSuperAdministrator()
    );
  }

  public static isAtLeastEditorOrSuperEditorOn(user: CurrentUser, question: Question): boolean {
    return user.isEditorOrSuperEditorOnChannel(question.channelId) || user.isSuperAdministrator();
  }

  public static isAtLeastEditorOn(user: CurrentUser, question: Question): boolean {
    return user.isAdministrator() || user.isSuperAdministrator() || user.isEditorOnChannel(question.channelId);
  }

  public static canEditClassifications(user: CurrentUser, question: Question): boolean {
    return (
      (user.isEditorOrSuperEditorOnChannel(question.channelId) || user.isSuperAdministrator()) &&
      question.status === QuestionStatus.ACE
    );
  }

  public static questionIsAssignedToCurrentUser(user: CurrentUser, question: Question): boolean {
    return question.status === QuestionStatus.ASS && user.id === question.assignedTo?.id;
  }

  public static questionCanBeAssignedByUser(user: CurrentUser, question: Question): boolean {
    if (question.status === QuestionStatus.REJ || question.status === QuestionStatus.CES) return false;

    if (user.isSuperExpertOnTopic(question.subRubricId)) {
      return (
        question.status === QuestionStatus.ING ||
        question.status === QuestionStatus.ASS ||
        question.status === QuestionStatus.PROG
      );
    } else if (user.isEditorOrSuperEditorOnChannel(question.channelId)) {
      return !question.isBlockedForPublication();
    } else {
      return user.isSuperAdministrator();
    }
  }

  public static answerCanBeProposedByUser(user: CurrentUser, question: Question) {
    return question.status === QuestionStatus.PROG && user.id === question.assignedTo?.id;
  }

  public static answerCanBeAcceptedByUser(user: CurrentUser, question: Question): boolean {
    if (question.status === QuestionStatus.ANS) {
      return (
        user.isSuperExpertOnTopic(question.subRubricId) ||
        user.isEditorOrSuperEditorOnChannel(question.channelId) ||
        user.isSuperAdministrator()
      );
    } else return false;
  }

  public static questionCanBeNotifiedByUser(user: CurrentUser, question: Question): boolean {
    if (question.status === QuestionStatus.READY) {
      return AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(user, question);
    } else return false;
  }

  public static questionCanBePublishedByUser(user: CurrentUser, question: Question): boolean {
    if (
      question.status === QuestionStatus.NOTIFIED ||
      question.status === QuestionStatus.PUBLISHED ||
      question.status === QuestionStatus.UNPUB ||
      question.status === QuestionStatus.VALIDATED
    ) {
      return AuthorizationHelper.isAtLeastEditorOn(user, question);
    } else return false;
  }

  public static questionCanBeRePublishedByUser(user: CurrentUser, question: Question): boolean {
    if (
      question.status === QuestionStatus.NOTIFIED ||
      question.status === QuestionStatus.PUBLISHED ||
      question.status === QuestionStatus.VALIDATED
    ) {
      return AuthorizationHelper.isAtLeastEditorOn(user, question);
    } else return false;
  }

  public static questionCanBeValidatedByUser(currentUser: CurrentUser, question: Question): boolean {
    return this.canModifyQuestionToValidate(currentUser, question);
  }

  public static assignableUserMatchesQuestionTopic(user: UserAssignableToQuestion, question: Question): boolean {
    if (UserRole.isEditor(user.role)) {
      return user.channels.find((c) => c.id === question.channelId) !== undefined;
    } else if (UserRole.isExpert(user.role)) {
      return (
        user.channels
          .find((c) => c.id === question.channelId)
          ?.rubrics.find((r) => r.id === question.rubricId)
          ?.subRubrics.find((s) => s.id === question.subRubricId) !== undefined
      );
    } else return true;
  }

  public static canAssignQuestionToMe = (question: Question, currentUser: CurrentUser): boolean => {
    const isRightStatusForExpertOrSuperExpert = (question: Question): boolean => {
      return (
        (currentUser.isExpertOnTopic(question.subRubricId) && question.status === QuestionStatus.ING) ||
        (currentUser.isSuperExpertOnTopic(question.subRubricId) &&
          (question.status === QuestionStatus.ASS || question.status === QuestionStatus.PROG))
      );
    };

    const isNotInAnInvalidStatus = (question: Question): boolean => {
      return question.status !== QuestionStatus.REJ && question.status !== QuestionStatus.CES;
    };

    return (
      isNotInAnInvalidStatus(question) &&
      (AuthorizationHelper.isAtLeastEditorOrSuperEditorOn(currentUser, question) ||
        isRightStatusForExpertOrSuperExpert(question))
    );
  };

  public static filterChannelsForUser(user: CurrentUser, channels: Channel[]): Channel[] | undefined {
    const userChannelIds = user.channels.map((channel) => channel.id);
    return channels.filter((channel) => userChannelIds.includes(channel.channelId));
  }

  private static canModifyQuestionToValidate(user: CurrentUser, question: Question): boolean {
    const hasQuestionNecessaryStatus = question.status === QuestionStatus.TO_VALIDATE
    const isExpertAssignedToQuestion = user.id === question.assignedTo?.id
    return (
      isExpertAssignedToQuestion && hasQuestionNecessaryStatus
    )
  }
}
