import {FC, useEffect} from 'react';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifyConfirmSignIn,
  AmplifyForgotPassword,
  AmplifyLoadingSpinner,
  AmplifyRequireNewPassword,
  AmplifySignIn,
  AmplifyTotpSetup,
  AmplifyVerifyContact,
} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange, TOAST_AUTH_ERROR_EVENT, UI_AUTH_CHANNEL} from '@aws-amplify/ui-components';
import {Hub, HubCallback} from '@aws-amplify/core';
import {Modal} from 'antd';
import './Login.css';
import NavigationService from '../../services/NavigationService';

// If you want to fully customize the error toast, this is the last hope.
// https://github.com/aws-amplify/amplify-js/issues/6479

type LoginProps = {
  navigationService: NavigationService;
  orgName: string;
};

const Login: FC<LoginProps> = (props) => {
  useEffect(() => {
    const onSignedIn = () => {
      props.navigationService.kickToHome();
    };

    const handleToastErrors: HubCallback = ({ payload }) => {
      if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
        Modal.error({
          title: 'Attenzione',
          content: payload.message,
          centered: true,
        });
      }
    };

    onAuthUIStateChange((nextAuthState, _) => {
      if (nextAuthState === AuthState.SignedIn) {
        onSignedIn();
        return;
      }
    });

    Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);

    return () => {
      Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
    };
  }, [props.navigationService]);

  return (
    <div className="login-page">
      <header>L'Esperto Risponde {props.orgName}</header>
      <AmplifyAuthContainer>
        <AmplifyAuthenticator usernameAlias="email" hideToast={true}>
          <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp={true} />
          <AmplifyForgotPassword usernameAlias="email" />
          <AmplifyConfirmSignIn />
          <AmplifyRequireNewPassword />
          <AmplifyVerifyContact />
          <AmplifyTotpSetup />
          <AmplifyLoadingSpinner />
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </div>
  );
};

export default Login;
