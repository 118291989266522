import { ExportToCSV } from '@molteni/export-csv';
import { ChartData } from 'chart.js';
import { CSVlike } from '../../../services/ReportService';

export const downloadCSVFromChartData = (data: ChartData<'pie'>, title: string, columns: string[]) => {
  const csvData = data?.labels?.map((label: unknown, i: number) => {
    return {
      label,
      value: data.datasets[0].data[i],
    };
  });
  const exporter = new ExportToCSV();
  if (csvData) exporter.exportColumnsToCSV(csvData, `${title}.csv`, columns);
};

export const downloadCSVFromJSON = (csvData: any[], title: string, columns: string[]) => {
  const exporter = new ExportToCSV();
  exporter.exportColumnsToCSV(csvData, `${title}.csv`, columns);
};

export const downloadCSVFromText = (data: CSVlike, title: string) => {
  ExportToCSV.download(`${title}.csv`, data);
};
