import { Button, Form, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import CurrentUser from '../../../../models/CurrentUser';
import { Comment, Question } from '../../../../models/Question';
import QuestionService from '../../../../services/QuestionService';
import AuthorizationHelper from '../../../../utils/AuthorizationHelper';
import { CommentsFormItemList } from './CommentsFormItemList';

interface Props {
  questionService: QuestionService;
  question: Question;
  comments: Comment[] | undefined;
  currentUser: CurrentUser;
}

const CommentsFormItem: React.FC<Props> = (props) => {
  const [comments, setComments] = useState<Comment[] | undefined>(props.comments);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <>
      {canAddComments() && (
        <Form form={form} layout="vertical" data-testid="add-comment-form" onFinish={addComment}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Campo obbligatorio',
                whitespace: true,
              },
            ]}
            name="comment"
          >
            <TextArea disabled={loading} placeholder="Commento da aggiungere" autoSize />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" loading={loading} onClick={addComment}>
              Aggiungi commento
            </Button>
          </Form.Item>
        </Form>
      )}

      <CommentsFormItemList
        comments={comments}
        currentUser={props.currentUser}
        questionService={props.questionService}
        updateComments={updateComments}
      />
    </>
  );

  function addComment() {
    setLoading(true);
    form
      .validateFields()
      .then(() => props.questionService.addComment(props.question.id, form.getFieldValue('comment')))
      .then(() => {
        updateComments();
        message.success('Commento aggiunto con successo');
        form.resetFields();
      })
      .catch((error) => message.error(JSON.stringify(error)))
      .finally(() => {
        setLoading(false);
      });
  }

  async function updateComments() {
    const updatedComments = (await props.questionService.getQuestionDetails(props.question.id)).comments;
    setComments(updatedComments);
  }

  function canAddComments() {
    return AuthorizationHelper.canAddComments(props.currentUser, props.question);
  }
};

export default CommentsFormItem;
