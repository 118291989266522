import { Alert, Form, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Channel, Rubric, SubRubric } from '../models/Channel';
import { Question } from '../models/Question';
import OrganizationService from '../services/OrganizationService';
import QuestionService from '../services/QuestionService';
import SelectUtils from '../utils/SelectUtils';
import LoadingSpinner from './LoadingSpinner';

const { Option } = Select;

interface Props {
  isModalVisible: boolean;
  questions?: Question[];
  onOk: (channel: Channel, rubric: Rubric, subRubric: SubRubric) => void;
  onCancel: () => void;
  questionService: QuestionService;
  organizationService: OrganizationService;
}

const AssociateQuestionsToContainerWithDifferentTaxonomyModal: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [subRubrics, setSubRubrics] = useState<SubRubric[] | undefined>(undefined);

  const [selectedRubric, setSelectedRubric] = useState<Rubric | undefined>(undefined);
  const [selectedSubRubric, setSelectedSubRubric] = useState<SubRubric | undefined>(undefined);

  const resetModalState = () => {
    setSelectedRubric(undefined);
    setSelectedSubRubric(undefined);
    setErrorMessage(undefined);
    form.resetFields();
  };

  const loadingDefaultOption = (
    <Select.Option disabled={true} value="">
      <LoadingSpinner fontSize={20} />
      <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
    </Select.Option>
  );

  useEffect(() => {
    if (!props.isModalVisible) {
      return;
    }
    (async () => {
      try {
        const channels = await props.organizationService.getOutputChannels();
        const channel = channels.find((c) => c.channelName === 'ER');

        setChannel(channel);

        setErrorMessage(undefined);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    })();
  }, [props.organizationService, props.isModalVisible]);

  useEffect(() => {
    setSelectedRubric(undefined);
    setRubrics(undefined);
    setSubRubrics(undefined);
    (async () => {
      try {
        const rubrics = await props.organizationService.getRubricsByChannelId(channel!.channelId);
        setRubrics(rubrics);
        setErrorMessage(undefined);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.channelId]);

  useEffect(() => {
    let rubric = rubrics?.find((rubric) => rubric.rubricId === selectedRubric?.rubricId);
    setSelectedSubRubric(undefined);
    setSubRubrics(rubric?.subRubrics);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRubric?.rubricId]);

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title="Associa Quesiti al fascicolo"
      visible={props.isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      data-testid="taxonomy-modal"
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item name="outputChannelId" label={` Canale ${channel?.channelName}`}>
          <Select disabled defaultValue={channel?.channelId}>
            <Option value={channel?.channelId}>{channel?.channelName}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="outputRubricId"
          label={` Rubrica`}
          rules={[{ required: true, message: 'Aggiungi una rubrica!' }]}
        >
          <Select
            showSearch
            placeholder="Seleziona una rubrica"
            value={selectedRubric?.rubricId}
            onSelect={setSelectedRubricById}
            filterOption={SelectUtils.filterOption}
            optionFilterProp="children"
          >
            {showRubricOptions(rubrics, loadingDefaultOption)}
          </Select>
        </Form.Item>
        <Form.Item
          name="outputSubRubricId"
          label={` SottoRubrica`}
          rules={[{ required: true, message: 'Aggiungi una sottoRubrica!' }]}
        >
          <Select
            showSearch
            placeholder="Seleziona una sotto rubrica"
            value={selectedSubRubric?.subRubricId}
            onSelect={setSelectedSubRubricById}
            optionFilterProp="children"
            filterOption={SelectUtils.filterOption}
            disabled={selectedRubric === undefined}
          >
            {showSubRubricOptions(subRubrics, loadingDefaultOption)}
          </Select>
        </Form.Item>
      </Form>
      {errorMessage !== undefined && (
        <div style={{ marginTop: 16 }}>
          <Alert message={errorMessage} type="error" showIcon></Alert>
        </div>
      )}
    </Modal>
  );

  function showRubricOptions(rubrics?: Rubric[], defaultOptions?: React.ReactElement) {
    if (rubrics === undefined) {
      return defaultOptions;
    }

    return rubrics.map(({ rubricId, rubricName }) => (
      <Select.Option key={rubricId} value={rubricId} role="taxonomy-field-rubric-options">
        {rubricName}
      </Select.Option>
    ));
  }

  function showSubRubricOptions(subRubrics?: SubRubric[], defaultOptions?: React.ReactElement) {
    if (subRubrics === undefined) {
      return defaultOptions;
    }
    return subRubrics.map(({ subRubricId, subRubricName }, index) => (
      <Select.Option key={subRubricId} value={subRubricId} role="taxonomy-field-subrubric-options">
        {subRubricName}
      </Select.Option>
    ));
  }

  async function onOk() {
    if (props.questions?.length === 0) {
      return setErrorMessage('Nessun Quesito Selezionato');
    }

    try {
      await form.validateFields();
      props.onOk(channel!, selectedRubric!, selectedSubRubric!);
      resetModalState();
    } catch (err: any) {
      setErrorMessage(err.message); // fixme: distinguish between validation errors and other errors
    }
  }

  function onCancel() {
    resetModalState();
    props.onCancel();
  }

  function setSelectedRubricById(id: string) {
    const rubric = rubrics?.find(({ rubricId }) => rubricId === id);
    setSelectedRubric(rubric);
  }

  function setSelectedSubRubricById(id: string) {
    let subRubric = subRubrics?.find(({ subRubricId }) => subRubricId === id);
    setSelectedSubRubric(subRubric);
  }
};

export default AssociateQuestionsToContainerWithDifferentTaxonomyModal;
