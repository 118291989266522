import React from 'react';
import { QuestionDetail, Sender } from '../../../models/Question';
import DateUtils from '../../../utils/DateUtils';
import './ChatsFormItem.css';

interface Props {
  question: QuestionDetail;
}

const ChatsFormItem: React.FC<Props> = (props) => {
  return (
    <div data-testid="chats-form-item">
      {props.question.infoRequestedDate && (
        <div className="message-starting-date">{DateUtils.format(props.question.infoRequestedDate)}</div>
      )}
      {props.question.chats?.map((chat) => (
        <div className="message-box-holder" key={chat.chatId}>
          <div className={`message-sender ${chat.sender === Sender.EndUser ? 'end-user' : 'back-office'}`}>
            <p>
              {chat.sender === Sender.BackOffice
                ? 'BackOffice'
                : `${props.question?.endUserName?.charAt(0)}. ${props.question?.endUserSurname?.charAt(0)}.`}
            </p>
          </div>
          <div className={`message-box ${chat.sender === Sender.EndUser ? 'end-user' : 'back-office'}`}>
            <p>{chat.chatMessage}</p>
            <div className={`message-date-time ${chat.sender === Sender.EndUser ? 'end-user' : 'back-office'}`}>
              {DateUtils.format(chat.chatCreationDate)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatsFormItem;
