import * as config from '../env/env.json';
import { Channel, Rubric } from '../models/Channel';
import { CreateChannelRequest } from '../models/requests/CreateChannelRequest';
import ApiGateway from './ApiGateway';

const cfg = (config as any).default;

export default class OrganizationService {
  private readonly GET_CHANNELS = '/inputChannels/get';

  private readonly GET_RUBRICS = '/inputChannels/rubrics/get';

  private readonly CREATE_CHANNEL = '/inputChannels/create';

  private readonly CREATE_RUBRIC = '/inputChannels/rubrics/create';

  private readonly UPDATE_RUBRIC = '/inputChannels/rubrics/update';

  private readonly UPDATE_CHANNEL = '/inputChannels/update';

  private readonly DELETE_CHANNEL = '/inputChannels/delete';

  private readonly DELETE_RUBRIC = '/inputChannels/rubrics/delete';

  private readonly DELETE_SUBRUBRIC = '/inputChannels/rubrics/subRubrics/delete';

  private readonly GET_MEDIATOPIC_CLASSIFICATIONS = '/questions/getMediatopicClassifications';

  allNames: Map<string, string> = new Map();

  ORGANIZATION_NAME: string = cfg.ORGANIZATION_NAME;
  CURRENT_ENV: string = cfg.CURRENT_ENV;

  getOrganizationName(): string {
    return this.ORGANIZATION_NAME;
  }

  getEnvironment(): string {
    return this.CURRENT_ENV;
  }

  getVisibleEnvironment(): string {
    if (this.getEnvironment() !== 'prod') {
      return ' - ' + this.getEnvironment();
    }
    return '';
  }

  getOrganizationAndEnv(): string {
    return this.getOrganizationName() + this.getVisibleEnvironment();
  }

  async getMediatopicClassifications(): Promise<string[]> {
    try {
      const response = await ApiGateway.get(this.GET_MEDIATOPIC_CLASSIFICATIONS);
      return response.data;
    } catch (e) {
      console.error('getMediatopicClassifications', e);
      throw new Error('Error retrieving mediatopic classifications.');
    }
  }

  async getChannels(includeDeleted?: boolean): Promise<Channel[]> {
    try {
      const response = await ApiGateway.get(this.GET_CHANNELS, includeDeleted ? { includeDeleted } : {});
      return response.data;
    } catch (e) {
      console.error('getChannels', e);
      throw new Error('Error retrieving channels.');
    }
  }

  async getInputChannels(includeDeleted?: boolean): Promise<Channel[]> {
    try {
      const channels = await this.getChannels(includeDeleted);
      return channels.filter(({ isInput }) => isInput);
    } catch (e) {
      console.error('getInputChannels', e);
      throw new Error('Error retrieving channels.');
    }
  }

  async getOutputChannels(includeDeleted?: boolean): Promise<Channel[]> {
    try {
      const channels = await this.getChannels(includeDeleted);
      return channels.filter(({ isOutput }) => isOutput);
    } catch (e) {
      console.error('getOutputChannels', e);
      throw new Error('Error retrieving channels.');
    }
  }

  async getRubrics(includeDeleted?: boolean): Promise<Rubric[]> {
    try {
      const response = await ApiGateway.get(this.GET_RUBRICS, includeDeleted ? { includeDeleted } : {});
      return response.data;
    } catch (e) {
      console.error('getRubricsByChannel', e);
      throw new Error('Error retrieving rubrics.');
    }
  }

  async getRubricsByChannelId(channelId: string): Promise<Rubric[]> {
    try {
      const response = await ApiGateway.get(this.GET_RUBRICS, { channelId });
      return response.data;
    } catch (e) {
      console.error('getRubricsByChannelId', e);
      throw new Error('Error retrieving rubrics. \n' + JSON.stringify(e, null, 2));
    }
  }

  async createOrUpdateChannel(request: CreateChannelRequest, action: 'create' | 'update'): Promise<void> {
    try {
      switch (action) {
        case 'create':
          await ApiGateway.post(this.CREATE_CHANNEL, { ...request });
          break;
        case 'update':
          await ApiGateway.post(this.UPDATE_CHANNEL, { ...request });
          break;
      }

      this.invalidateCache();
    } catch (e) {
      console.error(`${action}Channel`, e);

      if ((e as Error).toString().includes('duplicate key value')) {
        throw new Error('Esiste già un canale con questa descrizione');
      }

      throw e;
    }
  }

  async createOrUpdateRubric(request: any, action: 'create' | 'update'): Promise<void> {
    try {
      switch (action) {
        case 'create':
          await ApiGateway.post(this.CREATE_RUBRIC, { ...request });
          break;
        case 'update':
          await ApiGateway.post(this.UPDATE_RUBRIC, { ...request });
          break;
      }

      this.invalidateCache();
    } catch (e) {
      console.error('createRubric', e);

      if ((e as Error).toString().includes('duplicate key value violates unique constraint "rubric_rubric_name_key"')) {
        throw new Error('Esiste già una rubrica con questa descrizione');
      }

      if (
        (e as Error)
          .toString()
          .includes('duplicate key value violates unique constraint "subrubric_rubric_subrubric_name_key"')
      ) {
        throw new Error('Esiste già una sottorubrica con questa descrizione');
      }

      throw e;
    }
  }

  async deleteChannel(channelId: string): Promise<void> {
    try {
      await ApiGateway.post(this.DELETE_CHANNEL, { channelId });
    } catch (e) {
      console.error('deleteChannel', e);
      throw new Error('Error Deleting Channel');
    }
  }

  async deleteRubric(rubricId: string): Promise<void> {
    try {
      await ApiGateway.post(this.DELETE_RUBRIC, { rubricId });
    } catch (e) {
      console.error('delete Rubric', e);
      throw new Error('Error deleting Rubric');
    }
  }

  async deleteSubRubric(subRubricId: string): Promise<void> {
    try {
      await ApiGateway.post(this.DELETE_SUBRUBRIC, { subRubricId });
    } catch (e) {
      console.error('Delete SubRubric', e);
      throw new Error('Error deleting SubRubric');
    }
  }

  async getChannelsRubricsSubRubricsNamesWithDeleted(): Promise<Map<string, string>> {
    try {
      if (this.allNames.size !== 0) {
        return this.allNames;
      }

      let channels = await this.getChannels(true);
      let rubrics = await this.getRubrics(true);

      channels.forEach((channel) => {
        this.allNames.set(channel.channelId, channel.channelName);
      });

      rubrics.forEach((rubric) => {
        this.allNames.set(rubric.rubricId, rubric.rubricName);
        rubric.subRubrics.forEach((subRubric) => {
          this.allNames.set(subRubric.subRubricId, subRubric.subRubricName);
        });
      });

      return this.allNames;
    } catch (e) {
      console.error('Get Category Name', e);
      throw new Error('Error retrieving names');
    }
  }

  private invalidateCache() {
    this.allNames = new Map();
  }
}
