import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, message } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Constants from '../../../Constants';
import useAssociateQuestionsToContainer from '../../../hooks/useAssociateQuestionsToContainer';
import useDisassociateQuestionsFromContainer from '../../../hooks/useDisassociateQuestionsFromContainer';
import useSortQuestionsInContainer from '../../../hooks/useSortQuestionsInContainer';
import CurrentUser from '../../../models/CurrentUser';
import { DisplayUser } from '../../../models/DisplayUser';
import { Question } from '../../../models/Question';
import { QuestionsContainer } from '../../../models/QuestionsContainer';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import QuestionsContainerForm from '../QuestionsContainerForm';
import { translateContainerStatus } from '../QuestionsContainerHelper';
import ContainerData from './ContainerData/ContainerData';
import Popconfirm from './Popconfirm';

interface Props {
  navigationService: NavigationService;
  questionsContainerService: QuestionsContainerService;
  questionService: QuestionService;
  userService: UserService;
  currentUser: CurrentUser;
  organizationService: OrganizationService;
}

const EditQuestionsContainer: React.FC<Props> = (props) => {
  const [container, setContainer] = useState<QuestionsContainer | undefined>(undefined);
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [isWaiting, setIsWaiting] = useState(false);
  const [containerUpdateSucceed, setContainerUpdateSucceed] = useState<boolean | undefined>(undefined);
  const [containerUpdateMessage, setContainerUpdateMessage] = useState<string | undefined>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { containerId } = useParams<{ containerId: string }>();
  const [pubDate, setPubDate] = useState<moment.Moment | undefined>(undefined);
  const [updatedContainerStatus, setUpdatedContainerStatus] = useState(false);
  const [popConfirmExitIconWithChangesVisible, setPopConfirmExitIconWithChangesVisible] = useState<boolean>(false);
  const [popConfirmExitWithChangesVisible, setPopConfirmExitWithChangesVisible] = useState<boolean>(false);
  const [madeChanges, setMadeChanges] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [initialQuestions, setInitialQuestions] = useState<Question[]>([]);
  const { questionIdsToDisassociate } = useDisassociateQuestionsFromContainer();
  const { containerRubricsToAssociate } = useAssociateQuestionsToContainer();
  const { sortedQuestionsIdsWithSequence } = useSortQuestionsInContainer();

  const onComponentLoaded = useCallback(async () => {
    try {
      setIsWaiting(true);
      let containerReceived = await props.questionsContainerService.getQuestionsContainerById(atob(containerId));

      if (containerReceived) {
        const questions = await props.questionService.getQuestionsByContainer(containerReceived.containerId);
        setInitialQuestions(questions);
        setQuestions(questions);

        setContainer(containerReceived);
        setPubDate(moment(containerReceived.publicationDate, Constants.BACKEND_DATETIME_FORMAT));
        formRef.current.setFieldsValue({
          ...containerReceived,
          publicationDate: moment(containerReceived.publicationDate, Constants.BACKEND_DATETIME_FORMAT),
        });

        if (
          containerReceived.containerStatus === 'OPEN' ||
          (containerReceived.containerStatus === 'BLOCKED' && containerReceived.blockedBy?.id === props.currentUser.id)
        ) {
          setEditMode(true);

          await props.questionsContainerService.blockContainer(containerReceived.containerId, true);

          setUpdatedContainerStatus(true);
        }
      }
    } finally {
      setIsWaiting(false);
    }
  }, [containerId, props.currentUser.id, props.questionService, props.questionsContainerService]);

  const updateContainerStatus = useCallback(async (): Promise<void> => {
    if (updatedContainerStatus) {
      setIsWaiting(true);
      const updatedContainer: QuestionsContainer = await props.questionsContainerService.getQuestionsContainerById(
        atob(containerId!),
      );

      setContainer(updatedContainer);
      setUpdatedContainerStatus(false);
      setIsWaiting(false);
    }
  }, [containerId, props.questionsContainerService, updatedContainerStatus]);

  useEffect(() => {
    onComponentLoaded();
  }, [onComponentLoaded]);

  useEffect(() => {
    updateContainerStatus();
  }, [updateContainerStatus, updatedContainerStatus]);

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  const save = async (containerToSave: QuestionsContainer = container!) => {
    if (!madeChanges) {
      return;
    }

    try {
      await form.validateFields();

      const values = form.getFieldsValue();

      setIsWaiting(true);
      const updatedContainer: QuestionsContainer = {
        ...values,
        outputChannel: containerToSave.outputChannel,
        publicationDate: values.publicationDate.format(Constants.BACKEND_DATETIME_FORMAT),
        blockedBy: containerToSave?.blockedBy !== undefined ? containerToSave?.blockedBy?.id : '',
        containerId: containerToSave?.containerId,
        containerStatus: containerToSave?.containerStatus,
      };

      try {
        await props.questionsContainerService.createOrUpdateContainer(updatedContainer, 'update');

        if (questionIdsToDisassociate(initialQuestions, questions).length) {
          await props.questionsContainerService.disassociateQuestionsFromContainer(
            questionIdsToDisassociate(initialQuestions, questions),
            container!.containerId,
          );
        }

        if (containerRubricsToAssociate(initialQuestions, questions).length) {
          const containerRubrics = containerRubricsToAssociate(initialQuestions, questions);

          await props.questionsContainerService.assignQuestionsToContainer({
            containerId: container!.containerId,
            containerRubrics,
          });
        }

        await props.questionsContainerService.sortQuestionsInContainer({
          containerId: container!.containerId,
          questionsIdsAndSequence: sortedQuestionsIdsWithSequence(questions),
        });

        setContainerUpdateSucceed(true);
        setContainerUpdateMessage('Fascicolo Modificato con successo');
        setInitialQuestions(questions);
        setMadeChanges(false);

        setTimeout(() => {
          setContainerUpdateSucceed(undefined);
          setContainerUpdateMessage(undefined);
        }, 3000);
      } catch (error) {
        console.error(error);
        setContainerUpdateSucceed(false);
        setContainerUpdateMessage(`${JSON.stringify(error)}`);
      } finally {
        setIsWaiting(false);
      }
    } catch (error) {
      return;
    }
  };

  const saveAndGoBack = async () => {
    try {
      await save();
    } catch (error) {
      console.error(error);
    }
    setPopConfirmExitWithChangesVisible(false);
    props.navigationService.goToContainers();
  };

  const unblockAndGoBack = async () => {
    try {
      await unblockContainer();
    } catch (error) {
      console.error(error);
    }
    setPopConfirmExitWithChangesVisible(false);
    props.navigationService.goToContainers();
  };

  const saveUnblockAndGoBack = async () => {
    try {
      await save();
      await unblockContainer();
    } catch (error) {
      console.error(error);
    }
    setPopConfirmExitWithChangesVisible(false);
    props.navigationService.goToContainers();
  };

  const saveAndArchive = async () => {
    try {
      await save(container!);
      await props.questionsContainerService.closeContainer(container!.containerId);
      setContainerUpdateMessage('Fascicolo archiviato con successo!');
      setUpdatedContainerStatus(true);
      setEditMode(false);
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const unblockContainer = async () => {
    try {
      await props.questionsContainerService.blockContainer(container!.containerId, false);
    } catch (error) {
      console.error(error);
    }

    setUpdatedContainerStatus(true);
    setEditMode(true);
  };

  const title = () => {
    function displayBlocker(blockedBy: DisplayUser | undefined) {
      return blockedBy ? ' da ' + blockedBy.displayName : '';
    }

    let titleString = 'Modifica fascicolo ' + atob(containerId) + ' - ';
    titleString += container
      ? translateContainerStatus(container.containerStatus) + displayBlocker(container.blockedBy)
      : '';

    return titleString;
  };

  const isArchiveContainerVisible = () => {
    return (
      container?.lastExportBy &&
      editMode &&
      (props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator())
    );
  };

  const handlePopConfirmExitWithChangesCancel = () => {
    props.navigationService.goToContainers();
    setPopConfirmExitWithChangesVisible(false);
  };

  const showPopConfirm = (trigger: 'icon' | 'button') => {
    if (madeChanges) {
      trigger === 'button' && setPopConfirmExitWithChangesVisible(true);
      trigger === 'icon' && setPopConfirmExitIconWithChangesVisible(true);
    } else {
      props.navigationService.goToContainers();
    }
  };

  const buttons = (
    <>
      <Popconfirm
        placement="topLeft"
        visible={popConfirmExitWithChangesVisible}
        setVisible={setPopConfirmExitWithChangesVisible}
        onGoBackButtonClick={handlePopConfirmExitWithChangesCancel}
        onSaveAndBlockButtonClick={saveAndGoBack}
        onSaveAndUnblockButtonClick={saveUnblockAndGoBack}
      >
        <Button
          data-testid="questions-container-back-button"
          disabled={isWaiting}
          type="ghost"
          onClick={() => showPopConfirm('button')}
        >
          Annulla
        </Button>
      </Popconfirm>

      <Button
        data-testid="submit-button"
        type="primary"
        style={{ marginLeft: 12 }}
        onClick={() => save()}
        disabled={!editMode}
      >
        Salva
      </Button>
      <Button
        data-testid="save-and-go-back-button"
        type="primary"
        style={{ marginLeft: 12 }}
        onClick={saveAndGoBack}
        disabled={!editMode}
      >
        Blocca ed esci
      </Button>

      {(editMode || props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator()) && (
        <Button
          data-testid="unblock-container-button"
          type="primary"
          style={{ marginLeft: 12 }}
          onClick={() => unblockAndGoBack()}
          disabled={container?.containerStatus === 'CLOSED'}
        >
          Sblocca ed Esci
        </Button>
      )}

      {isArchiveContainerVisible() && (
        <Button
          data-testid="save-and-close-container-button"
          type="primary"
          style={{ marginLeft: 12 }}
          onClick={saveAndArchive}
          disabled={!editMode}
        >
          Salva e Archivia Fascicolo
        </Button>
      )}
    </>
  );

  const CloseButton = (
    <Popconfirm
      placement="bottomLeft"
      visible={popConfirmExitIconWithChangesVisible}
      setVisible={setPopConfirmExitIconWithChangesVisible}
      onGoBackButtonClick={handlePopConfirmExitWithChangesCancel}
      onSaveAndBlockButtonClick={saveAndGoBack}
      onSaveAndUnblockButtonClick={saveUnblockAndGoBack}
    >
      <CloseCircleOutlined key="back" onClick={() => showPopConfirm('icon')} data-testid="container-close-button" />
    </Popconfirm>
  );

  const Title = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div>{title()}</div> {CloseButton}
    </div>
  );

  return (
    <>
      <LoadingSpinner data-testid="edit-questions-container-spinner" isSpinning={isWaiting}>
        {containerUpdateSucceed === true && (
          <Alert
            message={containerUpdateMessage}
            type="success"
            showIcon
            action={
              <Button size="small" type="text" onClick={() => props.navigationService.goToContainers()}>
                Vai alla lista fascicoli
              </Button>
            }
            data-testid="update-container-success-alert"
            closable
          />
        )}
        {containerUpdateSucceed === false && (
          <Alert
            message="Modifica fascicolo fallita!"
            description={containerUpdateMessage}
            type="error"
            showIcon
            closable
          ></Alert>
        )}
        <br />

        <QuestionsContainerForm
          title={<Title />}
          formInstance={form}
          formName="edit-questions-container"
          editMode={editMode}
          pubDate={pubDate}
          setPubDate={setPubDate}
          additionalButtons={buttons}
          setFormFieldsChanged={setMadeChanges}
        >
          <Divider />
          <ContainerData
            editMode={editMode}
            questionService={props.questionService}
            container={container}
            userService={props.userService}
            currentUser={props.currentUser}
            organizationService={props.organizationService}
            questionsContainerService={props.questionsContainerService}
            setUpdatedContainerStatus={setUpdatedContainerStatus}
            setMadeChanges={setMadeChanges}
            questions={questions}
            setQuestions={setQuestions}
            saveContainer={save}
          />
        </QuestionsContainerForm>
      </LoadingSpinner>
    </>
  );
};

export default EditQuestionsContainer;
