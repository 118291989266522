import { Alert } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { FC, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import CurrentUser from '../../../models/CurrentUser';
import { Question } from '../../../models/Question';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import { UserTranslator } from '../../../utils/UserTranslator';
import QuestionsTable, { ColumnKey, defaultPagination } from '../QuestionsTable/QuestionsTable';

type QuestionsProps = {
  navigationService: NavigationService;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  currentUser: CurrentUser;
};

const WorkableQuestions: FC<QuestionsProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const [canCreateQuestion, setCanCreateQuestion] = useState(false);

  const refreshData = useCallback(
    async (
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<Question>,
      pagination: TablePaginationConfig,
    ): Promise<void> => {
      try {
        setIsRefreshingData(true);
        const userTranslator = new UserTranslator(props.userService);
        const queryResponse = await props.questionService.getWorkableQuestions(
          await userTranslator.translateUsersFor(filters),
          sorter,
          pagination,
        );

        setTotal(queryResponse.fullCount);
        setQuestions(queryResponse.questions);

        setIsRefreshingData(false);
      } catch (e: any) {
        setIsRefreshingData(false);
        console.error(e.message)
        setErrorMessage('Errore nel caricamento dei quesiti');
      }
    },
    [props.questionService, props.userService],
  );

  useEffect(() => {
    refreshData({}, {}, defaultPagination);
  }, [refreshData]);

  useEffect(() => {
    setCanCreateQuestion(
      props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator() || props.currentUser.isEditor(),
    );
  }, [props.currentUser]);
  return (
    <>
      <h2>Quesiti lavorabili</h2>
      <div>
        {errorMessage !== undefined && (
          <Alert message="Errore" description={errorMessage} type="error" showIcon></Alert>
        )}
        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <QuestionsTable
            currentUser={props.currentUser}
            data={questions}
            total={total}
            refreshData={refreshData}
            questionService={props.questionService}
            userService={props.userService}
            organizationService={props.organizationService}
            navigationService={props.navigationService}
            canCreateQuestion={canCreateQuestion}
            columnsOrder={[
              ColumnKey.id,
              ColumnKey.typology,
              ColumnKey.channel,
              ColumnKey.deadline,
              ColumnKey.subRubric,
              ColumnKey.subject,
              ColumnKey.assignedTo,
              ColumnKey.signerUser,
              ColumnKey.status,
              ColumnKey.creationDate,
              ColumnKey.actions,
            ]}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default WorkableQuestions;
