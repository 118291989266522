import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';

interface Props {
  key?: string | undefined;
  text: string;
}

const ExpandableParagraph: React.FC<Props> = (props) => {
  /*
    ELLIPSIS MUST HAVE AN ALWAYS MUTABLE AND UNIQUE KEY :( 
    https://stackoverflow.com/questions/57964521/expand-collapse-with-ant-typography
    https://codesandbox.io/s/upbeat-jepsen-4h7ek?file=/index.js:672-696 
  */
  const randomString = Math.random().toString().replace('.', '');
  const actualKey = props.key !== undefined ? `${props.key}-${randomString}` : `${randomString}`;

  return (
    <Paragraph
      title={props.text}
      key={actualKey}
      ellipsis={{
        rows: 3,
      }}
    >
      {props.text}
    </Paragraph>
  );
};

export default ExpandableParagraph;
