import { Alert } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { FC, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import CurrentUser from '../../models/CurrentUser';
import { Question } from '../../models/Question';
import NavigationService from '../../services/NavigationService';
import OrganizationService from '../../services/OrganizationService';
import QuestionsContainerService from '../../services/QuestionsContainerService';
import QuestionService from '../../services/QuestionService';
import UserService from '../../services/UserService';
import DashboardServiceFacade from '../../services/DashboardServiceFacade';
import OrganizationUtils from '../../utils/OrganizationUtils';
import QuestionsTable, { ColumnKey, defaultPagination } from '../Questions/QuestionsTable/QuestionsTable';
import { useParams } from 'react-router-dom';
import { TitlesMapEditor, TitlesMapExpert, DahsboardTypes } from './utilsDashboard';

type QuestionsProps = {
  navigationService: NavigationService;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  containerService: QuestionsContainerService;
  currentUser: CurrentUser;
};

const Dashboard: FC<QuestionsProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({});
  const { dashboardType } = useParams<{ dashboardType: DahsboardTypes }>();

  const refreshData = useCallback(
    async (
      newFilters: Record<string, FilterValue | null>,
      newSorter: SorterResult<Question> | SorterResult<Question>[],
      newPagination: TablePaginationConfig,
    ): Promise<void> => {
      try {
        setIsRefreshingData(true);

        setFilters(newFilters);

        const queryResponse = await DashboardServiceFacade.getDashboardQuestions({
          currentUser: props.currentUser,
          questionService: props.questionService,
          userService: props.userService,
          newPagination,
          newFilters,
          newSorter,
          dashboardType,
        });

        const retrievedQuestions = queryResponse.questions;

        await OrganizationUtils.addOrganizationNamesToQuestions(retrievedQuestions, props.organizationService);

        setTotal(queryResponse.fullCount);
        setQuestions(retrievedQuestions);

        setIsRefreshingData(false);
      } catch (e: any) {
        setErrorMessage(e.toString());
      }
    },
    [props.userService, props.currentUser, props.questionService, props.organizationService, dashboardType],
  );

  useEffect(() => {
    refreshData({}, {}, defaultPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsOrder = [
    ColumnKey.id,
    ColumnKey.typology,
    ColumnKey.channel,
    ColumnKey.deadline,
    ColumnKey.subRubric,
    ColumnKey.subject,
    ColumnKey.assignedTo,
    ColumnKey.signerUser,
    ColumnKey.status,
    ColumnKey.creationDate,
    ColumnKey.actions,
  ];

  const dashboardTitle = props.currentUser.isAtLeastEditor()
    ? TitlesMapEditor[dashboardType]
    : TitlesMapExpert[dashboardType];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h2>Dashboard - {dashboardTitle}</h2>
      </div>
      <div>
        {errorMessage !== undefined && <Alert message="Errore" description={errorMessage} type="error" showIcon />}
        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <QuestionsTable
            currentUser={props.currentUser}
            data={questions}
            total={total}
            textSearch={filters}
            refreshData={refreshData}
            questionService={props.questionService}
            userService={props.userService}
            organizationService={props.organizationService}
            navigationService={props.navigationService}
            containerService={props.containerService}
            columnsOrder={columnsOrder}
            canCreateQuestion={false}
            canPublishQuestion={true}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default Dashboard;
