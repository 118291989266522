import { Auth } from 'aws-amplify';
import ApiGateway from './ApiGateway';
import FullUserData from '../models/FullUserData';
import CurrentUser from '../models/CurrentUser';
import { UserChannel } from '../models/UserChannel';
import UserAssignableToQuestion from '../models/UserAssignableToQuestion';
import User from '../models/User';
export default class UserService {
  private readonly USERS_CREATE = '/users/create';
  private readonly USERS_UPDATE = '/users/update';
  private readonly GET_USERS_ASSIGNABLE_TO_QUESTION = '/users/assignableToQuestion';
  private readonly GET_USERS_ASSIGNABLE_TO_MULTIPLE_QUESTION = '/users/assignableToMultipleQuestion';
  private readonly GET_CURRENT_USER = '/users/current';
  private readonly GET_USER = '/users/get';
  private readonly QUERY_USERS = '/users/query';

  async logout(): Promise<void> {
    await Auth.signOut();
  }

  async getUser(email: string): Promise<FullUserData | null> {
    if (email.includes('@')) {
      email = btoa(email);
    }
    return (await ApiGateway.get(`${this.GET_USER}?userId=${email}`))?.data;
  }

  async getCurrentUser(): Promise<CurrentUser | null> {
    try {
      const response = await ApiGateway.get(this.GET_CURRENT_USER);
      const data: CurrentUserResponseType = response.data;
      return new CurrentUser(data.id, data.name, data.surname, data.role, data.channels, data.enableGenAI);
    } catch (e: any) {
      console.log(e);
      return null;
    }
  }

  async createOrUpdateUser(request: FullUserData, functionType: 'create' | 'update'): Promise<void> {
    if (functionType === 'create') {
      return this.createUser(request);
    } else {
      return this.updateUser(request);
    }
  }

  async createUser(request: FullUserData): Promise<void> {
    return ApiGateway.post(`${this.USERS_CREATE}/${request.role.toLowerCase()}`, request);
  }

  async updateUser(request: FullUserData): Promise<void> {
    return ApiGateway.post(`${this.USERS_UPDATE}`, request);
  }

  async getUsersAssignableToQuestion(questionId: string): Promise<UserAssignableToQuestion[]> {
    const result = await ApiGateway.get(`${this.GET_USERS_ASSIGNABLE_TO_QUESTION}?questionId=${questionId}`);
    return result.data;
  }

  async getUsersAssignableToMultipleQuestions(questionIds: string[]): Promise<UserAssignableToQuestion[]> {
    const queryString = questionIds.map(questionId => `questionIds=${questionId}`).join('&');
    const result = await ApiGateway.get(`${this.GET_USERS_ASSIGNABLE_TO_MULTIPLE_QUESTION}?${queryString}`);
    return result.data;
  }

  async queryUsers(): Promise<User[]> {
    const result = await ApiGateway.get(`${this.QUERY_USERS}`);
    return result.data;
  }
}

type CurrentUserResponseType = {
  id: string;
  name: string;
  surname: string;
  role: string;
  channels: UserChannel[];
  enableGenAI: boolean;
};
