import { Skeleton, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Rubric } from '../../../models/Channel';
import OrganizationService from '../../../services/OrganizationService';

interface RubricsTableProps {
  organizationService: OrganizationService;
  channelId: string;
  refreshData: boolean;
}

const RubricsTable: React.FC<RubricsTableProps> = (props) => {
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = [
    {
      title: 'Nome Rubrica',
      dataIndex: 'rubricName',
      key: 'rubricName',
    },
    {
      title: 'Tipologia',
      dataIndex: 'isFiscal',
      key: 'isFiscal',
      render: (isFiscal: boolean) => <span>{isFiscal ? 'Fiscale' : 'Non Fiscale'}</span>,
    },
  ];

  useEffect(() => {
    const onComponentLoad = async () => {
      setIsLoading(true);
      const rubrics = await props.organizationService.getRubricsByChannelId(props.channelId);
      setRubrics(rubrics);
      setIsLoading(false);
    };

    onComponentLoad();
  }, [props.organizationService, props.channelId, props.refreshData]);

  return (
    <React.Fragment>
      {isLoading ? <Skeleton /> : <Table dataSource={rubrics} columns={columns} pagination={false} />}
    </React.Fragment>
  );
};

export default RubricsTable;
