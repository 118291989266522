import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Form } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { FC, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import CurrentUser from '../../../models/CurrentUser';
import { Question } from '../../../models/Question';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import OrganizationUtils from '../../../utils/OrganizationUtils';
import { UserTranslator } from '../../../utils/UserTranslator';
import QuestionsTable, { ColumnKey, defaultPagination } from '../QuestionsTable/QuestionsTable';
import AdvancedSearchForm from './components/AdvancedSearchForm';
import SimpleSearchForm from './components/SimpleSearchForm';

type QuestionsProps = {
  navigationService: NavigationService;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  containerService: QuestionsContainerService;
  currentUser: CurrentUser;
  match: {
    params: {
      questionID?: string;
    };
  };
};

const AllQuestions: FC<QuestionsProps> = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search); // looking for the initial question details
  const { initialQuestionDetails }: { initialQuestionDetails?: string } = Object.fromEntries(urlSearchParams.entries());

  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [canCreateQuestion, setCanCreateQuestion] = useState(false);

  const [isAdvancedSearchSelected, setIsAdvancedSearchSelected] = useState(false);
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({});
  const [advancedFilterForm] = Form.useForm();
  const [simpleFilterForm] = Form.useForm();
  const [sorter, setSorter] = useState<SorterResult<Question>>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);

  const getSimpleSearchFilter = (
    currentFilters: Record<string, FilterValue | null>,
  ): Record<string, FilterValue | null> => {
    const fieldValues = simpleFilterForm.getFieldsValue(true);

    delete currentFilters.subjectTextSearch;
    delete currentFilters.questionTextSearch;
    delete currentFilters.answerTextSearch;
    delete currentFilters.classificationTextSearch;
    delete currentFilters.endUserNameSurnameTextSearch;
    delete currentFilters.enduser_email;

    if (fieldValues.textToSearch) {
      const searchValues = fieldValues.textToSearch.trim().split(' ');
      return { ...currentFilters, fullTextSearch: searchValues };
    } else {
      delete currentFilters.fullTextSearch;
      return { ...currentFilters };
    }
  };

  const getAdvancedSearchFilter = (
    currentFilters: Record<string, FilterValue | null>,
  ): Record<string, FilterValue | null> => {
    const fieldValues = advancedFilterForm.getFieldsValue(true);
    delete currentFilters.fullTextSearch;
    const advancedSearchFilters = {
      ...currentFilters,
      subjectTextSearch:
        fieldValues.subject && fieldValues.subject !== '' ? fieldValues.subject.trim().split(' ') : null,
      questionTextSearch:
        fieldValues.question && fieldValues.question !== '' ? fieldValues.question.trim().split(' ') : null,
      answerTextSearch: fieldValues.answer && fieldValues.answer !== '' ? fieldValues.answer.trim().split(' ') : null,
      classificationTextSearch:
        fieldValues.classification && fieldValues.classification !== ''
          ? fieldValues.classification.trim().split(' ')
          : null,
      endUserNameSurnameTextSearch:
        fieldValues.endUserNameSurname && fieldValues.endUserNameSurname !== ''
          ? fieldValues.endUserNameSurname.trim().split(' ')
          : null,
      enduser_email:
        fieldValues.endUserEmail && fieldValues.endUserEmail !== '' ? fieldValues.endUserEmail.trim().split(' ') : null,
    };

    return advancedSearchFilters;
  };

  const getAdditionalFilters = (myFilters: Record<string, FilterValue | null>): Record<string, FilterValue | null> => {
    return isAdvancedSearchSelected ? getAdvancedSearchFilter(myFilters) : getSimpleSearchFilter(myFilters);
  };

  const refreshData = async (
    newFilters: Record<string, FilterValue | null>,
    newSorter: SorterResult<Question>,
    newPagination: TablePaginationConfig,
  ): Promise<void> => {
    const newFiltersWithSearch = getAdditionalFilters(newFilters);
    try {
      setIsRefreshingData(true);

      setFilters(newFiltersWithSearch);
      setSorter(newSorter);
      setPagination(newPagination);

      const userTranslator = new UserTranslator(props.userService);

      const queryResponse = await props.questionService.getQuestions(
        await userTranslator.translateUsersFor(newFiltersWithSearch),
        newSorter,
        newPagination,
      );

      const retrievedQuestions = queryResponse.questions;

      await OrganizationUtils.addOrganizationNamesToQuestions(retrievedQuestions, props.organizationService);

      setTotal(queryResponse.fullCount);
      setQuestions(retrievedQuestions);

      setIsRefreshingData(false);
    } catch (e: any) {
      setIsRefreshingData(false);
      console.error(e.message)
      setErrorMessage('Errore nel caricamento dei quesiti');
    }
  };

  useEffect(() => {
    setCanCreateQuestion(
      props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator() || props.currentUser.isEditor(),
    );
  }, [props.currentUser]);

  useEffect(() => {
    refreshData({}, {}, defaultPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchPressed = () => {
    refreshData(filters, sorter, pagination);
  };

  const columnsOrder = [
    ColumnKey.id,
    ColumnKey.typology,
    ColumnKey.channel,
    ColumnKey.status,
    ColumnKey.creationDate,
    ColumnKey.subRubric,
    ColumnKey.subject,
    ColumnKey.assignedTo,
    ColumnKey.signerUser,
    ColumnKey.containers,
    ColumnKey.outputChannels,
    ColumnKey.actions,
  ].filter(
    (columnName: string) =>
      !(columnName === 'containers' && props.currentUser.isExpert()) &&
      !(columnName === 'outputChannels' && props.currentUser.isExpert()),
  );

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <h2>Tutti i Quesiti</h2>
      </div>
      <div style={{ maxWidth: '50rem', marginLeft: '1rem' }}>
        {isAdvancedSearchSelected === false && (
          <>
            <Button
              type="default"
              onClick={() => {
                setIsAdvancedSearchSelected(true);
              }}
            >
              Ricerca avanzata
            </Button>
            <SimpleSearchForm simpleFilterForm={simpleFilterForm} />
            <Button type="primary" onClick={() => onSearchPressed()}>
              <SearchOutlined />
            </Button>
          </>
        )}
        {isAdvancedSearchSelected === true && (
          <>
            <Button
              type="default"
              onClick={() => {
                setIsAdvancedSearchSelected(false);
              }}
            >
              Ricerca base
            </Button>
            <AdvancedSearchForm advancedFilterForm={advancedFilterForm} />
            <Button type="primary" onClick={() => onSearchPressed()}>
              <SearchOutlined />
            </Button>
          </>
        )}
      </div>

      <div>
        {errorMessage !== undefined && <Alert message="Errore" description={errorMessage} type="error" showIcon />}
        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <QuestionsTable
            initialQuestionDetails={initialQuestionDetails}
            currentUser={props.currentUser}
            data={questions}
            total={total}
            textSearch={filters}
            refreshData={refreshData}
            questionService={props.questionService}
            userService={props.userService}
            organizationService={props.organizationService}
            navigationService={props.navigationService}
            containerService={props.containerService}
            columnsOrder={columnsOrder}
            canCreateQuestion={canCreateQuestion}
            canPublishQuestion={true}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default AllQuestions;
