import { Form, Row, Col, Input } from 'antd';
import React from 'react';

interface SimpleSearchFormProps {
  simpleFilterForm: any;
}

const SimpleSearchForm: React.FC<SimpleSearchFormProps> = ({ simpleFilterForm }) => {
  return (
    <Form form={simpleFilterForm} layout="horizontal" name="simple_search">
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            data-testid="advanced-form-filter-subject"
            name={`textToSearch`}
            label={`Ricerca`}
            rules={[{ whitespace: true }]}
          >
            <Input placeholder="Parola chiave ..." />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SimpleSearchForm;
