import { CloseCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Popconfirm, Space, Table, Tooltip } from 'antd';
import React from 'react';
import ActionButton from '../../components/ActionButton';
import ResizableTableHeader from '../../components/ResizableTableHeader/ResizableTableHeader';
import TableScrollNavigation from '../../components/TableScrollNavigation';
import CurrentUser from '../../models/CurrentUser';
import { QuestionsContainer } from '../../models/QuestionsContainer';
import NavigationService from '../../services/NavigationService';
import QuestionsContainerService from '../../services/QuestionsContainerService';
import UserService from '../../services/UserService';
import DateUtils from '../../utils/DateUtils';
import './QuestionsContainersTable.css';

type Props = {
  data: QuestionsContainer[];
  refreshData: () => Promise<void>;
  userService: UserService;
  navigationService: NavigationService;
  questionsContainerService: QuestionsContainerService;
  currentUser: CurrentUser;
};
type State = {
  columns: any;
};

class QuestionsContainersTable extends React.Component<Props, State> {
  state = {
    columns: [
      {
        title: 'Titolo',
        dataIndex: 'title',
        width: 200,
      },
      {
        title: 'Stato',
        width: 200,
        render: (container: QuestionsContainer) => (
          <div>
            {container.containerStatus === 'OPEN' && (
              <Tooltip title="Aperto">
                <UnlockOutlined style={{ fontSize: '2em', color: '#08c' }} />
              </Tooltip>
            )}

            {container.containerStatus === 'CLOSED' && (
              <Tooltip title="Archiviato">
                <CloseCircleOutlined style={{ fontSize: '2em', color: '#08c' }} />
              </Tooltip>
            )}

            {container.containerStatus === 'BLOCKED' && (
              <Tooltip title="Bloccato">
                <LockOutlined style={{ fontSize: '2em', color: '#08c' }} /> {container.blockedBy?.displayName}
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: 'Numero',
        dataIndex: 'issueNumber',
        width: 150,
      },
      {
        title: 'Data pubblicazione',
        dataIndex: 'publicationDate',
        width: 150,
        render: (publicationDate: Date) => <span>{DateUtils.formatOnlyDate(publicationDate)}</span>,
      },
      {
        title: '',
        key: 'action',
        width: 60,
        render: (_: string, container: QuestionsContainer) => (
          <Space size="middle">
            {this.isCloseContainerVisible(container) && (
              <Popconfirm
                title="Sei sicuro di voler archiviare il fascicolo?"
                okText="SI"
                cancelText="NO"
                placement="top"
                onConfirm={async () => {
                  await this.props.questionsContainerService.closeContainer(container.containerId);
                  await this.props.refreshData();
                }}
              >
                <ActionButton title="Archivia" icon={<CloseCircleOutlined />} testId="close-button" />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
  };

  isCloseContainerVisible = (container: QuestionsContainer) => {
    const containerIsBlockedByCurrentUser =
      container.containerStatus === 'BLOCKED' && container?.blockedBy?.id === this.props.currentUser.id;

    return (
      container.lastExportBy &&
      (container.containerStatus === 'OPEN' || containerIsBlockedByCurrentUser) &&
      (this.props.currentUser.isAdministrator() || this.props.currentUser.isSuperAdministrator())
    );
  };

  components = {
    header: {
      cell: ResizableTableHeader,
    },
  };

  handleResize =
    (index: number) =>
    (e: any, { size }: any) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };

  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column: { width: number }) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    return (
      <TableScrollNavigation>
        <Table
          sticky={true}
          bordered
          className="questions-container-table"
          rowClassName="questions-container-table-row"
          components={this.components}
          columns={columns as any}
          dataSource={this.props.data}
          onRow={(container: QuestionsContainer, _) => {
            return {
              onClick: (_) => this.props.navigationService.goToUpdateContainers(container.containerId),
            };
          }}
        />
      </TableScrollNavigation>
    );
  }
}

export default QuestionsContainersTable;
