import { DoubleRightOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import React from 'react';
import { QuestionEvent } from '../../../models/QuestionEvent';
import DateUtils from '../../../utils/DateUtils';
import QuestionStatus from '../../../utils/QuestionStatus';
import './ChatsFormItem.css';

interface Props {
  questionEvents: QuestionEvent[];
}

const QuestionEventsItem: React.FC<Props> = ({ questionEvents }) => {
  return (
    <div data-testid="events-item">
      <Timeline mode="left">
        {questionEvents.map((event) => (
          <Timeline.Item
            key={event.version}
            label={
              <div>
                <b>{event.assigner.displayName}</b> alle {DateUtils.format(event.creationDate)}
              </div>
            }
          >
            {QuestionStatus.label(event.originalStatus)} <DoubleRightOutlined />{' '}
            {QuestionStatus.label(event.destinationStatus)}
            {event.assignee && <div>Assegnato a: {event.assignee.displayName}</div>}
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default QuestionEventsItem;
