import DateUtils from '../../../utils/DateUtils';
import moment from 'moment';

export const INITIAL_CREATION_DATE_FILTER_RANGE = [moment().startOf('month'), moment().endOf('month')];
export const INITIAL_CREATION_DATE_FILTER_RANGE_FORMATTED: [string, string] = [
  DateUtils.formatForBackend(INITIAL_CREATION_DATE_FILTER_RANGE[0].toDate()),
  DateUtils.formatForBackend(INITIAL_CREATION_DATE_FILTER_RANGE[1].toDate()),
];
export const DEFAULT_FILTERS = {
  date: INITIAL_CREATION_DATE_FILTER_RANGE_FORMATTED,
};

export const DEFAULT_PAGINATION = {
  showSizeChanger: false,
  current: 1,
  pageSize: 50,
};
