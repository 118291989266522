import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { Question } from '../models/Question';
import SelectUtils from '../utils/SelectUtils';
import QuestionsContainerService from '../services/QuestionsContainerService';
import { QuestionsContainer } from '../models/QuestionsContainer';
import { DisplayQuestionsContainer } from '../models/DisplayQuestionsContainer';
import DateUtils from '../utils/DateUtils';
import CurrentUser from '../models/CurrentUser';

interface Props {
  questions: Question[];
  containerService: QuestionsContainerService;
  selectedContainer: DisplayQuestionsContainer | undefined;
  setSelectedContainer: React.Dispatch<React.SetStateAction<DisplayQuestionsContainer | undefined>>;
  onContainerSelected: (container: QuestionsContainer) => void;
  currentUser: CurrentUser;
}

const AssignQuestionToContainerSelect: React.FC<Props> = (props) => {
  const [selectableContainers, setSelectableContainers] = useState<QuestionsContainer[] | undefined>(undefined);

  const onComponentLoaded = useCallback(async () => {
    try {
      let containers: QuestionsContainer[] = await props.containerService.getQuestionsContainers();

      containers = containers.filter(
        (container) =>
          container.containerStatus !== 'CLOSED' &&
          (container.blockedBy?.id === props.currentUser.id || container.containerStatus === 'OPEN'),
      );

      const containersAlreadySelected: QuestionsContainer[][] = await Promise.all(
        props.questions.map(async (question) => {
          return props.containerService.getQuestionsContainersByQuestionId(question.id);
        }),
      );

      const containersAlreadySelectIds = new Set(
        containersAlreadySelected.flat().map(({ containerId }) => containerId),
      );

      containers = containers.filter(({ containerId }) => !containersAlreadySelectIds.has(containerId));

      setSelectableContainers(containers);
    } catch (error) {
      console.error(error);
    }
  }, [props.containerService, props.currentUser.id, props.questions]);

  const onContainerSelected = (containerId: string) => {
    const container = selectableContainers?.find((e) => e.containerId === containerId);
    if (container) {
      props.setSelectedContainer(container);
      props.onContainerSelected(container);
    }
  };

  useEffect(() => {
    onComponentLoaded();
  }, [onComponentLoaded]);

  return (
    <Select
      showSearch
      style={{ width: '25rem' }}
      placeholder={selectableContainers === undefined ? 'Caricamento in corso...' : 'Seleziona un fascicolo'}
      optionFilterProp="children"
      filterOption={SelectUtils.filterOption}
      onSelect={(value: string) => {
        onContainerSelected(value);
      }}
      loading={selectableContainers === undefined}
      value={selectableContainers === undefined ? undefined : props.selectedContainer?.containerId}
      data-testid="assign-to-container-field"
    >
      {!selectableContainers && (
        <Select.Option disabled={true} value="">
          <LoadingSpinner fontSize={20} />
          <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
        </Select.Option>
      )}
      {selectableContainers &&
        selectableContainers.map((container) => (
          <Select.Option
            key={container.containerId}
            value={container.containerId}
            role="assign-to-container-field-options"
          >
            {DateUtils.formatOnlyDate(container.publicationDate)} - {container.title}
          </Select.Option>
        ))}
    </Select>
  );
};

export default AssignQuestionToContainerSelect;
