import { Button, message, Popconfirm } from 'antd';
import QuestionsContainerService from '../../../../services/QuestionsContainerService';

interface Props {
  containerId: string;
  containerService: QuestionsContainerService;
  setUpdatedContainerStatus: React.Dispatch<React.SetStateAction<boolean>>;
  saveContainer: () => Promise<void>;
}

const CreateXMLDraftButton: React.FC<Props> = ({
  containerId,
  containerService,
  setUpdatedContainerStatus,
  saveContainer,
}) => {
  const getDraft = async () => {
    try {
      await saveContainer();
      await containerService.getContainerDraft(containerId);

      message.success('Bozza XML inviata correttamente');
      setUpdatedContainerStatus(true);
    } catch (error) {
      console.error(error);
      message.error(`${JSON.stringify(error)}`);
    }
  };

  return (
    <Popconfirm
      title="Se ci sono modifiche non salvate, verranno salvate prima dell'invio della bozza. Sei sicuro di voler inviare la bozza?"
      onConfirm={getDraft}
    >
      <Button style={{ marginRight: '1em' }} type="default">
        Crea Bozza
      </Button>
    </Popconfirm>
  );
};
export default CreateXMLDraftButton;
