import QuestionService from './QuestionService';
import UserService from './UserService';
import CurrentUser from '../models/CurrentUser';
import { Question } from '../models/Question';
import { TablePaginationConfig, SorterResult, FilterValue } from 'antd/lib/table/interface';
import { UserTranslator } from '../utils/UserTranslator';
import { orderFilterMapEditor, DahsboardTypes } from '../pages/Dashboard/utilsDashboard';
import { QueryResponse } from '../models/Question';

export const getAllQuestionsFilters = ({
  tableFilters,
  dashboardFilters,
  currentUser,
}: {
  tableFilters: Record<string, FilterValue | null>;
  dashboardFilters: Record<string, FilterValue | null>;
  currentUser: CurrentUser;
}): Record<string, FilterValue | null> => {
  const userFiltersWithDashboardFilters = { ...tableFilters, ...dashboardFilters };

  // if the user has no channels, return all the filters, that means all the questions
  if (currentUser.getChannels().length < 1) {
    return userFiltersWithDashboardFilters;
  }

  const availableChannels = currentUser.getChannels().map((channel) => channel.id);
  const hasUserFilteredByChannel = !!userFiltersWithDashboardFilters.channel;

  // if the user has not filtered by channel, return all the channels available to the user
  if (!hasUserFilteredByChannel) {
    return {
      ...userFiltersWithDashboardFilters,
      channel: availableChannels,
    };
  }

  // only return the channels that are available to the user
  const channelFilters = availableChannels.filter((channel) =>
    userFiltersWithDashboardFilters.channel?.includes(channel),
  );

  return {
    ...userFiltersWithDashboardFilters,
    channel: channelFilters.length ? channelFilters : availableChannels,
  };
};

export const getMyQuestionsFilters = ({
  tableFilters,
  dashboardFilters,
}: {
  tableFilters: Record<string, FilterValue | null>;
  dashboardFilters: Record<string, FilterValue | null>;
}): Record<string, FilterValue | null> => {
  return {
    ...tableFilters,
    ...dashboardFilters,
  };
};

const getDashboardQuestions = async ({
  currentUser,
  questionService,
  userService,
  newPagination,
  dashboardType,
  newFilters,
  newSorter,
}: {
  currentUser: CurrentUser;
  questionService: QuestionService;
  userService: UserService;
  newPagination: TablePaginationConfig;
  dashboardType: DahsboardTypes;
  newFilters: Record<string, FilterValue | null>;
  newSorter: SorterResult<Question> | SorterResult<Question>[];
}): Promise<QueryResponse> => {
  const userTranslator = new UserTranslator(userService);

  const shouldFetchAllQuestions = currentUser.isAtLeastEditor();

  const { filters, order } = orderFilterMapEditor[dashboardType];

  const getSorter = () => {
    if (Object.entries(newSorter).length > 0) {
      return newSorter;
    }
    return order;
  };

  const queryResponse = shouldFetchAllQuestions
    ? await questionService.getQuestions(
        await userTranslator.translateUsersFor(
          getAllQuestionsFilters({ tableFilters: newFilters, dashboardFilters: filters, currentUser }),
        ),
        getSorter(),
        newPagination,
      )
    : await questionService.getMyQuestions(
        await userTranslator.translateUsersFor(
          getMyQuestionsFilters({ tableFilters: newFilters, dashboardFilters: filters }),
        ),
        getSorter(),
        newPagination,
      );

  return queryResponse;
};

const DashboardServiceFacade = {
  getDashboardQuestions,
};

export default DashboardServiceFacade;
