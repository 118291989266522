import { Question } from '../../models/Question';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

export type DahsboardTypes =
  | 'inseritiNonAssegnati'
  | 'assegnatiNonPresiInCarico'
  | 'inseritiPresiInCarico'
  | 'attesaChiarimenti'
  | 'daValidare'
  | 'inAttesaDiApprovazione';

type TitleMap = {
  [key in DahsboardTypes]: string;
};

type OrderAndFilterMap = {
  [key in DahsboardTypes]: {
    filters: Record<string, FilterValue | null>;
    order: SorterResult<Question> | SorterResult<Question>[];
  };
};
const orderByTypologyDeadlineAndCreationDate: SorterResult<Question>[] = [
  {
    field: 'typology',
    order: 'descend',
  },
  {
    field: 'deadline',
    order: 'ascend',
  },
  {
    field: 'creationDate',
    order: 'ascend',
  },
];

const onlyAppenaInseritiFilter = {
  status: ['ING'],
};

const onlyAssegnatiFilters = {
  status: ['ASS'],
};

const onlyPresiInCaricoFilters = {
  status: ['PROG'],
};

const onlyToValidateFilters = {
  status: ['TOVALIDATE'],
};

const onlyAttesaDiChiarimentiFilters = {
  status: ['INFO'],
};

const onlyInAttesaDiApprovazioneFilters = {
  status: ['ANS'],
};

export const orderFilterMapEditor: OrderAndFilterMap = {
  inseritiNonAssegnati: {
    filters: onlyAppenaInseritiFilter,
    order: orderByTypologyDeadlineAndCreationDate,
  },
  assegnatiNonPresiInCarico: {
    filters: onlyAssegnatiFilters,
    order: orderByTypologyDeadlineAndCreationDate,
  },
  inseritiPresiInCarico: {
    filters: onlyPresiInCaricoFilters,
    order: orderByTypologyDeadlineAndCreationDate,
  },
  attesaChiarimenti: {
    filters: onlyAttesaDiChiarimentiFilters,
    order: orderByTypologyDeadlineAndCreationDate,
  },
  daValidare: {
    filters: onlyToValidateFilters,
    order: orderByTypologyDeadlineAndCreationDate,
  },
  inAttesaDiApprovazione: {
    filters: onlyInAttesaDiApprovazioneFilters,
    order: orderByTypologyDeadlineAndCreationDate,
  },
};

export const TitlesMapEditor: TitleMap = {
  inseritiNonAssegnati: 'Quesiti inseriti non ancora assegnati',
  assegnatiNonPresiInCarico: 'Quesiti assegnati non ancora presi in carico',
  inseritiPresiInCarico: 'Quesiti presi in carico',
  attesaChiarimenti: 'Quesiti per cui sono stati richiesti chiarimenti',
  inAttesaDiApprovazione: 'Quesiti risposti in attesa di approvazione',
  daValidare: 'Quesiti da validare',
};

export const TitlesMapExpert: TitleMap = {
  inseritiNonAssegnati: '',
  assegnatiNonPresiInCarico: 'Quesiti assegnati a me da prendere in carico',
  inseritiPresiInCarico: 'Quesiti presi in carico da me non ancora risposti',
  attesaChiarimenti: 'Quesiti per cui sono stati richiesti chiarimenti',
  inAttesaDiApprovazione: '',
  daValidare: 'Quesiti assegnati a me da validare',
};

export const TitlesOrderEditor: DahsboardTypes[] = [
  'inseritiNonAssegnati',
  'assegnatiNonPresiInCarico',
  'attesaChiarimenti',
  'inseritiPresiInCarico',
  'inAttesaDiApprovazione',
  'daValidare',
];
export const TitlesOrderExpert: DahsboardTypes[] = [
  'assegnatiNonPresiInCarico',
  'attesaChiarimenti',
  'inseritiPresiInCarico',
  'daValidare',
];
