import { Table } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import TableScrollNavigation from '../../../components/TableScrollNavigation';
import CurrentUser from '../../../models/CurrentUser';
import { QuestionsContainer } from '../../../models/QuestionsContainer';
import NavigationService from '../../../services/NavigationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import UserService from '../../../services/UserService';
import DateUtils from '../../../utils/DateUtils';
import './ArchivedQuestionsContainersPage.css';

type Props = {
  navigationService: NavigationService;
  userService: UserService;
  questionsContainerService: QuestionsContainerService;
  currentUser: CurrentUser;
};

const ArchivedQuestionsContainersPage: FC<Props> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [containers, setContainers] = useState<QuestionsContainer[]>([]);

  const refreshData = useCallback(async (): Promise<void> => {
    setIsRefreshingData(true);
    const retrievedContainers: any[] = await props.questionsContainerService.getArchivedQuestionsContainers();

    setContainers(retrievedContainers);

    setIsRefreshingData(false);
  }, [props.questionsContainerService]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  let columns = [
    {
      title: 'Titolo',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Numero',
      dataIndex: 'issueNumber',
      width: 150,
    },
    {
      title: 'Data pubblicazione',
      dataIndex: 'publicationDate',
      width: 150,
      render: (publicationDate: Date) => <span>{DateUtils.formatOnlyDate(publicationDate)}</span>,
    },
  ];

  return (
    <>
      <h2>Archivio Fascicoli</h2>
      <LoadingSpinner isSpinning={isRefreshingData} fontSize={200} text="Caricamento fascicoli in corso...">
        <div>
          <TableScrollNavigation>
            <Table
              sticky={true}
              rowClassName={'archived-question-container-table-row'}
              bordered
              columns={columns}
              dataSource={containers}
              onRow={(questionsContainer, _rowIndex) => {
                return {
                  onClick: () => props.navigationService.goToArchivedDetailsContainers(questionsContainer.containerId),
                };
              }}
            />
          </TableScrollNavigation>
        </div>
      </LoadingSpinner>
    </>
  );
};

export default ArchivedQuestionsContainersPage;
