import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

interface LoadingSpinnerProps {
  isSpinning?: boolean;
  fontSize?: number;
  text?: string;
  'data-testid'?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const spinning = props.isSpinning !== undefined ? props.isSpinning : true;
  const size = props.fontSize !== undefined ? props.fontSize : 80;
  const questionCreationLoadingIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
  return (
    <Spin
      spinning={spinning}
      indicator={questionCreationLoadingIcon}
      tip={props.text}
      data-testid={props['data-testid']}
      style={{ minHeight: '100px' }}
    >
      {props.children}
    </Spin>
  );
};

export default LoadingSpinner;
