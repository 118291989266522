import { Alert, Button } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import CurrentUser from '../../models/CurrentUser';
import User from '../../models/User';
import { userChannelsToTopics } from '../../models/UserChannel';
import NavigationService from '../../services/NavigationService';
import OrganizationService from '../../services/OrganizationService';
import UserService from '../../services/UserService';
import UserRole from '../../utils/UserRole';
import UsersTable from './UsersTable/UsersTable';

type UsersProps = {
  currentUser: CurrentUser | undefined;
  navigationService: NavigationService;
  userService: UserService;
  organizationService: OrganizationService;
};

const Users: FC<UsersProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const refreshData = useCallback(async (): Promise<void> => {
    try {
      setIsRefreshingData(true);
      const channelsNames = await props.organizationService.getChannelsRubricsSubRubricsNamesWithDeleted();

      let retrievedUsers = await props.userService.queryUsers();
      retrievedUsers.forEach((user) => {
        addNamesToOrganizationEntities(user, channelsNames);
        user.key = user.id;
        user.topics = userChannelsToTopics(user.channels);
        user.topicsAsSuperExpert = user.topics.filter(({ isSuperExpert }) => isSuperExpert);
        user.topicsAsExpert = user.topics.filter(({ isSuperExpert }) => !isSuperExpert);
      });

      let visibleUsers: User[] = retrievedUsers;

      if (props.currentUser?.isEditor()) {
        visibleUsers = getAllUsersInTheSameChannels(retrievedUsers, props.currentUser!);
      }

      if (props.currentUser?.isAdministrator()) {
        visibleUsers = getAllUsersExceptSuperAdmins(retrievedUsers);
      }

      setUsers(visibleUsers);

      setIsRefreshingData(false);
    } catch (e: any) {
      setIsRefreshingData(false);
      setErrorMessage(e.toString());
    }
  }, [props.currentUser, props.organizationService, props.userService]);

  const canCreateUser = (): boolean => {
    return (
      props.currentUser !== undefined &&
      (props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator())
    );
  };

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <>
      <h2>Utenti</h2>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        {canCreateUser() && (
          <Button type="primary" onClick={() => props.navigationService.goToCreateUsers()}>
            Crea utente
          </Button>
        )}
      </div>
      <div>
        {errorMessage !== undefined && (
          <Alert message="Errore" description={errorMessage} type="error" showIcon></Alert>
        )}

        <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
          <UsersTable
            data={users}
            refreshData={refreshData}
            userService={props.userService}
            navigationService={props.navigationService}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default Users;

function addNamesToOrganizationEntities(user: User, channelsNames: Map<string, string>) {
  user.channels.forEach((channel) => {
    channel.name = channelsNames.get(channel.id) ?? 'not found';
    channel.rubrics.forEach((rubric) => {
      rubric.name = channelsNames.get(rubric.id) ?? 'not found';
      rubric.subRubrics.forEach((subRubric) => {
        subRubric.name = channelsNames.get(subRubric.id) ?? 'not found';
      });
    });
  });
}

// function getAllExpertsInTheSameChannels(retrievedUsers: User[], superExpert: CurrentUser): User[] {
//   const subRubricsUserIsSuperExpertOn = superExpert.channels.flatMap((channel) =>
//     channel.rubrics.flatMap((rubric) =>
//       rubric.subRubrics.filter((subRubric) => subRubric.isSuperExpert).map((subRubric) => subRubric.id),
//     ),
//   );
//   return retrievedUsers.filter((user) => {
//     const userSubRubrics = user.channels.flatMap((channel) =>
//       channel.rubrics.flatMap((rubric) => rubric.subRubrics).map((subRubric) => subRubric.id),
//     );
//     return subRubricsUserIsSuperExpertOn.some((subRubric) => userSubRubrics.includes(subRubric));
//   });
// }

function getAllUsersInTheSameChannels(retrievedUsers: User[], editor: CurrentUser): User[] {
  const editorChannels = editor.channels.map((channel) => channel.id);
  return retrievedUsers.filter((user) => {
    const userChannels = user.channels.map((channel) => channel.id);
    return editorChannels.some((channel) => userChannels.includes(channel));
  });
}
function getAllUsersExceptSuperAdmins(retrievedUsers: User[]): User[] {
  return retrievedUsers.filter((user) => user.role !== UserRole.SUPER_ADMINISTRATOR);
}
