import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, message, Space, Switch } from 'antd';
import OrganizationService from '../../../services/OrganizationService';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Rubric } from '../../../models/Channel';

interface props {
  setCreateRubricDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  createRubricDrawerVisible: boolean;
  organizationService: OrganizationService;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRubric: Rubric | undefined;
}

const CreateEditRubricDrawer: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [deletedSubRubricsIds, setDeletedSubRubricsIds] = useState<string[]>([]);

  const createChannelButton = (
    <Button
      loading={isLoading}
      onClick={() => {
        createOrEditRubric('create');
      }}
      type="primary"
    >
      Crea
    </Button>
  );

  const editChannelButton = (
    <Button
      loading={isLoading}
      onClick={() => {
        createOrEditRubric('update');
      }}
      type="primary"
    >
      Modifica
    </Button>
  );

  const createOrEditRubric = async (action: 'create' | 'update') => {
    const deletedSubRubrics = deletedSubRubricsIds.map((id) => ({ id: id }));
    form
      .validateFields()
      .then(async () => {
        setIsLoading(true);
        await props.organizationService
          .createOrUpdateRubric(
            {
              rubricName: form.getFieldValue('rubricName'),
              rubricId: props.selectedRubric?.rubricId,
              isFiscal: form.getFieldValue('isFiscal') ?? false,
              subRubrics: deletedSubRubrics.concat(form.getFieldValue('subRubrics')),
            },
            action,
          )
          .then(() => {
            message.success(isEditing() ? 'Rubrica modificata con successo!' : 'Rubrica creata con successo!');
            props.setCreateRubricDrawerVisible(false);
            form.resetFields();
            props.setRefreshData(true);
          })
          .catch((e) => {
            message.error(isEditing() ? `Errore nel modificare Rubrica ${e}` : `Errore nel creare Rubrica: ${e}`);
          });
        setIsLoading(false);
      })
      .catch((errorInfo) => {});
  };

  useEffect(() => {
    if (props.selectedRubric) {
      populateForm(props.selectedRubric);
    } else {
      resetComponent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRubric]);

  return (
    <React.Fragment>
      <Drawer
        title={isEditing() ? 'Modifica Rubrica' : 'Crea una nuova Rubrica'}
        width={720}
        onClose={() => props.setCreateRubricDrawerVisible(false)}
        visible={props.createRubricDrawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button
              onClick={() => {
                props.setCreateRubricDrawerVisible(false);
              }}
            >
              Indietro
            </Button>
            {isEditing() ? editChannelButton : createChannelButton}
          </Space>
        }
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Form form={form} name="create-rubric-form" autoComplete="off">
            <Form.Item
              label="Nome Rubrica"
              name="rubricName"
              rules={[{ required: true, message: 'Nome Rubrica Obbligatorio' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Fiscale" name="isFiscal" valuePropName={'checked'}>
              <Switch />
            </Form.Item>
            <Form.List
              name="subRubrics"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('Almeno 1 SottoRubrica'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item label="SottoRubrica" required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        name={[index, 'name']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Aggiungi un nome per la sotto rubrica oppure elimina questo campo',
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="Nome SottoRubrica" style={{ width: '60%' }} />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          disabled={fields.length === 1}
                          className="dynamic-delete-button"
                          onClick={() => {
                            setDeletedSubRubricsIds([
                              ...deletedSubRubricsIds,
                              form.getFieldValue(['subRubrics', field.name]).id,
                            ]);
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} style={{ width: '60%' }} icon={<PlusOutlined />}>
                      Aggiungi SottoRubrica
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Space>
      </Drawer>
    </React.Fragment>
  );

  function isEditing() {
    return props.selectedRubric !== undefined;
  }

  function populateForm(selectedRubric: Rubric) {
    form.setFieldsValue({
      ...selectedRubric,
      subRubrics: selectedRubric.subRubrics.map((subRubric) => {
        return {
          name: subRubric.subRubricName,
          id: subRubric.subRubricId,
        };
      }),
    });
  }

  function resetComponent() {
    form.resetFields();
    setDeletedSubRubricsIds([]);
    setIsLoading(false);
  }
};

export default CreateEditRubricDrawer;
