import { Select, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Question } from '../models/Question';
import User from '../models/User';
import UserAssignableToQuestion from '../models/UserAssignableToQuestion';
import { UserChannel } from '../models/UserChannel';
import UserService from '../services/UserService';
import LoadingSpinner from './LoadingSpinner';

interface Props {
  question: Question | undefined;
  questions?: Question[] | undefined;
  isMultipleQuestions?: boolean | false;
  userService: UserService;
  selectedExpert: UserAssignableToQuestion | undefined;
  onExpertSelected: (expert: UserAssignableToQuestion) => void;
  disabled?: boolean;
  width?: string;
}

interface UserAssignableToQuestionWithLabel extends UserAssignableToQuestion {
  label?: React.ReactNode;
}

const sortExpertBySurname = (a: UserAssignableToQuestion, b: UserAssignableToQuestion) => {
  if (a.surname < b.surname) {
    return -1;
  }
  if (a.surname > b.surname) {
    return 1;
  }
  return 0;
};

const isSuperExpertOnTopic = (
  expertChannels: UserChannel[],
  topicChannel: string,
  topicRubric: string,
  topicSubRubric: string,
): boolean => {
  return (
    expertChannels
      .find((c) => c.id === topicChannel)
      ?.rubrics.find((r) => r.id === topicRubric)
      ?.subRubrics.find((s) => s.id === topicSubRubric)?.isSuperExpert === true
  );
};

const isExpertOnTopic = (
  expertChannels: UserChannel[],
  topicChannel: string,
  topicRubric: string,
  topicSubRubric: string,
): boolean => {
  return (
    expertChannels
      .find((c) => c.id === topicChannel)
      ?.rubrics.find((r) => r.id === topicRubric)
      ?.subRubrics.find((s) => s.id === topicSubRubric)?.isSuperExpert === false
  );
};

const getExpertsWithLabels = (
  experts: UserAssignableToQuestion[],
  questions: Question[],
): UserAssignableToQuestionWithLabel[] => {
  const onlySuperExpertsOnTopic: UserAssignableToQuestionWithLabel[] = [];
  const onlyExpertsOnTopic: UserAssignableToQuestionWithLabel[] = [];
  // const onlySuperEditors: UserAssignableToQuestionWithLabel[] = [];
  // const onlyEditors: UserAssignableToQuestionWithLabel[] = [];
  const otherUsers: UserAssignableToQuestionWithLabel[] = [];

  experts.forEach((expert) => {
    const labels = questions.map((question) => {
      if (isSuperExpertOnTopic(expert.channels, question.channelId, question.rubricId, question.subRubricId)) {
        return User.SUPER_ESPERTO;
      } else if (isExpertOnTopic(expert.channels, question.channelId, question.rubricId, question.subRubricId)) {
        return User.ESPERTO;
      } else if (expert.role === 'Administrator') {
        return User.AMMINISTRATORE;
      } else {
        return expert.role === 'Expert' ? 'Altro' : expert.role;
      }
    });

    let label: React.ReactNode = null;

    if (labels.every((label) => label === labels[0])) {
      if (labels[0] === User.SUPER_ESPERTO) {
        label = <Tag color="blue">{User.SUPER_ESPERTO}</Tag>;
        onlySuperExpertsOnTopic.push({ ...expert, label });
      } else if (labels[0] === User.ESPERTO) {
        label = <Tag color="blue">{User.ESPERTO}</Tag>;
        onlyExpertsOnTopic.push({ ...expert, label });
        // IL CODICE SOTTO SERVE AD ORDINARE ANCHE I SUPER EDITOR E GLI EDITOR
        /* } else if (labels[0] === User.REDATTORE) {
        label = <Tag>{User.REDATTORE}</Tag>;
        onlyEditors.push({ ...expert, label });
      } else if (labels[0] === User.AMMINISTRATORE) {
        label = <Tag>{User.AMMINISTRATORE}</Tag>;
        onlySuperEditors.push({ ...expert, label });
      } */
      } else {
        const uniqueLabels = new Set(labels);
        label = <Tag>{uniqueLabels}</Tag>;
        otherUsers.push({ ...expert, label });
      }
    } else {
      // const labelsSet = Array.from(new Set(labels));
      label = <Tag>Altro</Tag>;
      otherUsers.push({ ...expert, label });
    }
  });

  // Se necessario, aggiungere ...onlySuperEditors, ...onlyEditors,
  return [...onlySuperExpertsOnTopic, ...onlyExpertsOnTopic, ...otherUsers];
};

const AssignQuestionToExpertSelect: React.FC<Props> = (props) => {
  const [selectableExperts, setSelectableExperts] = useState<UserAssignableToQuestionWithLabel[] | undefined>(
    undefined,
  );
  const [selectedExpert, setSelectedExpert] = useState<UserAssignableToQuestion | undefined>(undefined);

  const onComponentLoaded = useCallback(async () => {
    if (props.isMultipleQuestions && props.questions) {
      const questionIds = props.questions.map((question) => question.id);
      const experts = await (
        await props.userService.getUsersAssignableToMultipleQuestions(questionIds)
      ).sort(sortExpertBySurname);
      const expertsWithLabels = getExpertsWithLabels(experts, props.questions);
      setSelectableExperts([...expertsWithLabels]);
    } else {
      const experts = await (
        await props.userService.getUsersAssignableToQuestion(props.question!.id)
      ).sort(sortExpertBySurname);

      const topicChannel = props.question?.channelId!;
      const topicRubric = props.question?.rubricId!;
      const topicSubRubric = props.question?.subRubricId!;

      const onlySuperExpertsOnTopic = experts.filter(({ channels }) =>
        isSuperExpertOnTopic(channels, topicChannel, topicRubric, topicSubRubric),
      );
      const onlyExpertsOnTopic = experts.filter(({ channels }) =>
        isExpertOnTopic(channels, topicChannel, topicRubric, topicSubRubric),
      );
      const otherUsers = experts.filter(
        ({ channels }) =>
          !isExpertOnTopic(channels, topicChannel, topicRubric, topicSubRubric) &&
          !isSuperExpertOnTopic(channels, topicChannel, topicRubric, topicSubRubric),
      );

      setSelectableExperts([...onlySuperExpertsOnTopic, ...onlyExpertsOnTopic, ...otherUsers]);
    }
  }, [props.question, props.userService, props.isMultipleQuestions, props.questions]);

  const onExpertSelected = (expertId: string) => {
    const expert = selectableExperts?.find((e) => e.id === expertId);
    if (expert) {
      setSelectedExpert(expert);
      props.onExpertSelected(expert);
    }
  };

  useEffect(() => {
    onComponentLoaded();
  }, [onComponentLoaded]);

  const labelForExpertSelection = (expert: UserAssignableToQuestion, question: Question) => {
    if (isSuperExpertOnTopic(expert.channels, question.channelId, question.rubricId, question.subRubricId)) {
      return <Tag color="blue">{User.SUPER_ESPERTO}</Tag>;
    }

    if (isExpertOnTopic(expert.channels, question.channelId, question.rubricId, question.subRubricId)) {
      return <Tag color="blue">{User.ESPERTO}</Tag>;
    }

    if (expert.role === 'Administrator') {
      return <Tag>{User.AMMINISTRATORE}</Tag>;
    }

    return <Tag>{'Expert' === expert.role ? 'Altro' : expert.role}</Tag>;
  };

  const filterOption = (input: string, option: any): boolean => {
    const inputValue = input.toLowerCase();
    const optionValue = option.children[0].toLowerCase();
    return option !== undefined && optionValue.indexOf(inputValue) >= 0;
  };

  return (
    <Select
      showSearch
      style={{ width: props.width }}
      placeholder={selectableExperts === undefined ? 'Caricamento in corso...' : 'Seleziona un esperto'}
      optionFilterProp="children"
      filterOption={(input, option) => filterOption(input, option)}
      onSelect={(value: string) => {
        onExpertSelected(value);
      }}
      loading={selectableExperts === undefined}
      value={selectedExpert ? selectedExpert?.displayName : props.question?.assignedTo?.displayName}
      data-testid="assign-to-expert-field"
      disabled={props.disabled}
      virtual={false}
    >
      {!selectableExperts && (
        <Select.Option disabled={true} value="">
          <LoadingSpinner fontSize={20} />
          <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
        </Select.Option>
      )}
      {selectableExperts &&
        selectableExperts.map((expert) => (
          <Select.Option key={expert.id} value={expert.id} role="assign-to-expert-field-options">
            {expert.displayName}&nbsp;
            {props.isMultipleQuestions && props.questions && expert.label}
            {!props.isMultipleQuestions && labelForExpertSelection(expert, props.question!)}
          </Select.Option>
        ))}
    </Select>
  );
};

export default AssignQuestionToExpertSelect;
