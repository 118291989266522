import { Button, DatePicker, Form, Select, TreeSelect } from 'antd';
import moment from 'moment';
import DateUtils from '../../../../utils/DateUtils';
import { DEFAULT_FILTERS, INITIAL_CREATION_DATE_FILTER_RANGE, SelectData, TreeData } from '../../utils';
import './ReportsControlBar.css';

const Filters = ['channel', 'rubric', 'status', 'statusSubSet', 'creationDate', 'typology'] as const;
type FiltersAvailable = typeof Filters[number];

export type FiltersCallbackResult = {
  channel?: string[];
  rubric?: {
    [key: string]: string[];
  };
  status?: string[];
  typology?: string[];
  date: [string, string];
};

type ReportsControlBarProps = {
  onSubmit: (filters: FiltersCallbackResult) => void;
  onDownloadCSV: () => void;
  channelData: SelectData[];
  rubricTreeData: TreeData[];
  questionStatusData: SelectData[];
  filtersAvailable: FiltersAvailable[];
};

const STATUS_SUBSET_OPTIONS = ['ACE', 'ASS', 'NOTIFIED'];

const ReportsControlBar = (props: ReportsControlBarProps) => {
  const [form] = Form.useForm();

  const onSumbit = () => {
    const values = form.getFieldsValue();

    const rubricsValues = values.rubrics as string[] | undefined;

    const rubrics = rubricsValues?.reduce<{
      [key: string]: string[];
    }>((acc, curr) => {
      const [rubric, subRubric] = curr.split('||');

      if (!acc[rubric]) acc[rubric] = [];

      if (subRubric) acc[rubric].push(subRubric);

      return acc;
    }, {});

    const filters: FiltersCallbackResult = {
      channel: values.channel,
      rubric: rubrics,
      status: values.status,
      date: [
        DateUtils.formatForBackend(values.creationDate[0].startOf('day')), 
        DateUtils.formatForBackend(values.creationDate[1].endOf('day'))
      ],
      typology: values.typology ? [values.typology] : undefined,
    };

    props.onSubmit(filters);
  };

  const onReset = () => {
    form.resetFields();
    props.onSubmit(DEFAULT_FILTERS);
  };

  const filtersMap = {
    typology: (
      <Form.Item key="typology" name="typology" className="ReportsControlBar_inputElement">
        <Select placeholder="Tipologia" allowClear>
          <Select.Option value="PAY">PAY</Select.Option>
          <Select.Option value="FREE">FREE</Select.Option>
        </Select>
      </Form.Item>
    ),
    status: (
      <Form.Item key="status" name="status" className="ReportsControlBar_inputElement">
        <Select placeholder="Stato" allowClear mode="multiple" maxTagCount={1} className="ReportsControlBar_treeSelect">
          {props.questionStatusData.map((e) => (
            <Select.Option key={e.value} value={e.value}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    statusSubSet: (
      <Form.Item key="statusSubSet" name="status" className="ReportsControlBar_inputElement">
        <Select placeholder="Stato" allowClear mode="multiple" maxTagCount={1} className="ReportsControlBar_treeSelect">
          {props.questionStatusData
            .filter((s) => STATUS_SUBSET_OPTIONS.includes(s.value))
            .map((e) => (
              <Select.Option key={e.value} value={e.value}>
                {e.text}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    ),
    channel: (
      <Form.Item key="channel" name="channel" className="ReportsControlBar_inputElement">
        <Select
          placeholder="Canali"
          allowClear
          mode="multiple"
          maxTagCount={1}
          className="ReportsControlBar_treeSelect"
        >
          {props.channelData.map((e) => (
            <Select.Option key={`${e.value}-subset`} value={e.value}>
              {e.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    rubric: (
      <Form.Item key="rubrics" name="rubrics" className="ReportsControlBar_inputElement">
        <TreeSelect
          maxTagCount={1}
          className="ReportsControlBar_treeSelect"
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          placeholder="Rubrica"
          treeCheckable={true}
          allowClear
          treeData={props.rubricTreeData}
        />
      </Form.Item>
    ),
    creationDate: (
      <Form.Item
        key="creationDate"
        name="creationDate"
        initialValue={INITIAL_CREATION_DATE_FILTER_RANGE}
        className="ReportsControlBar_dateRange"
      >
        <DatePicker.RangePicker
          allowClear={false}
          ranges={{
            Oggi: [moment().startOf('day'), moment().endOf('day')],
            'Questa Settimana': [moment().startOf('week'), moment().endOf('week')],
            'Questo Mese': [moment().startOf('month'), moment().endOf('month')],
            'Questo Anno': [moment().startOf('year'), moment().endOf('year')],
          }}
          format="YYYY/MM/DD"
        />
      </Form.Item>
    ),
  };

  const getFilters = () => {
    return props.filtersAvailable.map((filter) => filtersMap[filter]);
  };

  return (
    <div className="ReportsControlBar">
      <Form style={{ gap: '10px' }} layout="inline" onFinish={onSumbit} onReset={onReset} form={form} name="fiters">
        {getFilters()}
        <Button type="link" htmlType="reset" className="ReportsControlBar_reset">
          Cancella filtri
        </Button>
        <Button type="primary" htmlType="submit">
          Cerca
        </Button>
      </Form>
      <Button type="default" onClick={props.onDownloadCSV}>
        Scarica Report
      </Button>
    </div>
  );
};

export default ReportsControlBar;
