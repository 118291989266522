import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, FormInstance, Select, Space } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Channel, Rubric, SubRubric } from '../../../../models/Channel';
import OrganizationService from '../../../../services/OrganizationService';
import SelectUtils from '../../../../utils/SelectUtils';

interface ExpertChannelFormListRowProps {
  formListName: string;
  fieldName: number;
  channels: Channel[] | undefined;
  remove: any;
  form: FormInstance;
  organizationService: OrganizationService;
  disabled?: boolean;
  editorChannels?: string[];
}

const ExpertChannelFormListRow: React.FC<ExpertChannelFormListRowProps> = ({
  formListName,
  fieldName,
  channels,
  remove,
  form,
  organizationService,
  disabled,
  editorChannels,
}) => {
  const [selectableRubrics, setSelectableRubrics] = useState<Rubric[] | undefined>(undefined);
  const [selectableSubRubrics, setSelectableSubRubrics] = useState<SubRubric[] | undefined>(undefined);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
  const [selectedRubric, setSelectedRubric] = useState<Rubric | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [rowIsDisabled, setRowIsDisabled] = useState(false);

  const onChannelSelected = async (channelId: string) => {
    let channel = channels?.find((channel) => channel.channelId === channelId);

    setSelectedChannel(channel);
    clearSelectedRubric();
    await loadRubricsByChannel(channelId);
  };

  const onRubricSelected = (rubricId: string) => {
    let rubric = selectableRubrics?.find((rubric) => rubric.rubricId === rubricId);
    setSelectedRubric(rubric);
    clearSelectedSubRubric();
    setSelectableSubRubrics(rubric?.subRubrics);
  };

  const loadRubricsByChannel = useCallback(
    async (channelId: string): Promise<Rubric[]> => {
      const rubricsByChannel = await organizationService.getRubricsByChannelId(channelId);
      setSelectableRubrics(rubricsByChannel);
      return rubricsByChannel;
    },
    [organizationService],
  );

  const channelIsAvailable = useCallback(
    (channelId: string): boolean => {
      if (!editorChannels) return true;

      return editorChannels?.includes(channelId);
    },
    [editorChannels],
  );

  const loadTopicValues = useCallback(async () => {
    const channel = form.getFieldValue(['expertChannels', fieldName, 'channelName']);
    const rubric = form.getFieldValue(['expertChannels', fieldName, 'rubricName']);

    setSelectedChannel(channel);
    setSelectedRubric(rubric);
    setRowIsDisabled(!channelIsAvailable(channel));

    let retrievedRubrics: Rubric[] | undefined = undefined;
    if (channel) {
      retrievedRubrics = await loadRubricsByChannel(channel);
    }

    if (rubric) {
      const selectableSubRubrics = retrievedRubrics?.find(
        (selectableRubric) => selectableRubric.rubricId === rubric,
      )?.subRubrics;

      setSelectableSubRubrics(selectableSubRubrics);
    }
  }, [channelIsAvailable, fieldName, form, loadRubricsByChannel]);

  const clearSelectedRubric = (): void => {
    setSelectedRubric(undefined);
    form.resetFields([[formListName, fieldName, 'rubricName']]);

    clearSelectedSubRubric();
  };

  const clearSelectedSubRubric = (): void => {
    setSelectableSubRubrics(undefined);
    form.resetFields([[formListName, fieldName, 'subRubricName']]);
  };

  useEffect(() => {
    setIsLoading(true);
    loadTopicValues();
    setIsLoading(false);
  }, [loadTopicValues]);

  return (
    <>
      {!isLoading && (
        <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
          <Form.Item
            name={[fieldName, 'channelName']}
            rules={[{ required: true, message: 'Selezionare un canale' }]}
            data-testid={`form-item-channel`}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Seleziona un canale"
              optionFilterProp="children"
              filterOption={SelectUtils.filterOption}
              onSelect={(value: string) => onChannelSelected(value)}
              disabled={disabled || rowIsDisabled}
            >
              {channels === undefined && loadingSelectOption}
              {channels !== undefined &&
                channels.map((channel, index) => (
                  <Select.Option
                    key={index}
                    value={channel.channelId}
                    disabled={!channelIsAvailable(channel.channelId)}
                    role="form-item-channel-options"
                  >
                    {channel.channelName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={[fieldName, 'rubricName']}
            rules={[{ required: true, message: 'Selezionare una rubrica' }]}
            data-testid={`form-item-rubric`}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Seleziona una rubrica"
              optionFilterProp="children"
              disabled={disabled || rowIsDisabled || selectedChannel === undefined}
              onSelect={(value: string) => onRubricSelected(value)}
              filterOption={SelectUtils.filterOption}
            >
              {selectableRubrics === undefined && loadingSelectOption}
              {selectableRubrics !== undefined &&
                selectableRubrics.map((rubric, index) => (
                  <Select.Option key={index} value={rubric.rubricId} role="form-item-rubric-options">
                    {rubric.rubricName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={[fieldName, 'subRubricName']}
            rules={[{ required: true, message: 'Selezionare una sotto rubrica' }]}
            data-testid={`form-item-subrubric`}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Seleziona una sotto rubrica"
              optionFilterProp="children"
              disabled={disabled || rowIsDisabled || selectedRubric === undefined}
              filterOption={SelectUtils.filterOption}
            >
              {selectableSubRubrics === undefined && loadingSelectOption}
              {selectableSubRubrics !== undefined &&
                selectableSubRubrics.map((subRubric, index) => (
                  <Select.Option key={index} value={subRubric.subRubricId} role="form-item-subrubric-options">
                    {subRubric.subRubricName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            data-testid="form-item-is-super-expert-checkbox"
            valuePropName="checked"
            initialValue={false}
            name={[fieldName, 'isSuperExpert']}
          >
            <Checkbox disabled={disabled || rowIsDisabled}>Superesperto</Checkbox>
          </Form.Item>

          <Button disabled={disabled || rowIsDisabled} onClick={() => remove(fieldName)} type="text">
            <MinusCircleOutlined data-testid="remove-button" />
          </Button>
        </Space>
      )}
    </>
  );

  function loadingSelectOption() {
    return (
      <Select.Option disabled={true} value="">
        <LoadingSpinner fontSize={20} />
        <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
      </Select.Option>
    );
  }
};
export default ExpertChannelFormListRow;
