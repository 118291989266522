import generateUUID from '../../../../utils/generateUUID';
import { useState } from 'react';
import { Table, TableProps } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';

type onPageSelectedChangeFunction = (pagination: TablePaginationConfig) => Promise<void> | void;
type ReportsTableProps = {
  onPageSelectedChange: onPageSelectedChangeFunction;
  total: number | undefined;
  pagination: TablePaginationConfig;
};

const ReportsTable = ({
  columns,
  dataSource,
  loading,
  onPageSelectedChange,
  total,
  pagination,
}: ReportsTableProps & TableProps<any>) => {
  const [currentPagination, setPagination] = useState<TablePaginationConfig>(pagination);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
    onPageSelectedChange(newPagination);
  };

  return (
    <Table
      rowKey={() => generateUUID()}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      onChange={onTableChange}
      pagination={{
        total,
        ...currentPagination,
      }}
    />
  );
};

export default ReportsTable;
