import { Descriptions, Divider, Form, FormInstance, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { QuestionDetail } from '../../../models/Question';
import QuestionStatus from '../../../utils/QuestionStatus';
import './EditorFieldsFormItem.css';

const { TextArea } = Input;

interface Props {
  readonly: boolean;
  question: QuestionDetail;
  form: FormInstance<any>;
  selectedAnswerIndex: number;
}

const EditorFieldsFormItem: React.FC<Props> = (props) => {
  const [isFirstLineRequired, setIsFirstLineRequired] = useState(false);
  const [secondLine, setSecondLine] = useState('');

  const updateRequirements = useCallback(() => {
    if (secondLine !== '') {
      setIsFirstLineRequired(true);
    } else {
      setIsFirstLineRequired(false);
    }
  }, [secondLine]);

  const isAnswerDisabled = () => {
    return (
      props.selectedAnswerIndex === -1 ||
      (props.selectedAnswerIndex === (props.question.answers?.length ?? 0) - 1 &&
        QuestionStatus.beforeACE(props.question.status))
    );
  };

  useEffect(() => {
    setSecondLine(props.form.getFieldValue('editorSubjectLine2'));
    updateRequirements();
  }, [props.form, updateRequirements]);

  return (
    <>
      {/* READONLY */}
      {props.readonly === true && (
        <Descriptions bordered size="small" layout="vertical" data-testid="editor-subject">
          <Descriptions.Item label="Soggetto editoriale" span={3}>
            {props.question!.editorSubject}
          </Descriptions.Item>
          <Descriptions.Item label="Quesito editoriale" span={3}>
            {props.question!.editorQuestion}
          </Descriptions.Item>
          <Descriptions.Item label="Risposta editoriale" span={3}>
            {props.question!.answers?.[props.question.answers.length - 1]?.editorAnswer}
          </Descriptions.Item>
        </Descriptions>
      )}

      {/* EDITABLE */}
      {props.readonly === false && (
        <>
          <h1 className="editorSubjectTitle">Soggetto Editoriale</h1>
          <Form.Item
            className="editorSubjectFormItem"
            name="editorSubjectLine1"
            label="Riga 1"
            data-testid="question-modal-form-editor-subject"
            rules={[
              {
                type: 'string',
                required: isFirstLineRequired,
                message: 'Non puoi inserire solo la seconda riga',
              },
            ]}
          >
            <TextArea rows={1} cols={25} showCount maxLength={35} />
          </Form.Item>
          <Form.Item
            className="editorSubjectFormItem"
            name="editorSubjectLine2"
            label="Riga 2"
            data-testid="question-modal-form-editor-subject"
            rules={[
              {
                type: 'string',
                required: false,
              },
            ]}
          >
            <TextArea
              onChange={(e) => {
                setSecondLine(e.currentTarget.value);
              }}
              rows={1}
              cols={25}
              showCount
              maxLength={35}
            />
          </Form.Item>
          <Divider />
          <Form.Item
            name="editorQuestion"
            label="Quesito editoriale"
            data-testid="question-modal-form-editor-question"
            rules={[
              {
                type: 'string',
                required: false,
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
          <Form.List name="answers">
            {(_) => {
              return (
                <Form.Item
                  name={[props.selectedAnswerIndex, 'editorAnswer']}
                  label="Risposta editoriale"
                  data-testid="question-modal-form-editor-answer"
                  rules={[
                    {
                      type: 'string',
                      required: false,
                    },
                  ]}
                >
                  <TextArea disabled={isAnswerDisabled()} rows={3} />
                </Form.Item>
              );
            }}
          </Form.List>
        </>
      )}
    </>
  );
};

export default EditorFieldsFormItem;
