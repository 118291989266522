import React from 'react';
import UserService from '../../../../services/UserService';
import { Dropdown, Menu } from 'antd';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './UserMenu.css';
import CurrentUser from '../../../../models/CurrentUser';
import NavigationService from '../../../../services/NavigationService';
import { userChannelsToTopics } from '../../../../models/UserChannel';
import User from '../../../../models/User';

interface UserMenuProps {
  currentUser: CurrentUser;
  navigationService: NavigationService;
  userService: UserService;
}

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const logout = async () => {
    await props.userService.logout();
    props.navigationService.kickToLogin();
  };

  const roleLabelFor = (user: CurrentUser) => {
    if (!user.isExpert()) {
      return user.role;
    }

    let isSuperExpert = userChannelsToTopics(user.channels).some(({ isSuperExpert }) => isSuperExpert);
    let isExpert = userChannelsToTopics(user.channels).some(({ isSuperExpert }) => !isSuperExpert);

    return isSuperExpert
      ? isExpert
        ? `${User.SUPER_ESPERTO}&${User.ESPERTO}`
        : User.SUPER_ESPERTO
      : User.ESPERTO;
  };

  const userMenu = (
    <div className="user-menu">
      <div className="user-menu-info">
        <div className="user-menu-info-header">Utente</div>
        <div>
          {props.currentUser.name} {props.currentUser.surname}
        </div>
        <div className="user-menu-info-header" style={{ paddingTop: 10 }}>
          Ruolo
        </div>
        <div>{roleLabelFor(props.currentUser)}</div>
      </div>
      <Menu>
        <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />} data-testid="logout-button">
          Esci
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Dropdown overlay={userMenu} trigger={['click']}>
      <span style={{ cursor: 'pointer' }} data-testid="dropdown-user-menu">
        <UserOutlined />
        <DownOutlined style={{ fontWeight: 'bold', fontSize: 16 }} />
      </span>
    </Dropdown>
  );
};

export default UserMenu;
