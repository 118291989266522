import { Question } from '../models/Question';
import OrganizationService from '../services/OrganizationService';

export default class OrganizationUtils {
  public static addOrganizationNamesToQuestions = async (
    questions: Question[],
    organizationService: OrganizationService,
  ) => {
    const organizationNames = await organizationService.getChannelsRubricsSubRubricsNamesWithDeleted();
    questions.forEach((question) => {
      question.channelName = organizationNames.get(question.channelId) ?? 'not found';
      question.rubricName = organizationNames.get(question.rubricId) ?? 'not found';
      question.subRubricName = organizationNames.get(question.subRubricId) ?? 'not found';

      if (question.publicationDetails) {
        question.publicationDetails.forEach((publicationDetail) => {
          publicationDetail.outputChannelName = organizationNames.get(publicationDetail.outputChannel) ?? 'not found';
        });
      }
    });
  };
}
