import { Card, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row } from 'antd';
import { ReactNode } from 'react';
import Constants from '../../Constants';

interface Props {
  title: string | ReactNode;
  formInstance: FormInstance<any>;
  formName: string;
  editMode: boolean;
  pubDate: moment.Moment | undefined;
  setPubDate: React.Dispatch<React.SetStateAction<moment.Moment | undefined>>;
  additionalButtons: ReactNode;
  setFormFieldsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionsContainerForm: React.FC<Props> = ({
  title,
  formInstance,
  formName,
  editMode,
  pubDate,
  setPubDate,
  additionalButtons,
  setFormFieldsChanged,
  children,
}) => {
  return (
    <Card title={title} type="inner">
      <Form form={formInstance} layout="vertical" name={formName} onFieldsChange={() => setFormFieldsChanged(true)}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              data-testid="form-item-title"
              name={`title`}
              label={`Titolo`}
              rules={[{ required: true, message: 'Titolo Fascicolo Obbligatorio' }]}
            >
              <Input disabled={!editMode} placeholder="Titolo" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid="form-item-issueNumber"
              name={`issueNumber`}
              label={`Numero uscita`}
              rules={[{ required: true, message: 'Numero di Uscita Fascicolo Obbligatorio' }]}
            >
              <Input disabled={!editMode} placeholder="Numero Uscita" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              data-testid="form-item-publicationDate"
              name={`publicationDate`}
              label={`Data pubblicazione`}
              rules={[{ required: true, message: 'Data di pubblicazione Fascicolo Obbligatorio' }]}
            >
              <DatePicker
                data-testid="publicationDate-datePicker"
                format={Constants.FRONTEND_DATE_FORMAT}
                onChange={(dateMoment) => setPubDate(dateMoment!)}
                value={pubDate}
                allowClear={false}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              data-testid="form-item-startQuestionNumber"
              name={`startQuestionNumber`}
              label={`Numero progressivo quesito`}
              rules={[{ required: true, message: 'Numero progressivo quesito Obbligatorio' }]}
            >
              <InputNumber disabled={!editMode} min={1} />
            </Form.Item>
          </Col>
        </Row>

        {children}

        <div style={{ textAlign: 'right' }}>{additionalButtons}</div>
      </Form>
    </Card>
  );
};

export default QuestionsContainerForm;
