export default class UserRole {
  public static readonly SUPER_ADMINISTRATOR = 'SuperAdministrator';
  public static readonly ADMINISTRATOR = 'Administrator';
  public static readonly EDITOR = 'Editor';
  public static readonly EXPERT = 'Expert';

  public static isSuperAdministrator(roleName: string) {
    return roleName.toLowerCase() === UserRole.SUPER_ADMINISTRATOR.toLowerCase();
  }

  public static isAdministrator(roleName: string) {
    return roleName.toLowerCase() === UserRole.ADMINISTRATOR.toLowerCase();
  }

  public static isEditor(roleName: string) {
    return roleName.toLowerCase() === UserRole.EDITOR.toLowerCase();
  }

  public static isExpert(roleName: string) {
    return roleName.toLowerCase() === UserRole.EXPERT.toLowerCase();
  }
}
