import { Tabs } from 'antd';
import { ChartData } from 'chart.js';
import { FC, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ReportService, { RubricheQuesitiData } from '../../../services/ReportService';
import PieChart from '../components/PieChart/PieChart';
import ReportsControlBar, { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import { DEFAULT_FILTERS, downloadCSVFromChartData, downloadCSVFromJSON, ReportProps } from '../utils';
import { mapRubricheDataToChart, mapSottoRubricheToChart, mapStatusQuesitiDataToChart } from './ReportQuesitiUtils';

type TabTypes = 'stati-quesiti' | 'quesiti-per-rubrica-sottorubrica';
const DEFAULT_TAB: TabTypes = 'stati-quesiti';

const ReportQuesiti: FC<ReportProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [activeTab, setActiveTab] = useState<TabTypes>(DEFAULT_TAB);
  const [reportStatusQuesiti, setReportStatusQuesiti] = useState<ChartData<'pie'> | null>(null);
  const [rubricheQuesitiData, setRubricheQuesitiData] = useState<RubricheQuesitiData[] | null>(null);
  const [reportRubricheQuesiti, setReportRubricheQuesiti] = useState<ChartData<'pie'> | null>(null);
  const [reportSottoRubricheQuesiti, setReportSottoRubricheQuesiti] = useState<ChartData<'pie'> | null>(null);

  const onTabChange = (key: TabTypes) => {
    setActiveTab(key);
  };

  const getReportStatusQuesiti = async (theFilters: FiltersCallbackResult) => {
    setIsRefreshingData(true);
    try {
      const data = await ReportService.fetchReportStatusQuesiti(theFilters);
      setReportStatusQuesiti(mapStatusQuesitiDataToChart(data, 'Stati quesiti'));
    } catch {
      setReportStatusQuesiti(null);
      console.error('Impossibile recuperare gli stati dei quesiti');
    } finally {
      setIsRefreshingData(false);
    }
  };

  const getReportQuesitiPerRubricaSottorubrica = async (theFilters: FiltersCallbackResult) => {
    setIsRefreshingData(true);
    setReportSottoRubricheQuesiti(null);
    try {
      const data = await ReportService.fetchReportRubricheQuesiti(theFilters);
      setRubricheQuesitiData(data);
      if (data.length === 1) {
        // If there is only one rubrica, we show the sotto rubriche
        if (rubricheQuesitiData) setReportSottoRubricheQuesiti(mapSottoRubricheToChart(rubricheQuesitiData, 0));
      }
      setReportRubricheQuesiti(mapRubricheDataToChart(data, 'Rubriche quesiti'));
    } catch {
      setReportRubricheQuesiti(null);
      setRubricheQuesitiData(null);
      console.error('Impossibile recuperare le rubriche dei quesiti');
    } finally {
      setIsRefreshingData(false);
    }
  };

  const getTheRightReportData = (filters: FiltersCallbackResult) => {
    switch (activeTab) {
      case 'stati-quesiti':
        getReportStatusQuesiti(filters);
        break;
      case 'quesiti-per-rubrica-sottorubrica':
        getReportQuesitiPerRubricaSottorubrica(filters);
        break;
      default:
        break;
    }
  };

  const onRubricheQuesitiSliceClick = (index: number) => {
    if (!rubricheQuesitiData) return;
    if (reportSottoRubricheQuesiti) {
      setReportSottoRubricheQuesiti(null);
      return;
    }
    setReportSottoRubricheQuesiti(mapSottoRubricheToChart(rubricheQuesitiData, index));
  };

  useEffect(() => {
    getTheRightReportData(DEFAULT_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleDownloadCSV = (data: ChartData<'pie'> | null, title: string, columns: string[]) => {
    if (!data) return;
    downloadCSVFromChartData(data, title, columns);
  };

  const handleDownloadRubricheQuesitiCSV = (data: RubricheQuesitiData[] | null, title: string, columns: string[]) => {
    if (!data) return;

    const csvData: {
      [key: string]: string | number;
    }[] = [];

    data.forEach((item) => {
      csvData.push({
        rubrica: item.name,
        sottorubrica: '',
        value: item.total,
      });
      item.subRubrics.forEach((subItem) => {
        csvData.push({
          rubrica: item.name,
          sottorubrica: subItem.name,
          value: subItem.total,
        });
      }, []);
    }, []);

    downloadCSVFromJSON(csvData, title, columns);
  };

  return (
    <div>
      <h2>Report Quesiti</h2>
      <Tabs
        onChange={(newKey) => onTabChange(newKey as TabTypes)}
        defaultActiveKey={DEFAULT_TAB}
        style={{ minHeight: '70vh' }}
      >
        <Tabs.TabPane tab="Stati dei Quesiti" key="stati-quesiti">
          <ReportsControlBar
            filtersAvailable={['typology', 'channel', 'status', 'creationDate']}
            channelData={props.channelData}
            rubricTreeData={props.rubricTreeData}
            questionStatusData={props.questionStatusData}
            onSubmit={(values) => getTheRightReportData(values)}
            onDownloadCSV={() => handleDownloadCSV(reportStatusQuesiti, 'Stati dei Quesiti', ['label', 'value'])}
          />
          <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
            {reportStatusQuesiti && <PieChart data={reportStatusQuesiti} />}
          </LoadingSpinner>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Quesiti per Rubrica e Sottorubrica" key="quesiti-per-rubrica-sottorubrica">
          <ReportsControlBar
            filtersAvailable={['typology', 'channel', 'rubric', 'creationDate']}
            channelData={props.channelData}
            rubricTreeData={props.rubricTreeData}
            questionStatusData={props.questionStatusData}
            onSubmit={(values) => getTheRightReportData(values)}
            onDownloadCSV={() =>
              handleDownloadRubricheQuesitiCSV(rubricheQuesitiData, 'Rubriche dei Quesiti', [
                'rubrica',
                'sottorubrica',
                'value',
              ])
            }
          />
          <LoadingSpinner fontSize={150} isSpinning={isRefreshingData}>
            {reportRubricheQuesiti && !reportSottoRubricheQuesiti && (
              <PieChart data={reportRubricheQuesiti} onSliceClickAtIndex={onRubricheQuesitiSliceClick} />
            )}
            {reportSottoRubricheQuesiti && (
              <PieChart data={reportSottoRubricheQuesiti} onSliceClickAtIndex={onRubricheQuesitiSliceClick} />
            )}
          </LoadingSpinner>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ReportQuesiti;
