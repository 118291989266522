import { FC, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import ReportsTable from '../components/ReportsTable/ReportsTable';
import ReportsControlBar, { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import { ReportProps, DEFAULT_FILTERS, DEFAULT_PAGINATION, downloadCSVFromText } from '../utils';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import ReportService, { ExpertStatiInfo, AverageAnswerTimeByExpertInfo } from '../../../services/ReportService';
import LoadingSpinner from '../../../components/LoadingSpinner';

import {
  TabTypes,
  DEFAULT_TAB,
  DEFAULT_SORTER_STATI_QUESITI_PER_ESPERTO,
  DEFAULT_SORTER_MEDIA_TEMPI_RISPOSTA_PER_ESPERTO,
  columnsStatiQuesitiEsperti,
  columnsMediaTempiRispostaEsperti,
  getReportEspertiStatusQuesitiType,
  getReportEspertiMediaTempiRispostaType,
} from './ReportEspertiUtils';

const ReportEsperti: FC<ReportProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [activeTab, setActiveTab] = useState<TabTypes>(DEFAULT_TAB);
  const [reportStatusQuesitiPerEsperto, setReportStatusQuesitiPerEsperto] = useState<ExpertStatiInfo[] | null>(null);
  const [totalStatusQuesitiPerEsperto, setTotalStatusQuesitiPerEsperto] = useState(0);
  const [reportMediaTempiQuesitiPerEsperto, setReportMediaTempiQuesitiPerEsperto] = useState<
    AverageAnswerTimeByExpertInfo[] | null
  >(null);
  const [totalMediaTempiQuesitiPerEsperto, setTotalMediaTempiQuesitiPerEsperto] = useState(0);
  const [currentFiltersStatiDeiQuesiti, setCurrentFiltersStatiDeiQuesiti] =
    useState<FiltersCallbackResult>(DEFAULT_FILTERS);
  const [currentFiltersMediaTempiQuesiti, setCurrentFiltersMediaTempiQuesiti] =
    useState<FiltersCallbackResult>(DEFAULT_FILTERS);

  const onTabChange = (key: TabTypes) => {
    setActiveTab(key);
  };

  const getReportEspertiStatusQuesiti: getReportEspertiStatusQuesitiType = async (
    theFilters,
    sorter = DEFAULT_SORTER_STATI_QUESITI_PER_ESPERTO,
    pagination = DEFAULT_PAGINATION,
  ) => {
    setIsRefreshingData(true);
    try {
      const data = await ReportService.fetchReportEspertiStatiDeiQuesiti(theFilters, sorter, pagination);
      setReportStatusQuesitiPerEsperto(data.experts);
      setTotalStatusQuesitiPerEsperto(data.fullCount);
      setCurrentFiltersStatiDeiQuesiti(theFilters);
    } catch {
      setReportStatusQuesitiPerEsperto(null);
      setTotalStatusQuesitiPerEsperto(0);
      console.error('Impossibile recuperare le rubriche dei quesiti');
    } finally {
      setIsRefreshingData(false);
    }
  };

  const getReportEspertiMediaTempiRisposta: getReportEspertiMediaTempiRispostaType = async (
    theFilters,
    sorter = DEFAULT_SORTER_MEDIA_TEMPI_RISPOSTA_PER_ESPERTO,
    pagination = DEFAULT_PAGINATION,
  ) => {
    setIsRefreshingData(true);
    try {
      const data = await ReportService.fetchReportEspertiMediaTempiRisposta(theFilters, sorter, pagination);
      setReportMediaTempiQuesitiPerEsperto(data.experts);
      setTotalMediaTempiQuesitiPerEsperto(data.fullCount);
      setCurrentFiltersMediaTempiQuesiti(theFilters);
    } catch {
      setReportMediaTempiQuesitiPerEsperto(null);
      setTotalMediaTempiQuesitiPerEsperto(0);
      console.error('Impossibile recuperare le rubriche dei quesiti');
    } finally {
      setIsRefreshingData(false);
    }
  };

  const getTheRightReportData = (filters: FiltersCallbackResult) => {
    switch (activeTab) {
      case 'stati-quesiti-per-esperto':
        getReportEspertiStatusQuesiti(filters);
        break;
      case 'media-tempi-quesiti-per-esperto':
        getReportEspertiMediaTempiRisposta(filters);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getTheRightReportData(DEFAULT_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const onDownloadCSVMediaTempiRisposta = async () => {
    try {
      const data = await ReportService.downloadReportEspertiMediaTempiRisposta(currentFiltersMediaTempiQuesiti);
      downloadCSVFromText(data, 'Media Temi Risposta per Esperto');
    } catch {
      console.error('Impossibile scaricare il report');
    }
  };

  const onDownloadCSEspertiEStatiQuesiti = async () => {
    try {
      const data = await ReportService.downloadReportEspertiStatiDeiQuesiti(currentFiltersStatiDeiQuesiti);
      downloadCSVFromText(data, 'Stati dei Quesiti per Esperto');
    } catch {
      console.error('Impossibile scaricare il report');
    }
  };

  const onPageSelectedStatusQuesitiPerEspertoChange = (newPagination: TablePaginationConfig) => {
    getReportEspertiStatusQuesiti(currentFiltersStatiDeiQuesiti, {}, newPagination);
  };

  const onPageSelectedEspertiMediaTempiRispostaChange = (newPagination: TablePaginationConfig) => {
    getReportEspertiStatusQuesiti(currentFiltersMediaTempiQuesiti, {}, newPagination);
  };

  return (
    <div>
      <h2>Report Esperti</h2>
      <Tabs onChange={(newKey) => onTabChange(newKey as TabTypes)} defaultActiveKey={DEFAULT_TAB}>
        <Tabs.TabPane tab="Stati dei Quesiti" key="stati-quesiti" style={{ minHeight: '50vh' }}>
          <ReportsControlBar
            filtersAvailable={['typology', 'channel', 'creationDate']}
            channelData={props.channelData}
            rubricTreeData={props.rubricTreeData}
            questionStatusData={props.questionStatusData}
            onSubmit={(values) => getReportEspertiStatusQuesiti(values)}
            onDownloadCSV={onDownloadCSEspertiEStatiQuesiti}
          />
          <LoadingSpinner isSpinning={isRefreshingData && !reportStatusQuesitiPerEsperto}>
            {reportStatusQuesitiPerEsperto && (
              <ReportsTable
                columns={columnsStatiQuesitiEsperti}
                dataSource={reportStatusQuesitiPerEsperto}
                loading={isRefreshingData}
                total={totalStatusQuesitiPerEsperto}
                pagination={DEFAULT_PAGINATION}
                onPageSelectedChange={onPageSelectedStatusQuesitiPerEspertoChange}
              />
            )}
          </LoadingSpinner>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Media Tempi Risposta" key="media-tempi-quesiti-per-esperto" style={{ minHeight: '50vh' }}>
          <ReportsControlBar
            filtersAvailable={['typology', 'channel', 'status', 'creationDate']}
            channelData={props.channelData}
            rubricTreeData={props.rubricTreeData}
            questionStatusData={props.questionStatusData}
            onSubmit={(values) => getReportEspertiMediaTempiRisposta(values)}
            onDownloadCSV={onDownloadCSVMediaTempiRisposta}
          />
          <LoadingSpinner isSpinning={isRefreshingData && !reportMediaTempiQuesitiPerEsperto}>
            {reportMediaTempiQuesitiPerEsperto && (
              <ReportsTable
                columns={columnsMediaTempiRispostaEsperti}
                dataSource={reportMediaTempiQuesitiPerEsperto}
                loading={isRefreshingData}
                total={totalMediaTempiQuesitiPerEsperto}
                pagination={DEFAULT_PAGINATION}
                onPageSelectedChange={onPageSelectedEspertiMediaTempiRispostaChange}
              />
            )}
          </LoadingSpinner>
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Classifica Esperti" key="classifica-esperti">
          <ReportsControlBar
            filtersAvailable={['typology', 'channel', 'status', 'creationDate']}
            channelData={props.channelData}
            rubricTreeData={props.rubricTreeData}
            questionStatusData={props.questionStatusData}
            onSubmit={(values) => console.log(values)}
            onDownloadCSV={() => console.log('download csv')}
          />
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
};

export default ReportEsperti;
