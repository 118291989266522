import { Divider, List, Tag } from 'antd';
import React from 'react';
import { Classification } from '../../../../models/Question';

interface Props {
  classifications: Classification[];
}

export const ArchivedClassificationsItem: React.FC<Props> = (props: Props) => {
  return (
    <List
      data-testid="comments-list"
      className="comment-list"
      itemLayout="horizontal"
      dataSource={props.classifications}
      renderItem={(classification) => (
        <li>
          <Tag>
            {classification.manual ? 'MANUAL' : `AUTO ${classification.rank}%`}
            <Divider type="vertical" orientation="center" />
            {classification.classificationValue}
          </Tag>
        </li>
      )}
    />
  );
};
