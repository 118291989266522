import { Avatar, Comment as CommentComponent, List, message } from 'antd';
import CurrentUser from '../../../../models/CurrentUser';
import { Comment } from '../../../../models/Question';
import QuestionService from '../../../../services/QuestionService';
import DateUtils from '../../../../utils/DateUtils';

type Props = {
  comments?: Comment[];
  currentUser?: CurrentUser;
  questionService?: QuestionService;
  updateComments?: () => Promise<void>;
};

export const CommentsFormItemList = (props: Props) => {
  const isCommentAuthor = (comment: Comment) => comment.user.id === props.currentUser?.id;

  const deleteComment = async (commenId: string) => {
    try {
      await props.questionService?.deleteComment(commenId);
      await props.updateComments?.();
      message.success('Commento eliminato con successo');
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  return (
    <>
      {props.comments && (
        <List
          data-testid="comments-list"
          className="comment-list"
          itemLayout="horizontal"
          dataSource={props.comments}
          renderItem={(comment) => (
            <li key={comment.id}>
              <CommentComponent
                actions={
                  isCommentAuthor(comment)
                    ? [
                        <span id="comment-delete-action" onClick={() => deleteComment(comment.id)}>
                          Elimina
                        </span>,
                      ]
                    : []
                }
                data-testid="comment-item"
                avatar={<Avatar>{comment.user.displayName[0]}</Avatar>}
                author={<p style={{ color: '#21303d' }}>{comment.user.displayName}</p>}
                content={
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {comment.text}
                  </div>
                }
                datetime={DateUtils.format(comment.creationDate)}
              />
            </li>
          )}
        />
      )}
    </>
  );
};
