import { FC } from 'react';
import './App.less';
import Home from './pages/Home/Home';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import UserService from './services/UserService';
import NotFound from './pages/NotFound/NotFound';
import NavigationService from './services/NavigationService';
import { createBrowserHistory } from 'history';
import OrganizationService from './services/OrganizationService';
import QuestionService from './services/QuestionService';
import QuestionsContainerService from './services/QuestionsContainerService';
import UploadService from './services/UploadService';

const App: FC = () => {
  const history = createBrowserHistory();
  const userService = new UserService();
  const navigationService = new NavigationService(history);
  const organizationService = new OrganizationService();
  const questionService = new QuestionService();
  const questionsContainerService = new QuestionsContainerService();
  const uploadService = new UploadService();

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={navigationService.getHomePath()}
          render={(props: any) => (
            <Home
              navigationService={navigationService}
              userService={userService}
              organizationService={organizationService}
              questionService={questionService}
              questionsContainerService={questionsContainerService}
              uploadService={uploadService}
              {...props}
            />
          )}
        />
        <Route
          path={navigationService.getLoginPath()}
          render={(props: any) => (
            <Login
              navigationService={navigationService}
              orgName={organizationService.getVisibleEnvironment()}
              {...props}
            />
          )}
        />
        <Redirect exact from="/" to={navigationService.getLoginPath()} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
