import { CloseCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import Text from 'antd/lib/typography/Text';
import { ReactNode } from 'react';

interface Props {
  title: string;
  buttons: ReactNode;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  placement: TooltipPlacement;
}

const PopconfirmWithCustomButtons: React.FC<Props> = ({ title, buttons, visible, setVisible, children, placement }) => {
  const CloseIcon = () => (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setVisible(false);
      }}
    >
      <Tooltip title="Esci">
        <CloseCircleOutlined style={{ cursor: 'pointer' }} />
      </Tooltip>
    </div>
  );

  return (
    <Tooltip
      overlayClassName="popconfirm-with-custom-buttons"
      title={
        <div>
          <CloseIcon />
          <Space direction="vertical" align="end" style={{ marginLeft: '1.5rem' }}>
            <Text>{title}</Text>
            <Space>{buttons}</Space>
          </Space>
        </div>
      }
      placement={placement}
      visible={visible}
      trigger="click"
      color="#fff"
      overlayStyle={{
        color: 'rgba(0,0,0,.85)',
        backgroundColor: '#fff',
        maxWidth: '40rem',
      }}
      overlayInnerStyle={{
        color: 'rgba(0,0,0,.85)',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        padding: '1rem',
        borderRadius: '2px',
        boxShadow: '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)',
      }}
    >
      {children}
    </Tooltip>
  );
};

export default PopconfirmWithCustomButtons;
