import moment from 'moment';
import Constants from '../Constants';

export default class DateUtils {
  public static format(date: Date | undefined): string {
    if (!date) return '';
    return moment(date).format(Constants.FRONTEND_DATETIME_FORMAT);
  }

  public static formatForBackend(date: Date | undefined): string {
    if (!date) return '';
    return moment(date).format(Constants.BACKEND_DATETIME_FORMAT);
  }

  public static formatOnlyDate(date: Date | undefined): string {
    if (!date) return '';
    return moment(date).format(Constants.FRONTEND_DATE_FORMAT);
  }
}
