import { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { ExpertStatiInfo, AverageAnswerTimeByExpertInfo } from '../../../services/ReportService';

export type TabTypes = 'stati-quesiti-per-esperto' | 'media-tempi-quesiti-per-esperto' | 'classifica-esperti';
export const DEFAULT_TAB: TabTypes = 'stati-quesiti-per-esperto';
export const DEFAULT_SORTER_STATI_QUESITI_PER_ESPERTO: SorterResult<ExpertStatiInfo> = {
  field: 'fullName',
  order: 'ascend',
};
export const DEFAULT_SORTER_MEDIA_TEMPI_RISPOSTA_PER_ESPERTO: SorterResult<AverageAnswerTimeByExpertInfo> = {
  columnKey: 'fullName',
  order: 'ascend',
};

export const columnsStatiQuesitiEsperti = [
  {
    title: 'Nome Esperto',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Assegnato',
    dataIndex: 'ass',
    key: 'ass',
  },
  {
    title: 'Accettato',
    dataIndex: 'ace',
    key: 'ace',
  },
  {
    title: 'Rifiutato definitivamente',
    dataIndex: 'rif',
    key: 'rif',
  },
  {
    title: 'Richiesta informazioni',
    dataIndex: 'info',
    key: 'info',
  },
  {
    title: 'Preso in carico',
    dataIndex: 'prog',
    key: 'prog',
  },
  {
    title: 'Risposto',
    dataIndex: 'ans',
    key: 'ans',
  },
  {
    title: 'Risposte Rifiutate',
    dataIndex: 'ansrif',
    key: 'ansrif',
  },
  {
    title: 'Risposta Aggiornate',
    dataIndex: 'ansagg',
    key: 'ansagg',
  },
];

export const columnsMediaTempiRispostaEsperti = [
  {
    title: 'Nome Esperto',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Media (in ore)',
    dataIndex: 'averageTime',
    key: 'averageTime',
  },
  {
    title: 'Min (in ore)',
    dataIndex: 'minTime',
    key: 'minTime',
  },
  {
    title: 'Max (in ore)',
    dataIndex: 'maxTime',
    key: 'maxTime',
  },
];

export type getReportEspertiStatusQuesitiType = (
  filters: FiltersCallbackResult,
  sorter?: SorterResult<ExpertStatiInfo>,
  pagination?: TablePaginationConfig,
) => Promise<void>;

export type getReportEspertiMediaTempiRispostaType = (
  filters: FiltersCallbackResult,
  sorter?: SorterResult<AverageAnswerTimeByExpertInfo>,
  pagination?: TablePaginationConfig,
) => Promise<void>;
