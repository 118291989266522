import { Descriptions } from 'antd';
import { ArchivedQuestion } from '../../../../models/ArchivedQuestionContainerDetails';
import DateUtils from '../../../../utils/DateUtils';
import QuestionStatus from '../../../../utils/QuestionStatus';
import { ArchivedClassificationsItem } from './ArchivedClassificationsItem';

type Props = {
  question: ArchivedQuestion;
};

export const ArchivedQuestionItem = (props: Props) => {
  return (
    <>
      <Descriptions bordered size="small">
        <Descriptions.Item label="Subject" span={4}>
          {props.question.subject}
        </Descriptions.Item>
        <Descriptions.Item label="Question" span={4}>
          {props.question.question}
        </Descriptions.Item>
        <Descriptions.Item label="Canale" span={4}>
          {props.question.channelName}
        </Descriptions.Item>
        <Descriptions.Item label="Rubrica" span={4}>
          {props.question.rubricName}
        </Descriptions.Item>
        <Descriptions.Item label="Sotto-rubrica" span={4}>
          <>{props.question.subRubricName}</>
        </Descriptions.Item>
        <Descriptions.Item label="Stato" span={4}>
          {QuestionStatus.label(props.question.status)}
        </Descriptions.Item>
        <Descriptions.Item label="Data di creazione" span={4}>
          {DateUtils.format(props.question.creationDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Classificazione" span={4}>
          {props.question.classifications && (
            <ArchivedClassificationsItem classifications={props.question.classifications} />
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
