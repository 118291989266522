import { CloseCircleOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, message, Popconfirm, Radio, Row, Select, Switch, Upload } from 'antd';
import { Rule } from 'antd/lib/form';
import ImgCrop from 'antd-img-crop';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as config from '../../../env/env.json';
import CurrentUser from '../../../models/CurrentUser';
import FullUserData from '../../../models/FullUserData';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import UploadService, { S3UploadInfo } from '../../../services/UploadService';
import UserService from '../../../services/UserService';
import { mandatoryTextFieldCheckRule } from '../../../utils/FormHelper';
import EditorChannelFormList from './components/EditorChannelFormList';
import ExpertChannelFormList from './components/ExpertChannelFormList';
import {
  DEFAULT_EXIT_WITHOUT_SAVE_POP_CONFIRM_MESSAGE,
  DEFAULT_SAVE_POP_CONFIRM_MESSAGE,
  SAVE_WITH_MAIL_CHANGED_POP_CONFIRM_MESSAGE,
} from './components/PopConfirmMessages';
import User from '../../../models/User';
import './CreateOrEditUser.css';

const cfg = (config as any).default;

interface CreateUserProps {
  navigationService: NavigationService;
  userService: UserService;
  organizationService: OrganizationService;
  uploadService: UploadService;
  currentUser: CurrentUser;
}

const CreateOrEditUser: React.FC<CreateUserProps> = (props) => {
  const [form] = Form.useForm();
  const [isRefreshigUsers, setIsRefreshingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userType, setUserType] = useState('person');
  const [userRole, setUserRole] = useState<String>();
  const [isWaiting, setIsWaiting] = useState(false);
  const [userCreationSucceed, setUserCreationSucceed] = useState<boolean | undefined>(undefined);
  const [userCreationMessage, setUserCreationMessage] = useState<string | undefined>(undefined);
  const { userId } = useParams<{ userId?: string }>();
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [user, setUser] = useState<FullUserData | undefined>(undefined);
  const [formFieldsChanged, setFormFieldsChanged] = useState<boolean>(false);
  const [popConfirmExitWithChangesVisible, setPopConfirmExitWithChangesVisible] = useState<boolean>(false);
  const [savePopConfirmMessage, setSavePopConfirmMessage] = useState<JSX.Element>(<DEFAULT_SAVE_POP_CONFIRM_MESSAGE />);

  const [popConfirmExitIconWithChangesVisible, setPopConfirmExitIconWithChangesVisible] = useState<boolean>(false);

  const [enableGenAI, setEnableGenAI] = useState<boolean>(false);
  const currentUserRole = props.currentUser.role;

  useEffect((): void => {
    if (!user) return;

    const fetchUsers = async (): Promise<void> => {
      try {
        setIsRefreshingUsers(true);
        const retrievedUsers = await props.userService.queryUsers();
        const usersWithoutCurrentUser = retrievedUsers.filter((u) => u.id !== user.email);

        setUsers(usersWithoutCurrentUser);

        setIsRefreshingUsers(false);
      } catch (e: any) {
        console.error(JSON.stringify(e));
      } finally {
        setIsRefreshingUsers(false);
      }
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const aNotUsedEmailRule: Rule = {
    message: 'Questa mail è già in uso da un altro utente',
    validator: async (_, value) => {
      if (isRefreshigUsers) return Promise.resolve();
      if (value) {
        let userWithSameEmail = users.find((u: User) => u.id === value);
        if (userWithSameEmail) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    },
  };

  useEffect(() => {
    if (!userId) return;
    setIsWaiting(true);
    props.userService
      .getUser(userId)
      .then((user) => {
        if (!user) return;

        form.setFieldsValue({ ...user });
        setUser(user);
        setEnableGenAI(user.enableGenAI || false);
        setUserType(user.userType || 'person');
        console.log(user);
        setUserRole(user.role);
        if (user.avatarUrl) {
          setImageUrl(cfg.IMAGE_BASE_URL + user.avatarUrl);
        }
        setIsWaiting(false);
      })
      .catch(console.error)
      .finally(() => {
        setFormFieldsChanged(false);
        setIsWaiting(false);
      });
  }, [form, props.userService, userId]);

  const CloseButton = () => (
    <Popconfirm
      placement="topLeft"
      title={<DEFAULT_EXIT_WITHOUT_SAVE_POP_CONFIRM_MESSAGE />}
      visible={popConfirmExitIconWithChangesVisible}
      onConfirm={handlePopConfirmExitWithoutChangesOk}
      onCancel={() => setPopConfirmExitIconWithChangesVisible(false)}
      okText="Esci senza salvare"
      cancelText="Annulla"
      okButtonProps={{ loading: isWaiting }}
      icon={
        <div style={{ cursor: 'pointer' }} onClick={() => setPopConfirmExitIconWithChangesVisible(false)}>
          <CloseCircleOutlined style={{ cursor: 'pointer' }} data-testid="question-modal-close-without-saving-button" />
        </div>
      }
    >
      <CloseCircleOutlined key="back" onClick={showPopExitIconConfirm} data-testid="container-close-button" />
    </Popconfirm>
  );

  const Title = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {userId ? `Anagrafica utente ${atob(userId)}` : `Crea utente`} <CloseButton />
    </div>
  );

  const isMailChanged = () => {
    if (!user) return false;
    const prevMail = user.email;
    const newMail = form.getFieldValue('email');
    return prevMail !== newMail;
  };

  const getSavePopConfirmMessage = (): JSX.Element => {
    if (isMailChanged() && user) {
      return <SAVE_WITH_MAIL_CHANGED_POP_CONFIRM_MESSAGE newMail={form.getFieldValue('email')} oldMail={user.email} />;
    }

    return <DEFAULT_SAVE_POP_CONFIRM_MESSAGE />;
  };

  return (
    <>
      {userCreationSucceed === true && (
        <>
          <Alert
            message={(userId && 'Anagrafica utente modificata con successo!') || 'Utente creato con successo!'}
            type="success"
            showIcon
            action={
              <Button size="small" type="text" onClick={() => props.navigationService.goToUsers()}>
                Vai alla lista utenti
              </Button>
            }
            data-testid="create-user-success-alert"
          />
          <br />
        </>
      )}
      {userCreationSucceed === false && (
        <>
          <Alert message="Creazione utente fallita!" description={userCreationMessage} type="error" showIcon></Alert>
          <br />
        </>
      )}

      <Card title={Title()} type="inner">
        <Form
          form={form}
          layout="vertical"
          name="create_user"
          className="ant-create-user-form"
          onFieldsChange={() => {
            setFormFieldsChanged(true);
            setSavePopConfirmMessage(getSavePopConfirmMessage);
          }}
          onFinish={createOrEditUser}
          initialValues={{ userType: userType, enabled: true, enableGenAI: enableGenAI }}
        >
          {userRole === 'Expert' && (
            <Row gutter={24}>
              <Col span={12}>
                {canEdit() ? (
                  <ImgCrop>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                    >
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                      ) : (
                        <div>
                          {isWaiting ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>Carica avatar dell'esperto</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                ) : (
                  imageUrl && <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                )}
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                data-testid="form-item-name"
                name={`name`}
                label={`Nome`}
                rules={[mandatoryTextFieldCheckRule]}
              >
                <Input placeholder="Nome" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                data-testid="form-item-surname"
                name={`surname`}
                label={`Cognome`}
                rules={[mandatoryTextFieldCheckRule]}
              >
                <Input placeholder="Cognome" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                data-testid="form-item-email"
                name={`email`}
                label={`Email`}
                rules={[
                  { ...mandatoryTextFieldCheckRule, type: 'email', message: 'E-Mail non valida' },
                  aNotUsedEmailRule,
                ]}
              >
                <Input placeholder="Email principale" type="email" />
              </Form.Item>

              <Form.List name="otherEmails">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item label={`Email aggiuntiva`} required={true} key={field.key}>
                        <Form.Item
                          {...field}
                          name={[index]}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            { ...mandatoryTextFieldCheckRule, type: 'email', message: 'E-Mail non valida' },
                            aNotUsedEmailRule,
                          ]}
                          noStyle
                        >
                          <Input placeholder="Email" type="email" readOnly={!canEdit()} style={{ width: '95%' }} />
                        </Form.Item>

                        <MinusCircleOutlined
                          className="email-delete-button"
                          onClick={() => remove(field.name)}
                          disabled={!canEdit()}
                        />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                        disabled={!canEdit()}
                      >
                        Aggiungi email
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-signature" name={`signature`} label={`Alias`}>
                <Input placeholder="Alias" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.List name="phone">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item label="Numero di telefono" required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          name={[index]}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: false,
                              pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,6}$/im),
                              message: 'Numero di telefono non valido',
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Numero di telefono"
                            type="tel"
                            readOnly={!canEdit()}
                            style={{ width: '95%' }}
                          />
                        </Form.Item>

                        <MinusCircleOutlined
                          className="phone-delete-button"
                          onClick={() => remove(field.name)}
                          disabled={!canEdit()}
                        />
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                        disabled={!canEdit()}
                      >
                        Aggiungi numero di telefono
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-organization" name={`organization`} label={`Organizzazione`}>
                <Input placeholder="Organizzazione" type="text" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-job" name={`jobPosition`} label={`Posizione Lavorativa`}>
                <Input placeholder="Posizione lavorativa" type="text" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-member-of" name={`memberOf`} label={`Membro di`}>
                <Input type="text" readOnly={!canEdit()} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item
                data-testid="form-item-userType"
                name="userType"
                label="Tipologia di utente"
                rules={[mandatoryTextFieldCheckRule]}
              >
                <Radio.Group
                  disabled={!canEdit()}
                  onChange={(e) => {
                    setUserType(e.target.value);
                  }}
                  value={userType}
                >
                  <Radio data-testid="form-item-userType-company" value="company">
                    Azienda
                  </Radio>
                  <Radio data-testid="form-item-userType-person" value="person">
                    Persona
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              {userType === 'company' && (
                <Form.Item
                  data-testid="form-item-company"
                  name={`vatCode`}
                  label={`Partita IVA`}
                  rules={[
                    {
                      required: hasExpertRole(userRole),
                      message: 'Partita IVA obbligatoria per gli esperti',
                      whitespace: false,
                    },
                  ]}
                >
                  <Input placeholder="Partita IVA" readOnly={!canEdit()} />
                </Form.Item>
              )}
              {userType === 'person' && (
                <Form.Item
                  data-testid="form-item-person"
                  name={`vatCode`}
                  label={`Codice fiscale`}
                  rules={[
                    {
                      required: hasExpertRole(userRole),
                      message: 'Codice fiscale obbligatorio per gli esperti',
                      whitespace: false,
                    },
                  ]}
                >
                  <Input placeholder="Codice fiscale" readOnly={!canEdit()} />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-bio" name={`bio`} label={`Biografia`}>
                <TextArea rows={3} readOnly={!canEdit()} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name={`enableGenAI`}
                    data-testid="form-item-enableGenAI"
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <span style={{ margin: '0 30px' }}>
                      Abilita generazione Risposte dal servizio <strong>GenAI</strong>:
                    </span>
                    <Switch
                      checkedChildren="attivo"
                      unCheckedChildren="non attivo"
                      defaultChecked={false}
                      checked={enableGenAI}
                      disabled={currentUserRole !== 'Super Admin' && currentUserRole !== 'Super Editor'}
                      style={{ marginLeft: 'auto' }}
                      onChange={(checked: boolean, _) => {
                        form.setFieldsValue({ enableGenAI: checked });
                        setEnableGenAI(checked);
                        setFormFieldsChanged(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item name="enabled" data-testid="form-item-enabled" label={'Status'} valuePropName={'checked'}>
                    <Switch
                      checkedChildren="attivo"
                      unCheckedChildren="non attivo"
                      defaultChecked={true}
                      disabled={!userId || !user?.cognitoRegistered || !canEnableUser()}
                      onChange={(checked: boolean, _) => {
                        form.setFieldsValue({ enabled: checked });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    data-testid="form-item-role"
                    name={`role`}
                    label={`Ruolo`}
                    rules={[mandatoryTextFieldCheckRule]}
                  >
                    <Select
                      disabled={!canEdit()}
                      data-testid="form-item-role"
                      onChange={(e: string) => {
                        setUserRole(e);
                      }}
                    >
                      <Select.Option role="form-item-role-options" value="Expert" disabled={!canCreateExpert()}>
                        Expert
                      </Select.Option>

                      <Select.Option role="form-item-role-options" value="Editor" disabled={!canCreateEditor()}>
                        Editor
                      </Select.Option>

                      <Select.Option
                        role="form-item-role-options"
                        value="Administrator"
                        disabled={!canCreateAdministrator()}
                      >
                        Super Editor
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item data-testid="form-item-articles" name={`lastArticles`} label={`Link Articoli`}>
                <TextArea rows={3} readOnly={!canEdit()} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {(hasEditorRole(userRole) || hasSuperEditorRole(userRole)) && (
                <>
                  <p>Canali:</p>
                  <EditorChannelFormList
                    organizationService={props.organizationService}
                    disabled={!canModifyEditorChannels()}
                  />
                </>
              )}
              {hasExpertRole(userRole) && (
                <>
                  <p>Canali:</p>
                  <ExpertChannelFormList
                    form={form}
                    organizationService={props.organizationService}
                    disabled={!canEdit()}
                    currentUser={props.currentUser}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item data-testid="form-item-note" name={`userNote`} label={`Note`}>
                <TextArea rows={3} readOnly={!canEdit()}></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ textAlign: 'right' }}>
            <Popconfirm
              placement="topLeft"
              title={<DEFAULT_EXIT_WITHOUT_SAVE_POP_CONFIRM_MESSAGE />}
              visible={popConfirmExitWithChangesVisible}
              onConfirm={handlePopConfirmExitWithoutChangesOk}
              onCancel={handlePopConfirmExitWithoutChangesCancel}
              okText="Esci senza salvare"
              cancelText="Annulla"
              okButtonProps={{ loading: isWaiting }}
              icon={
                <div style={{ cursor: 'pointer' }} onClick={handlePopConfirmExitWithoutChangesCancel}>
                  <CloseCircleOutlined
                    style={{ cursor: 'pointer' }}
                    data-testid="question-modal-close-without-saving-button"
                  />
                </div>
              }
            >
              <Button disabled={isWaiting} data-testid="back-button" type="ghost" onClick={showPopConfirm}>
                Indietro
              </Button>
            </Popconfirm>
            {userId ? (
              <Popconfirm
                title={savePopConfirmMessage}
                onConfirm={onSave}
                onCancel={() => {}}
                okText="Sì"
                cancelText="No"
                disabled={!formFieldsChanged}
              >
                <Button
                  disabled={!formFieldsChanged}
                  loading={isWaiting}
                  data-testid="create-user-button"
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 12 }}
                >
                  Salva
                </Button>
              </Popconfirm>
            ) : (
              <Button
                loading={isWaiting}
                data-testid="create-user-button"
                type="primary"
                htmlType="submit"
                style={{ marginLeft: 12 }}
              >
                Salva
              </Button>
            )}
          </div>
        </Form>
      </Card>
    </>
  );

  function onSave() {
    form.submit();
    setTimeout(() => setFormFieldsChanged(false), 0);
  }

  function showPopConfirm() {
    if (formFieldsChanged) {
      setPopConfirmExitWithChangesVisible(true);
    } else {
      props.navigationService.goBack();
    }
  }

  function showPopExitIconConfirm() {
    if (formFieldsChanged) {
      setPopConfirmExitIconWithChangesVisible(true);
    } else {
      props.navigationService.goBack();
    }
  }

  function handlePopConfirmExitWithoutChangesOk() {
    setPopConfirmExitWithChangesVisible(false);
    props.navigationService.goBack();
  }

  function handlePopConfirmExitWithoutChangesCancel() {
    setPopConfirmExitWithChangesVisible(false);
  }

  function hasExpertRole(userRole?: String) {
    return userRole !== undefined && userRole === 'Expert';
  }

  function hasEditorRole(userRole?: String) {
    return userRole !== undefined && userRole === 'Editor';
  }

  function hasSuperEditorRole(userRole?: String) {
    return userRole !== undefined && userRole === 'Administrator';
  }

  async function createOrEditUser(request: FullUserData) {
    let s3UploadInfoResponse: S3UploadInfo | undefined;
    if (imageFile) {
      s3UploadInfoResponse = await props.uploadService.getS3ExpertAvatarUploadInfo(imageFile.name);

      uploadImage(imageFile, s3UploadInfoResponse);
    }

    console.log(request);

    setIsWaiting(true);
    props.userService
      .createOrUpdateUser(
        {
          ...user,
          ...request,
          cognitoRegistered: user?.cognitoRegistered,
          ...(s3UploadInfoResponse && { avatarUrl: s3UploadInfoResponse.fields.key }),
        },
        userId ? 'update' : 'create',
      )
      .then(() => {
        setUserCreationSucceed(true);
        setUserCreationMessage('');
      })
      .catch((e) => {
        console.error(e);
        setUserCreationSucceed(false);
        setUserCreationMessage(`${e}`);
      })
      .finally(() => setIsWaiting(false));
  }

  function canEdit() {
    return props.currentUser.isAtLeastEditor();
  }

  function canEnableUser() {
    if (props.currentUser.isAdministrator() && user?.role === 'Administrator') {
      return false;
    }

    return props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator();
  }

  function canModifyEditorChannels() {
    return props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator();
  }

  function canCreateExpert() {
    return (
      props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator() || props.currentUser.isEditor()
    );
  }
  function canCreateEditor() {
    return props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator();
  }
  function canCreateAdministrator() {
    return props.currentUser.isSuperAdministrator();
  }

  async function beforeUpload(file: File) {
    const isJpg = file.type === 'image/jpeg';
    if (!isJpg) {
      message.error("L'unico formato supportato è JPG");
      throw new Error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Le dimensioni dell'immagine non possono superare 2MB");
      throw new Error('Image must smaller than 2MB!');
    }

    setFormFieldsChanged(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = document.createElement('img');
      img.src = reader.result as string;
      img.onload = async () => {
        var canvas = document.createElement('canvas');
        canvas.width = 300;
        canvas.height = 300;
        var ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, 300, 300);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob!], file.name, file);
          setImageFile(resizedFile);

          let URLObj = window.URL || window.webkitURL;

          const previewUrl = URLObj.createObjectURL(blob!);
          setImageUrl(previewUrl);
        });
      };
    };
  }

  function uploadImage(imageFile: File, s3UploadInfo: S3UploadInfo): void {
    props.uploadService.uploadImage(imageFile, s3UploadInfo).then((response) => {
      console.log(response);
    });
  }
};

export default CreateOrEditUser;
