import { Descriptions, Form, Input } from 'antd';
import React from 'react';
import { QuestionDetail } from '../../../models/Question';
import './EditorFieldsFormItem.css';
import AuthorizationHelper from '../../../utils/AuthorizationHelper';
import CurrentUser from '../../../models/CurrentUser';

type Props = {
  question: QuestionDetail;
  currentUser?: CurrentUser;
};

const EndUserFormItem: React.FC<Props> = (props) => {
  return (
    <>
      <Descriptions bordered size="small">
        <Descriptions.Item label="ID">{props.question.endUserId}</Descriptions.Item>
        <Descriptions.Item label="Nome">{props.question.endUserName}</Descriptions.Item>
        <Descriptions.Item label="Cognome">{props.question.endUserSurname}</Descriptions.Item>
        <Descriptions.Item label="Provincia">
          {props.currentUser && AuthorizationHelper.isAdministratorOrHigher(props.currentUser) ? (
            <Form.Item name="endUserCity" data-testid="question-modal-form-user-city" style={{ marginBottom: '0' }}>
              <Input placeholder={"Provincia"}/>
            </Form.Item>
          ) : (
            props.question.endUserCity
          )}
        </Descriptions.Item>
        {props.question.endUserPhone && (
          <Descriptions.Item label="Telefono">{props.question.endUserPhone}</Descriptions.Item>
        )}
        {props.question.endUserEmail && (
          <Descriptions.Item label="Email">{props.question.endUserEmail}</Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default EndUserFormItem;
