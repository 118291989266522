import { Collapse, Descriptions } from 'antd';
import ChatsFormItem from '../../../components/QuestionModal/components/ChatsFormItem';
import { CommentsFormItemList } from '../../../components/QuestionModal/components/CommentsFormItem/CommentsFormItemList';
import EndUserFormItem from '../../../components/QuestionModal/components/EndUserFormItem';
import PublishingFormItem from '../../../components/QuestionModal/components/PublishingFormItem';
import { ArchivedQuestion } from '../../../models/ArchivedQuestionContainerDetails';
import ArchivedAnswersFormItem from './components/ArchivedAnswersFormItem';
import { ArchivedQuestionItem } from './components/ArchivedQuestionItem';

const { Panel } = Collapse;

type Props = {
  question: ArchivedQuestion;
};

export const ArchivedQuestionDetails = (props: Props) => {
  return (
    <>
      <Collapse
        defaultActiveKey={[
          'question-panel',
          'question-details',
          'question-answer',
          'question-chat-data',
          'publishing-output-channel-data',
          'question-comments',
        ]}
      >
        <Panel header="Domanda" key="question-panel">
          <ArchivedQuestionItem question={props.question}></ArchivedQuestionItem>
        </Panel>

        <Panel header="Dettagli dell'utente" key="question-user-data">
          <EndUserFormItem question={props.question} />
        </Panel>

        <Panel header="Risposta" key="question-answer" data-testid="question-modal-form-answer-panel">
          <ArchivedAnswersFormItem lastValidatedAnswer={props.question.answers![props.question.answers!.length - 1]} />
        </Panel>

        <Panel header="Campi editoriali" key="editor-fields" data-testid="question-modal-form-editor-fields">
          <Descriptions bordered size="small" layout="vertical" data-testid="editor-subject">
            <Descriptions.Item label="Soggetto editoriale" span={3}>
              {props.question.editorSubject}
            </Descriptions.Item>
            <Descriptions.Item label="Quesito editoriale" span={3}>
              {props.question.editorQuestion}
            </Descriptions.Item>
            <Descriptions.Item label="Risposta editoriale" span={3}>
              {props.question.answers?.[0]?.editorAnswer}
            </Descriptions.Item>
          </Descriptions>
        </Panel>

        <Panel key="publishing-output-channel-data" header="Pubblicazione">
          <PublishingFormItem question={props.question} />
        </Panel>

        <Panel header="Richiesta chiarimenti" key="question-chat-data">
          <ChatsFormItem question={props.question}></ChatsFormItem>
        </Panel>

        <Panel header="Commenti" key="question-comments" data-testid="question-modal-form-comments-panel">
          {props.question.comments && <CommentsFormItemList comments={props.question.comments}></CommentsFormItemList>}
        </Panel>
      </Collapse>
    </>
  );
};
