import { Alert, Button, Form } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Constants from '../../../Constants';
import { QuestionsContainer } from '../../../models/QuestionsContainer';
import NavigationService from '../../../services/NavigationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import QuestionsContainerForm from '../QuestionsContainerForm';

interface Props {
  navigationService: NavigationService;
  questionsContainerService: QuestionsContainerService;
}

const CreateQuestionsContainer: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const [isWaiting, setIsWaiting] = useState(false);
  const [containerCreationSucceed, setContainerCreationSucceed] = useState<boolean | undefined>(undefined);
  const [containerCreationMessage, setContainerCreationMessage] = useState<string | undefined>(undefined);
  const [pubDate, setPubDate] = useState<moment.Moment | undefined>(undefined);

  const createContainer = async () => {
    await form
      .validateFields()
      .then((values) => {
        setIsWaiting(true);

        // TODO: Implementare outputChannel
        const updatedContainer: QuestionsContainer = {
          ...values,
          outputChannel: 'fascicolo-cartaceo',
          publicationDate: values.publicationDate.format(Constants.BACKEND_DATETIME_FORMAT),
        };

        props.questionsContainerService
          .createOrUpdateContainer(updatedContainer, 'create')
          .then(() => {
            setContainerCreationSucceed(true);
            setContainerCreationMessage('');
          })
          .catch((e) => {
            console.error(e);
            setContainerCreationSucceed(false);
            setContainerCreationMessage(`${JSON.stringify(e)}`);
          })
          .finally(() => setIsWaiting(false));
      })
      .catch(() => {});
  };

  const buttons = (
    <>
      {' '}
      <Button
        data-testid="questions-container-back-button"
        type="ghost"
        onClick={() => props.navigationService.goBack()}
      >
        Annulla
      </Button>
      <Button
        data-testid="create-container-button"
        type="primary"
        style={{ marginLeft: 12 }}
        onClick={() => createContainer()}
      >
        Crea fascicolo
      </Button>
    </>
  );

  return (
    <LoadingSpinner data-testid="create-questions-container-spinner" isSpinning={isWaiting}>
      {containerCreationSucceed && (
        <Alert
          message="Fascicolo creato con successo!"
          type="success"
          showIcon
          action={
            <Button size="small" type="text" onClick={() => props.navigationService.goToContainers()}>
              Vai alla lista fascicoli
            </Button>
          }
          data-testid="create-container-success-alert"
        />
      )}
      {containerCreationSucceed === false && (
        <Alert message="Creazione fascicolo fallita!" description={containerCreationMessage} type="error" showIcon />
      )}
      {(containerCreationSucceed === undefined || containerCreationSucceed === false) && (
        <QuestionsContainerForm
          additionalButtons={buttons}
          title={''}
          formInstance={form}
          formName={'create-questions-container'}
          editMode={true}
          pubDate={pubDate}
          setPubDate={setPubDate}
          setFormFieldsChanged={() => {}}
        />
      )}
    </LoadingSpinner>
  );
};

export default CreateQuestionsContainer;
