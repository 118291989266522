import { FC, useEffect, useState } from 'react';
import ReportsTable from '../components/ReportsTable/ReportsTable';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import ReportsControlBar, { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import ReportService, { ChannelInfo } from '../../../services/ReportService';
import { ReportProps, downloadCSVFromText, DEFAULT_FILTERS, DEFAULT_PAGINATION } from '../utils';
import LoadingSpinner from '../../../components/LoadingSpinner';

const DEFAULT_SORTER: SorterResult<ChannelInfo> = {};

const numberFormatter = new Intl.NumberFormat('it-IT', { maximumFractionDigits: 1 });
const numberRenderer = (value: number | string) => numberFormatter.format(Number(value));

const columns = [
  {
    title: 'Channel',
    dataIndex: 'channel',
    key: 'channel',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Media (in ore)',
    dataIndex: 'average',
    key: 'average',
    render: numberRenderer,
  },
  {
    title: 'Max (in ore)',
    dataIndex: 'max',
    key: 'max',
    render: numberRenderer,
  },
  {
    title: 'Min (in ore)',
    dataIndex: 'min',
    key: 'min',
    render: numberRenderer,
  },
];

type getReportDataFunction = (theFilters?: FiltersCallbackResult, pagination?: TablePaginationConfig) => Promise<void>;

const ReportRedazione: FC<ReportProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [reportData, setReportData] = useState<ChannelInfo[] | null>(null);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<FiltersCallbackResult>(DEFAULT_FILTERS);

  const getReportData: getReportDataFunction = async (
    newFilters = DEFAULT_FILTERS,
    newPagination = DEFAULT_PAGINATION,
  ) => {
    setIsRefreshingData(true);
    try {
      const res = await ReportService.fetchReportRedazioneMediaTempiRisposte(newFilters, {}, newPagination);
      setReportData(res.channels);
      setTotal(res.fullCount);
      setCurrentFilters(newFilters);
    } catch {
      setReportData(null);
      console.error('Impossibile recuperare il personale esperto');
    } finally {
      setIsRefreshingData(false);
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  const onDownloadCSV = async () => {
    try {
      const data = await ReportService.downloadReportRedazioneMediaTempiRisposte(currentFilters, DEFAULT_SORTER);
      downloadCSVFromText(data, 'Report Redazione');
    } catch {
      console.error('Impossibile scaricare il report');
    }
  };

  const onPageSelectedChange = (pagination: TablePaginationConfig) => {
    getReportData(currentFilters, pagination);
  };

  return (
    <div>
      <h2>Report Redazione</h2>
      <ReportsControlBar
        filtersAvailable={['typology', 'statusSubSet', 'creationDate']}
        channelData={props.channelData}
        rubricTreeData={props.rubricTreeData}
        questionStatusData={props.questionStatusData}
        onSubmit={(values) => getReportData(values)}
        onDownloadCSV={onDownloadCSV}
      />
      <LoadingSpinner isSpinning={isRefreshingData && !reportData}>
        {reportData && (
          <ReportsTable
            columns={columns}
            dataSource={reportData}
            loading={isRefreshingData}
            total={total}
            pagination={DEFAULT_PAGINATION}
            onPageSelectedChange={onPageSelectedChange}
          />
        )}
      </LoadingSpinner>
    </div>
  );
};

export default ReportRedazione;
