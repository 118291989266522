import { DeleteOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, {  useState } from 'react';
import {  SubRubric } from '../../../models/Channel';
import OrganizationService from '../../../services/OrganizationService';

interface props {
  organizationService: OrganizationService;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: boolean;
  subRubrics: SubRubric[];
  isSuperAdministrator: boolean
}

const SubRubricsTable: React.FC<props> = (props) => {
  const [isDisabled, setIsDisabled] = useState(!props.isSuperAdministrator);

  const deleteSubRubric = async (subRubricId: string) => {
    setIsDisabled(true);
    await props.organizationService
      .deleteSubRubric(subRubricId)
      .then(() => {
        message.success('SottoRubrica eliminata con successo!');
        props.setRefreshData(true);
      })
      .catch((e) => {
        message.error(`Errore nell'eliminare SottoRubrica: ${e}`);
      });

    setIsDisabled(!props.isSuperAdministrator);
  };

  return (
    <React.Fragment>
      <div className="SubRubricsTable">
        {props.subRubrics.map((subRubric) => (
          <div className="SubRubricsRow">
            {subRubric.subRubricName}{' '}
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              disabled={isDisabled || props.subRubrics.length === 1}
              onClick={() => deleteSubRubric(props.subRubrics[0].subRubricId)}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default SubRubricsTable;
