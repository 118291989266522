import {
  BankOutlined,
  BarChartOutlined,
  CalendarOutlined,
  DatabaseOutlined,
  EuroCircleOutlined,
  HomeOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Channel } from '../../../../models/Channel';
import CurrentUser from '../../../../models/CurrentUser';
import NavigationService from '../../../../services/NavigationService';
import OrganizationService from '../../../../services/OrganizationService';

interface SideBarProps {
  navigationService: NavigationService;
  organizationService: OrganizationService;
  currentUser: CurrentUser | undefined;
  ERChannel: Channel | undefined;
}

const SideBar: React.FC<SideBarProps> = (props) => {
  const location = useLocation();
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

  const getMenuActiveKey = (): string => {
    const pathParts = location.pathname.split('/');
    const activeKey = `/${pathParts[1]}/${pathParts[2]}`;
    // console.log('LOCATION', location.pathname);
    // console.log('RESULT', activeKey);
    return activeKey;
  };

  const canSeeContainers = () => {
    if (props.currentUser?.isSuperAdministrator() || props.currentUser?.isAdministrator()) return true;
    if (props.currentUser?.isEditorOrSuperEditorOnChannel(props.ERChannel?.channelId ?? '')) return true;

    return false;
  };

  const canSeeReports = (myUser: CurrentUser | undefined) => {
    if (!myUser) return false;
    if (myUser.isExpert() || myUser.isSuperAdministrator() || myUser.isEditor() || myUser.isAdministrator())
      return true;
    return false;
  };

  const canSeeGestionePaghe = (myUser: CurrentUser | undefined) => {
    if (!myUser) return false;
    if (myUser.isSuperAdministrator() || myUser.isAdministrator()) return true;
    return false;
  };

  return (
    <Layout.Sider collapsible collapsed={sideBarCollapsed} onCollapse={setSideBarCollapsed}>
      <Menu
        theme="dark"
        mode="inline"
        activeKey={getMenuActiveKey()}
        selectedKeys={[getMenuActiveKey()]}
        defaultOpenKeys={['quesiti']}
      >
        <Menu.Item
          key={props.navigationService.getHomePath()}
          icon={<HomeOutlined />}
          onClick={() => {
            props.navigationService.goToHome();
            setSideBarCollapsed(true);
          }}
        >
          Home
        </Menu.Item>

        {canSeeReports(props.currentUser) && (
          <SubMenu key="report" title="Report" icon={<BarChartOutlined />}>
            {props.currentUser?.isExpert() && (
              <Menu.Item
                key="report-personale-experto"
                icon={<PieChartOutlined />}
                onClick={() => {
                  props.navigationService.goToPersonaleEspertoReport();
                  setSideBarCollapsed(true);
                }}
              >
                Mie Lavorazioni
              </Menu.Item>
            )}
            {(props.currentUser?.isSuperAdministrator() ||
              props.currentUser?.isAdministrator() ||
              props.currentUser?.isEditor()) && (
              <>
                <Menu.Item
                  key="report-quesiti"
                  icon={<PieChartOutlined />}
                  onClick={() => {
                    props.navigationService.goToQuesitiReport();
                    setSideBarCollapsed(true);
                  }}
                >
                  Quesiti
                </Menu.Item>
                <Menu.Item
                  key="report-experti"
                  icon={<PieChartOutlined />}
                  onClick={() => {
                    props.navigationService.goToEspertiReport();
                    setSideBarCollapsed(true);
                  }}
                >
                  Esperti
                </Menu.Item>
                <Menu.Item
                  key="report-clienti"
                  icon={<PieChartOutlined />}
                  onClick={() => {
                    props.navigationService.goToClientiReport();
                    setSideBarCollapsed(true);
                  }}
                >
                  Clienti
                </Menu.Item>
              </>
            )}
            {props.currentUser?.isSuperAdministrator() && (
              <Menu.Item
                key="report-redazione"
                icon={<PieChartOutlined />}
                onClick={() => {
                  props.navigationService.goToRedazioneReport();
                  setSideBarCollapsed(true);
                }}
              >
                Redazione
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {props.currentUser?.isAtLeastEditor() && (
          <Menu.Item
            key={props.navigationService.getUsersPath()}
            icon={<TeamOutlined />}
            onClick={() => {
              props.navigationService.goToUsers();
              setSideBarCollapsed(true);
            }}
          >
            Utenti
          </Menu.Item>
        )}

        <SubMenu key="quesiti" icon={<QuestionCircleOutlined />} title="Quesiti">
          <Menu.Item
            key={props.navigationService.getAllQuestionsPath()}
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              props.navigationService.goToAllQuestions();
              setSideBarCollapsed(true);
            }}
          >
            Tutti i quesiti
          </Menu.Item>
          <Menu.Item
            key={props.navigationService.getWorkableQuestionsPath()}
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              props.navigationService.goToWorkableQuestions();
              setSideBarCollapsed(true);
            }}
          >
            Quesiti lavorabili
          </Menu.Item>
          <Menu.Item
            key={props.navigationService.getMyQuestionsPath()}
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              props.navigationService.goToMyQuestions();
              setSideBarCollapsed(true);
            }}
          >
            I miei quesiti
          </Menu.Item>

          {!props.currentUser?.isExpert() && (
            <Menu.Item
              key={props.navigationService.getPublishableQuestionsPath()}
              icon={<QuestionCircleOutlined />}
              onClick={() => {
                props.navigationService.goToPublishableQuestions();
                setSideBarCollapsed(true);
              }}
            >
              Quesiti Pubblicabili
            </Menu.Item>
          )}
        </SubMenu>

        {canSeeContainers() && (
          <SubMenu key="fascicoli" icon={<DatabaseOutlined />} title="Fascicoli">
            <Menu.Item
              key={props.navigationService.getContainersPath()}
              icon={<DatabaseOutlined />}
              onClick={() => {
                props.navigationService.goToContainers();
                setSideBarCollapsed(true);
              }}
            >
              Fascicoli
            </Menu.Item>
            <Menu.Item
              key={props.navigationService.getArchivedContainersPath()}
              icon={<CalendarOutlined />}
              onClick={() => {
                props.navigationService.goToArchivedContainers();
                setSideBarCollapsed(true);
              }}
            >
              Archivio Fascicoli
            </Menu.Item>
          </SubMenu>
        )}

        {(props.currentUser?.isAdministrator() || props.currentUser?.isSuperAdministrator()) && (
          <Menu.Item
            key={props.navigationService.getOrganizationPath()}
            icon={<BankOutlined />}
            onClick={() => {
              props.navigationService.goToOrganization();
              setSideBarCollapsed(true);
            }}
          >
            Organizzazione
          </Menu.Item>
        )}

        {canSeeGestionePaghe(props.currentUser) && (
          <Menu.Item
            key={props.navigationService.getGestionePaghePath()}
            icon={<EuroCircleOutlined />}
            onClick={() => {
              props.navigationService.goToGestionePaghe();
              setSideBarCollapsed(true);
            }}
          >
            Gestione Paghe
          </Menu.Item>
        )}
      </Menu>
    </Layout.Sider>
  );
};

export default SideBar;
