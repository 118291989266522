export const DEFAULT_SAVE_POP_CONFIRM_MESSAGE: React.FC = () => (
  <span>Sei sicuro di voler applicare le modifiche?</span>
);

export const DEFAULT_EXIT_WITHOUT_SAVE_POP_CONFIRM_MESSAGE: React.FC = () => (
  <span>Ci sono delle modifiche non salvate. Sei sicuro di voler uscire senza salvare?</span>
);

export const SAVE_WITH_MAIL_CHANGED_POP_CONFIRM_MESSAGE: React.FC<{
  newMail: string;
  oldMail: string;
}> = ({ newMail, oldMail }) => (
  <div
    style={{
      maxWidth: '40vw',
    }}
  >
    <h3>Attenzione!</h3>
    <p>
      La mail è stata cambiata da <strong>{oldMail}</strong> a <strong>{newMail}</strong>
    </p>
    <p>
      L'account associato alla mail <strong>{oldMail}</strong> verrà distrutto e sostituito con un nuovo account legato
      a <strong>{newMail}</strong>. Tutti i contenuti creati con l'account <strong>{oldMail}</strong> verranno associati
      a <strong>{newMail}</strong>.
    </p>
    <p>
      L'utente riceverà una mail su <strong>{newMail}</strong> con una password provvisoria che dovrà cambiare al primo
      accesso.
    </p>
    <p>L'operazione potrà durare diversi secondi e non è reversibile, sei sicuro di voler procedere?</p>
  </div>
);

export const SAVE_AND_EXIT_OR_EXIT_WITHOUSAVE_WITH_MAIL_CHANGED_POP_CONFIRM_MESSAGE: React.FC<{
  newMail: string;
  oldMail: string;
}> = ({ newMail, oldMail }) => (
  <div
    style={{
      maxWidth: '40vw',
    }}
  >
    <h3>Attenzione!</h3>
    <p>Ci sono delle modifiche non salvate.</p>
    <p>
      In particolare la mail è stata cambiata da <strong>{oldMail}</strong> a <strong>{newMail}</strong>
    </p>
    <p>Sei sicuro di voler uscire senza salvare?</p>
  </div>
);
