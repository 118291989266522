import NavigationService from '../../../services/NavigationService';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Divider, Form, Input, Select, Switch } from 'antd';
import QuestionService from '../../../services/QuestionService';
import OrganizationService from '../../../services/OrganizationService';
import CreateQuestionRequest from '../../../models/requests/CreateQuestionRequest';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SelectUtils from '../../../utils/SelectUtils';
import { Channel, Rubric, SubRubric } from '../../../models/Channel';
import CurrentUser from '../../../models/CurrentUser';
import AuthorizationHelper from '../../../utils/AuthorizationHelper';

const { TextArea } = Input;

interface CreateQuestionProps {
  navigationService: NavigationService;
  organizationService: OrganizationService;
  questionService: QuestionService;
  currentUser: CurrentUser | undefined;
}

const CreateQuestion: React.FC<CreateQuestionProps> = (props) => {
  let useEffectSubscribed = true;

  const [questionForm] = Form.useForm();
  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [subRubrics, setSubRubrics] = useState<SubRubric[] | undefined>(undefined);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
  const [selectedRubric, setSelectedRubric] = useState<Rubric | undefined>(undefined);
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  const [questionCreationSucceed, setQuestionCreationSucceed] = useState<boolean | undefined>(undefined);
  const [questionCreationMessage, setQuestionCreationMessage] = useState<string | undefined>(undefined);

  const loadingSelectOption = (
    <Select.Option disabled={true} value="">
      <LoadingSpinner fontSize={20} />
      <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
    </Select.Option>
  );

  const loadChannels = async (): Promise<void> => {
    const channels = await props.organizationService.getInputChannels();
    if (props.currentUser && props.currentUser?.channels.length > 0) {
      setChannels(AuthorizationHelper.filterChannelsForUser(props.currentUser, channels));
    } else {
      if (useEffectSubscribed) setChannels(channels);
    }
  };

  const loadRubricsByChannel = async (channelId: string): Promise<void> => {
    const rubrics = await props.organizationService.getRubricsByChannelId(channelId);
    setRubrics(rubrics);
  };

  const onChannelSelected = async (channelId: string) => {
    let channel = channels?.find((channel) => channel.channelId === channelId);
    setSelectedChannel(channel);
    clearSelectedRubric();
    await loadRubricsByChannel(channelId);
  };

  const onRubricSelected = async (rubricId: string) => {
    let rubric = rubrics?.find((rubric) => rubric.rubricId === rubricId);
    setSelectedRubric(rubric);
    clearSelectedSubRubric();
    setSubRubrics(rubric?.subRubrics);
  };

  const clearSelectedRubric = (): void => {
    setSelectedRubric(undefined);
    setRubrics(undefined);
    setSubRubrics(undefined);
    questionForm.setFieldsValue({
      rubricId: undefined,
      subRubricId: undefined,
    });
  };

  const clearSelectedSubRubric = (): void => {
    setSubRubrics(undefined);
    questionForm.setFieldsValue({
      subRubricId: undefined,
    });
  };

  const createQuestion = async (request: CreateQuestionRequest) => {
    setIsCreatingQuestion(true);
    props.questionService
      .createQuestion(request)
      .then(() => {
        setQuestionCreationSucceed(true);
        setQuestionCreationMessage('');
      })
      .catch((e) => {
        console.error(e);
        setQuestionCreationSucceed(false);
        setQuestionCreationMessage(`${e}`);
      })
      .finally(() => setIsCreatingQuestion(false));
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffectSubscribed = true;
    loadChannels();
    questionForm.setFieldsValue({ typology: 'FREE' });
    return () => {
      useEffectSubscribed = false;
    };
  }, []);

  return (
    <>
      <LoadingSpinner isSpinning={isCreatingQuestion}>
        {questionCreationSucceed === true && (
          <Alert
            message="Quesito creato con successo!"
            type="success"
            showIcon
            action={
              <Button size="small" type="text" onClick={() => props.navigationService.goToWorkableQuestions()}>
                Vai alla lista dei quesiti
              </Button>
            }
            data-testid="create-question-success-alert"
          />
        )}
        {questionCreationSucceed === false && (
          <Alert
            message="Creazione quesito fallita!"
            description={questionCreationMessage}
            type="error"
            showIcon
          ></Alert>
        )}

        {(questionCreationSucceed === undefined || questionCreationSucceed === false) && (
          <Card title="Crea quesito" type="inner">
            <Form form={questionForm} name="create-question-form" onFinish={createQuestion} layout="vertical">
              <Form.Item
                name="channelId"
                label="Canale"
                data-testid="create-question-field-channel"
                rules={[{ required: true, message: 'Selezionare un canale' }]}
              >
                <Select
                  showSearch
                  placeholder="Seleziona un canale"
                  optionFilterProp="children"
                  filterOption={SelectUtils.filterOption}
                  onSelect={onChannelSelected}
                >
                  {channels === undefined && loadingSelectOption}
                  {channels !== undefined &&
                    channels.map((channel, index) => (
                      <Select.Option key={index} value={channel.channelId} role="create-question-field-channel-options">
                        {channel.channelName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="rubricId"
                label="Rubrica"
                data-testid="create-question-field-rubric"
                rules={[{ required: true, message: 'Selezionare una rubrica' }]}
              >
                <Select
                  showSearch
                  placeholder="Seleziona una rubrica"
                  onSelect={onRubricSelected}
                  filterOption={SelectUtils.filterOption}
                  optionFilterProp="children"
                  disabled={selectedChannel === undefined}
                >
                  {rubrics === undefined && loadingSelectOption}
                  {rubrics !== undefined &&
                    rubrics.map((rubric, index) => (
                      <Select.Option key={index} value={rubric.rubricId} role="create-question-field-rubric-options">
                        {rubric.rubricName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="subRubricId"
                label="SottoRubrica"
                data-testid="create-question-field-subrubric"
                rules={[{ required: true, message: 'Selezionare una sotto rubrica' }]}
              >
                <Select
                  showSearch
                  placeholder="Seleziona una sotto rubrica"
                  optionFilterProp="children"
                  filterOption={SelectUtils.filterOption}
                  disabled={selectedRubric === undefined}
                >
                  {subRubrics === undefined && loadingSelectOption}
                  {subRubrics !== undefined &&
                    subRubrics.map((subRubric, index) => (
                      <Select.Option
                        key={index}
                        value={subRubric.subRubricId}
                        role="create-question-field-subrubric-options"
                      >
                        {subRubric.subRubricName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="subject"
                label="Oggetto"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    whitespace: true,
                    message: "L'oggetto del quesito è obbligatorio.",
                  },
                ]}
                data-testid="create-question-field-subject"
              >
                <Input placeholder={'Oggetto'} />
              </Form.Item>
              <Form.Item
                name="question"
                label="Domanda"
                rules={[
                  { type: 'string', required: true, whitespace: true, message: 'Il testo del quesito è obbligatorio.' },
                ]}
                data-testid="create-question-field-question"
              >
                <TextArea rows={10} placeholder={'Domanda'} />
              </Form.Item>
              <Form.Item name="typology" data-testid="create-question-field-typology">
                <Switch
                  checkedChildren="PAY"
                  unCheckedChildren="FREE"
                  onChange={(checked: boolean, _) => {
                    questionForm.setFieldsValue({ typology: checked ? 'PAY' : 'FREE' });
                  }}
                />
              </Form.Item>
              <Divider />
              <Form.Item
                name="endUserId"
                initialValue={'EditorBackoffice'}
                label="User ID"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'inserire id cliente',
                  },
                ]}
                data-testid="create-question-field-user-id"
              >
                <Input placeholder={'Identificativo Utente'} />
              </Form.Item>
              <Form.Item
                name="endUserName"
                initialValue={'Editor'}
                label="Nome"
                rules={[{ type: 'string', required: true, message: 'inserire Nome cliente' }]}
                data-testid="create-question-field-name"
              >
                <Input placeholder={'Nome'} />
              </Form.Item>
              <Form.Item
                name="endUserSurname"
                initialValue={'Backoffice'}
                label="Cognome"
                rules={[{ type: 'string', required: true, message: 'inserire Cognome cliente' }]}
                data-testid="create-question-field-surname"
              >
                <Input placeholder={'Cognome'} />
              </Form.Item>
              <Form.Item
                name="endUserCity"
                label="Provincia"
                rules={[{ type: 'string', required: false }]}
                data-testid="create-question-field-city"
              >
                <Input placeholder={'Provincia'} />
              </Form.Item>
              <Form.Item
                name="endUserPhone"
                label="Telefono"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im),
                    message: 'Numero di telefono non valido',
                  },
                ]}
                data-testid="create-question-field-phone"
              >
                <Input placeholder={'Numero di Telefono'} />
              </Form.Item>
              <Form.Item
                name="endUserEmail"
                label="Email"
                rules={[
                  {
                    required: false,
                    type: 'email',
                    message: 'Email non valido',
                  },
                ]}
                data-testid="create-question-field-email"
              >
                <Input placeholder={'Indirizzo email'} />
              </Form.Item>

              <div style={{ textAlign: 'right' }}>
                <Button data-testid="back-button" type="ghost" onClick={() => props.navigationService.goBack()}>
                  Indietro
                </Button>
                <Button
                  data-testid="create-question-button-submit"
                  htmlType="submit"
                  type="primary"
                  style={{ marginLeft: 12 }}
                >
                  Crea Quesito
                </Button>
              </div>
            </Form>
          </Card>
        )}
      </LoadingSpinner>
    </>
  );
};

export default CreateQuestion;
