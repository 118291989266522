import React from 'react';
import { Layout } from 'antd';
import UserService from '../../../../services/UserService';
import UserMenu from '../UserMenu/UserMenu';
import './Header.css';
import NavigationService from '../../../../services/NavigationService';
import OrganizationService from '../../../../services/OrganizationService';
import CurrentUser from '../../../../models/CurrentUser';

interface PageHeaderProps {
  currentUser: CurrentUser | undefined;
  navigationService: NavigationService;
  userService: UserService;
  organizationService: OrganizationService;
}

const Header: React.FC<PageHeaderProps> = (props) => {
  return (
    <Layout.Header className="header">
      <div className="logo" onClick={() => props.navigationService.goToHome()}>
        L'Esperto Risponde
      </div>
      <div className="organization">Organizzazione: {props.organizationService.getOrganizationAndEnv()}</div>
      <div className="actions" style={{ flex: 1 }}>
        {props.currentUser !== undefined && (
          <div className="who">
            {props.currentUser.name + ' ' + props.currentUser.surname + ' '}
            <UserMenu
              currentUser={props.currentUser}
              navigationService={props.navigationService}
              userService={props.userService}
            />
          </div>
        )}
      </div>
    </Layout.Header>
  );
};

export default Header;
