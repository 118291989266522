import { Table, Button } from 'antd';
import { useMemo } from 'react';
import { PaymentExpertRow } from '../../../../services/GestionePagheService';
import generateUUID from '../../../../utils/generateUUID';

type GestionePagheTableProps = {
  data: PaymentExpertRow[];
  onTableRowClick: (row: PaymentExpertRow) => void;
  isLoading: boolean;
};

const GestionePagheTable = (props: GestionePagheTableProps) => {
  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_: string, record: PaymentExpertRow) => (
          <Button type="link" onClick={() => props.onTableRowClick(record)}>
            Modifica
          </Button>
        ),
        width: 140,
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Cognome',
        dataIndex: 'surname',
        key: 'surname',
      },
      {
        title: 'Codice fiscale',
        dataIndex: 'fiscalCode',
        key: 'fiscalCode',
        width: 200,
      },
      {
        title: 'Canale',
        dataIndex: 'channel',
        key: 'channel',
      },
      { title: 'Quesiti pay calcolati', dataIndex: 'payQuestionsAnswersComputed', key: 'payQuestionsAnswersComputed' },
      { title: 'Quesiti pay da pagare', dataIndex: 'payQuestionsAnswersToBePaid', key: 'payQuestionsAnswersToBePaid' },
      {
        title: 'Quesiti pay risp. in ritardo',
        dataIndex: 'payQuestionsAnswersGivenLate',
        key: 'payQuestionsAnswersGivenLate',
      },

      { title: 'Quesiti free calcolati', dataIndex: 'freeQuestionsAnswersCounted', key: 'freeQuestionsAnswersCounted' },
      {
        title: 'Quesiti free da pagare',
        dataIndex: 'freeQuestionsAnswersToBePaid',
        key: 'freeQuestionsAnswersToBePaid',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Table
      rowKey={(record) =>
        `${record.fiscalCode}-${record.channel}-${record.details.map((d) => d.answerId).join('-')}-${generateUUID()}`
      }
      dataSource={props.data}
      loading={props.isLoading}
      columns={columns}
      scroll={{ y: '60vh' }}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

export default GestionePagheTable;
