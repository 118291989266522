import { Button } from 'antd';
import { FC, useEffect, useState } from 'react';
import NavigationService from '../../services/NavigationService';
import UserService from '../../services/UserService';
import { QuestionsContainer } from '../../models/QuestionsContainer';
import LoadingSpinner from '../../components/LoadingSpinner';
import CurrentUser from '../../models/CurrentUser';
import QuestionsContainerService from '../../services/QuestionsContainerService';
import QuestionsContainersTable from './QuestionsContainersTable';

type Props = {
  navigationService: NavigationService;
  userService: UserService;
  questionsContainerService: QuestionsContainerService;
  currentUser: CurrentUser;
};

const QuestionsContainersPage: FC<Props> = (props) => {
  let useEffectSubscribed = false;

  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [canCreateQuestionContainer, setCanCreateQuestionContainer] = useState(false);
  const [containers, setContainers] = useState<QuestionsContainer[]>([]);

  const onComponentLoaded = async () => {
    if (useEffectSubscribed) {
      setCanCreateQuestionContainer(
        props.currentUser.isSuperAdministrator() || props.currentUser.isAdministrator() || props.currentUser.isEditor(),
      );
      refreshData();
    }
  };

  const refreshData = async (): Promise<void> => {
    setIsRefreshingData(true);
    const retrievedContainers: QuestionsContainer[] = await props.questionsContainerService.getQuestionsContainers();

    retrievedContainers.forEach((questionsContainer) => (questionsContainer.key = questionsContainer.containerId));

    setContainers(retrievedContainers);

    setIsRefreshingData(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffectSubscribed = true;
    onComponentLoaded();
    return () => {
      useEffectSubscribed = false;
    };
  }, []);

  return (
    <>
      <h2>Tutti i Fascicoli</h2>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        {canCreateQuestionContainer === true && (
          <Button type="primary" onClick={() => props.navigationService.goToCreateContainers()}>
            Crea fascicolo
          </Button>
        )}
      </div>
      <div>
        <LoadingSpinner isSpinning={isRefreshingData} fontSize={200} text="Caricamento fascicoli in corso...">
          <QuestionsContainersTable
            data={containers}
            refreshData={refreshData}
            userService={props.userService}
            navigationService={props.navigationService}
            questionsContainerService={props.questionsContainerService}
            currentUser={props.currentUser}
          />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default QuestionsContainersPage;
