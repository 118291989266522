import { Alert, Button, message, Modal, Space } from 'antd';
import { useState } from 'react';
import { Question } from '../models/Question';
import UserAssignableToQuestion from '../models/UserAssignableToQuestion';
import QuestionService from '../services/QuestionService';
import UserService from '../services/UserService';
import AssignQuestionToExpertSelect from './AssignQuestionToExpertSelect';
import UserAssignableToQuestionNotMatchingTopicAlert from './UserAssignableToQuestionNotMatchingTopicAlert/UserAssignableToQuestionNotMatchingTopicAlert';

interface IntegrateAnswerModalProps {
  isModalVisible: boolean;
  question: Question;
  onOk: () => void;
  onCancel: () => void;
  userService: UserService;
  questionService: QuestionService;
}

const IntegrateAnswerModal: React.FC<IntegrateAnswerModalProps> = (props) => {
  const [selectedExpert, setSelectedExpert] = useState<UserAssignableToQuestion | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  async function onOk() {
    setIsLoading(true);

    if (!selectedExpert || !props.question) {
      props.onCancel();
      setIsLoading(false);
      return;
    }

    try {
      await props.questionService.integrateAnswer(props.question.id, selectedExpert.id);
      props.onOk();
      message.success(`Quesito assegnato all'esperto ${selectedExpert.displayName} per integrare la risposta`);
    } catch (e: any) {
      setErrorMessage(e.toString());
      message.error(
        `Si è verificato un errore durante l'assegnazione del quesito all'esperto ${selectedExpert.displayName} per integrare la risposta`,
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function onCancel() {
    props.onCancel();
  }

  return (
    <Modal
      title="Assegna a un esperto per integrare la risposta"
      visible={props.isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      data-testid="assign-to-expert-modal"
      footer={
        <>
          <Button key="cancel" data-testid="assign-to-expert-modal-cancel" onClick={onCancel}>
            Annulla
          </Button>
          <Button key="ok" data-testid="assign-to-expert-modal-ok" type="primary" loading={isLoading} onClick={onOk}>
            OK
          </Button>
        </>
      }
    >
      <div>
        <Space size="middle">
          <AssignQuestionToExpertSelect
            question={props.question}
            userService={props.userService}
            selectedExpert={selectedExpert}
            onExpertSelected={(expert) => setSelectedExpert(expert)}
            width={'470px'}
          />
        </Space>
      </div>
      {errorMessage !== undefined && (
        <div style={{ marginTop: 16 }}>
          <Alert message={errorMessage} type="error" showIcon></Alert>
        </div>
      )}
      <UserAssignableToQuestionNotMatchingTopicAlert question={props.question} user={selectedExpert} />
    </Modal>
  );
};

export default IntegrateAnswerModal;
