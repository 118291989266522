import './GestionPagheControlBar.css';
import { Select, Form, Button, Popconfirm } from 'antd';
import { MonthlyPaymentsFilters } from '../../../../services/GestionePagheService';
import { useState } from 'react';

type GestionPagheControlBarProps = {
  isLoading: boolean;
  onSubmit: (filters: MonthlyPaymentsFilters) => void;
  onGenerateFileClick: () => void;
  channelData: {
    text: string;
    value: string;
  }[];
  defaults: MonthlyPaymentsFilters;
  channels: string[];
};

const CURRENT_YEAR = new Date().getFullYear();
const YEARS = Array.from({ length: 10 }, (_, k) => CURRENT_YEAR - k).reverse();
const MONTHS = Array.from({ length: 12 }, (_, k) => k + 1).map((month) => {
  const text = month < 10 ? `0${month}` : month.toString();
  return { text, value: month };
});

const GestionPagheControlBar = (props: GestionPagheControlBarProps) => {
  const [form] = Form.useForm();
  const [openPop, setOpenPop] = useState<boolean>(false);

  const onSumbit = () => {
    const values = form.getFieldsValue();

    const filters: MonthlyPaymentsFilters = {
      month: values.month,
      year: values.year,
      channel: values.channel,
    };

    props.onSubmit(filters);
  };

  const onReset = () => {
    form.resetFields();
    props.onSubmit(props.defaults);
  };

  const onGenerateFileClick = () => {
    setOpenPop(false);
    props.onGenerateFileClick();
  };

  const handleCancel = () => {
    setOpenPop(false);
  };
  const showPopconfirm = () => {
    setOpenPop(true);
  };

  if (props.channelData.length === 0) return null;

  return (
    <div className="GestionPagheControlBar">
      <Form style={{ gap: '10px' }} layout="inline" onFinish={onSumbit} onReset={onReset} form={form} name="fiters">
        <Form.Item
          initialValue={props.defaults.year}
          key="year"
          name="year"
          className="GestionPagheControlBar_inputElement"
        >
          <Select placeholder="Anno" className="GestionPagheControlBar_treeSelect" disabled={props.isLoading}>
            {YEARS.map((y) => (
              <Select.Option key={y} value={y}>
                {y}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={props.defaults.month}
          key="month"
          name="month"
          className="GestionPagheControlBar_inputElement"
        >
          <Select placeholder="Mese" className="GestionPagheControlBar_treeSelect" disabled={props.isLoading}>
            {MONTHS.map((m) => (
              <Select.Option key={m.value} value={m.value}>
                {m.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={props.defaults.channel}
          key="channel"
          name="channel"
          className="GestionPagheControlBar_inputElement"
        >
          <Select
            placeholder="Canali"
            className="GestionPagheControlBar_treeSelect"
            disabled={props.isLoading}
            allowClear={true}
          >
            {props.channelData.map((e) => (
              <Select.Option key={e.value} value={e.value}>
                {e.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="link" htmlType="reset" disabled={props.isLoading}>
          Reset filtri
        </Button>
        <Button type="primary" htmlType="submit" disabled={props.isLoading}>
          Cerca
        </Button>
      </Form>
      <Popconfirm
        title={`Sei sicuro di voler generare il file per ${
          props.channels.length === 1 ? 'il canale' : 'i canali'
        } ${props.channels.join(', ')}?`}
        visible={openPop}
        onConfirm={onGenerateFileClick}
        okButtonProps={{ loading: props.isLoading }}
        onCancel={handleCancel}
      >
        <Button
          style={{ float: 'right' }}
          type="default"
          onClick={showPopconfirm}
          disabled={props.isLoading || props.channels.length === 0}
        >
          Genera File Geco
        </Button>
      </Popconfirm>
    </div>
  );
};

export default GestionPagheControlBar;
