import { Alert, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Channel, Rubric, SubRubric } from '../models/Channel';
import CurrentUser from '../models/CurrentUser';
import { DisplayQuestionsContainer } from '../models/DisplayQuestionsContainer';
import { Question } from '../models/Question';
import {
  //AssignQuestionsToContainerResponseError,
  isAssignQuestionsToContainerResponseError,
} from '../models/responses/AssignQuestionsToContainerResponse';
import OrganizationService from '../services/OrganizationService';
import QuestionsContainerService from '../services/QuestionsContainerService';
import QuestionService from '../services/QuestionService';
import DateUtils from '../utils/DateUtils';
import AssignQuestionToContainerSelect from './AssignQuestionToContainerSelect';
import AssociateQuestionsToContainerWithDifferentTaxonomyModal from './AssociateQuestionsToContainerWithDifferentTaxonomyModal';

interface AssignQuestionToContainerModalProps {
  isModalVisible: boolean;
  questions: Question[];
  onOk: () => void;
  onCancel: () => void;
  questionService: QuestionService;
  containerService: QuestionsContainerService;
  organizationService: OrganizationService;
  currentUser: CurrentUser;
}

const AssignQuestionToContainerModal: React.FC<AssignQuestionToContainerModalProps> = (props) => {
  const [selectedContainer, setSelectedContainer] = useState<DisplayQuestionsContainer | undefined>(undefined);
  // const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [assignQuestionsToContainerModalVisible, setAssignQuestionsToContainerModalVisible] = useState(false);
  const [taxonomyModalVisible, setTaxonomyModalVisible] = useState(false);

  useEffect(() => {
    setAssignQuestionsToContainerModalVisible(props.isModalVisible);
  }, [props.isModalVisible]);

  const onContainerSelected = (container: DisplayQuestionsContainer) => {
    setSelectedContainer(container);
  };

  const onOk = async () => {
    if (selectedContainer && props.questions) {
      try {
        setErrorMessage(undefined);
        setConfirmLoading(true);
        const isNotAllQuestionsFromEr = props.questions.some(({ channelName }) => channelName !== 'ER');

        if (isNotAllQuestionsFromEr) {
          setTaxonomyModalVisible(true);
        } else {
          await handleAssign();
        }
      } catch (e: any) {
        setErrorMessage(e.toString());
      } finally {
        setConfirmLoading(false);
      }
    } else props.onCancel();
  };

  const onTaxonomyModalOk = async (channel: Channel, rubric: Rubric, subRubric: SubRubric) => {
    setTaxonomyModalVisible(false);

    await handleAssign(channel, rubric, subRubric);
  };

  const handleAssign = async (channel?: Channel, rubric?: Rubric, subRubric?: SubRubric) => {
    const containerRubrics = props.questions.map((question) =>
      question.channelName !== 'ER' && channel && rubric && subRubric
        ? {
            questionId: question.id,
            channelId: channel.channelId,
            rubricId: rubric.rubricId,
            subRubricId: subRubric.subRubricId,
          }
        : {
            questionId: question.id,
            channelId: question.channelId,
            rubricId: question.rubricId,
            subRubricId: question.subRubricId,
          },
    );

    const response = await props.containerService.assignQuestionsToContainer({
      containerId: selectedContainer!.containerId,
      containerRubrics: containerRubrics,
    });
    if (isAssignQuestionsToContainerResponseError(response)) {
      const errors = await Promise.all(
        response.map(async (error) => {
          const questionId = error.message.split(' ')[1];
          const containerId = error.message.split(' released on ')[1];
          if (containerId) {
            const container = await props.containerService.getQuestionsContainerById(containerId);
            return `Quesito ${questionId} già pubblicato in ${container.title} numero ${
              container.issueNumber
            } del ${DateUtils.formatOnlyDate(container.publicationDate)}`;
          }
          return error.message;
        }),
      );
      errors.forEach((error) => message.error(error));
      setErrorMessage(errors.join(', '));
    } else {
      setSelectedContainer(undefined);
      message.success('Quesito/i associato/i con successo');
      setAssignQuestionsToContainerModalVisible(false);
      props.onOk();
    }
  };

  return (
    <>
      <Modal
        title="Assegna a un fascicolo"
        visible={assignQuestionsToContainerModalVisible}
        onOk={onOk}
        onCancel={() => props.onCancel()}
        confirmLoading={confirmLoading}
        data-testid="assign-to-container-modal"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AssignQuestionToContainerSelect
            questions={props.questions}
            containerService={props.containerService}
            selectedContainer={selectedContainer}
            onContainerSelected={onContainerSelected}
            currentUser={props.currentUser}
            setSelectedContainer={setSelectedContainer}
          />
        </div>
        {errorMessage && (
          <div style={{ marginTop: 16 }}>
            <Alert message={errorMessage} type="error" showIcon></Alert>
          </div>
        )}
      </Modal>
      <AssociateQuestionsToContainerWithDifferentTaxonomyModal
        isModalVisible={taxonomyModalVisible}
        onOk={onTaxonomyModalOk}
        onCancel={() => setTaxonomyModalVisible(false)}
        questionService={props.questionService}
        organizationService={props.organizationService}
      />
    </>
  );
};

export default AssignQuestionToContainerModal;
