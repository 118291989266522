import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurrentUser from '../../models/CurrentUser';
import NavigationService from '../../services/NavigationService';
import OrganizationService from '../../services/OrganizationService';
import QuestionsContainerService from '../../services/QuestionsContainerService';
import QuestionService from '../../services/QuestionService';
import UserService from '../../services/UserService';
import QuestionStatus from '../../utils/QuestionStatus';
import ReportClienti from './Clienti/ReportClienti';
import ReportEsperti from './Esperti/ReportEsperti';
import ReportPersonaleEsperto from './PersonaleEsperto/ReportPersonaleEsperto';
import ReportQuesiti from './Quesiti/ReportQuesiti';
import ReportRedazione from './Redazione/ReportRedazione';

import { SelectData, TreeData } from './utils';

type ReportsProps = {
  navigationService: NavigationService;
  userService: UserService;
  questionService: QuestionService;
  organizationService: OrganizationService;
  containerService: QuestionsContainerService;
  currentUser: CurrentUser;
};

type ReportType = 'personale-esperto' | 'redazione' | 'quesiti' | 'esperti' | 'clienti';

const Reports: FC<ReportsProps> = (props) => {
  const { reportType } = useParams<{ reportType: ReportType }>();

  const [rubricTreeData, setRubricTreeData] = useState<TreeData[]>([]);
  const [channelData, setChannelData] = useState<SelectData[]>([]);

  useEffect(() => {
    const getRubrics = async () => {
      const rubrics = await props.organizationService.getRubrics();
      const treeData = rubrics.map((rubric) => {
        const children = rubric.subRubrics.map((subRubric) => ({
          title: subRubric.subRubricName,
          value: `${rubric.rubricId}||${subRubric.subRubricId}`,
          key: `${rubric.rubricId}||${subRubric.subRubricId}`,
        }));
        return {
          title: rubric.rubricName,
          value: rubric.rubricId,
          key: rubric.rubricId,
          children,
        };
      });
      setRubricTreeData(treeData);
    };
    const getChannels = async () => {
      const channels = await props.organizationService.getChannels();
      const channelData = channels.map((channel) => ({
        text: channel.channelName,
        value: channel.channelId,
      }));
      setChannelData(channelData);
    };
    getRubrics();
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (reportType) {
    case 'personale-esperto':
      return (
        <ReportPersonaleEsperto
          {...props}
          rubricTreeData={rubricTreeData}
          channelData={channelData}
          questionStatusData={QuestionStatus.toFilterArray()}
        />
      );
    case 'redazione':
      return (
        <ReportRedazione
          {...props}
          rubricTreeData={rubricTreeData}
          channelData={channelData}
          questionStatusData={QuestionStatus.toFilterArray()}
        />
      );
    case 'quesiti':
      return (
        <ReportQuesiti
          {...props}
          rubricTreeData={rubricTreeData}
          channelData={channelData}
          questionStatusData={QuestionStatus.toFilterArray()}
        />
      );
    case 'esperti':
      return (
        <ReportEsperti
          {...props}
          rubricTreeData={rubricTreeData}
          channelData={channelData}
          questionStatusData={QuestionStatus.toFilterArray()}
        />
      );
    case 'clienti':
      return (
        <ReportClienti
          {...props}
          rubricTreeData={rubricTreeData}
          channelData={channelData}
          questionStatusData={QuestionStatus.toFilterArray()}
        />
      );
    default:
      return <div>Report non trovato</div>;
  }
};

export default Reports;
