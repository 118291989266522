import { TablePaginationConfig } from 'antd/lib/table/interface';
import { FC, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ReportService, { CustomerInfo } from '../../../services/ReportService';
import ReportsControlBar, { FiltersCallbackResult } from '../components/ReportsFilters/ReportsControlBar';
import ReportsTable from '../components/ReportsTable/ReportsTable';
import { DEFAULT_FILTERS, DEFAULT_PAGINATION, downloadCSVFromText, ReportProps } from '../utils';

type getReportDataFunction = (theFilters?: FiltersCallbackResult, pagination?: TablePaginationConfig) => Promise<void>;

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Totale Quesiti',
    dataIndex: 'total',
    key: 'total',
  },
];

const ReportClienti: FC<ReportProps> = (props) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [reportData, setReportData] = useState<CustomerInfo[] | null>(null);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<FiltersCallbackResult>(DEFAULT_FILTERS);

  const getReportData: getReportDataFunction = async (
    newFilters = DEFAULT_FILTERS,
    newPagination = DEFAULT_PAGINATION,
  ) => {
    setIsRefreshingData(true);
    try {
      const res = await ReportService.fetchReportClientiUnici(newFilters, newPagination);
      setReportData(res.customers);
      setTotal(res.fullCount);
      setCurrentFilters(newFilters);
    } catch {
      setReportData(null);
      console.error('Impossibile recuperare il report');
    } finally {
      setIsRefreshingData(false);
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  const onDownloadCSV = async () => {
    try {
      const data = await ReportService.downloadReportClientiUnici(currentFilters);
      downloadCSVFromText(data, 'Report Clienti');
    } catch {
      console.error('Impossibile scaricare il report');
    }
  };

  const onPageSelectedChange = (newPagination: TablePaginationConfig) => {
    getReportData(currentFilters, newPagination);
  };

  return (
    <div>
      <h2>Report Clienti</h2>
      <ReportsControlBar
        filtersAvailable={['typology', 'channel', 'status', 'rubric', 'creationDate']}
        channelData={props.channelData}
        rubricTreeData={props.rubricTreeData}
        questionStatusData={props.questionStatusData}
        onSubmit={(values) => getReportData(values)}
        onDownloadCSV={onDownloadCSV}
      />
      <LoadingSpinner isSpinning={isRefreshingData && !reportData}>
        {reportData && (
          <ReportsTable
            columns={columns}
            dataSource={reportData}
            loading={isRefreshingData}
            onPageSelectedChange={onPageSelectedChange}
            total={total}
            pagination={DEFAULT_PAGINATION}
          />
        )}
      </LoadingSpinner>
    </div>
  );
};

export default ReportClienti;
