import { Alert, Checkbox, DatePicker, Form, Modal, message, Select } from 'antd';
import { Question } from '../models/Question';
import React, { useEffect, useState } from 'react';
import QuestionService from '../services/QuestionService';
import Constants from '../Constants';
import moment from 'moment';
import QuestionStatus from '../utils/QuestionStatus';
import OrganizationService from '../services/OrganizationService';
import { Channel, Rubric, SubRubric } from '../models/Channel';
import LoadingSpinner from './LoadingSpinner';
import SelectUtils from '../utils/SelectUtils';
import CurrentUser from '../models/CurrentUser';
import AuthorizationHelper from '../utils/AuthorizationHelper';

interface Props {
  isModalVisible: boolean;
  questions?: Question[];
  onOk: () => void;
  onCancel: () => void;
  questionService: QuestionService;
  organizationService: OrganizationService;
  currentUser: CurrentUser;
}

const PublishQuestionModal: React.FC<Props> = (props) => {
  const [publishQuestionForm] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [pubDate, setPubDate] = useState<moment.Moment | undefined>(undefined);
  const [exclusive, setExclusive] = useState<boolean>(false);
  const [publishingQuestion, setPublishingQuestion] = useState<boolean>(false);

  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [subRubrics, setSubRubrics] = useState<SubRubric[] | undefined>(undefined);

  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
  const [selectedRubric, setSelectedRubric] = useState<Rubric | undefined>(undefined);
  const [selectedSubRubric, setSelectedSubRubric] = useState<SubRubric | undefined>(undefined);

  const resetModalState = () => {
    setPubDate(undefined);
    setSelectedChannel(undefined);
    setSelectedRubric(undefined);
    setRubrics(undefined);
    setSubRubrics(undefined);
    setErrorMessage(undefined);
    publishQuestionForm.resetFields();
  };

  const channelSelectPlaceholder =
    channels === undefined ? 'Caricamento in corso...' : 'Seleziona un canale di pubblicazione';
  const loadingDefaultOption = (
    <Select.Option disabled={true} value="">
      <LoadingSpinner fontSize={20} />
      <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
    </Select.Option>
  );

  useEffect(() => {
    if (!props.isModalVisible) {
      return;
    }
    (async () => {
      try {
        const channels = await props.organizationService.getOutputChannels();
        if (props.currentUser && props.currentUser?.channels.length > 0) {
          setChannels(AuthorizationHelper.filterChannelsForUser(props.currentUser, channels));
        } else {
          setChannels(channels);
        }
        setErrorMessage(undefined);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questions, props.isModalVisible]);

  useEffect(() => {
    setSelectedRubric(undefined);
    setRubrics(undefined);
    setSubRubrics(undefined);
    (async () => {
      try {
        const rubrics = await props.organizationService.getRubricsByChannelId(selectedChannel!.channelId);
        setRubrics(rubrics);
        setErrorMessage(undefined);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel?.channelId]);

  useEffect(() => {
    let rubric = rubrics?.find((rubric) => rubric.rubricId === selectedRubric?.rubricId);
    setSelectedSubRubric(undefined);
    setSubRubrics(rubric?.subRubrics);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRubric?.rubricId]);

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title="Pubblica quesito"
      visible={props.isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      data-testid="publish-question-modal"
      okButtonProps={{ loading: publishingQuestion }}
      cancelButtonProps={{ loading: publishingQuestion }}
    >
      {containsMultipleTaxonomies(props.questions) && (
        <>
          <Alert message="Hai selezionato delle domande con tassonomie divergenti" type="warning" showIcon></Alert>
          <br />
          <br />
        </>
      )}
      <Form form={publishQuestionForm} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="outputChannelId"
          label={` Canale di pubblicazione`}
          rules={[{ required: true, message: 'Aggiungi un canale di pubblicazione!' }]}
        >
          <Select
            showSearch
            style={{ width: '480' }}
            placeholder={channelSelectPlaceholder}
            optionFilterProp="children"
            filterOption={SelectUtils.filterOption}
            value={selectedChannel?.channelId}
            onSelect={setSelectedChannelById}
            data-testid="select-output-channel-field"
          >
            {showChannelOptions(channels, loadingDefaultOption)}
          </Select>
        </Form.Item>

        {hasDifferentInputAndOutputChannels(props.questions, selectedChannel) && (
          <>
            <Form.Item
              name="outputRubricId"
              label={` Rubrica di pubblicazione`}
              rules={[{ required: true, message: 'Aggiungi una rubrica di pubblicazione!' }]}
            >
              <Select
                showSearch
                placeholder="Seleziona una rubrica"
                value={selectedRubric?.rubricId}
                onSelect={setSelectedRubricById}
                filterOption={SelectUtils.filterOption}
                optionFilterProp="children"
                disabled={selectedChannel === undefined}
              >
                {showRubricOptions(rubrics, loadingDefaultOption)}
              </Select>
            </Form.Item>
            <Form.Item
              name="outputSubRubricId"
              label={` SottoRubrica di pubblicazione`}
              rules={[{ required: true, message: 'Aggiungi una sottoRubrica di pubblicazione!' }]}
            >
              <Select
                showSearch
                placeholder="Seleziona una sotto rubrica"
                value={selectedSubRubric?.subRubricId}
                onSelect={setSelectedSubRubricById}
                optionFilterProp="children"
                filterOption={SelectUtils.filterOption}
                disabled={selectedRubric === undefined}
              >
                {showSubRubricOptions(subRubrics, loadingDefaultOption)}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Checkbox
            checked={exclusive}
            onChange={(checkValue) => setExclusive(checkValue.target.checked)}
            data-testid={'exclusive-publication-checkbox'}
            disabled={
              !props.questions?.length ||
              props.questions.find((question) => question.status !== QuestionStatus.NOTIFIED)
                ? true
                : false
            }
          >
            Pubblicazione in esclusiva
          </Checkbox>
        </Form.Item>
        {exclusive && (
          <Form.Item label={` Data di fine embargo`}>
            <DatePicker
              format={Constants.FRONTEND_DATE_FORMAT}
              onChange={(dateMoment) => setPubDate(dateMoment!)}
              value={pubDate}
              allowClear={false}
              data-testid={'fine-embargo-date-picker'}
            />
          </Form.Item>
        )}
      </Form>
      {errorMessage !== undefined && (
        <div style={{ marginTop: 16 }}>
          <Alert message={errorMessage} type="error" showIcon></Alert>
        </div>
      )}
    </Modal>
  );

  function containsMultipleTaxonomies(questions: Question[] = []): boolean {
    return questions.reduce(toDistinctTaxonomies, []).length > 1;

    function toDistinctTaxonomies(distinct: any, { channelId, rubricId, subRubricId }: Question) {
      const findByTaxonomy = (d: any) =>
        d.channelId === channelId && d.rubricId === rubricId && d.subRubricId === subRubricId;

      return distinct.some(findByTaxonomy) ? distinct : [...distinct, { channelId, rubricId, subRubricId }];
    }
  }

  function showChannelOptions(channels?: Channel[], defaultOptions?: React.ReactElement) {
    if (channels === undefined) {
      return defaultOptions;
    }
    return channels.map(({ channelId, channelName }) => (
      <Select.Option key={channelId} value={channelId} role="output-channel-field-options">
        {channelName}
      </Select.Option>
    ));
  }

  function showRubricOptions(rubrics?: Rubric[], defaultOptions?: React.ReactElement) {
    if (rubrics === undefined) {
      return defaultOptions;
    }

    return rubrics.map(({ rubricId, rubricName }) => (
      <Select.Option key={rubricId} value={rubricId} role="create-question-field-rubric-options">
        {rubricName}
      </Select.Option>
    ));
  }

  function showSubRubricOptions(subRubrics?: SubRubric[], defaultOptions?: React.ReactElement) {
    if (subRubrics === undefined) {
      return defaultOptions;
    }
    return subRubrics.map(({ subRubricId, subRubricName }, index) => (
      <Select.Option key={subRubricId} value={subRubricId} role="create-question-field-subrubric-options">
        {subRubricName}
      </Select.Option>
    ));
  }

  async function onOk() {
    if (props.questions?.length === 0) {
      return setErrorMessage('Nessun Quesito Selezionato');
    }

    if (exclusive && !pubDate) {
      return setErrorMessage('Per pubblicare in modo esclusivo immettere una data di pubblicazione');
    }

    if (selectedChannel === undefined) {
      return setErrorMessage('Immettere un canale di pubblicazione');
    }

    try {
      await publishQuestionForm.validateFields();
      setPublishingQuestion(true);
      await props.questionService.publishQuestion(
        props.questions!.map((question) => question.id),
        selectedChannel.channelId,
        selectedRubric?.rubricId,
        selectedSubRubric?.subRubricId,
        pubDate?.toDate(),
      );
      message.success('Quesito/i pubblicato con successo');
      props.onOk();
      resetModalState();
    } catch (err: any) {
      setErrorMessage(err.message); // fixme: distinguish between validation errors and other errors
    } finally {
      setPublishingQuestion(false);
    }
  }

  function onCancel() {
    resetModalState();
    props.onCancel();
  }

  function setSelectedChannelById(id: string) {
    const channel = channels?.find(({ channelId }) => channelId === id);
    setSelectedChannel(channel);
  }

  function setSelectedRubricById(id: string) {
    const rubric = rubrics?.find(({ rubricId }) => rubricId === id);
    setSelectedRubric(rubric);
  }

  function setSelectedSubRubricById(id: string) {
    let subRubric = subRubrics?.find(({ subRubricId }) => subRubricId === id);
    setSelectedSubRubric(subRubric);
  }

  function hasDifferentInputAndOutputChannels(questions?: Question[], selectedChannel?: Channel) {
    if (selectedChannel === undefined) {
      return false;
    }

    if (questions === undefined) {
      return false;
    }

    return questions.some(({ channelId }: Question) => channelId !== selectedChannel?.channelId);
  }
};

export default PublishQuestionModal;
