import { Alert, Col, Descriptions, Form, FormInstance, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Rubric, SubRubric } from '../../../models/Channel';
import CurrentUser from '../../../models/CurrentUser';
import { QuestionDetail } from '../../../models/Question';
import UserAssignableToQuestion from '../../../models/UserAssignableToQuestion';
import OrganizationService from '../../../services/OrganizationService';
import UserService from '../../../services/UserService';
import AuthorizationHelper from '../../../utils/AuthorizationHelper';
import DateUtils from '../../../utils/DateUtils';
import QuestionStatus from '../../../utils/QuestionStatus';
import SelectUtils from '../../../utils/SelectUtils';
import AssignQuestionToExpertSelect from '../../AssignQuestionToExpertSelect';
import LoadingSpinner from '../../LoadingSpinner';
import UserAssignableToQuestionNotMatchingTopicAlert from '../../UserAssignableToQuestionNotMatchingTopicAlert/UserAssignableToQuestionNotMatchingTopicAlert';
import ClassificationsFormItem from './ClassificationsFormItem';
import './EditorFieldsFormItem.css';
import './QuestionFormItem.css';

interface Props {
  currentUser: CurrentUser;
  question: QuestionDetail;
  questionForm: FormInstance<any>;
  organizationService: OrganizationService;
  userService: UserService;
  assignedTo: UserAssignableToQuestion | undefined;
  setAssignedTo: React.Dispatch<React.SetStateAction<UserAssignableToQuestion | undefined>>;
  canEditRubricAndSubRubric: () => boolean;
  canEditClassifications: () => boolean;
  setFormFieldsChanged: (formFieldsChanged: boolean) => void;
}

const MetadataFormItem: React.FC<Props> = ({
  currentUser,
  question,
  questionForm,
  organizationService,
  userService,
  assignedTo,
  setAssignedTo,
  canEditRubricAndSubRubric,
  canEditClassifications,
  setFormFieldsChanged,
}) => {
  const [rubricOptionsInSelect, setRubricOptionsInSelect] = useState<Rubric[] | undefined>(undefined);
  const [subRubricOptionsInSelect, setSubRubricOptionsInSelect] = useState<SubRubric[] | undefined>(undefined);
  const [rubricIsSelected, setRubricIsSelected] = useState<boolean>(false);
  const [mediatopicClassifications, setMediatopicClassifications] = useState<string[]>([]);
  const [isAssigneeChanged, setIsAssigneeChanged] = useState<boolean>(false);
  const [isRubricChanged, setIsRubricChanged] = useState<boolean>(false);

  const loadingSelectOption = (
    <Select.Option disabled={true} value="">
      <LoadingSpinner fontSize={20} />
      <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
    </Select.Option>
  );

  const questionCanBeAssigned = (): boolean => {
    return AuthorizationHelper.questionCanBeAssignedByUser(currentUser, question);
  };

  const onRubricSelected = async (rubricId: string) => {
    setIsRubricChanged(true);
    let selectedRubric = rubricOptionsInSelect?.find((rubric) => rubric.rubricId === rubricId);
    setRubricIsSelected(selectedRubric !== undefined);
    clearSelectedSubRubric();
    setSubRubricOptionsInSelect(selectedRubric?.subRubrics);
  };

  const clearSelectedSubRubric = (): void => {
    setSubRubricOptionsInSelect(undefined);
    questionForm.setFieldsValue({
      subRubric: undefined,
    });
  };

  const loadMediatopicClassifications = useCallback(async (): Promise<void> => {
    const classifications: string[] = await organizationService.getMediatopicClassifications();
    setMediatopicClassifications(classifications);
  }, [organizationService]);

  const loadRubrics = useCallback(async (): Promise<Rubric[]> => {
    const rubrics = await organizationService.getRubricsByChannelId(question.channelId);
    setRubricOptionsInSelect(rubrics);
    return rubrics;
  }, [organizationService, question.channelId]);

  const loadSelectableRubricAndSubRubricData = useCallback(async () => {
    const rubrics = await loadRubrics();

    let rubricToBeSelected = rubrics?.find((rubric) => rubric.rubricId === question.rubricId);
    setRubricIsSelected(rubricToBeSelected !== undefined);
    setSubRubricOptionsInSelect(rubricToBeSelected?.subRubrics);

    questionForm.setFieldsValue({
      rubric: question.rubricId,
      subRubric: question.subRubricId,
    });
  }, [loadRubrics, question.rubricId, question.subRubricId, questionForm]);

  const onExpertSelected = (expert: UserAssignableToQuestion) => {
    setIsAssigneeChanged(true);
    if (assignedTo?.id !== expert.id) {
      setAssignedTo(expert);
      setFormFieldsChanged(true);
    }
  };

  function onRubricChangeBlockEdit(): boolean {
    return isRubricChanged && isExpertAndCanChangeRubricSubRubricAndAssignee();
  }

  function onAssigneeChangeBlockEdit(): boolean {
    return isAssigneeChanged && isExpertAndCanChangeRubricSubRubricAndAssignee();
  }

  function isExpertAndCanChangeRubricSubRubricAndAssignee() {
    return (
      currentUser.isSuperExpertOnTopic(question.subRubricId) && canEditRubricAndSubRubric() && questionCanBeAssigned()
    );
  }

  useEffect(() => {
    if (canEditRubricAndSubRubric()) {
      loadSelectableRubricAndSubRubricData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canEditClassifications()) {
      loadMediatopicClassifications();
    }
  }, [canEditClassifications, loadMediatopicClassifications]);

  return (
    <>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="Canale">{question.channelName}</Descriptions.Item>
      </Descriptions>
      <Row>
        <Col span={isExpertAndCanChangeRubricSubRubricAndAssignee() ? 19 : 24}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Rubrica" style={{ height: '47.5px' }}>
              {canEditRubricAndSubRubric() === false && <> {question.rubricName}</>}
              {canEditRubricAndSubRubric() === true && (
                <Form.Item
                  name="rubric"
                  style={{ padding: 0, margin: 0, width: '100%' }}
                  data-testid="question-modal-field-rubric"
                  rules={[{ required: true, message: 'Selezionare una rubrica' }]}
                >
                  <Select
                    showSearch
                    placeholder="Seleziona una rubrica"
                    onSelect={onRubricSelected}
                    filterOption={SelectUtils.filterOption}
                    optionFilterProp="children"
                    disabled={onAssigneeChangeBlockEdit()}
                  >
                    {rubricOptionsInSelect === undefined && loadingSelectOption}
                    {rubricOptionsInSelect !== undefined &&
                      rubricOptionsInSelect.map((rubric, index) => (
                        <Select.Option key={index} value={rubric.rubricId} role="question-modal-field-rubric-options">
                          {rubric.rubricName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Sotto-rubrica" style={{ height: '47.5px' }}>
              {canEditRubricAndSubRubric() === false && <> {question.subRubricName}</>}
              {canEditRubricAndSubRubric() === true && (
                <Form.Item
                  name="subRubric"
                  style={{ padding: 0, margin: 0, width: '100%' }}
                  data-testid="question-modal-field-subRubric"
                  rules={[{ required: true, message: 'Selezionare una sotto rubrica' }]}
                >
                  <Select
                    showSearch
                    placeholder="Seleziona una sotto rubrica"
                    onSelect={() => setIsRubricChanged(true)}
                    optionFilterProp="children"
                    filterOption={SelectUtils.filterOption}
                    disabled={rubricIsSelected === false || onAssigneeChangeBlockEdit()}
                  >
                    {subRubricOptionsInSelect === undefined && loadingSelectOption}
                    {subRubricOptionsInSelect !== undefined &&
                      subRubricOptionsInSelect.map((subRubric, index) => (
                        <Select.Option
                          key={index}
                          value={subRubric.subRubricId}
                          role="question-modal-field-subrubric-options"
                        >
                          {subRubric.subRubricName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Assegnato a" style={{ height: '47.5px' }}>
              {questionCanBeAssigned() && (
                <div style={{ width: '100%' }} data-testid="assign-question-to-expert-select">
                  <AssignQuestionToExpertSelect
                    question={question}
                    userService={userService}
                    onExpertSelected={onExpertSelected}
                    selectedExpert={assignedTo}
                    disabled={onRubricChangeBlockEdit()}
                    width={'100%'}
                  />
                  <UserAssignableToQuestionNotMatchingTopicAlert question={question} user={assignedTo} />
                </div>
              )}
              {questionCanBeAssigned() === false && <>{question.assignedTo?.displayName}</>}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={5}>
          {isExpertAndCanChangeRubricSubRubricAndAssignee() && (
            <Alert
              style={{ fontSize: 'small', height: '100%' }}
              message={'Non è consentito modificare rubrica o sotto-rubrica e cambiare assegnatario in contemporanea.'}
              type="warning"
            />
          )}
        </Col>
      </Row>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="Stato">{QuestionStatus.getStatusDescription(question)}</Descriptions.Item>
        <Descriptions.Item label="Data di creazione">{DateUtils.format(question.creationDate)}</Descriptions.Item>
        <Descriptions.Item label="Classificazione">
          <ClassificationsFormItem
            form={questionForm}
            classifications={mediatopicClassifications}
            canEditClassifications={canEditClassifications}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default MetadataFormItem;
