import React from 'react';
import { Button, Space, Tooltip } from 'antd';

type ActionProps = {
  title: string;
  onClick?: (event?: any) => void;
  testId?: string;
  icon: React.ReactNode;
  text?: string;
  disabled?: boolean;
};

class ActionButton extends React.Component<ActionProps> {
  render() {
    return (
      <Tooltip title={this.props.title}>
        <Space size="small">
          <Button
            type="primary"
            shape="circle"
            onClick={this.props.onClick}
            data-testid={this.props.testId}
            icon={this.props.icon}
            disabled={this.props.disabled}
          />
          {this.props.text && <span>{this.props.text}</span>}
        </Space>
      </Tooltip>
    );
  }
}

export default ActionButton;
