import { useEffect, useState } from 'react';
import GestionPagheControlBar from './components/GestionPagheControlBar/GestionPagheControlBar';
import GestionePagheTable from './components/GestionePagheTable/GestionePagheTable';
import GestionePagheModal from './components/GestionePagheModal/GestionePagheModal';
import OrganizationService from '../../services/OrganizationService';
import GestionePagheService, {
  PaymentExpertRow,
  PaymentDetail,
  MonthlyPaymentsFilters,
} from '../../services/GestionePagheService';
import { notification } from 'antd';

type GestionePagheEspertiProps = {
  organizationService: OrganizationService;
};

const DEFAULT_MONTH = new Date().getMonth() === 0 ? 12 : new Date().getMonth();
const DEFAULT_YEAR = new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();

const GestionePagheEsperti = (props: GestionePagheEspertiProps) => {
  const [channelData, setChannelData] = useState<
    {
      text: string;
      value: string;
    }[]
  >([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PaymentExpertRow | undefined>(undefined);
  const [paymentsData, setPaymentsData] = useState<PaymentExpertRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<MonthlyPaymentsFilters>({
    month: DEFAULT_MONTH,
    year: DEFAULT_YEAR,
    channel: undefined,
  });

  const fetchPeriodPerChannel = async () => {
    setIsLoading(true);
    try {
      const data = await GestionePagheService.fetchExpertPerChannel({ ...filters });
      setPaymentsData(data.experts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriodPerChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const getChannels = async () => {
      const channels = await props.organizationService.getChannels();
      const channelData = channels.map((channel: { channelName: string; channelId: string }) => ({
        text: channel.channelName,
        value: channel.channelId,
      }));
      setChannelData(channelData);
    };
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAnswersPayability = async (answersToUpdate: PaymentDetail[]) => {
    if (!selectedRow?.channel) return;
    const channel = selectedRow.channel;
    setIsLoading(true);
    try {
      const promises = answersToUpdate.map(async (answer) => {
        return await GestionePagheService.updateAswserPayability({
          answerId: answer.answerId,
          payable: answer.payable,
          channel,
          month: filters.month,
          year: filters.year,
        });
      });
      await Promise.all(promises);
      notification.success({
        message: 'Operazione conclusa con successo',
        description: `Risposte aggiornate con successo`,
      });
      fetchPeriodPerChannel();
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Operazione conclusa con errori',
        description: `Errore durante l'aggiornamento delle risposte`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateFileAndSendPayments = async () => {
    setIsLoading(true);
    try {
      await GestionePagheService.sendMonthlyPayments(filters);
      fetchPeriodPerChannel();
      notification.success({
        message: 'Operazione conclusa con successo',
        description: `Paghe esperti per il periodo ${filters.month}/${filters.year} inviate con successo al sistema Geco`,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Operazione conclusa con errori',
        description: `Le paghe esperti per il periodo ${filters.month}/${filters.year} NON sono state inviate con successo al sistema Geco`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onTableRowClick = (row: PaymentExpertRow) => {
    setSelectedRow({ ...row });
    setIsModalVisible(true);
  };

  const onModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(undefined);
  };

  const onModalSaveConfirm = () => {
    setIsModalVisible(false);
    setSelectedRow(undefined);
  };

  if (channelData.length === 0) return <div>loading....</div>;

  const uniqueChannelsInPaymentsData = Array.from(new Set(paymentsData.map((payment) => payment.channel)));

  return (
    <div>
      <h2>Gestione paghe esperti</h2>
      <GestionPagheControlBar
        isLoading={isLoading}
        onSubmit={(newFilters) => setFilters(newFilters)}
        onGenerateFileClick={generateFileAndSendPayments}
        channelData={channelData}
        defaults={{
          channel: undefined,
          month: DEFAULT_MONTH,
          year: DEFAULT_YEAR,
        }}
        channels={uniqueChannelsInPaymentsData}
      />
      <GestionePagheTable data={paymentsData} onTableRowClick={onTableRowClick} isLoading={isLoading} />
      {selectedRow && (
        <GestionePagheModal
          isModalVisible={isModalVisible}
          onModalSaveConfirm={onModalSaveConfirm}
          onModalCancel={onModalCancel}
          updateAnswersPayability={updateAnswersPayability}
          paymentExpertRow={selectedRow}
        />
      )}
    </div>
  );
};

export default GestionePagheEsperti;
