import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Channel } from '../../../../models/Channel';
import OrganizationService from '../../../../services/OrganizationService';
import SelectUtils from '../../../../utils/SelectUtils';

interface Props {
  organizationService: OrganizationService;
  disabled?: boolean;
}

const EditorChannelFormList: React.FC<Props> = (props) => {
  let useEffectSubscribed = false;
  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);

  const loadChannels = async (): Promise<void> => {
    const channels = await props.organizationService.getInputChannels();
    if (useEffectSubscribed) setChannels(channels);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffectSubscribed = true;
    loadChannels();
    return () => {
      useEffectSubscribed = false;
    };
  }, []);

  return (
    <Form.List
      name="editorChannels"
      rules={[
        {
          validator: async (_, selectedChannels) => {
            if (!selectedChannels || selectedChannels.length === 0) {
              return Promise.reject(new Error('Selezionare almeno un canale'));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <Form.Item
                name={[field.name, 'channelName']}
                fieldKey={[field.key, 'channelName']}
                rules={[{ required: true, message: 'Selezionare un canale' }]}
              >
                <Select
                  data-testid="form-item-channel"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona un canale"
                  optionFilterProp="children"
                  filterOption={SelectUtils.filterOption}
                  disabled={props.disabled}
                >
                  {channels === undefined && (
                    <Select.Option disabled={true} value="">
                      <LoadingSpinner fontSize={20} />
                      <span style={{ marginLeft: 10 }}>Caricamento in corso...</span>
                    </Select.Option>
                  )}
                  {channels !== undefined &&
                    channels.map((channel, index) => (
                      <Select.Option key={index} value={channel.channelId} role="form-item-channel-options">
                        {channel.channelName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Button disabled={props.disabled ?? false} onClick={() => remove(field.name)} type="text">
                <MinusCircleOutlined data-testid="remove-button" />
              </Button>
            </Space>
          ))}
          <Form.Item>
            <Button
              disabled={props.disabled}
              data-testid="add-button"
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Aggiungi canale
            </Button>
          </Form.Item>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};

export default EditorChannelFormList;
