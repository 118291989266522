import { Button, Collapse, message, Spin } from 'antd';
import React, { useState } from 'react';
import { Rubric } from '../../../models/Channel';

import { useEffect } from 'react';
import OrganizationService from '../../../services/OrganizationService';
import CreateEditRubricDrawer from './CreateEditRubricDrawer';
import SubRubricsTable from './SubRubricsTable';
import './RubricsTab.css';
import CurrentUser from '../../../models/CurrentUser';

const { Panel } = Collapse;

interface props {
  organizationService: OrganizationService;
  currentUser: CurrentUser;
}

const RubricsTab: React.FC<props> = (props) => {
  const [createRubricDrawerVisible, setCreateRubricDrawerVisible] = useState(false);
  const [rubrics, setRubrics] = useState<Rubric[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [selectedRubric, setSelectedRubric] = useState<Rubric | undefined>(undefined);

  useEffect(() => {
    const getRubrics = async () => {
      setIsLoading(true);
      const rubrics = await props.organizationService.getRubrics();
      setRubrics(rubrics);
      setIsLoading(false);
    };

    if (refreshData) {
      getRubrics();

      setRefreshData(false);
    }
  }, [props.organizationService, refreshData]);

  const deleteRubric = async (event: React.MouseEvent<HTMLElement>, rubricId: string) => {
    event.stopPropagation();

    await props.organizationService
      .deleteRubric(rubricId)
      .then(() => {
        message.success('Rubrica Eliminata con successo!');
        setRefreshData(true);
      })
      .catch((e) => {
        message.error(`Errore nell'eliminare Rubrica: ${e}`);
      });
  };

  return (
    <div className="RubricsTab">
      <Button
        type="primary"
        className="create-rubric-button"
        onClick={() => {
          setCreateRubricDrawerVisible(true);
          setSelectedRubric(undefined);
        }}
      >
        Crea Rubrica
      </Button>
      <Spin spinning={isLoading} tip="Loading...">
        {rubrics?.map((rubric) => (
          <React.Fragment key={rubric.rubricId}>
            <Collapse collapsible="header">
              <Panel
                header={rubric.rubricName + `${rubric.isFiscal ? '  [Fiscale]' : '  [Non Fiscale]'}`}
                key="1"
                extra={
                  <>
                    <Button
                      onClick={(e) => deleteRubric(e, rubric.rubricId)}
                      disabled={!props.currentUser.isSuperAdministrator()}
                    >
                      Elimina
                    </Button>
                    <Button
                      onClick={(e) => {
                        setSelectedRubric(rubric);
                        return setCreateRubricDrawerVisible(true);
                      }}
                      disabled={!(props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator())}
                    >
                      Modifica
                    </Button>
                  </>
                }
              >
                <SubRubricsTable
                  organizationService={props.organizationService}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                  subRubrics={rubric.subRubrics}
                  isSuperAdministrator={props.currentUser.isSuperAdministrator()}
                />
              </Panel>
            </Collapse>
          </React.Fragment>
        ))}
      </Spin>
      <CreateEditRubricDrawer
        setCreateRubricDrawerVisible={setCreateRubricDrawerVisible}
        createRubricDrawerVisible={createRubricDrawerVisible}
        organizationService={props.organizationService}
        setRefreshData={setRefreshData}
        selectedRubric={selectedRubric}
      />
    </div>
  );
};

export default RubricsTab;
