import { CommentOutlined, EuroOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Input, message, Space, Table, Tooltip } from 'antd';
import { FilterDropdownProps, FilterValue, Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React from 'react';
import AssignQuestionToContainerModal from '../../../components/AssignQuestionToContainerModal';
import AssignQuestionToExpertModal from '../../../components/AssignQuestionToExpertModal';
import DateFilterSelect from '../../../components/DateFilterSelect';
import ExpandableParagraph from '../../../components/ExpandableParagraph';
import IntegrateAnswerModal from '../../../components/IntegrateAnswerModal';
import PublishQuestionModal from '../../../components/PublishQuestionModal';
import MenuAction from '../../../components/QuestionMenuActions/MenuActions';
import QuestionActions from '../../../components/QuestionMenuActions/QuestionActions';
import QuestionModal from '../../../components/QuestionModal/QuestionModal';
import ResizableTableHeader from '../../../components/ResizableTableHeader/ResizableTableHeader';
import TableScrollNavigation from '../../../components/TableScrollNavigation';
import Constants from '../../../Constants';
import { Channel } from '../../../models/Channel';
import CurrentUser from '../../../models/CurrentUser';
import { DisplayUser } from '../../../models/DisplayUser';
import { PublicationDetails, Question } from '../../../models/Question';
import NavigationService from '../../../services/NavigationService';
import OrganizationService from '../../../services/OrganizationService';
import QuestionsContainerService from '../../../services/QuestionsContainerService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import DateUtils from '../../../utils/DateUtils';
import QuestionStatus from '../../../utils/QuestionStatus';
import ContainersColumn from './Columns/ContainersColumn';
import './QuestionsTable.css';

export const defaultPagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '50'],
  showSizeChanger: true,
};

export enum ColumnKey {
  id = 'id',
  typology = 'typology',
  channel = 'channel',
  rubric = 'rubric',
  subRubric = 'subRubric',
  status = 'status',
  deadline = 'deadline',
  creationDate = 'creationDate',
  subject = 'subject',
  question = 'question',
  assignedTo = 'assignedTo',
  signerUser = 'signerUser',
  answerDateTime = 'answerDateTime',
  containers = 'containers',
  blockedUntil = 'blockedUntil',
  endUser = 'endUser',
  outputChannels = 'outputChannels',
  actions = 'actions',
}

type DataRange = {
  from: string;
  to: string;
};

type Props = {
  data: Question[];
  total?: number;
  textSearch?: Record<string, FilterValue | null>;
  refreshData: (
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question>,
    pagination: TablePaginationConfig,
  ) => Promise<void>;
  questionService: QuestionService;
  organizationService: OrganizationService;
  navigationService?: NavigationService;
  userService: UserService;
  currentUser: CurrentUser;
  containerService?: QuestionsContainerService;
  columnsOrder: ColumnKey[];
  canCreateQuestion?: boolean;
  canPublishQuestion?: boolean;
  setCheckedQuestions?: (questions: Question[]) => void;
  initialQuestionDetails?: string;
};

type State = {
  columns: any[];
  isQuestionDetailsModalVisible: boolean;
  isInitialQuestionDetailsModalVisible: boolean;
  isPublishQuestionModalVisible: boolean;
  isAssignQuestionToContainerModalVisible: boolean;
  isAssignQuestionToExpertModalVisible: boolean;
  isIntegrateAnswerModalVisible: boolean;
  selectedQuestion: Question | undefined;
  selectedQuestions: Question[] | undefined;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<Question>;
  pagination: TablePaginationConfig;
  checkedQuestions: Question[];
  allChannelsInfo: Channel[];
};

class QuestionsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      checkedQuestions: [],
      isInitialQuestionDetailsModalVisible: props.initialQuestionDetails !== undefined,
    };
  }

  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => {
              this.handleReset(clearFilters);
              confirm({ closeDropdown: false });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: true });
            }}
          >
            Chiudi
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  getColumnSearchDateProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      return (
        <div style={{ padding: 8 }}>
          <div style={{ marginBottom: 8, display: 'block' }}>
            <DatePicker
              format={Constants.FRONTEND_DATE_FORMAT}
              onChange={(dateMoment) => {
                this.dataRanges[dataIndex as string] = {
                  from: dateMoment ? dateMoment.startOf('day').format(Constants.BACKEND_DATETIME_FORMAT) : '',
                  to: this.dataRanges[dataIndex as string].to,
                };
                setSelectedKeys([this.dataRanges[dataIndex as string].from, this.dataRanges[dataIndex as string].to]);
              }}
              value={
                this.dataRanges[dataIndex as string].from
                  ? moment(this.dataRanges[dataIndex as string].from, Constants.BACKEND_DATETIME_FORMAT)
                  : null
              }
              allowClear={false}
              placeholder={'Dalla data'}
            />
            <DatePicker
              format={Constants.FRONTEND_DATE_FORMAT}
              onChange={(dateMoment) => {
                this.dataRanges[dataIndex as string] = {
                  to: dateMoment ? dateMoment.endOf('day').format(Constants.BACKEND_DATETIME_FORMAT) : '',
                  from: this.dataRanges[dataIndex as string].from,
                };
                setSelectedKeys([this.dataRanges[dataIndex as string].from, this.dataRanges[dataIndex as string].to]);
              }}
              value={
                this.dataRanges[dataIndex as string].to
                  ? moment(this.dataRanges[dataIndex as string].to, Constants.BACKEND_DATETIME_FORMAT)
                  : null
              }
              allowClear={false}
              placeholder={'Alla data'}
            />
          </div>
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                this.dataRanges[dataIndex as string].from = '';
                this.dataRanges[dataIndex as string].to = '';
                this.handleReset(clearFilters);
                confirm({ closeDropdown: false });
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
            >
              Close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  setDateFilters = (dateFilters: any[] | null, filterName: string) => {
    const updatedFilters = this.state.filters;
    updatedFilters[filterName] = dateFilters;

    this.setState({ filters: updatedFilters });

    this.props.refreshData(updatedFilters, this.state.sorter, this.state.pagination);
  };

  state = {
    filters: {},
    sorter: {},
    pagination: defaultPagination,
    selectedQuestion: undefined,
    selectedQuestions: undefined,
    isQuestionDetailsModalVisible: false,
    isPublishQuestionModalVisible: false,
    isAssignQuestionToContainerModalVisible: false,
    allChannelsInfo: {},

    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        fixed: 'left',
        ...this.getColumnSearchProps('id'),
      },
      {
        title: '€',
        dataIndex: 'typology',
        key: 'typology',
        width: 50,
        filters: [
          { text: 'PAY', value: 'PAY' },
          { text: 'FREE', value: 'FREE' },
        ],
        render: (_: string, question: Question) => (
          <Space size="middle">
            <Tooltip title="Tipo">{question.typology === 'PAY' && <EuroOutlined />}</Tooltip>
          </Space>
        ),
      },
      {
        title: 'Canale di Input',
        dataIndex: 'channel',
        key: 'channel',
        width: 150,
        sorter: true,
        render: (_: string, question: Question) => <span>{question.channelName}</span>,
      },
      {
        title: 'Rubrica',
        dataIndex: 'rubric',
        key: 'rubric',
        width: 130,
        sorter: true,
        render: (_: string, question: Question) => <span>{question.rubricName}</span>,
      },
      {
        title: 'Sotto-rubrica',
        dataIndex: 'subRubric',
        key: 'subRubric',
        width: 130,
        sorter: true,
        filterMode: 'tree',
        render: (_: string, question: Question) => <span>{question.subRubricName}</span>,
      },
      {
        title: 'Stato',
        dataIndex: 'status',
        key: 'status',
        filters: QuestionStatus.toFilterArray(),
        width: 130,
        render: (_: string, record: Question) => (
          <span data-testid="status-column">{QuestionStatus.getStatusDescription(record)}</span>
        ),
      },
      {
        title: 'gg scad.',
        key: 'deadline',
        dataIndex: 'deadline',
        width: 130,
        sorter: true,
        render: (_: string, question: Question) => this.formatDeadline(question),
      },
      {
        title: 'Data di inserimento',
        dataIndex: 'creationDate',
        key: 'creationDate',
        width: 130,
        render: (_: string, question: Question) => <span>{DateUtils.format(question.creationDate)}</span>,
        sorter: true,
        ...this.getColumnSearchDateProps('creationDate'),
      },
      {
        title: 'Titolo',
        dataIndex: 'subject',
        key: 'subject',
        width: 200,
        render: (subject: string) => <ExpandableParagraph text={subject}></ExpandableParagraph>,
      },
      {
        title: 'Quesito',
        dataIndex: 'question',
        key: 'question',
        width: 200,
        render: (question: string) => <ExpandableParagraph text={question}></ExpandableParagraph>,
      },
      {
        title: 'Assegnato a',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        sorter: true,
        width: 200,
        render: (_: string, question: Question) => (
          <p data-testid="current-expert">{question.assignedTo?.displayName}</p>
        ),
        ...this.getColumnSearchProps('assignedTo'),
      },
      {
        title: 'Risposto Da',
        width: 200,
        dataIndex: 'signerUsers',
        key: 'signerUser',
        render: (signerUsers: DisplayUser[]) => this.signerUserComponent(signerUsers),
        ...this.getColumnSearchProps('signerUser'),
      },
      {
        title: 'Data Risposta',
        width: 130,
        dataIndex: 'answerDateTime',
        key: 'answerDateTime',
        render: (answerDateTime: Date) => <span>{DateUtils.format(answerDateTime)}</span>,
        ...this.getColumnSearchDateProps('answerDateTime'),
      },
      {
        title: 'Fascicoli',
        key: 'containers',
        width: 200,
        render: (question: Question) => (
          <ContainersColumn question={question} containerService={this.props.containerService} />
        ),
        ...this.getColumnSearchProps('containerSearch'),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Ricerca fascicoli`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, 'containerSearch')}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, 'containerSearch')}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Cerca
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setSelectedKeys(['N/A']);
                  this.handleSearch(selectedKeys, confirm, 'containerSearch');
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                N/A
              </Button>
              <Button
                onClick={() => {
                  this.handleReset(clearFilters);
                  confirm({ closeDropdown: false });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
              >
                Chiudi
              </Button>
            </Space>
          </div>
        ),
      },
      {
        title: 'Bloccato fino al',
        dataIndex: 'blockedUntil',
        key: 'blockedUntil',
        sorter: true,
        width: 130,
        render: (_: string, question: Question) => (
          <Text type={question.isBlockedForPublication() ? 'danger' : 'secondary'}>
            {DateUtils.formatOnlyDate(question.blockedUntil)}
          </Text>
        ),
        ...this.getColumnSearchDateProps('blockedUntil'),
      },
      {
        title: 'Cliente',
        key: 'endUser',
        width: 150,
        //TODO
        render: (_: string, question: Question) => <span>{'End user example - user@gmail.com ' + question.id}</span>,
      },
      {
        title: (
          <Space>
            <p>Canali pubblicazione</p>{' '}
            <DateFilterSelect setDateFilters={this.setDateFilters} filterName={'publicationDate'} />
          </Space>
        ),
        key: 'outputChannels',
        dataIndex: 'outputChannels',
        width: 200,
        render: (_: string, question: Question) => (
          <span>
            {question.publicationDetails &&
              this.formatPublicationDetails(question.publicationDetails, this.publicationDetailsMapper, question.id)}
          </span>
        ),
      },
      {
        title: '',
        key: 'actions',
        width: 70,
        fixed: 'right',
        render: (_: string, question: Question) => {
          const menuActions = new QuestionsTableMenuActions(
            this.props.questionService,
            this.showPublishQuestionModal,
            this.showAssignQuestionToContainerModal,
            this.showAssignQuestionToExpertModal,
            this.showAssignMultipleQuestionToExpertModal,
            this.showIntegrateAnswerModal,
            [question],
            this.props.currentUser,
          );

          return (
            <Space size="middle">
              <QuestionActions
                questions={[question]}
                currentUser={this.props.currentUser}
                assignedTo={undefined}
                isWorking={false}
                setIsWorking={() => {}}
                actionOnConfirm={this.actionOnConfirm}
                menuActions={menuActions}
                label={<MoreOutlined />}
                allChannelsInfo={this.state.allChannelsInfo}
              />
              {question.commentsCount !== undefined && question.commentsCount > 0 && (
                <>
                  <Tooltip title="Numero di commenti">
                    <Badge
                      data-testid="comment-count"
                      style={{ backgroundColor: 'rgb(95, 162, 225)' }}
                      count={question.commentsCount}
                    >
                      <CommentOutlined style={{ fontSize: '25px' }} />
                    </Badge>
                  </Tooltip>
                </>
              )}
            </Space>
          );
        },
      },
    ],
  } as State;

  components = {
    header: {
      cell: ResizableTableHeader,
    },
  };
  private searchInput: any;
  private dataRanges: Record<string, DataRange> = {
    creationDate: { from: '', to: '' },
    blockedUntil: { from: '', to: '' },
    answerDateTime: { from: '', to: '' },
    deadline: { from: '', to: '' },
  };

  componentDidMount() {
    this.setState({
      checkedQuestions: [],
    });
    this.props.organizationService.getChannels().then((channels: Channel[]) => {
      this.setState({
        columns: this.state.columns.map((column) => {
          if (column.dataIndex === 'channel') {
            column.filters = channels
              .filter((channel) => channel.isInput)
              .map((channel) => ({
                text: channel.channelName,
                value: channel.channelId,
              }));
          }
          if (column.dataIndex === 'outputChannels') {
            column.filters = channels
              .filter((channel) => channel.isOutput)
              .map((channel) => ({
                text: channel.channelName,
                value: channel.channelId,
              }));
          }
          return column;
        }),
      });
      this.setState({
        allChannelsInfo: channels,
      });
    });
    this.props.organizationService.getRubrics().then((response) => {
      this.setState({
        columns: this.state.columns.map((column) => {
          if (column.dataIndex === 'rubric') {
            column.filters = response.map((rubric) => ({
              text: rubric.rubricName,
              value: rubric.rubricId,
            }));
          }
          if (column.dataIndex === 'subRubric') {
            column.filters = response.map((rubric) => ({
              text: rubric.rubricName,
              value: rubric.rubricId,
              children: rubric.subRubrics.map((subRubric) => ({
                text: subRubric.subRubricName,
                value: subRubric.subRubricId,
              })),
            }));
          }
          return column;
        }),
      });
    });
  }

  handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question> | SorterResult<Question>[],
  ) => {
    let singleSorter: SorterResult<Question> = Array.isArray(sorter) ? sorter[0] : sorter;
    singleSorter = singleSorter.order ? singleSorter : {};

    this.setState({ filters: filters, sorter: singleSorter, pagination });

    const realFilter = {
      ...this.props.textSearch,
      ...filters,
      containers: null,
      containerSearch: filters.containers,
    };
    await this.props.refreshData(realFilter, singleSorter, pagination);
  };

  handleResize =
    (index: number) =>
    (e: any, { size }: any) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };

  actionOnConfirm = async (action: () => Promise<void>) => {
    try {
      await action();
      await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
    } catch (e: any) {
      message.error(e.toString(), 5);
    }
  };

  showQuestionDetailsModal = (question: Question) => {
    this.setState({ isQuestionDetailsModalVisible: true, selectedQuestion: question });
  };

  showPublishQuestionModal = (question?: Question) => {
    if (question) {
      this.setState({ isPublishQuestionModalVisible: true, selectedQuestion: question });
    } else {
      this.setState({ isPublishQuestionModalVisible: true, selectedQuestion: undefined });
    }
  };

  showAssignQuestionToContainerModal = (question?: Question) => {
    if (question) {
      this.setState({ isAssignQuestionToContainerModalVisible: true, selectedQuestion: question });
    } else {
      this.setState({ isAssignQuestionToContainerModalVisible: true, selectedQuestion: undefined });
    }
  };

  onQuestionModalOk = async () => {
    this.setState({
      isQuestionDetailsModalVisible: false,
      isInitialQuestionDetailsModalVisible: false,
      selectedQuestion: undefined,
    });
    await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
  };

  onQuestionModalCancel = () => {
    this.setState({
      isQuestionDetailsModalVisible: false,
      isInitialQuestionDetailsModalVisible: false,
      selectedQuestion: undefined,
    });
  };

  onPublishModalModalOk = async () => {
    if (this.state.isQuestionDetailsModalVisible) {
      this.setState({
        isPublishQuestionModalVisible: false,
      });
    } else {
      this.setState({
        isPublishQuestionModalVisible: false,
        selectedQuestion: undefined,
      });
      this.setCheckedQuestions([]);
      await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
    }
  };

  onPublishModalModalCancel = () => {
    this.setState({
      isPublishQuestionModalVisible: false,
      selectedQuestion: this.state.isQuestionDetailsModalVisible ? this.state.selectedQuestion : undefined,
    });
  };

  onAssignQuestionToContainerModalOk = async () => {
    if (this.state.isQuestionDetailsModalVisible) {
      this.setState({
        isAssignQuestionToContainerModalVisible: false,
      });
    } else {
      this.setState({
        isAssignQuestionToContainerModalVisible: false,
        selectedQuestion: undefined,
      });
      this.setCheckedQuestions([]);
      await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
    }
  };

  onAssignQuestionToContainerModalCancel = () => {
    this.setState({
      isAssignQuestionToContainerModalVisible: false,
      selectedQuestion: this.state.isQuestionDetailsModalVisible ? this.state.selectedQuestion : undefined,
    });
  };

  showAssignQuestionToExpertModal = (question?: Question) => {
    this.setState({
      isAssignQuestionToExpertModalVisible: true,
      selectedQuestion: question,
    });
  };

  showAssignMultipleQuestionToExpertModal = (questions?: Question[]) => {
    this.setState({
      isAssignQuestionToExpertModalVisible: true,
      selectedQuestions: questions,
    });
  };

  onAssignQuestionToExpertModalOk = async () => {
    this.setState({
      isAssignQuestionToExpertModalVisible: false,
      selectedQuestion: undefined,
      selectedQuestions: undefined,
    });
    await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
  };

  onAssignQuestionToExpertModalCancel = () => {
    this.setState({
      isAssignQuestionToExpertModalVisible: false,
      selectedQuestion: undefined,
      selectedQuestions: undefined,
    });
  };

  showIntegrateAnswerModal = (question?: Question) => {
    this.setState({
      isIntegrateAnswerModalVisible: true,
      selectedQuestion: question,
    });
  };

  onIntegrateAnswerModalOk = async () => {
    this.setState({ isIntegrateAnswerModalVisible: false, selectedQuestion: undefined });
    await this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
  };

  onIntegrateAnswerModalCancel = () => {
    this.setState({ isIntegrateAnswerModalVisible: false, selectedQuestion: undefined });
  };

  formatPublicationDetails = (list: PublicationDetails[], mapper: (_: any) => string, questionId: string) => {
    return (
      <ul>
        {list.map((item) => (
          <li key={questionId + item.outputChannel}>{mapper(item)}</li>
        ))}
      </ul>
    );
  };

  publicationDetailsMapper(publicationDetails: PublicationDetails) {
    if (publicationDetails.containerName) {
      return `${publicationDetails.containerName} del ${DateUtils.format(publicationDetails.publicationDate)}`;
    } else {
      return `${publicationDetails.outputChannelName} il ${DateUtils.format(publicationDetails.publicationDate)}`;
    }
  }

  render() {
    let columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column: { width: number }) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    let sortedColumns: any = this.props.columnsOrder.map((columnName: string) =>
      columns.find((col) => col.key === columnName),
    );

    columns = sortedColumns.filter((col: any) => {
      if (!col?.key) {
        return true;
      }
      if (this.props.currentUser.isExpert() && col.key === 'container') {
        return false;
      }
      return true;
    });

    const menuActions = new QuestionsTableMenuActions(
      this.props.questionService,
      this.showPublishQuestionModal,
      this.showAssignQuestionToContainerModal,
      this.showAssignQuestionToExpertModal,
      this.showAssignMultipleQuestionToExpertModal,
      this.showIntegrateAnswerModal,
      this.state.checkedQuestions,
      this.props.currentUser,
    );

    return (
      <>
        {this.props.initialQuestionDetails && (
          <QuestionModal
            visible={this.state.isInitialQuestionDetailsModalVisible}
            currentUser={this.props.currentUser}
            onOk={() => {
              this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
              this.onQuestionModalOk();
            }}
            onCancel={() => {
              this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
              this.onQuestionModalCancel();
            }}
            questionId={this.props.initialQuestionDetails}
            userService={this.props.userService}
            questionService={this.props.questionService}
            organizationService={this.props.organizationService}
            showPublishQuestionModal={this.showPublishQuestionModal}
            showAssignQuestionToContainerModal={this.showAssignQuestionToContainerModal}
          />
        )}
        {this.state.selectedQuestion?.id && (
          <QuestionModal
            visible={this.state.isQuestionDetailsModalVisible}
            currentUser={this.props.currentUser}
            onOk={() => {
              this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
              this.onQuestionModalOk();
            }}
            onCancel={() => {
              this.props.refreshData(this.state.filters, this.state.sorter, this.state.pagination);
              this.onQuestionModalCancel();
            }}
            questionId={this.state.selectedQuestion.id}
            userService={this.props.userService}
            questionService={this.props.questionService}
            organizationService={this.props.organizationService}
            showPublishQuestionModal={this.showPublishQuestionModal}
            showAssignQuestionToContainerModal={this.showAssignQuestionToContainerModal}
          />
        )}
        <PublishQuestionModal
          isModalVisible={this.state.isPublishQuestionModalVisible}
          questions={this.state.selectedQuestion ? [this.state.selectedQuestion] : this.state.checkedQuestions}
          onOk={this.onPublishModalModalOk}
          onCancel={this.onPublishModalModalCancel}
          questionService={this.props.questionService}
          organizationService={this.props.organizationService}
          currentUser={this.props.currentUser}
        />
        <AssignQuestionToContainerModal
          isModalVisible={this.state.isAssignQuestionToContainerModalVisible}
          questions={this.state.selectedQuestion ? [this.state.selectedQuestion] : this.state.checkedQuestions}
          onOk={this.onAssignQuestionToContainerModalOk}
          onCancel={this.onAssignQuestionToContainerModalCancel}
          questionService={this.props.questionService}
          containerService={this.props.containerService!}
          currentUser={this.props.currentUser}
          organizationService={this.props.organizationService}
        />
        {this.state.isAssignQuestionToExpertModalVisible && (
          <AssignQuestionToExpertModal
            isModalVisible={this.state.isAssignQuestionToExpertModalVisible}
            question={this.state.selectedQuestion!}
            questions={this.state.selectedQuestions}
            onOk={this.onAssignQuestionToExpertModalOk}
            onCancel={this.onAssignQuestionToExpertModalCancel}
            userService={this.props.userService}
            questionService={this.props.questionService}
          />
        )}
        <IntegrateAnswerModal
          isModalVisible={this.state.isIntegrateAnswerModalVisible}
          question={this.state.selectedQuestion!}
          onOk={this.onIntegrateAnswerModalOk}
          onCancel={this.onIntegrateAnswerModalCancel}
          userService={this.props.userService}
          questionService={this.props.questionService}
        />
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <Space size="middle">
            <QuestionActions
              questions={this.state.checkedQuestions}
              currentUser={this.props.currentUser}
              assignedTo={undefined}
              isWorking={false}
              setIsWorking={() => {}}
              actionOnConfirm={this.actionOnConfirm}
              menuActions={menuActions}
              label={'Azioni multiple'}
              allChannelsInfo={this.state.allChannelsInfo}
            />

            {this.props.canCreateQuestion && (
              <Button type="primary" onClick={() => this.props.navigationService!.goToCreateQuestions()}>
                Crea quesito
              </Button>
            )}
          </Space>
        </div>
        <TableScrollNavigation>
          <Table
            rowKey={(record: Question) => record.id}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: this.state.checkedQuestions.map((question: Question) => question.id),
              onChange: (selectedRowKeys: Key[], selectedRows: Question[]) => {
                this.setCheckedQuestions(selectedRows);
              },
            }}
            sticky={true}
            data-testid="questions-table"
            className="questions-table"
            components={this.components}
            columns={columns as any}
            dataSource={this.props.data}
            onChange={this.handleTableChange}
            onRow={(question, _rowIndex) => {
              return {
                onClick: () => this.showQuestionDetailsModal(question),
              };
            }}
            rowClassName="questions-table-row"
            scroll={{ x: 1300 }}
            pagination={{ ...this.state.pagination, total: this.props.total }}
          />
        </TableScrollNavigation>
      </>
    );
  }

  formatDeadline(question: Question): React.ReactNode {
    if (!question.hasActiveDeadline()) return null;
    let deadline: Date | undefined = question.deadline;

    const deadlineNode = (
      <>
        {DateUtils.format(deadline)}
        <br />
        {moment(deadline).fromNow()}
      </>
    );
    if (moment(deadline).isSame(moment(), 'day')) {
      return <Text type="danger">{deadlineNode}</Text>;
    }
    return <span>{deadlineNode}</span>;
  }

  setCheckedQuestions(selectedRows: Question[]) {
    this.setState({ checkedQuestions: selectedRows });
    this.props.setCheckedQuestions && this.props.setCheckedQuestions(selectedRows);
  }

  handleSearch(selectedKeys: any, confirm: any, _dataIndex: any) {
    confirm();
  }

  handleReset(clearFilters: any) {
    clearFilters();
  }

  signerUserComponent(signerUsers: DisplayUser[]): React.ReactNode {
    return <p>{signerUsers && signerUsers?.map((user) => user?.displayName).join(', ')}</p>;
  }
}

class QuestionsTableMenuActions implements MenuAction {
  constructor(
    private questionService: QuestionService,
    private showPublishQuestionModal: (question?: Question) => void,
    private showAssignQuestionToContainerModal: (question?: Question) => void,
    private showAssignQuestionToExpertModal: (question: Question) => void,
    private showAssignMultipleQuestionToExpertModal: (questions: Question[]) => void,
    private showIntegrateAnswerModal: (question: Question) => void,
    private questions: Question[],
    private currentUser: CurrentUser,
  ) {}
  QUESTION_ASK_VALIDATION = async () => this.questionService.askValidation(this.questions.map((q) => q.id));
  VALIDATE_QUESTION = async () => this.questionService.validateQuestion(this.questions[0].id);
  DISCARD = async () => this.questionService.discardQuestion(this.questions.map((q) => q.id));
  RETURN_TO_ASSIGN_STATUS = async () => this.questionService.returnToAssignStatus(this.questions[0].id);
  REJECT_BY_EXPERT = async () =>
    this.questionService.acceptOrRejectByExpertQuestion(
      this.questions.map((q) => q.id),
      false,
    );
  ACCEPT_BY_EXPERT = async () =>
    this.questionService.acceptOrRejectByExpertQuestion(
      this.questions.map((q) => q.id),
      true,
    );
  PUT_ANSWER_IN_READY = async () => this.questionService.readyQuestion(this.questions.map((q) => q.id));
  REFUSE_ANSWER = async () => this.questionService.refuseAnswer(this.questions.map((q) => q.id));
  ACCEPT_ANSWER = async () => this.questionService.acceptAnswer(this.questions.map((q) => q.id));
  UNLOCK_NOTIFIED_QUESTION = async () => this.questionService.unlockNotifiedQuestion(this.questions.map((q) => q.id));
  NOTIFY_TO_USER = async () => this.questionService.notifyQuestion(this.questions.map((q) => q.id));
  PUBLISH = async () => this.showPublishQuestionModal(this.questions?.length === 1 ? this.questions[0] : undefined);
  ASSIGN_TO_CONTAINER = async () =>
    this.showAssignQuestionToContainerModal(this.questions?.length === 1 ? this.questions[0] : undefined);
  ASSIGN_TO_EXPERT = async () => this.showAssignQuestionToExpertModal(this.questions[0]);
  MULTIPLE_ASSIGN_TO_EXPERT = async () => this.showAssignMultipleQuestionToExpertModal(this.questions);
  ASSIGN_TO_ME = async () => this.questionService.assignQuestionTo(this.questions[0].id, this.currentUser.id);
  INTEGRATE_ANSWER = async () => {
    this.showIntegrateAnswerModal(this.questions[0]);
  };
}

export default QuestionsTable;
