export type AssignQuestionsToContainerResponse =
  | AssignQuestionsToContainerResponseOk
  | AssignQuestionsToContainerResponseError[];

export interface AssignQuestionsToContainerResponseOk {}

export interface AssignQuestionsToContainerResponseError {
  message: string;
}

export function isAssignQuestionsToContainerResponseError(
  object: any,
): object is AssignQuestionsToContainerResponseError[] {
  return object instanceof Array && object.length && object[0].message;
}
