import UserService from '../services/UserService';
import { FilterValue } from 'antd/lib/table/interface';
import { flatMap } from './misc';
import User from '../models/User';

export function translateUsers(users: User[], filter: string[]): string[] {
  const filterUsersByFullName = (substringOfFullName: any) => (user: User) =>
    (user.name + user.surname).toLowerCase().includes(substringOfFullName.toLowerCase().replace(' ', ''));
  const arrayOfArrayOfUsers = filter.map((substringOfFullName) =>
    users.filter(filterUsersByFullName(substringOfFullName)),
  );
  const result = flatMap(arrayOfArrayOfUsers).map((user) => user.id);
  return removeDuplicates(result);
}

export class UserTranslator {
  constructor(private userService: UserService) {}

  private users: User[] = [];

  public async translateUsersFor(
    filters: Record<string, FilterValue | null>,
  ): Promise<Record<string, FilterValue | null>> {
    const fieldsRegardingUser = ['assignedTo', 'signerUser'];

    for (let field of fieldsRegardingUser) {
      if (filters[field]) {
        const userNamesToFind = filters[field] as string[];
        const userEmailFound = translateUsers(await this.findUsers(), userNamesToFind);
        if (userEmailFound.length > 0) {
          filters[field] = userEmailFound;
        }
      }
    }

    return filters;
  }

  private async findUsers(): Promise<User[]> {
    if (this.users.length === 0) {
      this.users = await this.userService.queryUsers();
    }
    return this.users;
  }
}
function removeDuplicates(result: string[]): string[] {
  return result.filter((item, index) => result.indexOf(item) === index);
}
