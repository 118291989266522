import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';
import Amplify, { I18n } from 'aws-amplify';
import vocabularies from './i18n/vocabularies';
import * as config from './env/env.json';
import 'moment/locale/it';

const cfg = (config as any).default;

I18n.putVocabularies(vocabularies);
I18n.setLanguage('it');
Amplify.configure({
  Auth: {
    // https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
    region: cfg.COGNITO_USER_POOL_REGION,
    userPoolId: cfg.COGNITO_USER_POOL_ID,
    userPoolWebClientId: cfg.COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={itIT}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
