import { AnswerReadonly } from '../../../../components/QuestionModal/components/AnswerFormItem';
import { Answer } from '../../../../models/Question';

interface props {
  lastValidatedAnswer: Answer;
}
const ArchivedAnswersFormItem: React.FC<props> = ({ lastValidatedAnswer }) => {
  return (
    <div>
      <AnswerReadonly answer={lastValidatedAnswer} />
    </div>
  );
};

export default ArchivedAnswersFormItem;
