import { Button, message, Popconfirm } from 'antd';
import useSortQuestionsInContainer from '../../../../hooks/useSortQuestionsInContainer';
import { Question } from '../../../../models/Question';
import QuestionsContainerService from '../../../../services/QuestionsContainerService';

interface Props {
  containerId: string;
  questionsContainerService: QuestionsContainerService;
  questions: Question[];
  updateQuestions: (questions: Question[]) => void;
}

const StandardOrderButton: React.FC<Props> = ({
  containerId,
  questionsContainerService,
  questions,
  updateQuestions,
}) => {
  const { reorderQuestionsBasedOnQuestionIds, fromQuestionsToContainerRubrics } = useSortQuestionsInContainer();

  const sortQuestionsWithStandardOrder = async () => {
    try {
      const sortedQuestionIds = await questionsContainerService.sortQuestionsInContainer({
        containerId,
        containerRubrics: fromQuestionsToContainerRubrics(questions),
      });
      const sortedQuestions = reorderQuestionsBasedOnQuestionIds(questions, sortedQuestionIds);
      updateQuestions([...sortedQuestions]);
    } catch (error) {
      console.error(error);
      message.error(`${JSON.stringify(error)}`);
    }
  };

  return (
    <Popconfirm
      title="Sei sicuro di voler cambiare l'ordinamento dei quesiti?"
      onConfirm={sortQuestionsWithStandardOrder}
      okText="Sì"
      cancelText="No"
    >
      <Button style={{ marginRight: '1em' }} type="default">
        Ordinamento Standard
      </Button>
    </Popconfirm>
  );
};
export default StandardOrderButton;
