import { Button, Collapse, message, Popconfirm, Spin, Tag } from 'antd';
import React, { useState } from 'react';
import { Channel } from '../../../models/Channel';
import CreateEditChannelDrawer from './CreateEditChannelDrawer';

import './ChannelsTab.css';
import RubricsTable from './RubricsTable';
import { useEffect } from 'react';
import OrganizationService from '../../../services/OrganizationService';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import CurrentUser from '../../../models/CurrentUser';
import UploadService from '../../../services/UploadService';

const { Panel } = Collapse;
interface props {
  organizationService: OrganizationService;
  uploadService: UploadService;
  currentUser: CurrentUser;
}

const ChannelsTab: React.FC<props> = (props) => {
  const [createChannelDrawerVisible, setCreateChannelDrawerVisible] = useState(false);
  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);

  const deleteChannel = async (channelId: string) => {
    await props.organizationService
      .deleteChannel(channelId)
      .then(() => {
        message.success('Canale eliminato con successo!');
        setRefreshData(true);
      })
      .catch((e) => {
        message.error(`Errore nell'eliminare il canale: ${e}`);
      });
  };

  useEffect(() => {
    const getChannels = async () => {
      setIsLoading(true);
      const channels = await props.organizationService.getChannels();
      setChannels(channels);
      setIsLoading(false);
    };

    if (refreshData) {
      getChannels();

      setRefreshData(false);
    }
  }, [props.organizationService, refreshData]);

  return (
    <div className="ChannelsTab">
      <Button
        type="primary"
        className="create-channel-button"
        onClick={() => {
          setSelectedChannel(undefined);
          return setCreateChannelDrawerVisible(true);
        }}
      >
        Crea Canale
      </Button>
      <br />
      <br />
      <Spin spinning={isLoading} tip="Loading...">
        {channels?.map((channel) => (
          <React.Fragment key={channel.channelId}>
            <Collapse collapsible="header">
              <Panel
                header={
                  <>
                    {channel.channelName}&nbsp;
                    {channel.isInput && (
                      <Tag color={'green'}>
                        <LoginOutlined />
                        &nbsp;Input
                      </Tag>
                    )}
                    {channel.isOutput && (
                      <Tag color={'red'}>
                        <LogoutOutlined />
                        &nbsp;Output
                      </Tag>
                    )}
                  </>
                }
                key="1"
                extra={
                  <>
                    <Button
                      onClick={(e) => {
                        setSelectedChannel(channel);
                        return setCreateChannelDrawerVisible(true);
                      }}
                      disabled={!(props.currentUser.isAdministrator() || props.currentUser.isSuperAdministrator())}
                    >
                      Modifica
                    </Button>
                    <Popconfirm
                      title={'Vuoi eliminare il canale?'}
                      okText="SI"
                      cancelText="NO"
                      placement="top"
                      onConfirm={() => deleteChannel(channel.channelId)}
                      disabled={!props.currentUser.isSuperAdministrator()}
                    >
                      <Button disabled={!props.currentUser.isSuperAdministrator()}>Elimina</Button>
                    </Popconfirm>
                  </>
                }
              >
                <RubricsTable
                  organizationService={props.organizationService}
                  channelId={channel.channelId}
                  refreshData={refreshData}
                />
              </Panel>
            </Collapse>
          </React.Fragment>
        ))}
      </Spin>
      <CreateEditChannelDrawer
        setCreateChannelDrawerVisible={setCreateChannelDrawerVisible}
        createChannelDrawerVisible={createChannelDrawerVisible}
        organizationService={props.organizationService}
        setRefreshData={setRefreshData}
        selectedChannel={selectedChannel}
        uploadService={props.uploadService}
      />
    </div>
  );
};

export default ChannelsTab;
