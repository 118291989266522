// https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/common/Translations.ts
const vocabularyDictionary = {
  it: {
    Loading: 'Caricamento in corso...',
    'Account recovery requires verified contact information': 'Verifica Account',
    'An account with the given email already exists.': 'Un account con questa email esiste già.',
    'Back to Sign In': 'Torna alla Login',
    'Change Password': 'Cambia la password',
    Code: 'Codice',
    Confirm: 'Conferma',
    'Confirm Sign In': 'Conferma di applicazione',
    'Confirm Sign Up': 'Registrazione Conferma',
    'Confirm a Code': 'Codice Conferma',
    'Confirmation Code': 'Codice di verifica',
    'Create Account': 'Crea account',
    'Create a new account': 'Creare un nuovo account',
    'Create account': 'Registrati',
    Email: 'E-mail',
    'Email Address *': 'Email',
    'Password *': 'Password',
    'Enter your email address': "Inserire l'email",
    'Enter your password': 'Inserire la password',
    'Enter your new password': 'Inserire la nuova password',
    'Enter your username': 'Inserisci il tuo nome utente',
    'Forgot your password?': 'Password dimenticata?',
    'Forgot Password': 'Password dimenticata',
    'Have an account?': 'Già registrato?',
    'Incorrect username or password.': 'Nome utente o password errati',
    'Invalid verification code provided, please try again.': 'Codice di verifica non valido, si prega di riprovare.',
    'Invalid password format': 'Formato della password non valido',
    'Invalid phone number format': 'Formato telefono non valido. Formattare un numero nel formato:. 12.345.678,9',
    'Attempt limit exceeded, please try after some time.':
      'Numero massimo di tentativi superato, si prega di riprovare tra poco.',
    'Lost your code?': 'Perso codice?',
    'New Password': 'Nuova password',
    'New password': 'Nuova password',
    'No account?': 'Nessun account?',
    Password: 'Password',
    'Password attempts exceeded': 'Il numero massimo di tentativi di accesso falliti è stato raggiunto',
    'Password did not conform with policy: Password not long enough': 'Password troppo corta',
    'Phone Number': 'Numero di telefono',
    'Resend Code': 'Codice Rispedisci',
    'Reset password': 'Recupero password',
    'Reset your password': 'Recupero password',
    'Send Code': 'Invia codice',
    'Sign In': 'Login',
    'Sign Out': 'Esci',
    'Sign Up': 'Iscriviti',
    'Sign in': 'Login',
    'Sign in to your account': 'Accedi',
    Skip: 'Salta',
    Submit: 'OK',
    'User already exists': 'Questo utente esiste già',
    'User does not exist': 'Questo utente non esiste',
    Username: 'Nome utente',
    'Username cannot be empty': 'Nome utente non può essere vuoto',
    Verify: 'Verifica',
    'Verify Contact': 'Contatto verifica',
    'Family Name': 'Nome',
    'Enter your family name': 'Inserisci il tuo nome',
    'Full Name': 'Cognome',
    'Enter your full name': 'Inserisci il tuo cognome',
    Change: 'Cambia',
    'Verification code': 'Codice di verifica',
    'Enter code': 'Inserire il codice',
  },
};

export default vocabularyDictionary;
