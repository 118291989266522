import { Button, Menu, Popconfirm } from 'antd';

interface Props {
  popconfirmText: string;
  onConfirm: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  buttonIsDisabled: boolean;
  buttonTestId: string;
  buttonText: string;
}

const PopconfirmMenuItem: React.FC<Props> = (props) => {
  return (
    <Menu.Item key={props.buttonTestId}>
      <Popconfirm title={props.popconfirmText} okText="SI" cancelText="NO" placement="top" onConfirm={props.onConfirm}>
        <Button
          type="text"
          style={{ margin: 0, padding: 0, width: '100%', textAlign: 'left' }}
          disabled={props.buttonIsDisabled}
          data-testid={props.buttonTestId}
        >
          {props.buttonText}
        </Button>
      </Popconfirm>
    </Menu.Item>
  );
};
export default PopconfirmMenuItem;
